import React from 'react'
import { observable, toJS } from "mobx"
import { Civilite, Operators, Segments, labelTransac } from '../utils/constants'
import moment from 'moment';
import api from '../api'
import UserStore from './UserStore'
import CardsStore from "./CardsStore";
import { toast } from "react-toastify";
import { number } from "../utils/numeral";

const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours'
    },
    columns: [
        {
            Header: 'Mode',
            accessor: 'ModeTransmission',
            Cell: ({ cell: { value } }) => 
                // console.log(value)
                parseInt(value) ?
                    <span style={{ fontSize: "0.8rem" }} className="text-success font-weight-bold">
                        Online
                    </span>
                    :
                    <span style={{ fontSize: "0.8rem", color: "#229046" }} className="font-weight-bold">
                        Offline
                    </span>


            ,
        },
        {
            Header: 'Date/Heure',
            accessor: 'DateTransaction',
            Cell: ({ cell: { value } }) => {
                // console.log(value)
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY [à] HH:mm:ss')
                else
                    return "-"
            },
        },
        {
            Header: "Numéro Incrément",
            accessor: 'NouveauNumListe',
            field: 'NouveauNumListe',
            isHidden: true
        },
        {
            Header: "Catégorie",
            accessor: 'Label',
            field: 'Label',
            // isHidden: true
        },
        {
            Header: 'Utilisateur',
            accessor: 'NP',
            field: 'NP',
            // isHidden: true
        },
        {
            Header: 'Enseigne',
            accessor: 'NomCommercant',
            field: 'NomCommercant',
        },
        {
            Header: 'N° carte',
            accessor: 'NumCarte',
            field: 'NumCarte',
        },
        {
            Header: "Type transaction",
            accessor: 'TypeTransac',
            field: 'TypeTransac',
            isHidden: true,
            Cell: ({ cell: { value } }) => labelTransac[value]
        },

        {
            Header: 'N° Réseau',
            accessor: 'NumReseau',
            field: 'NumReseau',
            isHidden: true,
        },

        {
            Header: 'N° TPE',
            accessor: 'NumTpe',
            field: 'NumTpe',
            isHidden: true,
        },

        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'creditPme1',
            field: 'creditPme1',
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'bonus1',
            field: 'bonus1',
            isHidden: true,
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'debitPme1',
            field: 'debitPme1',
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'creditPme2',
            field: 'creditPme2',
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'debitPme2',
            field: 'debitPme2',
            Cell: ({ cell: { value } }) => number(value)
        },

        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'SPOT',
            field: 'SPOT',
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'bonus2',
            field: 'bonus2',
            isHidden: true,
            Cell: ({ cell: { value } }) => number(value)
        }

    ],
    pattern: {
        idcommercantFid: {
            active: false,
            title: 'Enseigne(s)',
            name: 'idcommercantFid',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                name: 'idcommercantFid',
                type: 'multiple',
                list: [
                    { id: 1, label: '__--FILTRE NON CHARGE--__' },
                    { id: 2, label: '__--FILTRE NON CHARGE--__' },
                    { id: 3, label: '__--FILTRE NON CHARGE--__' },
                    { id: 4, label: '__--FILTRE NON CHARGE--__' },
                    { id: 5, label: '__--FILTRE NON CHARGE--__' },
                    { id: 6, label: '__--FILTRE NON CHARGE--__' },
                ],
            },
            value: [{
                name: 'idcommercantFid',
                field: 'idcommercantFid',
                campField: 'c.idcommercantFid',
                operator: 'in',
                type: 'text',
                value: []
            }],
            defaultValue: {
                name: 'idcommercantFid',
                field: 'idcommercantFid',
                campField: 'c.idcommercantFid',
                type: 'array',
                operator: 'in',
                value: []
            },
        },
        NumCarte: {
            active: false,
            quickSearch: true,
            title: 'N° de carte',
            name: 'NumCarte',
            operator: {
                type: 'select',
                list: ['=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'NumCarte',
                type: 'text'
            },
            value: [{
                name: 'NumCarte',
                field: 'NumCarte',
                campField: 'NumCarte',
                operator: '=',
                type: 'text',
                value: ''
            }],
            defaultValue: {
                name: 'NumCarte',
                field: 'NumCarte',
                campField: 'NumCarte',
                operator: 'like',
                type: 'text',
                value: ''
            }
        },
        NP: {
            active: false,
            quickSearch: true,
            title: 'Nom/Prénom',
            name: 'NP',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'NP',
                type: 'text'
            },
            value: [{
                name: 'NP',
                field: 'NP',
                campField: 'NP',
                operator: 'like',
                type: 'text',
                value: ''
            }],
            defaultValue: {
                name: 'NP',
                field: 'NP',
                campField: 'NP',
                operator: 'like',
                type: 'text',
                value: ''
            }
        },
        DateTransaction: {
            active: true,
            title: 'Date de transaction',
            canAddOr: false,
            name: 'DateTransaction',
            operator: {
                type: 'select',
                list: ['between'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'DateTransaction',
                type: 'date'
            },
            value: [{
                name: 'DateTransaction',
                field: "DATE(DateTransaction)",
                type: 'date',
                // custom: true,
                operator: 'between',
                value: [moment().subtract('1', 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
            }],
            defaultValue: {
                name: 'DateTransaction',
                field: "DATE(DateTransaction)",
                type: 'date',
                // custom: true,
                operator: 'between',
                value: [moment().subtract('1', 'months').format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')]
            }
        },
    },
    data: [],
    pageStart: 0,
    pageIndex: 0,
    pageLimit: 30,
    pageTotal: 0,
    pageCount: 0,
    total_credit_pme1: 0,
    total_debit_pme1: 0,
    total_credit_pme2: 0,
    total_debit_pme2: 0,
    total_gain: null,
    countActiveFilter: 0,
    savedPattern: {},
    savedFilters: [],
    loadedFilter: null,
    isOpenModalSave: false
}


const TransacFilterStore = {

    props: props,
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    async init(pattern) {
        this.reset()
        this.doLoading(true, "Chargement en cours ...")
        if (pattern)
            this.props.pattern = pattern
        console.log('initfilter')
        await this.initColumnsTransacs()
        if (UserStore.isReseau()) {
            await this.getEnseignes()
        }
        else {
            delete this.props.pattern.idcommercantFid
        }


        await this.getTransacs()
        // await this.getSavedFilters()

        Object.keys(this.props.pattern)
            .map(item => {
                if (UserStore.isWallet()) {
                    if (this.props.pattern[item].name == 'clientNumCard') {
                        this.props.pattern[item].title = 'N° Client'
                    }
                }
                this.props.pattern[item].title = this.props.pattern[item].title.replace('$$point$$', UserStore.props.user.unitesPME1)
                this.props.pattern[item].title = this.props.pattern[item].title.replace('$$euro$$', UserStore.props.user.unitesPME2)
            }
            )
        // await CardsStore.getClients()
        this.props.savedPattern = toJS(this.props.pattern)

        this.doLoading(false)

    },
    async initColumnsTransacs() {
        return new Promise(resolve => {
            this.props.columns = this.props.columns.map(item => {
                if (UserStore.isWallet() || UserStore.isNovacash()) {

                    if (
                        item.accessor == 'creditPme1'
                        || item.accessor == 'debitPme1'
                        || item.accessor == 'bonus1'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true
                    }

                }

                if (UserStore.isFidSimple()) {

                    if (
                        item.accessor == 'creditPme2'
                        || item.accessor == 'debitPme2'
                        || item.accessor == 'bonus2'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true
                    }

                }

                return item

            })

            resolve(true)
        })
    },
    async getTransacs() {

        this.props.sortBy = this.props.sortBy || 'DateTransaction'
        this.props.sortOrder = this.props.sortOrder || 'DESC'
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let where = ''

        if (UserStore.isReseau())
            where = this.getWhere('iDreseauFid = ' + idRes +
                ' AND TypeTransac not in (6,7) ')
        else
            where = this.getWhere('iDreseauFid = ' + idRes + ' AND idcommercantFid = ' + idCom +
                ' AND TypeTransac not in (6,7) ')

        // let where = this.getWhere(mainWhere)
        let data = {
            'objName': 'listeTransac',
            'cmd': 'getRowsP',
            'where': where,
            'start': this.props.pageStart,
            'limit': this.props.pageLimit,
            'sort': this.props.sortBy,
            'dir': this.props.sortOrder,
            // 'query': JSON.stringify(where)
        }

        const res = await api.post('/scripts/transac/transac.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.data = !res.data.rows ? [] : res.data.rows
                this.props.pageTotal = res.data.totalCount
                this.props.pageCount = Math.ceil(res.data.totalCount / this.props.pageLimit)

                if (res.data.totSolde.length) {
                    this.props.total_credit_pme1 = res.data.totSolde[0].c1.length ? res.data.totSolde[0].c1 : 0
                    this.props.total_debit_pme1 = res.data.totSolde[0].d1.length ? res.data.totSolde[0].d1 : 0
                    this.props.total_credit_pme2 = res.data.totSolde[0].c2.length ? res.data.totSolde[0].c2 : 0
                    this.props.total_debit_pme2 = res.data.totSolde[0].d2.length ? res.data.totSolde[0].d2 : 0
                    this.props.total_gain = res.data.totSolde[0].Gain.length ? res.data.totSolde[0].Gain : 0
                }

            } else {
                this.props.data = []
            }
        }
        // this.props.loading.status = false
    },
    async getFilteredTransacs() {
        this.doLoading(true, 'Chargement en cours ...')
        await this.getTransacs()
        this.doLoading(false)
    },
    getQuickSerachItems() {
        let results = []
        Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].quickSearch)
            .map(item => {
                if (UserStore.isWallet()) {
                    if (this.props.pattern[item].name == 'clientNumCard') {
                        this.props.pattern[item].title = 'N° Client'
                    }
                }

                results.push({
                    id: this.props.pattern[item].name, label: this.props.pattern[item].title
                })
            })

        return results
    },
    async nextPage() {
        this.props.pageIndex = this.props.pageIndex + 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getTransacs()
        this.doLoading(false)
    },
    async prevPage() {
        this.props.pageIndex = this.props.pageIndex - 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getTransacs()
        this.doLoading(false)
    },
    async goToPage(page) {
        this.props.pageIndex = page
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getTransacs()
        this.doLoading(false)
    },
    toggleItemFilter(key) {
        if (!this.props.pattern[key].isNotToggable)
            this.props.pattern[key].active = !this.props.pattern[key].active
    },
    activateItemFilter(key) {
        if (!this.props.pattern[key].isNotToggable)
            this.props.pattern[key].active = true
    },
    changeOperator(key, val, index) {
        console.log(val)
        this.props.pattern[key].value[index].operator = val
        if (val == 'between') {
            this.props.pattern[key].value[index].value = [null, null]
        } else {
            this.props.pattern[key].value[index].value = ''
        }

    },
    changeValue(key, val, index, order) {
        if (this.props.pattern[key].value[index].operator == 'between') {
            this.props.pattern[key].value[index].value[order] = val
        }
        else if (this.props.pattern[key].value[index].operator == 'in') {
            if (val != 'all') {
                let position = this.props.pattern[key].value[index].value.findIndex(o => o == val);
                console.log('position :', position)
                if (position === -1)
                    this.props.pattern[key].value[index].value = [...this.props.pattern[key].value[index].value, val]
                else
                    this.props.pattern[key].value[index].value = [...this.props.pattern[key].value[index].value.filter(item => item != val)]
            } else {
                if (this.props.pattern[key].field.list.length != this.props.pattern[key].value[index].value.length) {
                    this.props.pattern[key].value[index].value = [...this.props.pattern[key].field.list.map(item => item.id)]
                } else {
                    this.props.pattern[key].value[index].value = []
                }
            }
        }
        else {
            this.props.pattern[key].value[index].value = val
        }
        console.log(toJS(this.props.pattern[key].value[index].value))
    },
    addItemFilter(key, type) {
        let newVal = { ...this.props.pattern[key].defaultValue }
        newVal.logic = type
        this.props.pattern[key].value = [...this.props.pattern[key].value, newVal]
    },
    removeItemFilter(key, idx) {
        this.props.pattern[key].value = [...this.props.pattern[key].value.filter((item, index) => index != idx)]
    },
    submitFilter() {
        this.props.savedPattern = toJS(this.props.pattern)
        this.getCountActiveFilter()
    },
    setUnitsHeader(colId) {

        if (colId == 'Pme1')
            return 'Solde (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'Pme2')
            return 'Solde (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumDebPme2')
            return 'Debits (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumRech2')
            return 'Cumul Recharge (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumCredPme1')
            return 'Crédits (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'cumCredPme2')
            return 'Crédits (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumRech1')
            return 'Cumul Recharge (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'cumSpot')
            return 'Gains (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumBonus2')
            return 'Bonus (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumBonus1')
            return 'Bonus (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'bonus1')
            return 'Bonus (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'bonus2')
            return 'Bonus (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumDebPme1')
            return 'Debits (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'SPOT')
            return 'Gains (' + UserStore.props.user.unitesPME2court + ')'
        //-----//
        if (colId == 'NouveauPME1')
            return 'Solde (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'NouveauPME2')
            return 'Solde (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'creditPme1')
            return 'Crédit (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'debitPme1')
            return 'Débit (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'creditPme2')
            return 'Crédit (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'debitPme2')
            return 'Débit (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'Expiration_Pme1')
            return 'Expiration (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'Expiration_Pme2')
            return 'Expiration (' + UserStore.props.user.unitesPME2court + ')'

        return 'Vide'

    },
    resetFilter() {
        Object.keys(this.props.pattern)
            .map(item => {
                this.props.pattern[item].value = props.pattern[item].value
                this.props.savedPattern[item].value = props.pattern[item].value
                this.props.pattern[item].active = false
                this.props.savedPattern[item].active = false
            })

        this.activateItemFilter("DateTransaction")
    },
    undoPattern() {
        this.props.pattern = toJS(this.props.savedPattern)
    },
    getWhere(where = '') {

        const main = [...Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].active)
            .map(item => toJS(this.props.pattern[item].value))]

        console.log(main)
        for (let key in main) {
            if (main[key].isHidden)
                continue
            if (where.length) {
                if (main[key].length)
                    where += ' AND ( '
            }
            else
                where += '( '
            let pass = 0
            for (let id in main[key]) {
                if (pass)
                    where += " " + main[key][id].logic + " "
                pass = 1


                where += this.getClause(main[key][id], main[key][id].custom)


            }

            if (where.length) {
                if (main[key].length)
                    where += ')'
            }
        }

        return where

    },
    getClause(clause, isCustom = false) {
        let where = '-+--+-+ ERR -++-+-+'
        if (clause.operator == 'in') {
            where = clause.field + " IN (" + clause.value.join(',') + ") "
            console.log(where)
        }
        else if (clause.operator == 'between') {
            if (clause.type == 'date') {
                where = clause.field + " BETWEEN '" + clause.value[0] + "' AND '" + clause.value[1] + "' "
            }
            else if (clause.type == 'number') {
                where = clause.field + " BETWEEN " + clause.value[0] + " AND " + clause.value[1] + " "
            } else {
                where = '__ERROR__1'
            }
        }
        else if (clause.operator == 'like' || clause.operator == 'not like') {
            if (clause.type == 'text') {
                where = clause.field + " " + clause.operator + " '%" + clause.value + "%' "
            }
            else {
                where = '__ERROR__2'
            }
        }
        else if (
            clause.operator == '>' ||
            clause.operator == '<' ||
            clause.operator == '>=' ||
            clause.operator == '<=' ||
            clause.operator == '<>' ||
            clause.operator == '='
        ) {

            if (clause.type == 'date' || clause.type == 'text') {
                where = clause.field + " " + clause.operator + " '" + clause.value + "' "
            }
            else if (clause.type == 'number') {
                where = clause.field + " " + clause.operator + " " + clause.value + " "
            }
            else {
                where = '__ERROR__3'
            }
        }
        else {
            where = '__ERROR__4'
        }
        if (isCustom)
            return clause.field.replace('_$$$_', where.replace(clause.field, ''))
        return where
    },
    async getEnseignes() {

        const idRes = UserStore.props.user.idRes
        let data = {
            sort: 'NomCommercant',
            dir: 'DESC',
            cmd: 'getRows',
            objName: 'listeCommercants',
            where: 'iDreseauFid=' + idRes
        }

        const res = await api.post('/scripts/commerce/commercant.php', data)
        if (res.data.success) {
            const data = !res.data.rows ? [] : res.data.rows
            this.props.pattern.idcommercantFid.field.list = data.map((item) => ({ id: item.idcommercantFid, label: item.NomCommercant }))

            if (!this.props.pattern.idcommercantFid.value.length)
                this.props.pattern.idcommercantFid.value.forEach((item) => {
                    item.value = data.map((item) => item.idcommercantFid)
                })

        }

    },
    getCountActiveFilter() {
        this.props.countActiveFilter = Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].active).length
    },
    //FILTER ACTIONS
    getCurrentFilterJson() {
        let filter = {}

        Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].active)
            .map(item => { filter[item] = this.props.pattern[item].value })

        return toJS(filter)
    },
    reset() {
        this.props = props
    }
}


export default observable(TransacFilterStore)