import React,{useEffect, useState} from 'react'
import Logo from '../../assets/img/logo.png'
import UserStore from '../../stores/UserStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { observer } from "mobx-react"
import { Redirect,useLocation, Link } from 'react-router-dom'
import BlockUi from 'react-block-ui';
import Schema from 'validate'
import { mapValues, keyBy } from 'lodash';
import { useFormik } from 'formik';
import classNames from 'classnames'
import MainStore from '../../stores/MainStore';
import Loading from '../Loading/Loading';
import parse from 'html-react-parser';
import myYup from '../../utils/myYup';
import backbtn from '../../assets/img/backblue.png'


//Form Field
const MyField = ({ input, type, name, className, placeholder, onChange, value, touched, error, highlight }) => {
    // console.log(input)
    return (
        <>
            <input
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                className={className + classNames({ " is-invalid": (touched && error || highlight) })}
                type={type} />

        </>
    )
}


const ForgotView = () => {
    const location=useLocation();

    const [dataparam,setDataparam]=useState({
        token:'',
        selector:''
    });
    useEffect(()=>{
        async function canchange(param){
            return await UserStore.forgotVerifLink(param)
        }
        if(location.search=='')
            UserStore.setAction('forgot')
        else{
            UserStore.setAction('verify')
            const param=new URLSearchParams(location.search)
            setDataparam({...dataparam,
                token:param.get("token"),
                selector:param.get("selector")
            })
            console.log(param);
            canchange({
                token:param.get('token'),
                selector:param.get('selector')
            })
        }
    },[location])


    const verify = async (creds) => {
        Object.assign(creds,dataparam)
        console.log(creds)
        
        UserStore.props.loading.status = true
        await UserStore.submitResetPassword(creds)
        UserStore.props.loading.status = false
    }

    const forgotPassword = async (creds) => {
        // console.log(creds)
        UserStore.props.loading.status = true
        await UserStore.forgot(creds)
        UserStore.props.loading.status = false
    }

        const formNewPass = myYup.object().shape({
            new_password_1:myYup
            .string()
            .required("ce champs est requis")
            .min(10,'le mot de passe doit avoir au moins 10 caracteres.')
            .hasNumber("le mot de passe doit avoir au moins un chiffre")
            .hasMaJ('le mot de passe doit avoir au moins une lettre Majuscule')
            .hasSpecial('le mot de passe doit avoir au moins un caractere special. exemple:!\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~'),
            new_password_2:myYup
            .string()
            .required("ce champs est requis")
            .min(10,'le mot de passe doit avoir au moins 10 caracteres.')
            .hasNumber("le mot de passe doit avoir au moins un chiffre")
            .hasMaJ('le mot de passe doit avoir au moins une lettre Majuscule')
            .hasSpecial('le mot de passe doit avoir au moins un caractere special. exemple:!\"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~')
            .equals([myYup.ref('new_password_1')],"Les 2 mots de passe saisis sont différents"),
            // token:myYup.string().required("Le token est obligatoire"),
            // selector:myYup.string().required("Le token est obligatoire")
                
        })


    const checkForgot = (values) => {

        // console.log('rerer')

        const form = new Schema({
            username: {
                type: String,
                required: true,
                message: {
                    required: 'Ce champs est requis',
                }
            }
        })

        const errors = form.validate(values)
        // console.log(errors)
        return mapValues(keyBy(errors, 'path'), 'message')
    }


    const formik = useFormik({
        initialValues: {
            new_password_2: '',
            new_password_1: ''
            // token:dataparam.token,
            // selector:dataparam.selector
            
        },
        onSubmit: verify,
        validationSchema: formNewPass
    })

    const forgot = useFormik({
        initialValues: {
            username: '',
        },
        onSubmit: forgotPassword,
        validate: checkForgot
    })

    if (UserStore.props.user.isAuthenticated) {
        let to = null
        if (!UserStore.canDashboard() || UserStore.isSaisie()) {
            to = {
                pathname: '/cards',
                state: 'Liste des clients'
            }
        }
        else {
            to = {
                pathname: '/dashboard',
                state: 'Tableau de bord'
            }
        }

        // to = {
        //     pathname: '/geolocation',
        //     state: 'CUSTOM'
        // }

        return (
            <Redirect to={to}></Redirect>
        )
    }

    return (

        <div className="app flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <BlockUi tag="div" className="card-group rounded login-loader" loader={Loading}
                            message={UserStore.props.loading.msg}
                            blocking={UserStore.props.loading.status}>
                            <div className="card p-4">
                                <div className='row'>
                                    <div className="col text-left">
                                        <Link to="/login" className="px-4 d-flex"><img src={backbtn}/>Se connecter</Link>
                                    </div>
                                </div>
                        
                                {
                                    UserStore.props.action == 'verify' &&
                                    < div className="card-body">
                                        <h1>Creer un nouveau mot de passe</h1>
                                        {UserStore.props.fail.status && <div className="invalid-feedback d-inline-block text-center">Identifiants incorrects</div>}
                                        <form onSubmit={formik.handleSubmit} method="post">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend"><span className="input-group-text"><i className="icon-lock"></i></span></div>
                                                <MyField name="new_password_1" type="password" className="form-control" placeholder="mot de passe"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.new_password_1}
                                                    error={formik.errors.new_password_1}
                                                    highlight={UserStore.props.fail.status}
                                                    touched={formik.touched.new_password_1}>
                                                </MyField>
                                                {formik.touched.new_password_1 && (formik.errors.new_password_1 && <div className="invalid-feedback">{formik.errors.new_password_1}</div>)}
                                            </div>
                                            <div className="input-group mb-4">
                                                <div className="input-group-prepend"><span className="input-group-text"><i className="icon-lock"></i></span></div>
                                                <MyField name="new_password_2" type="password" className="form-control" placeholder="Confirmation mot de passe"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.new_password_2}
                                                    error={formik.errors.new_password_2}
                                                    highlight={UserStore.props.fail.status}
                                                    touched={formik.touched.new_password_2}>
                                                </MyField>
                                                {formik.touched.new_password_2 && (formik.errors.new_password_2 && <div className="invalid-feedback">{formik.errors.new_password_2}</div>)}
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button type="submit" className="btn btn-old px-4">Valider</button>
                                                </div>
                                                
                                            </div>
                                        </form>
                                    </div>
                                }
                                {
                                    UserStore.props.action == 'forgot' &&
                                    < div className="card-body">
                                        <h2>Réinitialisation de mot de passe</h2>
                                        <p className="text-muted">Veuillez saisir l'email de votre compte afin de recevoir le lien de réinitialisation de votre mot de passe.</p>
                                        <form onSubmit={forgot.handleSubmit} method="post">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend"><span className="input-group-text"><i className="icon-user"></i></span></div>
                                                <MyField name="username" type="text" className="form-control" placeholder="Saisissez votre adresse e-mail"
                                                    onChange={forgot.handleChange}
                                                    value={forgot.values.username}
                                                    error={forgot.errors.username}
                                                    touched={forgot.touched.username}>
                                                </MyField>
                                                {forgot.touched.username && (forgot.errors.username && <div className="invalid-feedback">{forgot.errors.username}</div>)}
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button type="submit" className="btn btn-old px-4">Valider</button>
                                                </div>
                                                
                                            </div>
                                        </form>
                                    </div>
                                }
                                {
                                    UserStore.props.action == 'successForgot' &&
                                    < div className="card-body">
                                        <h2>Mot de passe oublié</h2>
                                        <div class={classNames("alert",{'alert-success' : UserStore.props.forgot.success,'alert-danger' : !UserStore.props.forgot.success})} role="alert">
                                            {parse(UserStore.props.forgot.error)}
                                            </div>
                                        

                                    </div>
                                }
                                {
                                    UserStore.props.action=='successChange' &&
                                    < div className="card-body">
                                        <h2>Mot de passe oublié</h2>
                                        <div class={classNames("alert",{'alert-success' : UserStore.props.forgot.success,'alert-danger' : !UserStore.props.forgot.success})} role="alert">
                                            {parse(UserStore.props.forgot.error)}
                                            </div>
                                        

                                    </div>
                                }
                            </div>
                            <div className="card py-5 d-md-down-none">
                                <div className="card-body text-center">
                                    <div>
                                        <img className="login-image" src={Logo} alt="Fidlink Logo" />
                                    </div>
                                    <strong>NOVACARD devient <span className="text-fid">FIDLINK</span></strong>
                                </div>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div >
        </div >

    )
}




export default observer(ForgotView)