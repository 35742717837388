import React, { useEffect, useState } from 'react'
import { withRouter, Redirect } from 'react-router-dom'
import UserStore from '../../stores/UserStore'
import { observer } from 'mobx-react'
import MainStore from '../../stores/MainStore'

const IsLogged = (props) => {

    const { location } = props

    const selectedLocation=(loc)=>{
        return loc;
    }
    
    var [slocation,setSlocation]=useState(selectedLocation(location));
    useEffect(() => {
        async function init(){console.log('INIT')
        console.log(location)
        await UserStore.init()
    }
    init()
    
    }, [])

    

    useEffect(() => {

        MainStore.setCurrentRoute(location.state)
    }, [location.state])

    useEffect(() => {
        console.log("patheffect:",location.pathname)
        console.log("user:",UserStore.props.user.isPreAuthenticated)
    }, [])
    

    if (UserStore.props.user.isAuthenticated){
        
        return (
            <>
                {/* {console.log('Is Auth', location)} */}
                {/* {} */}
                {props.children}
            </>
        )
    }
    
    // if(UserStore.props.user.isPreAuthenticated){
    //     UserStore.logout(() =>  props.history.push('/login'))
    // }
        
    if (location.pathname != '/logout'
    &&location.pathname != '/login'
    &&location.pathname != '/forgot'
    &&location.pathname != '/setup_2fa'
    &&location.pathname != '/verify'){
            // props.history.push('/login')
            if(localStorage.getItem('two_factors_method'))
                UserStore.logout()
            return (
                <>
                    {/* {console.log('Not Auth ', location)} */}
                    <Redirect to="/login"></Redirect>
                </>
            )
        }


        if (!UserStore.props.user.isAuthenticated){
            
            return (
                <>
                    {/* {console.log('Not Auth ', location)} */}
                    {props.children}
                </>
            )
        }
    
    


}

export default withRouter(observer(IsLogged))