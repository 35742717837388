import moment from "moment"
import { number } from "./numeral"

export const generateDatasetsDouble = (data, legend) => {

    let result = []
    let colors = ['#46546c', '#ff9900']

    for (let category in data) {
        if (!data[category])
            continue
        let datasets = []
        let labels = []

        for (let i = 0; i < data[category].periods[0].length; i++) {

            labels.push([
                moment(data[category].periods[0][i]['x']).format('DD MMM YYYY'),
                moment(data[category].periods[1][i]['x']).format('DD MMM YYYY')
            ])
        }

        for (let period in data[category].periods) {
            let coords = []
            let labels = []
            for (let i = 0; i < data[category].periods[period].length; i++) {
                coords.push(parseFloat(data[category].periods[period][i]['y']))
            }
            datasets.push({
                label: 'Période du ' + legend[period][0] + ' au ' + legend[period][1],
                backgroundColor: colors[period],
                borderColor: colors[period],
                borderWidth: 2,
                bezierCurve: false,
                fill: false,
                hoverBackgroundColor: '#DD6B20',
                hoverBorderColor: '#ED8936',
                data: coords
            })
        }
        result.push({ key: data[category].key, data: { labels: labels, datasets: datasets }, ...data[category] })
        // console.log(result)
    }

    return result

}

export const generateDatasetsSimple = (data, legend, unites, groupBy) => {

    console.log(data.length)
    console.log('test')

    let result = []
    let colors = ['#46546c', '#ff9900']


    let datasets = []
    let labels = []
    let coords = []

    if (groupBy == 'day') {
        for (let i = 0; i < data.length; i++) {
            labels.push([
                moment(data[i]['x']).utc().format('DD MMM YYYY'),
            ])
        }
    }
    if (groupBy == 'month') {
        for (let i = 0; i < data.length; i++) {
            labels.push([
                moment(data[i]['x']).utc().format('MMM YYYY'),
            ])
        }
    }
    if (groupBy == 'year') {
        for (let i = 0; i < data.length; i++) {
            labels.push([
                moment(data[i]['x']).utc().format('YYYY'),
            ])
        }
    }

    for (let i = 0; i < data.length; i++) {
        coords.push(parseFloat(data[i]['y']))
    }


    datasets.push({
        label: legend,
        unites: unites,
        backgroundColor: colors[0],
        borderColor: colors[0],
        borderWidth: 2,
        bezierCurve: false,
        fill: false,
        hoverBackgroundColor: '#DD6B20',
        hoverBorderColor: '#ED8936',
        data: coords
    })

    // result.push({ key: data[category].key, data: { labels: labels, datasets: datasets }, ...data[category] })
    // console.log(result)


    return { labels: labels, datasets: datasets }

}

const dynamicColors = (index) => {

    let colors = [
        //Diff
        '#2f4b7c',
        '#665191',
        '#a05195',
        '#d45087',
        '#f95d6a',
        '#ff7c43',
        '#ffa600',
        //Diff
        '#00876c',
        '#63b179',
        '#aed987',
        '#ffff9d',
        '#fcc267',
        '#ef8250',
        '#d43d51',
        '#003f5c',

    ]

    if (index < colors.length)
        return colors[index]

    var r = Math.floor(Math.random() * 255);
    var g = Math.floor(Math.random() * 255);
    var b = Math.floor(Math.random() * 255);
    return "rgb(" + r + "," + g + "," + b + ")";
}

export const generateDatasetsMultiple = (data, labels, unites, groupBy) => {

    // console.log(labels)
    console.log('test')

    let result = []
    let labs = []



    let datasets = []
    let keys = Object.keys(data)

    for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        let subData = data[key];
        console.log(key)
        let coords = []

        for (let i = 0; i < subData.length; i++) {
            coords.push({ x: moment(subData[i]['x']).utc().toDate(), y: parseFloat(subData[i]['y']) })
        }

        let color = dynamicColors(i)
        datasets.push({
            label: key,
            unites: unites,
            backgroundColor: color,
            borderColor: color,
            borderWidth: 2,
            bezierCurve: false,
            fill: false,
            hoverBackgroundColor: '#DD6B20',
            hoverBorderColor: '#ED8936',
            data: coords
        })
    }

    // if (groupBy == 'day') {
    //     for (let i = 0; i < labels.length; i++) {
    //         labs.push([
    //             moment(labels[i]).utc().format('DD MMM YYYY'),
    //         ])
    //     }
    // }
    // if (groupBy == 'month') {
    //     for (let i = 0; i < labels.length; i++) {
    //         labs.push([
    //             moment(labels[i]).utc().format('MMM YYYY'),
    //         ])
    //     }
    // }
    // if (groupBy == 'year') {
    //     for (let i = 0; i < labels.length; i++) {
    //         labs.push([
    //             moment(labels[i]).utc().format('YYYY'),
    //         ])
    //     }
    // }

    // result.push({ key: data[category].key, data: { labels: labels, datasets: datasets }, ...data[category] })
    // console.log(result)


    return { labels: labs, datasets: datasets }

}

export const generateOptionsDouble = (charts, group_by) => {
    let options = {}

    for (let key in charts) {
        console.log(key)
        options[key] = {
            responsive: true,
            // bezierCurve: true,
            maintainAspectRatio: false,
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 25,
                    bottom: 0
                }
            },
            plugins: {
                datalabels: {
                    display: true,
                    align: 'top',
                    backgroundColor: function (context) {
                        return context.dataset.backgroundColor;
                    },
                    borderRadius: 4,
                    color: 'white',
                    font: {
                        weight: 'bold'
                    },
                    // formatter: Math.round
                }
            },
            legend: {
                display: true,
                position: 'bottom',
                labels: {
                    usePointStyle: true,
                }
            },
            scales: {
                xAxes: [
                    {
                        id: "axis_1",
                        display: true,
                        gridLines: {
                            display: false,
                        },
                        // type: 'time',
                        // time: {

                        // }
                    },
                ],
                yAxes: [{
                    display: true,
                    // ticks: {
                    //     // Include a dollar sign in the ticks
                    //     callback: function (value, index, values) {
                    //         return '$' + value;
                    //     }
                    // },
                    scaleLabel: {
                        display: true,
                        labelString: charts[key].y_label,
                        fontSize: 14
                    },
                    gridLines: {
                        display: true,
                    },

                }]

            },
            elements: {
                point: {
                    radius: 3,
                    hitRadius: 10,
                    hoverRadius: 4,
                    hoverBorderWidth: 3
                },

                line: {
                    // tension: 0
                }

            },
            tooltips: {
                mode: 'index',
                intersect: false
            },
            hover: {
                mode: 'nearest',
                intersect: true
            },
            showAllTooltips: true
        }

        let time = {}
        if (group_by == 'jour') {
            time = {
                tooltipFormat: 'DD MMM YYYY',
                unit: 'day',
                displayFormats: {
                    day: 'DD MMM'
                },
                parser: "YYYY-MM-DD"
            }
        }
        if (group_by == 'mois') {
            time = {
                tooltipFormat: 'MMM YYYY',
                unit: 'month',
                displayFormats: {
                    month: 'MMM YYYY'
                },
                parser: "YYYY-MM-DD"
            }
        }
        if (group_by == 'annee') {
            time = {
                tooltipFormat: 'YYYY',
                unit: 'year',
                displayFormats: {
                    year: 'YYYY'
                },
                parser: "YYYY"
            }
        }
    }


    // options.scales.xAxes[0].time = time
    // options.scales.xAxes[1].time = time
    // console.log(options)
    return options
}

export const generateOptionsSimple = (y_label) => {
    return {
        responsive: true,
        // bezierCurve: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 25,
                bottom: 0
            }
        },
        plugins: {
            datalabels: {
                display: true,
                align: 'top',
                backgroundColor: function (context) {
                    return context.dataset.backgroundColor;
                },
                borderRadius: 4,
                color: 'white',
                font: {
                    weight: 'bold',
                    size: '11'

                },
                formatter: function (value, context) {
                    return number(value)
                }
            }
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
            }
        },
        scales: {
            xAxes: [
                {
                    id: "axis_1",
                    display: true,
                    gridLines: {
                        display: false,
                    },
                    // type: 'time',
                    // time: {

                    // }
                },
            ],
            yAxes: [{
                display: true,
                // ticks: {
                //     // Include a dollar sign in the ticks
                //     callback: function (value, index, values) {
                //         return '$' + value;
                //     }
                // },
                scaleLabel: {
                    display: false,
                    labelString: y_label,
                    fontSize: 14
                },
                gridLines: {
                    display: true,
                },

            }]

        },
        elements: {
            point: {
                radius: 3,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3
            },

            line: {
                // tension: 0
            }

        },
        tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
                label: function (tooltipItem, data) {
                    var label = data.datasets[tooltipItem.datasetIndex].label || '';
                    var unites = data.datasets[tooltipItem.datasetIndex].unites || '';

                    if (label) {
                        label += ': ';
                    }
                    label += number(tooltipItem.yLabel)

                    if (unites) {
                        label += ' ' + unites;
                    }

                    return label;
                }
            }
        },
        hover: {
            mode: 'nearest',
            intersect: true
        },
        showAllTooltips: true
    }

}

export const generateOptionsMultiple = (groupBy) => {
    return {
        responsive: true,
        // bezierCurve: true,
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 25,
                bottom: 0
            }
        },
        plugins: {
            datalabels: {
                display: false,
            }
        },
        legend: {
            display: true,
            position: 'bottom',
            labels: {
                usePointStyle: true,
            }
        },
        scales: {
            xAxes: [
                {
                    id: "axis_1",
                    display: true,
                    gridLines: {
                        display: false,
                    },
                    type: 'time',
                    time: {
                        unit: groupBy,
                        tooltipFormat: groupBy == 'day' ? 'D MMM' : groupBy == 'month' ? 'MMM YYYY' : 'YYYY',
                        displayFormats: {
                            'day': 'D MMM',
                            'month': 'MMM YYYY',
                            'year': 'YYYY',
                        }
                    }
                },
            ],
            yAxes: [{
                display: true,
                // ticks: {
                //     // Include a dollar sign in the ticks
                //     callback: function (value, index, values) {
                //         return '$' + value;
                //     }
                // },
                scaleLabel: {
                    display: false,
                    // labelString: y_label,
                    fontSize: 14
                },
                gridLines: {
                    display: true,
                },

            }]

        },
        elements: {
            point: {
                radius: 3,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3
            },

            line: {
                // tension: 0
            }

        },
        tooltips: {
            mode: 'index',
            intersect: false,

        },
        hover: {
            mode: 'nearest',
            intersect: true
        },
        showAllTooltips: true
    }

}

export const TransparentOptions = (group_by, unit) => {

    let options = {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
            display: false
        },
        plugins: {
            datalabels: {
                display: false,
            }
        },
        scales: {
            xAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                    color: 'transparent',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    fontColor: '#fff',
                    maxTicksLimit: 3,
                    maxRotation: 0
                },
                // type: 'time',
                // time: {

                // }
            }],
            yAxes: [{
                gridLines: {
                    color: 'rgba(255,255,255,.2)',
                    zeroLineColor: 'rgba(255,255,255,.2)'
                },
                ticks: {
                    maxTicksLimit: 10,
                    fontColor: '#fff',
                    callback: function (value) {
                        return value + ' ' + unit
                    }
                }
            }]
        },
        elements: {
            point: {
                radius: 4,
                borderWidth: 2,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3
            }
        }
    }

    let time = {}
    if (group_by == 'jour') {
        time = {
            tooltipFormat: 'DD MMM YYYY',
            unit: 'day',
            displayFormats: {
                day: 'DD MMM YYYY'
            },
            parser: "YYYY-MM-DD"
        }
    }
    if (group_by == 'mois') {
        time = {
            tooltipFormat: 'MMM YYYY',
            unit: 'month',
            displayFormats: {
                month: 'MMM YYYY'
            },
            parser: "YYYY-MM-DD"
        }
    }
    if (group_by == 'annee') {
        time = {
            tooltipFormat: 'YYYY',
            unit: 'year',
            displayFormats: {
                year: 'YYYY'
            },
            parser: "YYYY"
        }
    }


    // options.scales.xAxes[0].time = time

    return options
}

export const RadarTransparent = () => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        pointLabelFontSize: 20,
        plugins: {
            datalabels: {
                display: false,
            }
        },
        legend: {
            display: false,
            fontSize: 14,
            fontColor: '#fff',
        },
        scale: {
            gridLines: {
                drawOnChartArea: false,
                color: 'rgba(255,255,255,.5)',
                zeroLineColor: 'rgba(255,255,255,.2)',
                lineWidth: 1,
            },
            ticks: {
                backdropColor: 'transparent',
                beginAtZero: true,
                fontColor: '#fff',
                fontSize: 12,
                // maxTicksLimit: 20,
                // min: 0,
                // max: 10,
                // stepSize: 2
            },
            pointLabels: {
                fontSize: 14,
                fontColor: '#fff',
            }
        },
        elements: {
            line: {

                borderColor: '#fff',
                borderWidth: 2,

            },
            point: {
                pointBackgroundColor: '#fff',
                backgroundColor: '#fff',
                borderColor: '#fff',
                radius: 2,
                borderWidth: 2,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3
            }
        }
    }
}

export const RadarSegmentation = () => {
    return {
        responsive: true,
        maintainAspectRatio: false,
        pointLabelFontSize: 20,
        plugins: {
            datalabels: {
                display: false,
            }
        },
        legend: {
            display: false,
            fontSize: 17,
            fontColor: '#fff',
        },
        scale: {
            gridLines: {
                // drawOnChartArea: false,
                color: 'rgba(255,255,255,.3)',
                zeroLineColor: 'rgba(255,255,255,.2)',
                lineWidth: 2,
            },
            ticks: {
                backdropColor: 'transparent',
                fontColor: '#fff',
                fontSize: 15,
                // maxTicksLimit: 20,
                // min: 0,
                // max: 10,
                // stepSize: 2
                beginAtZero: true,
                max: 10
            },
            pointLabels: {
                fontSize: 15,
                fontColor: '#fff',
            },
        },
        elements: {
            line: {

                borderColor: '#fff',
                borderWidth: 3,

            },
            point: {
                pointBackgroundColor: '#fff',
                backgroundColor: '#fff',
                borderColor: '#fff',
                radius: 2,
                borderWidth: 2,
                hitRadius: 10,
                hoverRadius: 4,
                hoverBorderWidth: 3
            }
        }
    }
}

export const BarTransparentOptions = (group_by) => {

    let options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            datalabels: {
                display: false,
            }
        },
        legend: {
            display: false
        },
        scales: {
            xAxes: [{
                gridLines: {
                    drawOnChartArea: false,
                    color: 'transparent',
                    zeroLineColor: 'transparent'
                },
                ticks: {
                    fontColor: '#fff',
                },
                barPercentage: 0.5
            }],
            yAxes: [{
                gridLines: {
                    color: 'rgba(255,255,255,.2)',
                    zeroLineColor: 'rgba(255,255,255,.2)'
                },
                ticks: {
                    fontColor: '#fff',
                    maxTicksLimit: 6,
                    stepSize: 1,
                    beginAtZero: false,
                    callback: function (value) {
                        if (value <= 1)
                            return value + ' passage'
                        return value + ' passages'
                    }
                }
            }]
        }
    }

    return options
}