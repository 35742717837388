import { observable, toJS } from 'mobx'
import api from '../api'
import UserStore from './UserStore'
import { toast } from 'react-toastify'

const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours ...'
    },
    data: [],
    subs: [],
    initialValues: {
        active: 1,
        stats: {}
    }
}

const NotificationStore = {

    props: props,

    /**** METHODS ****/
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    async init() {
        this.reset()
        this.doLoading(true, 'Chargement en cours ...')

        await this.getNotificationList()
        await this.getNotificationSubscription()



        this.doLoading(false)

    },

    async getNotificationList() {

        let to_ban = []
        let all_notifs = [
            { code: 'montants', label: 'Montants' },
            { code: 'panier_moyen', label: 'Panier Moyen' },
            { code: 'transactions', label: 'Nombre de transactions' },
            { code: 'coupons_offerts', label: 'Coupons offerts' },
            { code: 'coupons_utilises', label: 'Coupons utilisés' },
            { code: 'credits_emis', label: 'Crédits émis' },
            { code: 'credits_encaisses', label: 'Crédits encaissés' },
            { code: 'points_emis', label: 'Points émis' },
            { code: 'points_encaisses', label: 'Points encaissés' },
        ]

        if (UserStore.isFidSimple()) {
            to_ban = ['coupons_emis',
                'coupons_encaisses',
                'coupons_restant_a_encaisser',
                'credits_emis',
                'credits_encaisses',
                'credits_restant_a_encaisser']
        }


        if (UserStore.isNovacash()) {
            to_ban = ['points_emis',
                'points_encaisses',
                'points_restant_a_encaisser',
                'coupons_emis',
                'coupons_encaisses',
                'coupons_restant_a_encaisser']
        }
        if (UserStore.isFidCoupons()) {
            to_ban = ['points_emis',
                'points_encaisses',
                'points_restant_a_encaisser',
                'credits_emis',
                'credits_encaisses',
                'credits_restant_a_encaisser']
        }

        let notif_type = all_notifs.filter(item => !to_ban.includes(item.code));

        const data = {
            'objName': 'notif_type',
            'cmd': 'getRows',
        }

        this.props.data = notif_type

        // const res = await api.post('/scripts/user_notifications/notifications_type.php', data)
        // if (res.data.success) {
        //     if (res.data.rows) {
        //         this.props.data = res.data.rows


        //     }
        // }

    },
    async getNotificationSubscription() {

        const data = {
            objName: 'subscriptions',
            cmd: 'getRows',
            where: 'userId = ' + UserStore.props.user.idUser
        }

        const res = await api.post('/scripts/user_notifications/notifications_subscriptions.php', data)
        if (res.data.success) {
            if (res.data.rows.length) {
                let results = res.data.rows[0]
                results['stats'] = JSON.parse(res.data.rows[0].stats)
                // delete results['userId']
                console.log(results)

                this.props.initialValues = results

            }
        }

    },

    async submit(values) {
        this.doLoading(true, 'Chargement en cours ...')
        // console.log(values)
        let data = { ...toJS(values) }

        if (!data['id']) {
            data['newRecord'] = true
            data['userId'] = UserStore.props.user.idUser
        }

        data['stats'] = JSON.stringify(data['stats'])
        console.log(data)
        const toSend = {
            objName: 'subscriptions',
            cmd: 'saveRows',
            data: JSON.stringify([data])
        }

        const res = await api.post('/scripts/user_notifications/notifications_subscriptions.php', toSend)
        if (res.data.success) {
            await this.getNotificationSubscription()
            toast.success('La modification à bien été effectuée')

        }
        else {
            toast.error('Une erreur est survenue')
        }

        this.doLoading(false)
    },
    reset() {
        this.props = props
    }
}

export default observable(NotificationStore)