import React, { useEffect, useState } from 'react'
import Iframe from 'react-iframe'
import CouponsStore from '../../stores/CouponsStore'
import { observer } from 'mobx-react'
import { Alert } from 'reactstrap'



const CouponsView = observer((props) => {

    useEffect(() => {
        CouponsStore.initCoupons()
    }, [])

    const [alertVisible, setAlertVisible] = useState(true)
    const onDismiss = () => setAlertVisible(false);

    return (
        <>

            <div className="animated fadeIn position-relative" style={{ height: 'calc(100vh - 70px)' }}>
                <Alert color="info" isOpen={alertVisible} toggle={onDismiss} className="infos-coupons">
                    Afin de concevoir vos coupons veuillez nous contactez à l'adresse suivante : <b>support.fidlink@novatecweb.com</b>
                </Alert>
                <Iframe
                    url={CouponsStore.props.couponsUrl}
                    width="100%"
                    height="100%"
                    display="initial"
                    position="relative"
                    frameBorder={0}
                    styles={{ border: '0px' }}
                    allowFullScreen />

            </div>
        </>

    )
})

export default CouponsView