import { observable, toJS } from 'mobx'
import UserStore from './UserStore'
import { toast } from 'react-toastify'
import api from '../api'
import myYup from '../utils/myYup'

const props = {

    currentRoute: '',
    listPartenaires : [],
    loading: {
        status: false,
        msg: 'Connexion en cours'
    },
    initialValues: {
        NomCommercant: "THIRIET JABRUN",
        NumCommercant: null,
        ComAdresse1: "Rd Pt de Convenance",
        ComAdresse2: "Jabrun(Jardi Village)",
        ComCodePostal: "97122",
        ComVille: "BAIE-MAHAULT",
        ComContactCivilite: 1,
        ComNomContact: "ASSELIN",
        ComPrenomContact: "Patrick",
        ComFonctionContact: "Resp.Reseau THIRIET",
        ComGsmContact: "0696",
        ComTelContact: "0596",
        ComEmailContact: "@gkflg",
        nomExpediteur: "TEST",
        comEnteteTicket1: "THIRIET JABRUN",
        comEnteteTicket2: "Jardi Village",
        comEnteteTicket3: "Rd Pt de Convenance",
        comEnteteTicket4: "97122 BAIE-MAHAULT",
        comEnteteTicket5: "",
        comEnteteTicket6: "",
        comEnteteTicket7: "",
        comEnteteTicket8: "",
        comBasTicket1: "THIRIET vous remercie",
        comBasTicket2: "de votre fidelite",
        comBasTicket3: "et vous dit a bientot !",
        comBasTicket4: "",
        comBasTicket5: "------------------------",
        comBasTicket6: "www.efid.fr by NOVATEC",
        comBasTicket7: "------------------------",
        comBasTicket8: "",
    },
    validationSchemaCommercant: myYup.object().shape({
        NomCommercant: myYup.string().required(),
        ComEmailContact: myYup.string().email(),
        comEnteteTicket1: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comEnteteTicket2: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comEnteteTicket3: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comEnteteTicket4: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comEnteteTicket5: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comEnteteTicket6: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comEnteteTicket7: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comEnteteTicket8: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comBasTicket1: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comBasTicket2: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comBasTicket3: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comBasTicket4: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comBasTicket5: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comBasTicket6: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comBasTicket7: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
        comBasTicket8: myYup.string().matches(/^[a-z A-Z0-9\-\,\:\.\#\+\?\!]+$/, { excludeEmptyString: true, message: 'Les caractères spéciaux ne sont pas autorisés' }).max(24),
    }),

    validationSchemaReseau: myYup.object().shape({
        ResNomExpediteur: myYup.string().max(11),
        NomReseau: myYup.string().matches(/^[a-zA-Z0-9_ -]+$/i, { excludeEmptyString: true, message: "Veuillez saisir des caractères alphanumériques non accentués" }).max(24),
        ResNomContact: myYup.string().max(25),
        ResPrenomContact: myYup.string().max(25),
        ResFonctionContact: myYup.string().max(50),
        ResGsmContact: myYup.string().max(20),
        ResTelContact: myYup.string().max(20),
        ResEmailContact: myYup.string().max(50).email(),

    })
}

const SettingsStore = {
    /***** Props ******/
    props: props,


    /***** Methods ******/
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    async init() {
        this.reset()
        this.doLoading(true, 'Chargement en cours ...')
        await this.initPartenaires()
        await this.getInfos()
        this.doLoading(false)
    },
    async initPartenaires() {
        // this.props.loading.msg = "Chargement en cours"
        // this.props.loading.status = true
        const idPartenaire = UserStore.props.user.idPartenaire
        let data = {
            cmd: "GetPartenairesByCriteria",
            where: idPartenaire == -1 ? ' Id is not null' : " Id='" + idPartenaire + "'",
            json: true,
            callFromClient: true,
        }

        const res = await api.post('/scripts/partenaires/Services.Partenaires.php', data)
        if (res.data.success) {
            const data = !res.data.rows ? [] : res.data.rows.map(item => ({id : item.Id, label : item.Nom}))
            data.unshift({
                id: -1,
                label: "Pas de partenaire"
            })

            this.props.listPartenaires = data

        }
        // this.props.loading.status = false
    },
    async getInfos() {

        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom
        const isReseau = UserStore.isReseau()
        const isCommercant = UserStore.isCommercant()

        let objName = null
        let where = null
        let url = null

        if (isReseau) {
            objName = 'leReseau'
            where = 'idreseaufid=' + idRes
            url = '/scripts/reseau/reseau.php'
        }
        else if (isCommercant) {
            objName = 'listeCommercants'
            where = 'idcommercantFid=' + idCom
            url = '/scripts/commerce/commercant.php'
        }

        let data = {
            cmd: 'getRows',
            objName: objName,
            where: where
        }

        const res = await api.post(url, data)

        if (res.data.success) {

            if (isReseau) {
                this.props.initialValues = {
                    iDreseauFid: idRes,
                    IdPartenaire: res.data.rows[0]['IdPartenaire'],
                    NomReseau: res.data.rows[0]['NomReseau'],
                    ResNomExpediteur: res.data.rows[0]['ResNomExpediteur'],

                    NumReseau: res.data.rows[0]['NumReseau'],

                    ResContactCivilite: res.data.rows[0]['ResContactCivilite'],
                    ResNomContact: res.data.rows[0]['ResNomContact'],
                    ResPrenomContact: res.data.rows[0]['ResPrenomContact'],
                    ResFonctionContact: res.data.rows[0]['ResFonctionContact'],

                    ResGsmContact: res.data.rows[0]['ResGsmContact'],
                    ResTelContact: res.data.rows[0]['ResTelContact'],
                    ResEmailContact: res.data.rows[0]['ResEmailContact'],

                    estAlliance: res.data.rows[0]['estAlliance'],
                    ResLabelCustomFieldClient: res.data.rows[0]['ResLabelCustomFieldClient'],
                }
            }

            if (isCommercant) {
                this.props.initialValues = {
                    idcommercantFid : idCom,

                    ComContactCivilite: res.data.rows[0]['ComContactCivilite'],
                    ComNomContact: res.data.rows[0]['ComNomContact'],
                    ComPrenomContact: res.data.rows[0]['ComPrenomContact'],
                    ComFonctionContact: res.data.rows[0]['ComFonctionContact'],
                    ComGsmContact: res.data.rows[0]['ComGsmContact'],
                    ComTelContact: res.data.rows[0]['ComTelContact'],
                    ComEmailContact: res.data.rows[0]['ComEmailContact'],

                    NomCommercant: res.data.rows[0]['NomCommercant'],
                    ComNomExpediteur: res.data.rows[0]['ComNomExpediteur'],
                    NumCommercant: res.data.rows[0]['NumCommercant'],
                    ComAdresse1: res.data.rows[0]['ComAdresse1'],
                    ComAdresse2: res.data.rows[0]['ComAdresse2'],
                    ComCodePostal: res.data.rows[0]['ComCodePostal'],
                    ComVille: res.data.rows[0]['ComVille'],

                    comEnteteTicket1: res.data.rows[0]['comEnteteTicket1'],
                    comEnteteTicket2: res.data.rows[0]['comEnteteTicket2'],
                    comEnteteTicket3: res.data.rows[0]['comEnteteTicket3'],
                    comEnteteTicket4: res.data.rows[0]['comEnteteTicket4'],
                    comEnteteTicket5: res.data.rows[0]['comEnteteTicket5'],
                    comEnteteTicket6: res.data.rows[0]['comEnteteTicket6'],
                    comEnteteTicket7: res.data.rows[0]['comEnteteTicket7'],
                    comEnteteTicket8: res.data.rows[0]['comEnteteTicket8'],
                    comBasTicket1: res.data.rows[0]['comBasTicket1'],
                    comBasTicket2: res.data.rows[0]['comBasTicket2'],
                    comBasTicket3: res.data.rows[0]['comBasTicket3'],
                    comBasTicket4: res.data.rows[0]['comBasTicket4'],
                    comBasTicket5: res.data.rows[0]['comBasTicket5'],
                    comBasTicket6: res.data.rows[0]['comBasTicket6'],
                    comBasTicket7: res.data.rows[0]['comBasTicket7'],
                    comBasTicket8: res.data.rows[0]['comBasTicket8'],
                }
            }
        }
        else {
            toast.error('Une erreur est survenue')
        }
    },
    getInitialValues() {
        return this.props.initialValues
    },
    getValidationSchema() {
        return UserStore.isReseau() ? this.props.validationSchemaReseau : this.props.validationSchemaCommercant
    },
    async submit(values) {

        this.doLoading(true, 'Chargement en cours ...')

        if (UserStore.isCommercant()) {
            let nomExpediteur = values['ComNomExpediteur']
            delete values['ComNomExpediteur']

            let data = {
                cmd: 'saveRows',
                objName: 'lesCommercants',
                ref: 'SaveEnseigne',
                data: JSON.stringify([values]),
                comptesms: JSON.stringify([{ "nomExpediteur": nomExpediteur }])
            }

            const res = await api.post('/scripts/commerce/commercant.php', data)
            if (res.data.success) {
                toast.success('Les informations ont bien été enregistré')
                await UserStore.whoami()
            }
            else {
                toast.error('Une erreur est survenue')
            }
        }


        if (UserStore.isReseau()) {
            let nomExpediteur = values['ResNomExpediteur']
            delete values['ResNomExpediteur']

            let data = {
                cmd: 'saveRows',
                objName: 'leReseau_insert',
                ref: 'SaveReseau',
                data: JSON.stringify([values]),
                comptesms: JSON.stringify([{ "nomExpediteur": nomExpediteur }])
            }

            const res = await api.post('/scripts/reseau/reseau.php', data)
            if (res.data.success) {
                toast.success('Les informations ont bien été enregistré')
                await UserStore.whoami()
            }
            else {
                toast.error('Une erreur est survenue')
            }
        }

        this.doLoading(false)


    },
    reset() {
        this.props = props
    }

}


export default observable(SettingsStore)