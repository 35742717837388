import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import classnames from 'classnames';
import { unmountComponentAtNode, render } from 'react-dom';
import parse from 'html-react-parser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { utils, getImportWarnings } from '../../utils/utils'

const ModalInfo = (props) => {

    const { title, text, close } = props

    const [open, setOpen] = useState(true);

    return (
        <Modal backdrop="static" isOpen={open} toggle={() => setOpen(!open)}>
            <ModalHeader className="no-border">
                {title}
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            {parse(text)}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="no-border">
                <button className="btn-danger btn" onClick={() => { setOpen(!open); close(false) }}>Fermer</button>
            </ModalFooter>
        </Modal>
    )
}

const ModalAlertDoublons = (props) => {

    const { title, close, erreursLignes, colonnesIgnorees, isFatal } = props

    const [open, setOpen] = useState(true);

    const handleClick = () => {
        setOpen(false)
        close(true)
    }

    const abort = () => {
        setOpen(false)
        close(false)
    }

    return (
        <Modal backdrop="static" isOpen={open} size="lg" toggle={() => setOpen(!open)}>
            <ModalHeader className="no-border">
                {title}
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-12">
                        <div className="form-group">
                            {
                                isFatal <= 0 &&
                                <>
                                    <p>Votre fichier comporte des erreurs.
                                    Vous trouverez ci-dessous la liste de ces erreurs accompagnée de leurs types.</p>
                                    <p>Souhaitez vous tout de même poursuivre l'intégration ou fermer cette fenêtre ?</p>
                                </>
                            }

                            {
                                isFatal > 0 && <p>Votre fichier comporte des erreurs.
                                <br />Vous trouverez ci-dessous la liste de ces erreurs accompagnée de leurs types.
                                <br />
                                Des erreurs bloquantes vous empêche de poursuivre, elles sont mentionnées par un triangle rouge<br />
                                    <b>Veuillez modifier votre fichier s'il vous plaît.</b>
                                </p>
                            }
                        </div>
                    </div>
                </div>
                <div className="row d-flex center-h mb-2">
                    {!isFatal &&
                        <div className="col-auto">
                            <button className="btn-success btn btn-sm" onClick={handleClick}>
                                <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                                Continuer
                            </button>
                        </div>
                    }
                    {!isFatal &&
                        <div className="col-auto">
                            ou
                        </div>
                    }
                    <div className="col-auto">
                        <button className="btn-danger btn btn-sm" onClick={abort}>
                            <FontAwesomeIcon icon={faTimes} className="mr-2"></FontAwesomeIcon>
                            Annuler
                        </button>
                    </div>
                </div>
                <div className="row d-flex center-h">
                    {colonnesIgnorees.lenght && <div className="col-12 overflow-auto">
                        <b>Les colonnes suivantes seront ignorées :</b>
                        {
                            colonnesIgnorees.map((item) => (
                                <span>{item[0]}{', '}</span>
                            ))
                        }
                    </div>
                    }
                    <div className="col-12 overflow-auto">
                        <p><b>Listes des erreurs</b></p>
                        <div className="overflow-auto" style={{ maxHeight: '400px' }}>
                            <table className="table table-sm">
                                <thead>
                                    <th>Email</th>
                                    <th>Tél.</th>
                                    <th>Ligne</th>
                                    <th>Type</th>
                                </thead>
                                {erreursLignes.map((item) => {
                                    // console.log(item[3])
                                    let warn = getImportWarnings(item[3])

                                    return (
                                        <tr>
                                            <td>{item[0]}</td>
                                            <td>{item[1]}</td>
                                            <td>{item[2]}</td>
                                            <td>
                                                {
                                                    warn.map((item2) => (
                                                        <>
                                                            <FontAwesomeIcon icon={faExclamationTriangle}
                                                                className={"mr-2 text-" + item2.level}>
                                                            </FontAwesomeIcon>
                                                            {item2.value}
                                                            <br />
                                                        </>
                                                    ))
                                                }
                                            </td>
                                        </tr>
                                    )
                                })}
                            </table>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter className="no-border">
                <button className="btn-danger btn btn-sm" onClick={abort}>Fermer</button>
            </ModalFooter>
        </Modal>
    )
}

const modalAlert = (props) => {
    return new Promise(resolve => {
        let el = document.createElement('div');

        const handleResolve = result => {
            unmountComponentAtNode(el);
            el = null;
            resolve(result);
        };
        if (props.type == 'doublons')
            render(<ModalAlertDoublons {...props} close={handleResolve} />, el);
        else
            render(<ModalInfo {...props} close={handleResolve} />, el);
    });
};

export default modalAlert
