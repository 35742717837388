import React, { useState, useRef, useEffect } from 'react'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'
import CardsStore from '../../stores/CardsStore'
import { useTable } from 'react-table'
import { Collapse, PopoverHeader, PopoverBody, DropdownToggle, DropdownItem, DropdownMenu, Dropdown, Popover, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faColumns, faDownload, faCaretDown, faChevronCircleLeft, faChevronLeft, faChevronRight, faCreditCard, faPlus, faEye, faEdit, faArchive, faEuroSign, faTimes, faTrash, faListOl, faTrashAlt, faCaretSquareDown, faCaretSquareUp, faFileExcel, faList, faInfoCircle, faUpload, faIdCard, faClipboard, faClipboardList, faSearch, faCheck, faRecycle, faSave, faCoins, faArrowUp, faArrowDown, faComment, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import BlockUi from 'react-block-ui';
import { Radar, Line, Bar } from 'react-chartjs-2';
import { number } from '../../utils/numeral'
import { TransparentOptions, RadarTransparent, BarTransparentOptions } from '../../utils/chartUtils';
import classnames from 'classnames'
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap'
import { useFormik } from 'formik';
import excelEx from '../../assets/img/excel_ex.png'
import { statutRecharge } from '../../utils/constants'
import { BarLoader } from '../Loader/Loading'
import SimpleBar from 'simplebar-react';
import 'simplebar/dist/simplebar.min.css';
import UserStore from '../../stores/UserStore'
import AsyncCreatableSelect from 'react-select/async-creatable';
import MyField from '../../reusable/MyField'
import DataTable, { ColumnsToggle, DropdownPortal, Portal } from '../../reusable/DataTable'
import ClientFilterStore from '../../stores/ClientFilterStore'
import ClientFilter, { QuickSearch } from '../../reusable/ClientFilter'
import myYup from '../../utils/myYup'
import { createPortal } from 'react-dom'
import { withRouter } from 'react-router-dom'
import confirmation from '../Alerts/Confirmation'
import imgWelcome from '../../assets/img/welcome.png'
import moment from 'moment'



export const EditDropDown = observer((props) => {
    const {
        rowId, rowValues, openModal,
        isWallet,
        isCampagne,
        setSelectedRow, setActiveTab,
        disableEnableCard, deleteClient,
        toggleModalEditClient,
        toggleModalAffect,
        canRechargementUnique
    } = props
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => {
        setSelectedRow(rowId)
        setDropdownOpen(dropdownOpen => !dropdownOpen);
    }
    const handleClick = (e) => {
        console.log(e)
        e.preventDefault()
        // setSelectedRow(rowId)
        if (e.target.getAttribute('tab'))
            setActiveTab(e.target.getAttribute('tab'))
        openModal()
        toggle()
    }

    const rootSelector = document.getElementById('root');
    const div = document.createElement("div")
    div.className = "h-0"
    let portalContainer = useRef(div).current

    useEffect(() => {
        // let portalContainer = document.createElement("div");
        rootSelector.appendChild(portalContainer)
        return () => {
            rootSelector.removeChild(portalContainer)
        }
    }, [])

    return (

        <DropdownPortal id={"edit-drop" + rowId} isOpen={dropdownOpen} toggle={toggle} portalContainer={portalContainer}>
            <DropdownToggle
                tag="button"
                className="btn btn-xs btn-outline-success btn-action"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
            > Actions
                <FontAwesomeIcon className="ml-2" icon={faCaretDown}></FontAwesomeIcon>
            </DropdownToggle>
            <Portal target={portalContainer}>
                <DropdownMenu right className="p-0">
                    {!isCampagne &&
                        <a className="dropdown-item" onClick={handleClick}>
                            <FontAwesomeIcon icon={faEye} className="mr-2 text-old"></FontAwesomeIcon>
                    Détails</a>
                    }
                    {/* <a className="dropdown-item" tab="2" onClick={toggleModalEditClient}>
                    <FontAwesomeIcon icon={faEdit} className="mr-2 text-old"></FontAwesomeIcon>
                    Modifier le client</a> */}
                    {
                        isWallet &&
                        <a className="dropdown-item" tab="5" onClick={handleClick}>
                            <FontAwesomeIcon icon={faArrowUp} className="mr-2 text-success"></FontAwesomeIcon>
                    Créditer</a>
                    }
                    {
                        isWallet &&
                        <a className="dropdown-item" tab="6" onClick={handleClick}>
                            <FontAwesomeIcon icon={faArrowDown} className="mr-2 text-danger"></FontAwesomeIcon>
                    Débiter</a>
                    }
                    {
                        !isCampagne &&
                        <a className="dropdown-item" tab="3" onClick={handleClick}>
                            <FontAwesomeIcon icon={faListOl} className="mr-2 text-old"></FontAwesomeIcon>
                        Voir les transactions</a>
                    }
                    {canRechargementUnique != 0 && !isCampagne && <a className="dropdown-item" tab="4" onClick={handleClick}>
                        <FontAwesomeIcon icon={faPlus} className="mr-2 text-success"></FontAwesomeIcon>
                    Ajouter / Modifier une recharge</a>}
                    <div className="dropdown-divider m-0"></div>

                    {
                        // console.log(rowValues) &&
                        rowValues.cltNumCard == '' &&
                        rowValues.Pme2 == 0 &&
                        rowValues.Pme1 == 0 &&
                        rowValues.cumRech2 == 0 &&
                        rowValues.cumRech1 == 0 &&
                        rowValues.cumBonus2 == 0 &&
                        rowValues.cumBonus1 == 0 &&
                        rowValues.cumSpot == 0 &&
                        rowValues.cumDebPme2 == 0 &&
                        rowValues.cumDebPme1 == 0 &&
                        rowValues.cumCredPme1 == 0 &&
                        rowValues.cumCredPme2 == 0 &&
                        !isCampagne &&
                        <a className="dropdown-item" tab="4" onClick={() => { toggleModalAffect('cardToFiche'); toggle() }}>
                            <FontAwesomeIcon icon={faIdCard} className="mr-2 text-warning"></FontAwesomeIcon>
                    Affecter une carte à la fiche client</a>
                    }
                    {
                        rowValues.cltNumCard != '' &&
                        rowValues.cltNom == '' &&
                        rowValues.cltPrenom == '' &&
                        rowValues.cltNeLe == '' &&
                        rowValues.cltGsm == '' &&
                        rowValues.cltEmail == '' &&
                        !isCampagne &&
                        <a className="dropdown-item" tab="4" onClick={() => { toggleModalAffect('ficheToCard'); toggle() }}>
                            <FontAwesomeIcon icon={faClipboardList} className="mr-2 text-primary"></FontAwesomeIcon>
                    Affecter une fiche client à la carte</a>
                    }
                    <div className="dropdown-divider m-0"></div>
                    {!isCampagne &&
                        <>
                            {rowValues.cltCardOFF === "0" ?
                                <a className="dropdown-item" onClick={() => disableEnableCard(true)}>

                                    {!isWallet &&
                                        <>
                                            <FontAwesomeIcon icon={faCreditCard} className="mr-2 text-danger"></FontAwesomeIcon>
                                    Désactiver la carte
                                </>
                                    }
                                    {isWallet &&
                                        <>
                                            <FontAwesomeIcon icon={faTimes} className="mr-2 text-danger"></FontAwesomeIcon>
                                    Désactiver le client
                                </>
                                    }
                                </a>
                                :
                                <a className="dropdown-item" onClick={() => disableEnableCard(false)}>
                                    <FontAwesomeIcon icon={faCreditCard} className="mr-2 text-success"></FontAwesomeIcon>
                                    {!isWallet && 'Réactivier la carte'}
                                    {isWallet && 'Réactivier le client'}
                                </a>
                            }
                        </>
                    }
                    <a className="dropdown-item" onClick={deleteClient}>
                        <FontAwesomeIcon icon={faTrashAlt} className="mr-2 text-danger"></FontAwesomeIcon>
                    Supprimer le client</a>
                </DropdownMenu>
            </Portal>
        </DropdownPortal >

    )
})

export const EditRechargeDropDown = observer((props) => {
    const { rowId, openModal, setSelectedRow, deleteRecharge } = props
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => {
        if (!dropdownOpen)
            setSelectedRow(rowId)
        setDropdownOpen(dropdownOpen => !dropdownOpen);
    }
    const handleClick = (e) => {
        e.preventDefault()
        openModal()
    }
    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle
                tag="button"
                className="btn btn-xs btn-outline-info"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
            > Actions
                <FontAwesomeIcon className="ml-2" icon={faCaretDown}></FontAwesomeIcon>
            </DropdownToggle>
            <DropdownMenu right className="p-0">
                <a className="dropdown-item" tab="2" onClick={handleClick}>
                    <FontAwesomeIcon icon={faEdit} className="mr-2 text-info"></FontAwesomeIcon>
                    Modifier la recharge</a>
                <a className="dropdown-item" onClick={() => deleteRecharge()}>
                    <FontAwesomeIcon icon={faTrashAlt} className="mr-2 text-danger"></FontAwesomeIcon>
                    Supprimer la recharge</a>
            </DropdownMenu>
        </Dropdown >
    )
})

const Actions = observer((props) => {

    const { handleExport, toggleModalImport, editBlackList, isCampagne,deleteAllClients, handleExportCsv, canExportClientCsv } = props
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    return (
        <Dropdown isOpen={dropdownOpen} className=" ml-auto" toggle={toggle}>
            <DropdownToggle
                tag="button"
                className="btn btn-sm btn-old"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
            >
                Actions
                <FontAwesomeIcon className="ml-2" icon={faCaretDown}></FontAwesomeIcon>
            </DropdownToggle>
            <DropdownMenu right className="p-0">
                <a className="dropdown-item" onClick={() => { toggleModalImport(); toggle() }}>
                    <FontAwesomeIcon icon={faFileExcel} className="mr-2 text-success"></FontAwesomeIcon>
                    Importer des clients
                </a>
                {isCampagne &&
                    <a className="dropdown-item btn" onClick={() => { deleteAllClients(); toggle() }} >
                        <FontAwesomeIcon icon={faTrash} className="mr-2 text-danger"></FontAwesomeIcon>
                        Supprimer tout les clients
                    </a>
                }
                {!isCampagne &&
                    <a className="dropdown-item btn" onClick={() => { handleExport(); toggle() }} >
                        <FontAwesomeIcon icon={faDownload} className="mr-2 text-info"></FontAwesomeIcon>
                        Exporter
                    </a>
                }
                {!isCampagne && canExportClientCsv &&
                    <a className="dropdown-item btn" onClick={() => { handleExportCsv(); toggle() }} >
                        <FontAwesomeIcon icon={faFileExcel} className="mr-2 text-info"></FontAwesomeIcon>
                        Exporter en .CSV
                    </a>
                }
                {!isCampagne &&
                    <a className="dropdown-item" onClick={() => { editBlackList(); toggle() }}>
                        <FontAwesomeIcon icon={faList} className="mr-2 text-secondary"></FontAwesomeIcon>
                    Générer la blacklist
                </a>
                }
            </DropdownMenu>
        </Dropdown>
    )
})

const CreditForm = observer((props) => {
    const {
        initialValuesRecharge,
        validateRecharge,
        submitRecharge,
        listRecharge,
        canRechargementUnique,
        toggleModalEditRecharge,
        setSelectedRowRecharge,
        deleteRecharge,
        unite1,
        unite2
    } = props

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = useFormik({
        initialValues: initialValuesRecharge,
        validate: validateRecharge,
        onSubmit: submitRecharge,
        validateOnChange: false,
        validateOnBlur: false
    })

    return (
        <div className="row">
            {canRechargementUnique != 0 &&
                <form className="col-3" onSubmit={handleSubmit}>
                    <h5>Ajout recharge</h5>
                    <div className="row">
                        <div className="form-group col-12 mb-1">
                            <label for="DateDebutRecharge">Début validité</label>
                            <MyField name="DateDebutRecharge"
                                type="date"
                                className="form-control"
                                onChange={handleChange}
                                customOnChange={setFieldValue}
                                value={values['DateDebutRecharge']}
                                error={errors['DateDebutRecharge']}
                                touched={touched['DateDebutRecharge']}>
                            </MyField>
                        </div>
                        <div className="form-group col-12 mb-1">
                            <label for="DateFinRecharge">Fin validité</label>
                            <MyField name="DateFinRecharge" type="date"
                                className="form-control"
                                onChange={handleChange}
                                customOnChange={setFieldValue}
                                value={values['DateFinRecharge']}
                                error={errors['DateFinRecharge']}
                                touched={touched['DateFinRecharge']}>
                            </MyField>
                        </div>
                        <div className="form-group col-12 mb-1">
                            <label for="">MontantRecharge</label>
                            <MyField name="MontantRecharge" type="number"
                                className="form-control"
                                onChange={handleChange}
                                customOnChange={setFieldValue}
                                value={values['MontantRecharge']}
                                error={errors['MontantRecharge']}
                                touched={touched['MontantRecharge']}>
                            </MyField>
                        </div>
                        <div className="form-group col-12 mb-1">
                            <label for="">Motif</label>
                            <MyField name="Param_1" type="text"
                                className="form-control"
                                onChange={handleChange}
                                customOnChange={setFieldValue}
                                value={values['Param_1']}
                                error={errors['Param_1']}
                                touched={touched['Param_1']}>
                            </MyField>
                        </div>

                        <div className="form-group col-12 mb-3">
                            <label for="">Type de recharge</label>
                            <MyField name="NumeroPME" type="select"
                                className="form-control"
                                onChange={handleChange}
                                customOnChange={setFieldValue}
                                value={values['NumeroPME']}
                                list={[{ id: 1, label: 'Points' }, { id: 2, label: 'Euros' }]}
                                error={errors['NumeroPME']}
                                touched={touched['NumeroPME']}>
                            </MyField>
                        </div>

                        <div className="form-group col-12">
                            <button className="btn btn-success w-100">
                                <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>
                            Ajouter la recharge</button>
                        </div>
                    </div>

                </form>}
            <div className="col border-left">
                <h5>Liste des recharges</h5>
                <div className="row">
                    <div className="col-12">
                        <table className="table table-responsive-sm table-sm">
                            <thead>
                                <tr>
                                    <th>N° Incrément</th>
                                    <th>Début validité</th>
                                    <th>Fin validité</th>
                                    <th>Montant</th>
                                    <th>Motif</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>

                                {
                                    listRecharge.map((item, index) => (
                                        <tr>
                                            <td>{item.NumeroIncrement}</td>
                                            <td>{moment(item.DateDebutRecharge).format('DD/MM/YYYY')}</td>
                                            <td>{moment(item.DateFinRecharge).format('DD/MM/YYYY')}</td>
                                            <td>{number(item.MontantRecharge / 100)} {item.NumeroPME == 1 ? unite1 : unite2}</td>
                                            <td>{item.Param_1}</td>
                                            <td>
                                                <span className={classnames({
                                                    'badge bg-secondary': item.statut == 2,
                                                    'badge bg-warning': item.statut == 1,
                                                    'badge bg-warning': item.statut == 0,
                                                })}>
                                                    {statutRecharge[item.statut]}
                                                </span>
                                            </td>
                                            <td>
                                                {canRechargementUnique != 0 &&
                                                    <EditRechargeDropDown
                                                        rowId={index}
                                                        setSelectedRow={setSelectedRowRecharge}
                                                        openModal={toggleModalEditRecharge}
                                                        deleteRecharge={deleteRecharge}
                                                    ></EditRechargeDropDown>
                                                }
                                            </td>
                                        </tr>
                                    ))
                                }

                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

        </div >
    )

})

const WalletForm = observer((props) => {
    const {
        operation,
        submitOperationWallet,
        submitOperation,
        unite2court,
        item
    } = props

    const initialValues = {
        montant: null,
        motif: ''
    }

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = useFormik({
        initialValues: initialValues,
        onSubmit: submitOperationWallet,
        validationSchema: myYup.object().shape({
            montant: myYup
                .number()
                .typeError('La valeur doit être un nombre')
                .required()
                .notZero(),
        }),
        validateOnChange: false,
        validateOnBlur: false
    })

    useEffect(() => {
        setFieldValue('idcli', item.idcli)
        setFieldValue('numCarte', item.cltNumCard)
        setFieldValue('operation', operation)
    }, [])



    return (
        <div className="row d-flex center-h">
            <form className="col-4" onSubmit={handleSubmit}>
                {operation == 'debit' && <h5>Débiter compte</h5>}
                {operation == 'credit' && <h5>Créditer compte</h5>}
                <div className="row">
                    <div className="form-group col-12 mb-1">
                        <label for="">Montant ({unite2court})</label>
                        <MyField name="montant" type="number"
                            className="form-control"
                            onChange={handleChange}
                            value={values['montant']}
                            error={errors['montant']}
                            touched={touched['montant']}>
                        </MyField>
                    </div>
                    {/* <div className="form-group col-12 mb-1">
                        <label for="">Motif</label>
                        <MyField name="motif" type="text"
                            className="form-control"
                            onChange={handleChange}
                            value={values['motif']}
                            error={errors['motif']}
                            touched={touched['motif']}>
                        </MyField>
                    </div> */}

                    <div className="form-group col-12">
                        <button type="submit" className="btn btn-success w-100">
                            {operation == 'debit' && 'Débiter'}
                            {operation == 'credit' && 'Créditer'}
                        </button>
                    </div>
                </div>
            </form>
        </div >
    )

})

const ModalDetails = observer((props) => {
    const
        {
            isOpen,
            toggle,
            item,
            lineDataset,
            daysDataset,
            ficheClient,
            canRechargementUnique,
            fieldsEdit,
            initialValuesRecharge,
            validateRecharge,
            submitRecharge,
            listRecharge,
            setActiveTab,
            activeTab,
            unite1,
            unite2,
            unite1court,
            unite2court,
            //--Datatable--//
            columns,
            headerGroups,
            getTableProps,
            getTableBodyProps,
            rows,
            prepareRow,
            loadingMsg,
            loadingStatus,
            prevPage,
            nextPage,
            goToPage,
            pageCount,
            pageIndex,
            pageLimit,
            maxHeight,
            //Edit recharge
            toggleModalEditRecharge,
            toggleModalEditClient,
            setSelectedRowRecharge,
            deleteRecharge,
            displayClientInfo,
            //UserMode
            isWallet,
            isSaisie,
            isNovacash,
            submitOperationWallet,
            canSegmentation
        }
            = props

    // const [activeTab, setActiveTab] = useState('1');

    const toggleTab = tab => {
        console.log(tab)
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    console.log(activeTab)

    return (

        <Modal isOpen={isOpen} toggle={() => { toggle() }} className="modal-xxxl bg-body">

            <ModalHeader toggle={() => { toggle() }}>Détails client</ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col-12">
                        <div className="border rounded mb-3" style={{ height: '100px' }}>
                            <div className="card-body p-0 d-flex align-items-center h-100">
                                <div className="d-flex center-v bg-gradient-info p-4 mr-3 text-white rounded-left h-100">
                                    <i className={item.cltCiv != 1 ? 'icon-user-female fa-2x' : 'icon-user fa-2x'}></i>
                                </div>
                                <div>
                                    <div className="text-value text-primary">{item.cltNom} {' '} {item.cltPrenom}</div>
                                    <div className="h5 text-muted text-uppercase">
                                        {
                                            !isWallet &&

                                            'N° de carte'
                                        }
                                        {
                                            isWallet &&

                                            'N° client'
                                        }
                                        {' : '}
                                        {item.cltNumCard}
                                    </div>
                                    {
                                        canSegmentation &&
                                        <div className="text-muted text-uppercase">
                                            <span className="h6">Score : </span>
                                            <span className="badge badge-info h5 text-white">
                                                <span className="h5">
                                                    {number(item.RFM_Score)}
                                                </span>
                                                <span style={{ fontSize: '0.85rem' }}> / 10</span>
                                            </span>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 col-sm-12">
                        <div className="row">

                            <div className="col-12">
                                <div className="border rounded mb-3">
                                    <div className="card-body p-0 d-flex align-items-center">
                                        <div className="bg-gradient-old p-4 mr-3 text-white rounded-left">
                                            <FontAwesomeIcon size="2x" icon={faCoins}></FontAwesomeIcon>
                                        </div>
                                        <div>
                                            <div className="text-value text-old">Solde</div>
                                            {
                                                !isNovacash && !isWallet &&
                                                <div className="text-muted text-uppercase">
                                                    <span className="text-old h5">{number(item.Pme1)}</span>
                                                    <span className="ml-2 text-old h6">{unite1court}</span>
                                                </div>
                                            }
                                            <div className="text-muted text-uppercase">
                                                <span className="text-old h5">{number(item.Pme2)}</span>
                                                <span className="ml-2 text-old h6">{unite2court}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                canSegmentation &&
                                <>
                                    <div className="col-12">
                                        <div className="border rounded mb-3">
                                            <div className="card-body p-0 d-flex align-items-center">
                                                <div className="bg-gradient-danger p-4 mr-3 text-white rounded-left">
                                                    <i className="icon-clock fa-2x"></i>
                                                </div>
                                                <div>
                                                    <div className="text-value text-danger">Récence</div>
                                                    <div className="text-muted text-uppercase">
                                                        <span className="text-danger h5">{number(item.RFM_Recence)}</span>
                                                        <span className="text-danger h4"> / 10</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="border rounded mb-3">
                                            <div className="card-body p-0 d-flex align-items-center">
                                                <div className="bg-gradient-warning p-4 mr-3 text-white rounded-left">
                                                    <i className="icon-loop fa-2x"></i>
                                                </div>
                                                <div>
                                                    <div className="text-value text-warning">Fréquence</div>
                                                    <div className="text-muted text-uppercase">
                                                        <span className="text-warning h5">{number(item.RFM_Frequence)}</span>
                                                        <span className="text-warning h4"> / 10</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <div className="border rounded mb-3">
                                            <div className="card-body p-0 d-flex align-items-center">
                                                <div className="bg-gradient-success p-4 mr-3 text-white rounded-left">
                                                    <i className="icon-basket-loaded fa-2x"></i>
                                                </div>
                                                <div>
                                                    <div className="text-value text-success">Montant</div>
                                                    <div className="text-muted text-uppercase">
                                                        <span className="text-success h5">{number(item.RFM_Montant)}</span>
                                                        <span className="text-success h4"> / 10</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>

                    <div className="col-lg-9 col-sm-12">
                        <BlockUi tag="div" blocking={loadingStatus} message={loadingMsg} loader={<BarLoader></BarLoader>}>
                            <Nav tabs>
                                {
                                    canSegmentation &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '1' })}
                                            onClick={() => { toggleTab('1'); }}>
                                            <span className="h6">
                                                Habitude de consommation
                                            </span>
                                        </NavLink>
                                    </NavItem>
                                }
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '2' })}
                                        onClick={() => { toggleTab('2'); }}>
                                        <span className="h6">

                                            Fiche client
                                                </span>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: activeTab === '3' })}
                                        onClick={() => { toggleTab('3'); }}>
                                        <span className="h6">
                                            Liste des transactions

                                                </span>
                                    </NavLink>
                                </NavItem>
                                {
                                    !isWallet && !isSaisie &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '4' })}
                                            onClick={() => { toggleTab('4'); }}>
                                            <span className="h6">

                                                Recharges
                                                </span>
                                        </NavLink>
                                    </NavItem>
                                }
                                {
                                    isWallet &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '5' })}
                                            onClick={() => { toggleTab('5'); }}>
                                            <span className="h6">

                                                Créditer
                                                </span>
                                        </NavLink>
                                    </NavItem>
                                }
                                {
                                    isWallet &&
                                    <NavItem>
                                        <NavLink
                                            className={classnames({ active: activeTab === '6' })}
                                            onClick={() => { toggleTab('6'); }}>
                                            <span className="h6">

                                                Débiter
                                                </span>
                                        </NavLink>
                                    </NavItem>
                                }
                            </Nav>
                            <TabContent activeTab={activeTab}>
                                {
                                    canSegmentation &&
                                    <TabPane tabId="1" className="animated fadeIn">
                                        <div className="row">
                                            <div className="col-12" >
                                                <div className="card text-white bg-gradient-info">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 className="card-title">Tendance</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col" style={{ height: '180px' }}>
                                                                {lineDataset.datasets && <Line data={toJS(lineDataset)} options={TransparentOptions('jour', unite2court)}></Line>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-7" >
                                                <div className="card text-white bg-gradient-success">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 className="card-title">Fréquentation</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col" style={{ height: '200px' }}>
                                                                {daysDataset.datasets && <Bar data={toJS(daysDataset)} options={BarTransparentOptions('jour')}></Bar>}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-5">
                                                <div className="card text-white bg-gradient-danger">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            <div className="col">
                                                                <h5 className="card-title">Forme du client</h5>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col" style={{ height: '200px' }}>
                                                                <Radar
                                                                    data={{
                                                                        labels: ['Montant', 'Récence', 'Fréquence'],
                                                                        datasets: [
                                                                            {
                                                                                label: 'Forme du client',
                                                                                data: [
                                                                                    item.RFM_Montant,
                                                                                    item.RFM_Recence,
                                                                                    item.RFM_Frequence
                                                                                ]
                                                                            }
                                                                        ]
                                                                    }}
                                                                    options={RadarTransparent()}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </TabPane>
                                }
                                <TabPane tabId="2" className="animated fadeIn">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="card-title">Informations client</h5>
                                        </div>
                                    </div>
                                    <div className="row">

                                        <table className="table table-sm">
                                            <tbody>
                                                {
                                                    ficheClient.map((item) => (
                                                        <tr>
                                                            <td>{item.label} : </td>
                                                            <td><strong>{displayClientInfo(item.label, item.value)}</strong></td>
                                                        </tr>
                                                    ))
                                                }
                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <button onClick={toggleModalEditClient} className="float-right btn btn btn-info">
                                                            <FontAwesomeIcon icon={faEdit} className="mr-2"></FontAwesomeIcon>
                                                        Modifier</button>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>


                                    </div>



                                </TabPane>
                                <TabPane tabId="3" className="animated fadeIn">
                                    <div className="row">
                                        <div className="col">
                                            <h5 className="card-title">Transactions</h5>
                                        </div>
                                    </div>

                                    <DataTable
                                        headerGroups={headerGroups}
                                        getTableBodyProps={getTableBodyProps}
                                        rows={rows}
                                        prepareRow={prepareRow}
                                        getTableProps={getTableProps}
                                        columns={columns}
                                        loadingMsg={loadingMsg}
                                        loadingStatus={loadingStatus}
                                        prevPage={prevPage}
                                        nextPage={nextPage}
                                        goToPage={goToPage}
                                        pageCount={pageCount}
                                        pageIndex={pageIndex}
                                        pageLimit={pageLimit}
                                        maxHeight={maxHeight}
                                        btnClassName="btn btn-outline-success btn-sm"
                                    ></DataTable>

                                </TabPane>
                                <TabPane tabId="4" className="animated fadeIn">
                                    <CreditForm
                                        initialValuesRecharge={initialValuesRecharge}
                                        canRechargementUnique={canRechargementUnique}
                                        validateRecharge={validateRecharge}
                                        submitRecharge={submitRecharge}
                                        listRecharge={listRecharge}
                                        setSelectedRowRecharge={setSelectedRowRecharge}
                                        toggleModalEditRecharge={toggleModalEditRecharge}
                                        deleteRecharge={deleteRecharge}
                                        unite1={unite1}
                                        unite2={unite2}
                                    >

                                    </CreditForm>
                                </TabPane>
                                <TabPane tabId="5" className="animated fadeIn">
                                    <WalletForm
                                        operation='credit'
                                        submitOperationWallet={submitOperationWallet}
                                        unite2court={unite2court}
                                        item={item}
                                    ></WalletForm>
                                </TabPane>
                                <TabPane tabId="6" className="animated fadeIn">
                                    <WalletForm
                                        operation='debit'
                                        submitOperationWallet={submitOperationWallet}
                                        unite2court={unite2court}
                                        item={item}
                                    ></WalletForm>
                                </TabPane>
                            </TabContent>

                        </BlockUi>
                    </div>
                </div>
            </ModalBody >
            <ModalFooter>
                <button className="btn-danger btn" onClick={toggle}>Fermer</button>{' '}
            </ModalFooter>

        </Modal >


    )
})

const ModalEditRecharge = observer((props) => {

    const {
        ModalEditRechargeIsOpen,
        toggleModalEditRecharge,
        EditRechargeLoading,
        EditRechargeLoadingMsg,
        initialValuesEditRecharge,
        validateEditRecharge,
        submitEditRecharge
    } = props

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: initialValuesEditRecharge,
        validate: validateEditRecharge,
        onSubmit: submitEditRecharge,
        validateOnChange: false,
        validateOnBlur: false
    })

    return (
        <Modal isOpen={ModalEditRechargeIsOpen} backdrop="static" size="sm" toggle={() => toggleModalEditRecharge()}>
            <BlockUi tag="div" blocking={EditRechargeLoading} message={EditRechargeLoadingMsg} loader={<BarLoader></BarLoader>}>
                <ModalHeader toggle={() => toggleModalEditRecharge()}>
                    Modification Recharge
                </ModalHeader>
                <ModalBody>
                    <form className="row" onSubmit={handleSubmit}>
                        <div className="form-group col-12 mb-1">
                            <label for="DateDebutRecharge">Début validité</label>
                            <MyField name="DateDebutRecharge"
                                type="date"
                                className="form-control"
                                onChange={handleChange}
                                customOnChange={setFieldValue}
                                value={values['DateDebutRecharge']}
                                error={errors['DateDebutRecharge']}
                                touched={touched['DateDebutRecharge']}>
                            </MyField>
                        </div>
                        <div className="form-group col-12 mb-1">
                            <label for="DateFinRecharge">Fin validité</label>
                            <MyField name="DateFinRecharge" type="date"
                                className="form-control"
                                onChange={handleChange}
                                customOnChange={setFieldValue}
                                value={values['DateFinRecharge']}
                                error={errors['DateFinRecharge']}
                                touched={touched['DateFinRecharge']}>
                            </MyField>
                        </div>
                        <div className="form-group col-12 mb-1">
                            <label for="">MontantRecharge</label>
                            <MyField name="MontantRecharge" type="number"
                                className="form-control"
                                onChange={handleChange}
                                customOnChange={setFieldValue}
                                value={values['MontantRecharge']}
                                error={errors['MontantRecharge']}
                                touched={touched['MontantRecharge']}>
                            </MyField>
                        </div>
                        <div className="form-group col-12 mb-1">
                            <label for="">Motif</label>
                            <MyField name="Param_1" type="text"
                                className="form-control"
                                onChange={handleChange}
                                customOnChange={setFieldValue}
                                value={values['Param_1']}
                                error={errors['Param_1']}
                                touched={touched['Param_1']}>
                            </MyField>
                        </div>

                        <div className="form-group col-12 mb-3">
                            <label for="">Type de recharge</label>
                            <MyField name="NumeroPME" type="select"
                                className="form-control"
                                onChange={handleChange}
                                customOnChange={setFieldValue}
                                value={values['NumeroPME']}
                                list={[{ id: 1, label: 'Points' }, { id: 2, label: 'Euros' }]}
                                error={errors['NumeroPME']}
                                touched={touched['NumeroPME']}>
                            </MyField>
                        </div>

                        <div className="form-group col-12">
                            <button type="submit" className="btn btn-success w-100">
                                <FontAwesomeIcon icon={faEdit} className="mr-2"></FontAwesomeIcon>
                            Modifier la recharge</button>
                        </div>
                    </form>
                </ModalBody>
            </BlockUi>
        </Modal>
    )

})

const ModalEditClient = observer((props) => {

    const {
        ModalEditClientIsOpen,
        EditClientLoading,
        toggleModalEditClient,
        EditClientLoadingMsg,
        clientFields,
        initialValuesEditClient,
        canRechargementUnique,
        submitEditClient,
        validateClient,
        fetchCards
    } = props

    const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValuesEditClient,
        enableReinitialize: true,
        validate: validateClient,
        onSubmit: submitEditClient,
        validateOnChange: false,
        validateOnBlur: false
    })

    return (
        <Modal isOpen={ModalEditClientIsOpen} backdrop="static" size="lg" toggle={() => toggleModalEditClient()}>
            <BlockUi tag="div" blocking={EditClientLoading} message={EditClientLoadingMsg} loader={<BarLoader></BarLoader>}>
                <ModalHeader toggle={() => toggleModalEditClient()}>
                    Modification Client {values['cltNumCard']}
                </ModalHeader>
                {values &&
                    <form onSubmit={handleSubmit}>
                        <ModalBody>
                            <div className="row">
                                {
                                    clientFields.map((item) => (
                                        <div className={classnames("form-group col-4")} >
                                            <MyField name={item.field} type={item.typeInput}
                                                className={item.className ? item.className : "form-control"}
                                                placeholder={item.Header}
                                                onChange={handleChange}
                                                customOnChange={setFieldValue}
                                                value={values[item.field]}
                                                error={errors[item.field]}
                                                fetch={fetchCards}
                                                list={item.list}
                                                label={item.Header}
                                                checkboxLabel={item.label}
                                                touched={touched[item.field]}>
                                            </MyField>
                                        </div>
                                    ))
                                }

                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <button type="button" onClick={() => toggleModalEditClient()} className="btn btn-danger">Annuler</button>
                            <button type="submit" className="btn btn-success">Modifier</button>
                        </ModalFooter>
                    </form>}
            </BlockUi>
        </Modal >
    )
})

const ModalAffectation = observer((props) => {

    const {
        ModalAffectIsOpen,
        AffectLoading,
        AffectLoadingMsg,
        titleAffect,
        toggleModalAffect,
        typeAffect,
        selectedRow,
        fichesToAffect,
        affect,
        searchCard,
        cardsToAffect,
        searchFiche
    } = props

    const [search, setSearch] = useState('')
    const [type, setType] = useState('clientNom')
    const [choice, setChoice] = useState({})

    return (
        <Modal isOpen={ModalAffectIsOpen} backdrop="static" size="lg" toggle={() => toggleModalAffect()}>
            <BlockUi tag="div" blocking={AffectLoading} message={AffectLoadingMsg} loader={<BarLoader></BarLoader>}>
                <ModalHeader toggle={() => toggleModalAffect()}>
                    {titleAffect}
                </ModalHeader>
                <ModalBody>
                    {
                        typeAffect == "cardToFiche" &&
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <h6>Fiche client :</h6>
                                    <table className="table table-borderless table-sm">
                                        <tbody>
                                            <tr>
                                                <td>Nom : </td>
                                                <td><strong>{selectedRow.cltNom}</strong></td>

                                                <td>Prénom : </td>
                                                <td><strong>{selectedRow.cltprenom}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Email : </td>
                                                <td><strong>{selectedRow.cltEmail}</strong></td>

                                                <td>N° Tél mobile : </td>
                                                <td><strong>{selectedRow.cltGsm}</strong></td>
                                            </tr>
                                            <tr>
                                                <td>Ville : </td>
                                                <td><strong>{selectedRow.cltVille}</strong></td>
                                                <td>Code postal : </td>
                                                <td><strong>{selectedRow.cltCP}</strong></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h6>Ci-dessous, la liste des cartes disponibles pour affectation :</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-auto form-group">
                                    <label>Rechercher un numéro de carte :</label>

                                    <div className="input-group">
                                        <input className="form-control"
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    searchCard(search)
                                                }
                                            }}
                                            onChange={(e) => setSearch(e.target.value)} type="text" />
                                        <div className="input-group-append">
                                            <button className="btn btn-success"
                                                onClick={() => searchCard(search)}
                                                type="button">
                                                <FontAwesomeIcon icon={faSearch} className="mr-2"></FontAwesomeIcon>
                                                    Rechercher
                                                    </button>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <SimpleBar style={{ maxHeight: '400px' }} className="mt-3 border rounded">
                                        <table className="table table-sm">
                                            <thead>
                                                <th>Numéro de carte</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                {
                                                    cardsToAffect.map(item =>
                                                        <tr>
                                                            <td>{item.clientNumCard}</td>
                                                            <td>
                                                                <button onClick={() => affect(item, 'cardToFiche')} className="btn btn-sm btn-success">
                                                                    <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                                                                Affecter à cette carte</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </SimpleBar>
                                </div>
                            </div>
                        </>
                    }

                    {
                        typeAffect == "ficheToCard" &&
                        <>
                            <div className="row">
                                <div className="col-12">
                                    <h6>N° de carte :</h6>
                                    <p><strong>{selectedRow.cltNumCard}</strong></p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <hr />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <h6>Ci-dessous, la liste des fiches disponibles pour affectation :</h6>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-auto form-group">
                                    <label>Rechercher par :</label>
                                </div>
                                <div className="col-auto form-group">

                                    <select onChange={(e) => setType(e.target.value)} className="form-control">
                                        <option value="clientNom">Nom</option>
                                        <option value="clientPrenom">Prénom</option>
                                        <option value="clientEmail">Email</option>
                                        <option value="clientGsm">Tél mobile</option>
                                    </select>
                                </div>
                                <div className="col-auto form-group">

                                    <input className="form-control form-control"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                searchFiche(search, type)
                                            }
                                        }}
                                        onChange={(e) => setSearch(e.target.value)} type="text" />
                                </div>
                                <div className="col-auto">
                                    <button className="btn btn-success"
                                        onClick={() => searchFiche(search, type)}
                                        type="button">
                                        <FontAwesomeIcon icon={faSearch} className="mr-2"></FontAwesomeIcon>
                                                    Rechercher
                                                    </button>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <SimpleBar style={{ maxHeight: '400px' }} className="mt-3 border rounded">
                                        <table className="table table-sm">
                                            <thead>
                                                <th>Nom</th>
                                                <th>Prénom</th>
                                                <th>Date de naissance</th>
                                                <th>Email</th>
                                                <th>N° Tél mobile</th>
                                                <th></th>
                                            </thead>
                                            <tbody>
                                                {
                                                    fichesToAffect.map(item =>
                                                        <tr>
                                                            <td>{item.clientNom}</td>
                                                            <td>{item.clientPrenom}</td>
                                                            <td>{item.clientNeLe}</td>
                                                            <td>{item.clientEmail}</td>
                                                            <td>{item.clientGsm}</td>
                                                            <td>
                                                                <button onClick={() => affect(item, 'ficheToCard')} className="btn btn-sm btn-success">
                                                                    <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                                                                Affecter à cette fiche</button>
                                                            </td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </SimpleBar>
                                </div>
                            </div>
                        </>
                    }

                </ModalBody>
            </BlockUi >
        </Modal >
    )
})

const ModalImportClient = observer((props) => {

    const fileInput = useRef(null)


    const {
        ImportIsOpen,
        listEnseignes,
        toggleModalImport,
        getHelpFile,
        isReseau,
        isCampagne,
        importClients,
        importLoading,
        importLoadingMsg,
        setSelectedEnseigne
    } = props

    const doImport = async (e) => { await importClients(e); fileInput.current.value = null }

    return (
        <Modal isOpen={ImportIsOpen} backdrop="static" size="" toggle={() => toggleModalImport()}>
            <BlockUi tag="div" blocking={importLoading} message={importLoadingMsg} loader={<BarLoader></BarLoader>}>
                <ModalHeader toggle={() => toggleModalImport()}>
                    Importation clients
                </ModalHeader>
                <ModalBody>
                    <h5>
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-info"></FontAwesomeIcon>
                    Vous souhaitez importer vos clients ?</h5>
                    <div className="row">
                        <div className="col-12">
                            <p>Nous avons mis à votre disposition un fichier exemple que vous pourrez utiliser
                             afin de comprendre le format de fichier que nous attendons.</p>
                            <p>L'entête de votre fichier d'import doit être similaire à celle du fichier d'exemple.</p>
                        </div>
                        <div className="col-12">
                            <p>

                                <img src={excelEx} className="img-fluid"></img>
                            </p>
                        </div>
                        <div className="col-12">
                            <p>Le fichier doit être au format <strong>.csv</strong> ou <strong>.txt </strong>pour être pris en compte</p>
                        </div>
                        <div className="col-12">
                            <p>Entêtes autorisés :</p>
                        </div>
                        <div className="col-6">
                            <ul>
                                <li>Civilite</li>
                                <li>Nom</li>
                                <li>Prenom</li>
                                <li>Date de naissance</li>
                                <li>Adresse1</li>
                                <li>Adresse2</li>
                            </ul>
                        </div>
                        <div className="col-6">
                            <ul>
                                <li>Code Postal</li>
                                <li>Ville</li>
                                <li>Tel fixe</li>
                                <li>Tel mobile</li>
                                <li>Email</li>
                            </ul>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <hr />
                        </div>
                    </div>
                    {isReseau &&
                        <div className={classnames("row", { "d-none": isCampagne })}>
                            <div className="col-auto">
                                <div className="form-group">
                                    <label>Importer dans l'enseigne : </label>
                                </div>
                            </div>
                            <div className="col">
                                <div className="form-group">
                                    <select onChange={(e) => setSelectedEnseigne(e.target.value)} className="form-control form-control-sm">
                                        {listEnseignes.map((item, index) => <option value={index}>{item.NomCommercant}</option>)}
                                    </select>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="row  d-flex justify-content-center align-items-center mb-2">
                        <div className="col-auto p-0">
                            <button className="btn  btn-info" onClick={() => getHelpFile()}>
                                <FontAwesomeIcon icon={faDownload} className="mr-2"></FontAwesomeIcon>
                            Télécharger le fichier d'aide</button>
                        </div>
                        <div className="col-auto">
                            ou
                    </div>
                        <div className="col-auto p-0">
                            <input ref={fileInput} type="file" style={{ display: 'none' }} onChange={doImport} />
                            <button className="btn btn-success" onClick={() => fileInput.current.click()}>
                                <FontAwesomeIcon icon={faUpload} className="mr-2"></FontAwesomeIcon>
                            Importer un fichier client</button>
                        </div>
                    </div>
                </ModalBody>
            </BlockUi>
        </Modal>
    )

})

const Filter = observer((props) => {

    const {
        toggleItemFilter,
        changeValue,
        addItemFilter,
        removeItemFilter,
        changeOperator,
        pattern,
        maxHeightFilter,
        submitFilter,
        resetFilter
    } = props

    const [popoverOpen, setPopoverOpen] = useState(false);
    const toggle = () => setPopoverOpen(!popoverOpen);

    return (
        <div className="ml-2">
            <button id="btn-filter" className="btn btn-info btn-sm">
                <FontAwesomeIcon icon={faFilter} className="mr-2"></FontAwesomeIcon>
                Filtre
            </button>
            <Popover popperClassName="popover-filter" placement="bottom"
                target="btn-filter" isOpen={popoverOpen} toggle={toggle}>
                <PopoverHeader className="d-flex w-100 align-items-center justify-content-center border-bottom">
                    Filtrer par
        </PopoverHeader>
                <PopoverBody className="p-0">
                    <SimpleBar style={{ maxHeight: maxHeightFilter }} className="p-3">
                        <ClientFilter
                            toggleItemFilter={toggleItemFilter}
                            changeValue={changeValue}
                            addItemFilter={addItemFilter}
                            removeItemFilter={removeItemFilter}
                            changeOperator={changeOperator}
                            pattern={pattern}
                            submitFilter={submitFilter}
                            maxHeightFilter={maxHeightFilter}
                        ></ClientFilter>
                    </SimpleBar>
                </PopoverBody>
                <div className="border-top bg-light rounded-bottom p-2">
                    <div className="d-flex mb-2">
                        <div className="col-md-6">
                            <button onClick={() => { resetFilter() }} className="btn-sm btn btn-old btn-block">
                                <FontAwesomeIcon icon={faRecycle} className="mr-2"></FontAwesomeIcon>
                    Rénitialiser</button>
                        </div>
                        <div className="col-md-6">
                            <button onClick={() => { resetFilter() }} className="btn-sm btn btn-old btn-block ">
                                <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
                    Sauvegarder</button>
                        </div>
                    </div>
                    <div className="d-flex">
                        <div className="col-md-6">
                            <button onClick={() => { toggle(); }} className="btn-sm btn btn-danger btn-block">
                                <FontAwesomeIcon icon={faTimes} className="mr-2"></FontAwesomeIcon>
                    Fermer</button>
                        </div>
                        <div className="col-md-6">
                            <button onClick={() => { submitFilter(); toggle(); }} className="btn-sm btn btn-old btn-block">
                                <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                    Valider</button>
                        </div>
                    </div>
                </div>

            </Popover>
        </div >
    )
})

const CardsView = observer((props) => {
    const { location, history } = props
    console.log(location)
    useEffect(() => {
        CardsStore.init()
    }, [])

    // const cols = toJS(CardsStore.props.columns).filter(item => !item.isNotDisplay)
    const cltTable = useTable(
        {
            columns: CardsStore.props.columns,
            data: CardsStore.props.data,
            initialState: {
                hiddenColumns: CardsStore.props.columns.filter((item) => (item.isHidden)).map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: CardsStore.props.pageIndex,
                pageSize: CardsStore.props.pageLimit,
                pageCount: CardsStore.props.pageCount,
                pageTotal: CardsStore.props.pageTotal,
            },
            isWallet: UserStore.isWallet(),
            isCampagne: UserStore.isCampagne(),
            openModal: () => CardsStore.toggleModalDetails(),
            canRechargementUnique: UserStore.canRechargementUnique(),
            setSelectedRow: (id) => CardsStore.setSelectedRow(id),
            getBadgeColor: CardsStore.getBadgeColor,
            setActiveTab: (index) => CardsStore.setActiveTab(index),
            toggleModalEditClient: () => CardsStore.toggleModalEditClient(),
            disableEnableCard: (value) => CardsStore.disableEnableCard(value),
            deleteClient: () => CardsStore.deleteClient(),
            toggleModalAffect: (title) => CardsStore.toggleModalAffect(title),
            setUnitsHeader: CardsStore.setUnitsHeader,
        }
    )

    const cltTransac = useTable(
        {
            columns: CardsStore.props.columnsTransac,
            data: CardsStore.props.dataTransac,
            initialState: {
                hiddenColumns: CardsStore.props.columnsTransac.filter((item) => (item.isHidden)).map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: CardsStore.props.pageTransacIndex,
                pageSize: CardsStore.props.pageTransacLimit,
                pageCount: CardsStore.props.pageTransacCount,
                pageTransacTotal: CardsStore.props.pageTransacTotal,
            },
            setUnitsHeader: CardsStore.setUnitsHeader,
        }
    )

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const goCampagne = async (type) => {
        toggle()
        const res = await CardsStore.getNbSubscribers()
        if (res) {
            if (res['nb_desabonne'] > 0) {
                const next = await confirmation({
                    type: 'confirmNoPass',
                    text: '<p>Certains clients sélectionnés pour votre campagnes sont désabonnés. </p>' +
                        '<p><b>Nombre abonnés : ' + res['nb_abonne'] + '</b></p>' +
                        '<p><b>Nombre désabonnés : ' + res['nb_desabonne'] + '</b></p>' +
                        '<p>Voulez vous continuez ? <b>'
                })

                if (next)
                    history.push({
                        pathname: '/campaigns/new/' + type,
                        notResetFilter: true
                    })
            } else {
                history.push({
                    pathname: '/campaigns/new/' + type,
                    notResetFilter: true
                })
            }

        }
    }

    return (
        <div className="container-fluid mt-3">
            {/* <h2 className="card-title">Liste des cartes</h2> */}
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <BlockUi tag="div" blocking={CardsStore.props.loading.status}
                            message={CardsStore.props.loading.msg}
                            loader={<BarLoader></BarLoader>}
                            className="card" style={{ minHeight: 'calc(100vh - 150px)' }}>
                            <div className="card-body">
                                <div className="d-flex justify-content-between">
                                    <div className="d-flex">
                                        <ColumnsToggle
                                            columns={cltTable.allColumns}
                                            className="btn btn-old btn-sm mx-1"
                                            toggleColumn={(col) => CardsStore.toggleColumn(col)}
                                        // columnsFromTable={CardsStore.props.columnsFromTable}
                                        >
                                        </ColumnsToggle>
                                        <ClientFilter
                                            onSubmitFilter={() => CardsStore.getFilteredClients()}
                                            className="btn btn-old btn-sm  mx-1"
                                            innerButton={
                                                <>
                                                    <FontAwesomeIcon icon={faFilter} className="mr-2"></FontAwesomeIcon>
                                            Recherche avancée
                                        </>
                                            }
                                        >

                                        </ClientFilter>
                                        {
                                            !UserStore.isSaisie() &&

                                            <Dropdown isOpen={dropdownOpen} className=" ml-auto" toggle={toggle}>
                                                <DropdownToggle
                                                    tag="button"
                                                    className="btn btn-sm btn-success"
                                                    data-toggle="dropdown"
                                                    aria-expanded={dropdownOpen}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>
                                                Créer une campagne à partir de la sélection
                                                    <FontAwesomeIcon className="ml-2" icon={faCaretDown}></FontAwesomeIcon>
                                                </DropdownToggle>
                                                <DropdownMenu right className="p-0">
                                                    <a onClick={() => goCampagne('sms')} className="dropdown-item">
                                                        <FontAwesomeIcon icon={faComment} className="mr-2 text-success"></FontAwesomeIcon>
                                                        Campagne SMS
                                                    </a>
                                                    <a onClick={() => goCampagne('email')} className="dropdown-item">
                                                        <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-success"></FontAwesomeIcon>
                                                        Campagne Email
                                                    </a>
                                                </DropdownMenu>
                                            </Dropdown>
                                        }
                                    </div>
                                    <div>
                                        <QuickSearch
                                            onSubmitFilter={() => CardsStore.getFilteredClients()}
                                            className="btn btn-old btn-sm"
                                        ></QuickSearch>
                                    </div>
                                    <div>
                                        <Actions
                                            isCampagne={UserStore.isCampagne()}
                                            toggleModalImport={() => CardsStore.toggleModalImport()}
                                            deleteAllClients={() => CardsStore.deleteAllClients()}
                                            handleExport={() => CardsStore.exportClients()}
                                            handleExportCsv={() => CardsStore.exportClientsCsv()}
                                            canExportClientCsv={UserStore.canExportClientCsv()}
                                            editBlackList={() => CardsStore.editBlackList()}
                                        >

                                        </Actions>
                                    </div>
                                </div>
                                <DataTable
                                    headerGroups={cltTable.headerGroups}
                                    getTableBodyProps={cltTable.getTableBodyProps}
                                    onDoubleClickRow={(index, row) => CardsStore.onDoubleClickRow(index, row)}
                                    rows={cltTable.rows}
                                    prepareRow={cltTable.prepareRow}
                                    getTableProps={cltTable.getTableProps}
                                    columns={CardsStore.props.columns}
                                    loadingMsg={CardsStore.props.loading.msg}
                                    loadingStatus={CardsStore.props.loading.status}
                                    prevPage={() => CardsStore.prevPage()}
                                    nextPage={() => CardsStore.nextPage()}
                                    setSort={(sortBy) => CardsStore.setSort(sortBy)}
                                    sortOrder={CardsStore.props.sortOrder}
                                    sortBy={CardsStore.props.sortBy}
                                    goToPage={(page) => CardsStore.goToPage(page)}
                                    pageCount={CardsStore.props.pageCount}
                                    pageIndex={CardsStore.props.pageIndex}
                                    pageLimit={CardsStore.props.pageLimit}
                                    pageTotal={CardsStore.props.pageTotal}
                                    maxHeight="calc(100vh - 260px)"
                                    btnClassName="btn btn-outline-success btn-sm"
                                ></DataTable>
                            </div>
                            <ModalDetails
                                item={CardsStore.props.selectedRow}
                                isOpen={CardsStore.props.modalOpen}
                                toggle={() => CardsStore.toggleModalDetails()}

                                lineDataset={CardsStore.props.lineDataset}
                                daysDataset={CardsStore.props.daysDataset}
                                ficheClient={CardsStore.getFicheClient()}
                                toggleModalEditClient={() => CardsStore.toggleModalEditClient()}
                                initialValuesRecharge={CardsStore.getInitialValuesForRecharge()}
                                validateRecharge={(values) => CardsStore.validateRecharge(values)}
                                submitRecharge={(value) => CardsStore.submitRecharge(value)}
                                listRecharge={CardsStore.props.dataRecharge}
                                setActiveTab={(index) => CardsStore.setActiveTab(index)}
                                activeTab={CardsStore.props.activeTab}
                                unite1={UserStore.props.user.unitesPME1}
                                unite2={UserStore.props.user.unitesPME2}
                                unite1court={UserStore.props.user.unitesPME1court}
                                unite2court={UserStore.props.user.unitesPME2court}

                                isNovacash={UserStore.isNovacash()}
                                isWallet={UserStore.isWallet()}
                                isSaisie={UserStore.isSaisie()}

                                canSegmentation={UserStore.canSegmentation()}
                                canRechargementUnique={UserStore.canRechargementUnique()}

                                submitOperationWallet={(values) => CardsStore.submitOperationWallet(values)}

                                headerGroups={cltTransac.headerGroups}
                                getTableBodyProps={cltTransac.getTableBodyProps}
                                rows={cltTransac.rows}
                                prepareRow={cltTransac.prepareRow}
                                getTableProps={cltTransac.getTableProps}
                                columns={CardsStore.props.columns}
                                loadingMsg={CardsStore.props.loading.msg}
                                loadingStatus={CardsStore.props.loading.status}
                                prevPage={() => CardsStore.prevPageTransac()}
                                nextPage={() => CardsStore.nextPageTransac()}
                                goToPage={(page) => CardsStore.goToPageTransac(page)}
                                pageCount={CardsStore.props.pageTransacCount}
                                pageIndex={CardsStore.props.pageTransacIndex}
                                pageLimit={CardsStore.props.pageTransacLimit}
                                pageTotal={CardsStore.props.pageTransacTotal}
                                maxHeight="400px"
                                toggleModalEditRecharge={() => CardsStore.toggleModalEditRecharge()}
                                setSelectedRowRecharge={(id) => CardsStore.setSelectedRowRecharge(id)}
                                deleteRecharge={(item) => CardsStore.deleteRecharge(item)}
                                displayClientInfo={(id, value) => CardsStore.displayClientInfo(id, value)}
                            ></ModalDetails>
                            <ModalImportClient
                                ImportIsOpen={CardsStore.props.ImportIsOpen}
                                isReseau={UserStore.isReseau()}
                                isCampagne={UserStore.isCampagne()}
                                toggleModalImport={() => CardsStore.toggleModalImport()}
                                getHelpFile={() => CardsStore.getHelpFile()}
                                importClients={(e) => { CardsStore.importClients(e) }}
                                importLoading={CardsStore.props.importLoading}
                                importLoadingMsg={CardsStore.props.importLoadingMsg}
                                listEnseignes={CardsStore.props.listEnseignes}
                                setSelectedEnseigne={(value) => CardsStore.setSelectedEnseigne(value)}
                            >
                            </ModalImportClient>
                            <ModalEditRecharge
                                ModalEditRechargeIsOpen={CardsStore.props.ModalEditRechargeIsOpen}
                                toggleModalEditRecharge={() => CardsStore.toggleModalEditRecharge()}
                                EditRechargeLoading={CardsStore.props.loading.status}
                                EditRechargeLoadingMsg={CardsStore.props.loading.msg}
                                initialValuesEditRecharge={CardsStore.props.selectedRowRecharge}
                                validateEditRecharge={(values) => CardsStore.validateRecharge(values)}
                                submitEditRecharge={(values) => CardsStore.submitEditRecharge(values)}

                            ></ModalEditRecharge>
                            <ModalEditClient
                                ModalEditClientIsOpen={CardsStore.props.ModalEditClientIsOpen}
                                canRechargementUnique={UserStore.canRechargementUnique()}
                                toggleModalEditClient={() => CardsStore.toggleModalEditClient()}
                                EditClientLoading={CardsStore.props.loading.status}
                                EditClientLoadingMsg={CardsStore.props.loading.msg}
                                clientFields={CardsStore.getEditableFields()}
                                initialValuesEditClient={CardsStore.getInitialValuesForEditClient()}
                                validateClient={(values) => CardsStore.validateClient(values)}
                                submitEditClient={(values) => CardsStore.submitEditClient(values)}
                                fetchCards={(value) => CardsStore.fetchCards(value)}

                            ></ModalEditClient>
                            <ModalAffectation
                                toggleModalAffect={(title) => CardsStore.toggleModalAffect(title)}
                                typeAffect={CardsStore.props.typeAffect}
                                ModalAffectIsOpen={CardsStore.props.ModalAffectIsOpen}
                                AffectLoading={CardsStore.props.loading.status}
                                AffectLoadingMsg={CardsStore.props.loading.msg}
                                titleAffect={CardsStore.props.titleAffect}
                                typeAffect={CardsStore.props.typeAffect}
                                selectedRow={CardsStore.props.selectedRow}
                                cardsToAffect={CardsStore.props.cardsToAffect}
                                fichesToAffect={CardsStore.props.fichesToAffect}
                                searchCard={(value) => CardsStore.searchCard(value)}
                                searchFiche={(value, type) => CardsStore.searchFiche(value, type)}
                                affect={(item, type) => CardsStore.affect(item, type)}
                            >
                            </ModalAffectation>
                            {
                                !CardsStore.props.loading.status &&
                                <Modal isOpen={UserStore.props.user.firstTimeV2} backdrop="static" centered={true}
                                    tag="div"

                                >
                                    <ModalBody className="p-5">
                                        <img className="img-welcome" src={imgWelcome}></img>
                                        <div className="col-12 mb-4">
                                            <h2 className="text-center text-old">Bienvenue sur <span className="text-fid">FIDLINK</span></h2>
                                        </div>
                                        <div className="col-12 text-center mb-4">
                                            <p>Bienvenue sur la nouvelle version de <span className="text-fid">FIDLINK.</span></p>
                                            <p>Profitez d'une nouvelle interface ergonomique et fluide afin  de mieux analyser et gérer votre système de fidélité client.</p>
                                            <p>N'hésitez pas à communiquer grâce à notre nouveau système de campagne Email et SMS.</p>
                                        </div>
                                        <div className="col-12 d-flex center-h">
                                            <button
                                                onClick={() => UserStore.toggleFirstTimev2()}
                                                className="btn btn-lg btn-fid rounded">J'ai compris</button>
                                        </div>

                                    </ModalBody>
                                </Modal>

                            }
                        </BlockUi>
                    </div >
                </div>
            </div>
        </div>

    )
})

export default withRouter(CardsView)