import { observable } from 'mobx'
import api from '../api'
import { toast } from 'react-toastify'
import CardsStore from './CardsStore'
import DashboardStore from './DashboardStore'
import ClientFilterStore from './ClientFilterStore'
import CampagneStore from './CampagneStore'
import CampagneWizardStore from './CampagneWizardStore'
import TransacFilterStore from './TransacFilterStore'
import NotificationStore from './NotificationStore'
import StatsStore from './StatsStore'
import SettingsStore from './SettingsStore'
import GamesStore from './GamesStore'
import MainStore from './MainStore'
import MouvementsCreditsStore from './MouvementsCreditsStore'
import TransacStore from './TransacStore'
import CouponsStore from './CouponsStore'
import ParticipantsFilterStore from './ParticipantsFilterStore'
import SegmentationStore from './SegmentationStore'
import LogRocket, { error } from 'logrocket'

const props = {
    /***** Props ******/
    user: {

    },
    fail: {
        status: false,
        msg: ""
    },
    loading: {
        status: false,
        msg: 'Connexion en cours ...'
    },
    action: 'login',
    successChangePassword: {
        msg: '',
        success: false
    },
    forgot: {
        error: '',
        success: false
    },verified:{
        error: '',
        success: false
    },setup2fa:{
        error:'',
        success:false,
        qrcode_base64:'',
        secret:''
    }
}

const UserStore = {

    props: props,

    /***** Methods ******/
    async init() {
        this.props.loading.status = true
        console.log('init User')
        // const session = JSON.parse(localStorage.getItem('fidlink_api'))
        // console.log(session)
        // if (session && session.access_token) {
            // this.props.user = session
            await this.whoami()
            window.user = this
            // CampagneStore.getAllStatutCampagne(session.idCompteSMS)
        // }
        this.props.loading.status = false
    },
    setAction(action) {
        this.props.successForgot = false
        this.props.action = action
    },
    async whoami() {


        const res = await api.post('/scripts/whoami.php')
        if (res && res.data.success) {
            res.data.user.isPreAuthenticated = true
            res.data.user.isAuthenticated = false

            this.props.user = res.data.user
            if(this.props.user.two_factors_method==1){
                localStorage.setItem("two_factors_method",1)
                if(this.props.user.two_factors_verified==1){
                    
                    console.log("twoo_factor",this.props.user)
                    if(this.props.user.valid_otp_timestamp){
                        localStorage.setItem("two_factors_verified",1)
                        localStorage.removeItem('two_factors_method')
                        this.props.user.isAuthenticated=true
                        console.log("connected",this.props.user)
                    }
                }
            }else{
                this.props.user.isAuthenticated=true
            }
            // console.log(res.data.user)
            // localStorage.setItem('fidlink_api_user', JSON.stringify(res.data.user))
            if (process.env['NODE_ENV'] === 'production') {
                LogRocket.identify(res.data.user['idUser'], {
                    name: res.data.user['nom'],
                    email: res.data.user['email'],
                    username: res.data.user['login'],
                    company: res.data.user['nom_reseau']
                })
            }
        }
        else {
            this.props.user = {}
        }
    },
    async check_twoo_method(creds) {
        this.props.loading.msg = "Verification en cours ..."
        this.props.loading.status = true
        let data = {
            'user_id': creds.user_id,
            'one_time_password': creds.one_time_password
        }
        const res = await api.post('/scripts/users/two_factors_authentication_verify_otp.php',data )
        if (res && res.data.success) {
            console.log('LOGIN_SUCCESS')

            await this.whoami()
            this.props.fail.status = false
            this.props.fail.msg = ""
            this.props.loading.status = false
            // this.props.user = res.data.user

            // this.props.user.isAuthenticated = true
        }
        else {
            this.props.fail.status = true
            this.props.fail.msg = res.data.msg
            setTimeout(() => this.props.loading.status = false, 3000)
        }
        return res.data
    },
    async setup_twoo_method(creds) {
        this.props.loading.msg = "Verification en cours ..."
        this.props.loading.status = true
        let data = {
            'selector':creds.selector,
            'token':creds.token,
            'one_time_password': creds.one_time_password
        }
        const res = await api.post('/scripts/users/two_factors_authentication_verify_otp.php',data )
        if (res && res.data.success) {
            console.log('LOGIN_SUCCESS')

            // await this.whoami()
            this.props.fail.status = false
            this.props.fail.msg = ""
            this.props.loading.status = false
            // this.props.user = res.data.user
            this.props.verified=res.data
            setTimeout(() => this.props.loading.status = false, 3000)

            // this.props.user.isAuthenticated = true
        }
        else {
            this.props.fail.status = true
            this.props.fail.msg = res.data.msg
            setTimeout(() => this.props.loading.status = false, 3000)
        }
        return res.data
    },
    async login(creds,cb) {
        this.props.loading.msg = "Connexion en cours ..."
        this.props.loading.status = true
        let data = {
            'loginEuroFid': creds.username,
            'passEuroFid': creds.password,
            'archF': 'recherche',
        }
        const res = await api.post('/scripts/login.php',data )
        if (res && res.data.success) {
            console.log('LOGIN_SUCCESS')

            await this.whoami()
            this.props.fail.status = false
            this.props.fail.msg = ""
            this.props.loading.status = false
            // this.props.user = res.data.user
           
            // this.props.user.isAuthenticated = true
            return cb()
        }
        else {
            this.props.fail.status = true
            this.props.fail.msg = res.data.msg
            setTimeout(() => this.props.loading.status = false, 3000)
        }
        return res.data
    },
    async forgot(creds) {
        this.props.loading.msg = "Connexion en cours ..."
        this.props.loading.status = true
        let data = {
            'email': creds.username,
            // 'archF': 'search',
        }
        const res = await api.post('/scripts/users/forgot_password.php', data)

        if (res && res.data) {
            this.setAction('successForgot')
            this.props.loading.status = false
            this.props.forgot= res.data
            if(this.props.forgot.success){
                this.props.forgot.error=`
                    <p style="font-weight: normal;">Si un compte existant est lié à l’adresse e-mail <b>`+ creds.username +`</b>,
                                    des instructions de réinitialisation du mot de passe vous seront envoyées à cette adresse.</p>
                                    </br>
                                    <p style="font-weight: normal;">Cet e-mail devrait vous parvenir dans les cinq prochaines minutes.</p>
                                    <p style="font-weight: normal;">Veuillez aussi vérifier votre dossier de courrier indésirable.</p>

                `
            }
        }

    },
    async verify(param) {
        this.props.loading.msg = "verification en cours ..."
        this.props.loading.status = true
        const res = await api.get('/scripts/users/verify_email.php', {params:param})

        if (res && res.data) {
            this.setAction('successVerified')
            this.props.loading.status = false
            this.props.verified= res.data
            if(this.props.verified.success){
                this.props.verified.error=`
                    <h2 style="font-weight: normal;">Adresse mail vérifiée !</h2>
                                    </br>
                                    <p style="font-weight: normal;">Vos identifiants de connexion ont été transmis par email.</p><br/>
                                    <p style="font-weight: normal;">Cet e-mail devrait vous parvenir dans les cinq prochaines minutes.</p>
                                    <p style="font-weight: normal;">Veuillez aussi vérifier votre dossier de courrier indésirable.</p>

                `
            }
        }

    },
    async forgotVerifLink(param) {
        this.props.loading.msg = "Connexion en cours ..."
        this.props.loading.status = true
        
        const res = await api.get('/scripts/users/reset_password_verify_link.php', {params:param})

        if (res && res.data) {
            if(!res.data.success){
            this.setAction('successForgot')
            this.props.loading.status = false
            
            this.props.forgot = res.data
            }
        }

    },
    async setup2faVerifLink(param) {
        this.props.loading.msg = "Connexion en cours ..."
        this.props.loading.status = true
        
        const res = await api.get('/scripts/users/two_factors_authentication_setup.php', {params:param})

        if (res && res.data) {
            console.log(res)
            // if(!res.data.success){
            // this.setAction('setupsucces2fa')
            this.props.loading.status = false
            
            this.props.setup2fa = res.data
            this.props.fail.status=false
            this.props.fail.msg=''

            if(!res.data.success){
                this.props.fail.status=true
                this.props.fail.msg="Authentification à 2 facteurs <br>Impossible d'afficher votre configuration  </br>"+res.data.error
                this.props.setup2fa.error="Authentification à 2 facteurs <br>Impossible d'afficher votre configuration  </br>"+res.data.error
            }
            // }
        }

    },
    setSuccessChangePassword(status) {
        this.props.successChangePassword = status
    },
    async submitChangePassword(creds,resetForm) {
        this.props.loading.msg = "Connexion en cours ..."
        this.props.loading.status = true

        let data =
        {
            old_password: creds.oldPhrase,
            new_password_1: creds.newPhrase1,
            new_password_2: creds.newPhrase2
        }

        const res = await api.post('/scripts/users/change_password.php', data)
        console.log(res)

        if (res && res.data) {
            this.setAction('successChange')
            this.props.successChangePassword = res.data
            if(res.data.success){
                this.props.successChangePassword.msg=`
                    <h2>Votre mot de passe à été enregistré !</h2>
                    <br/>
                    <p style="font-weight: normal;"> Pensez à la copier, si vous 
                    souhaitez le stocker dans un gestionnaire de mot de passe sécurisé. </p>
                `;
            }else {
                toast.error(res.data.error)
            }
        }

        resetForm()

        this.props.loading.status = false

    },
    async submitResetPassword(creds) {
        this.props.loading.msg = "Chargement en cours ..."
        this.props.loading.status = true

        let data = creds

        const res = await api.post('/scripts/users/reset_password_update.php', data)
        console.log(res)

        if (res && res.data) {
            this.setAction('successChange')
            this.props.forgot = res.data
            if(res.data.success){
                this.props.forgot.error=`
                    <h2>Votre mot de passe à été enregistré !</h2>
                    <br/>
                    <p style="font-weight: normal;"> Pensez à la copier, si vous 
                    souhaitez le stocker dans un gestionnaire de mot de passe sécurisé. </p>
                `;
            }else {
                toast.error(res.data.error)
            }
        }

        // resetForm()

        this.props.loading.status = false

    },
    async logout() {
        console.log('Logout user')
        this.props.loading.status = true
        localStorage.removeItem('two_factors_method')
        // localStorage.removeItem('fidlink_api')
        const res = await api.get('/scripts/logout.php')
        if (res.data.success) {
            // this.props.successChangePassword = true
            // toast.error(res.data.msg)
            DashboardStore.reset()
            CardsStore.reset()
            ClientFilterStore.reset()
            CampagneStore.reset()
            CampagneWizardStore.reset()
            TransacFilterStore.reset()
            NotificationStore.reset()
            StatsStore.reset()
            SettingsStore.reset()
            GamesStore.reset()
            CouponsStore.reset()
            MainStore.reset()
            MouvementsCreditsStore.reset()
            ParticipantsFilterStore.reset()
            SegmentationStore.reset()
            TransacStore.reset()

            this.reset()
        }else {
            toast.error(res.data.error)
        }
        
    },
    toggleFirstTimev2() {
        this.props.user.firstTimeV2 = false
    },
    // Role utilisateur
    isReseau() {
        return this.props.user.scope == 4
    },
    isCommercant() {
        return this.props.user.scope == 5
    },
    isSaisie() {
        return this.props.user.scope == 6
    },
    // Type de fidélité
    isFidSimple() {
        return this.props.user.mode == 1
    },
    isNovacash() {
        return this.props.user.mode == 2
    },
    isFidCoupons() {
        return this.props.user.mode == 3
    },
    isWallet() {
        return this.props.user.mode == 5
    },
    // Permissions
    // "acces_mini_site" => $r['acces_mini_site'],
    // "acces_coupons" => $r['acces_coupons'],
    // "acces_dashboard" => $r['acces_dashboard'],
    // "acces_liste_carte" => $r['acces_cards'],
    // "acces_transactions" => $r['acces_transactions'],
    // "acces_campagne" => $r['acces_campagne'],
    // "acces_segmentation" => $r['acces_segmentation'],
    // "acces_jeux" => $r['acces_jeux'],
    // "acces_tablette" => $r['acces_tablette'],
    isCampagne() {
        return parseInt(this.props.user.is_campagne) > 0
    },
    canRechargementUnique() {
        return parseInt(this.props.user.canRechargementUnique) > 0
    },
    canRechargementPeriodique() {
        return parseInt(this.props.user.canRechargementPeriodique) > 0
    },
    canMiniSite() {
        return parseInt(this.props.user.acces_mini_site) > 0
    },
    canCoupons() {
        return parseInt(this.props.user.acces_coupons) > 0
    },
    canStats() {
        return parseInt(this.props.user.acces_stats) > 0
    },
    canDashboard() {
        return parseInt(this.props.user.acces_dashboard) > 0
    },
    canListeDesCartes() {
        return parseInt(this.props.user.acces_cards) > 0
    },
    canTransactions() {
        return parseInt(this.props.user.acces_transactions) > 0
    },
    canCampagne() {
        return parseInt(this.props.user.acces_campagne) > 0
    },
    canSegmentation() {
        return parseInt(this.props.user.acces_segmentation) > 0
    },
    canJeux() {
        return parseInt(this.props.user.acces_jeux) > 0
    },
    canTablette() {
        return parseInt(this.props.user.acces_tablette) > 0
    },
    canNotifEmail() {
        return parseInt(this.props.user.acces_notif_email) > 0
    },
    canOldVersion() {
        return parseInt(this.props.user.acces_old_version) > 0
    },
    canExportClientCsv() {
        return parseInt(this.props.user.canExportClientCSV) > 0
    },
    disconnect() {

    },
    reset() {
        this.props = props
    }
}


export default observable(UserStore)

