import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTachometerAlt, faChartBar, faSms, faWallet, faDice, faBell, faComment, faComments, faPlusCircle, faEnvelope, faUsers, faChartPie, faExchangeAlt, faWindowMaximize, faCoins, faTicketAlt, faChartLine, faMapMarker, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import UserStore from '../../stores/UserStore'
import { Roles, labelFid } from '../../utils/constants'
import PrivateLink from '../../reusable/PrivateLink'
import classnames from 'classnames'
import SimpleBar from 'simplebar-react'



const SidebarView = () => {

    // Type Fid
    const isWallet = UserStore.isWallet()
    const isNovacash = UserStore.isNovacash()
    const isFidSimple = UserStore.isFidSimple()
    const isCampagne = UserStore.isCampagne()

    // Role
    const isReseau = UserStore.isReseau()
    const isCommercant = UserStore.isCommercant()
    const isSaisie = UserStore.isSaisie()

    //Permissions
    const canDashboard = UserStore.canDashboard()
    const canStats = UserStore.canStats()
    const canTransactions = UserStore.canTransactions()
    const canSegmentation = UserStore.canSegmentation()
    const canCoupons = UserStore.canCoupons()
    const canJeux = UserStore.canJeux()
    const canNotifEmail = /*UserStore.canNotifEmail()*/ false
    const canOldVersion = UserStore.canOldVersion()

    const nom = isReseau ? UserStore.props.user.nom_reseau : UserStore.props.user.nom_commercant

    const menu = [
        {
            header: isCampagne ? 'CLIENTS' : 'PILOTER',
            children: [
                {
                    name: 'Tableau de bord',
                    icon: faTachometerAlt,
                    link: {
                        pathname: '/dashboard',
                        state: 'Tableau de bord'

                    },
                    hide: isCampagne || isSaisie || !canDashboard,
                },
                {
                    name: 'Notifications Mails',
                    icon: faBell,
                    link: {
                        pathname: '/notifications',
                        state: 'Notifications Mails'
                    },
                    hide: isWallet || isCampagne || isSaisie || isCommercant || !canNotifEmail,
                },
                {
                    name: (isWallet || isCampagne) ? 'Liste des clients' : 'Liste des cartes',
                    icon: (isWallet || isCampagne) ? faUsers : faWallet,
                    link: {
                        pathname: '/cards',
                        state: (isWallet || isCampagne) ? 'Liste des clients' : 'Liste des cartes'
                    },
                },
                {
                    name: 'Liste des transactions',
                    icon: faExchangeAlt,
                    link: {
                        pathname: '/transactions',
                        state: 'Liste des transactions'
                    },
                    hide: isCampagne || isSaisie || !canTransactions,
                },
                {
                    name: 'Géolocalisation',
                    icon: faMapMarkerAlt,
                    link: {
                        pathname: '/geolocation',
                        state: 'Géolocalisation'
                    },
                    hide: isCampagne || isSaisie || isWallet,
                },
            ]
        },
        {
            header: isCampagne ? 'COMMUNICATION' : 'VENDRE',
            hide: isSaisie,
            children: [
                {
                    name: 'Statistiques',
                    icon: faChartLine,
                    link: {
                        pathname: '/stats',
                        state: 'Statistiques'
                    },
                    hide: isCampagne ||!canStats ,
                },
                {
                    name: 'Segmentation',
                    icon: faChartPie,
                    link: {
                        pathname: '/segmentation',
                        state: 'Segmentation prédictive'
                    },
                    hide: isCampagne || !canSegmentation,
                },
                {
                    name: 'Campagnes',
                    icon: faComments,
                    // hide : isWallet,
                    children: [
                        {
                            name: 'Liste des campagnes',
                            icon: faEnvelope,
                            link: {
                                pathname: '/campaigns',
                                state: 'Liste des campagnes'
                            }
                        },
                        {
                            name: 'Nouvelle campagne Email',
                            icon: faEnvelope,
                            link: {
                                pathname: '/campaigns/new/email',
                                state: 'Nouvelle campagne Email'
                            }
                        },
                        {
                            name: 'Nouvelle campagne SMS',
                            icon: faComment,
                            link: {
                                pathname: '/campaigns/new/sms',
                                state: 'Nouvelle campagne SMS'
                            }
                        },
                        {
                            name: 'Mouvements de crédits',
                            icon: faCoins,
                            link: {
                                pathname: '/campaigns/credits',
                                state: 'Mouvements de crédits'
                            }
                        },
                    ]
                },
                {
                    name: 'Coupons',
                    icon: faTicketAlt,
                    link: {
                        pathname: '/coupons',
                        state: 'Coupons'
                    },
                    hide: isWallet || isCommercant || isSaisie || isCampagne || !canCoupons||true,
                },
                {
                    name: 'Jeux',
                    icon: faDice,
                    link: {
                        pathname: '/games',
                        state: 'Jeux'
                    },
                    hide: isWallet || isCommercant || isSaisie || isCampagne || !canJeux,
                },
            ]
        },
        {
            header: 'VERSION PRECEDENTE',
            hide: isCampagne || !canOldVersion,
            children: [
                {
                    name: 'Novacard',
                    _blank: true,

                    icon: faWindowMaximize,
                    link: process.env['REACT_APP_OLD_ADMIN_URL'],
                    // link: {
                    //     pathname: '/old',
                    //     state: 'Novacard'
                    // },
                }
            ]
        }

    ]


    return (
        <div className="sidebar">
            <div className="sidebar-header">
                <div className="avatar-first-letter">
                    {UserStore.props.user.firstLetter}
                </div>
                <div className="mt-2"><strong>{nom}</strong></div>
                <small>{Roles[UserStore.props.user.scope]}</small>
                <div className="text-muted" style={{ lineHeight: '1' }}><small>{labelFid.find(x => x.id == UserStore.props.user.mode)?.label}</small></div>
            </div>
            <SimpleBar style={{ height: '100%' }} autoHide={false} className="sidebar-nav">
                <ul className="nav">
                    {
                        menu.filter(item => !item.hide).map((item,index) => (
                            <>
                                {
                                    item.header && <li className="nav-title" key={index}>{item.header}</li>
                                }
                                {
                                    item.children.filter(item => !item.hide).map((child,i) => (
                                        <MenuItem key={index+'-'+i} {...child}></MenuItem>
                                    ))
                                }
                            </>
                        ))
                    }
                </ul>
            </SimpleBar>
        </div >
    )

}

const MenuItem = (props) => {

    const {
        name,
        link,
        children,
        icon,
        _blank
    } = props

    return (
        <>
            {
                children &&
                <SubMenuItem {...props}></SubMenuItem>

            }
            {
                !children &&
                <>
                    {
                        !_blank &&
                        <li className="nav-item">
                            <PrivateLink to={link} className="nav-link">
                                <FontAwesomeIcon icon={icon} className="mr-2 nav-icon"></FontAwesomeIcon>
                                {name}
                            </PrivateLink>
                        </li>
                    }
                    {
                        _blank &&
                        <a target="_blank" href={link} className="nav-link">
                            <FontAwesomeIcon icon={icon} className="mr-2 nav-icon"></FontAwesomeIcon>
                            {/* <FontAwesomeIcon icon={item.icon} className="mr-2"></FontAwesomeIcon> */}
                            {name}
                        </a>
                    }
                </>

            }
        </>
    )


}

const SubMenuItem = (props) => {

    const {
        name,
        children,
        icon
    } = props

    const [menuOpen, setMenuOpen] = useState(false)

    return (
        <li className={classnames('nav-item nav-dropdown', { 'open': menuOpen })}>
            <a className="nav-link nav-dropdown-toggle" onClick={() => setMenuOpen(!menuOpen)}>
                <FontAwesomeIcon icon={icon} className="mr-2 nav-icon"></FontAwesomeIcon>
                {name}
            </a>
            <ul className="nav-dropdown-items">
                {children.map((item,index) => (
                    <li className="nav-item" key={index}>

                        <PrivateLink to={item.link} className="nav-link">
                            {/* <FontAwesomeIcon icon={item.icon} className="mr-2"></FontAwesomeIcon> */}
                            {item.name}
                        </PrivateLink>

                    </li>

                ))
                }
            </ul>
        </li>
    )
}

export default observer(SidebarView)