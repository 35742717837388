import React, { useState, useRef, useEffect } from 'react'

const renderInputDefault=(props)=>{
    return (
    <div className='col-2'>
        <input {...props}/>
    </div>
    )
}
const OtpInput=({
    length=4,
    renderInput=renderInputDefault,
    inputStyle="form-control",
    containerStyle="row",
    value='',
    onchange=onchange
})=>{
    // const [otp,setOtp]=useState(new Array(length).fill(''))
    const [activeInput,setActiveInput]=useState(0)
    const inputsRefs=useRef([])

    useEffect(()=>{
        inputsRefs.current=inputsRefs.current.slice(0, length);
        inputsRefs.current[0].focus()
    },[length])

    const onFocus=(index)=>{
        var active=Math.max(Math.min(length-1,index),0)
        setActiveInput(active)
        inputsRefs.current[active].focus();
        inputsRefs.current[active].select();
        // event.target.select()
    }

    const getValue=()=>{
        // console
        return value?value.split(''):[];
        // return otp;
    }

    const handleChange=(event)=>{
        const val=event.target.value
        if(isInputValueValid(val)){
            console.log(val)
            setValueAtFocus(val)
            onFocus(activeInput+1)
        }else{
            var nativeEvent = event.nativeEvent;
            // @ts-expect-error - This was added previosly to handle and edge case
            // for dealing with keyCode "229 Unidentified" on Android. Check if this is
            // still needed.
            if (nativeEvent.data === null && nativeEvent.inputType === 'deleteContentBackward') {
                event.preventDefault();
                setValueAtFocus('');
                onFocus(activeInput - 1);
            }
        }
        
        
    }

    var isInputValueValid = function (value) {
        return typeof value === 'string' && value.trim().length === 1;
    };

    const setValueAtFocus = function (value) {
        var otp = getValue();
        // var newotp = [...otp];
        otp[activeInput] = value[0];
        // setOtp((o,n)=>newotp)
        handleOtpChange(otp);
        // handleOtpChange();
    };

    const handleOtpChange=(value)=>{
        var val=value.join('')
        console.log("val",val)
        onchange(val)
    }

    const handleKeydown=(event)=>{
        const keyval=event.key
        // event.preventDefault()
        switch(keyval){
            case "Backspace":
                event.preventDefault()
                setValueAtFocus('')
                onFocus(activeInput-1)
            break;
            case "Delete":
                event.preventDefault()
                setValueAtFocus('')
            break;
            case "ArrowLeft":
                event.preventDefault()
                onFocus(activeInput-1)
            break;
            case "ArrowRight":
                event.preventDefault()
                onFocus(activeInput+1)
            break;
            case "Space":
            case "SpaceBar":
            case "ArrowUp":
            case "ArrowBottom":
                event.preventDefault()
            break;
        }
    }

    return(
        <div className={containerStyle}>
            {
                Array.from({length:length},(_,i)=>i).map((val,index)=>{
                    var a;
                    return(React.createElement(React.Fragment,{key:index},
                    renderInput({
                        key:{index},
                        value:(a=getValue()[index])!=null||a!= void 0?a:'',
                        maxLength:1,
                        ref:((input)=>inputsRefs.current[index]=input),
                        className:typeof inputStyle ==='string'?inputStyle:undefined,
                        onChange:handleChange,
                        onClick:(e)=>{onFocus(index)},
                        onKeyDown:handleKeydown
                    })
                ))
                })
            }
        </div>
        
    )
}

export default OtpInput;