import React, { useEffect } from 'react';
import LogRocket from 'logrocket';
import setupLogRocketReact from 'logrocket-react';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-block-ui/style.css';
import './assets/css/main.scss';
import './assets/css/setup2fa.css';
import Routes from './routes'
import { toast } from 'react-toastify';


// https://docs.logrocket.com/v1.0/docs/understanding-user-flows
if (process.env['NODE_ENV'] === 'production') {
  LogRocket.init(process.env['REACT_APP_LOGROCKET_APP_ID']);
  setupLogRocketReact(LogRocket);
}


toast.configure({
  hideProgressBar: true
})

const App = () => {

  return (
    <>
      <Routes></Routes>
    </>
  )
}


export default App;
