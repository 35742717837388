import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import { BarLoader } from '../Loader/Loading'
import BlockUi from 'react-block-ui';
import UserStore from '../../stores/UserStore';
import MyField from '../../reusable/MyField';
import { useFormik } from 'formik';
import SettingsStore from '../../stores/SettingsStore';
import { Nav, NavItem, NavLink, TabPane, TabContent, Alert} from 'reactstrap'
import classnames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle, faCogs } from '@fortawesome/free-solid-svg-icons';


const SettingsView = observer((props) => {

    useEffect(() => {
        SettingsStore.init()
    }, [])

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit, validateForm, handleReset } = useFormik({
        enableReinitialize: true,
        initialValues: SettingsStore.getInitialValues(),
        validationSchema: SettingsStore.getValidationSchema(),
        onSubmit: (values) => SettingsStore.submit(values),
        validateOnChange: false,
        validateOnBlur: true
    })

    const [activeTab, setActiveTab] = useState('1')

    const toggleTab = tab => {
        console.log(tab)
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    return (
        <div className="container-fluid mt-3">
            <div className="animated fadeIn">
                <div className="row center-h">
                    <div className="col-8">
                        <BlockUi tag="div" blocking={SettingsStore.props.loading.status}
                            message={SettingsStore.props.loading.msg}
                            loader={<BarLoader></BarLoader>}
                            className="card" style={{ minHeight: 'calc(100vh - 250px)' }}>
                            <div className="profile-header d-flex flex-column center-v py-3">
                                <div class="avatar-first-letter-big">
                                    <FontAwesomeIcon icon={faCogs}></FontAwesomeIcon>
                                </div>
                                <div class="mt-2">
                                    {
                                        UserStore.isReseau() &&
                                        <strong className="profile-name">Paramétrage réseau</strong>
                                    }
                                    {
                                        UserStore.isCommercant() &&
                                        <strong className="profile-name">Paramétrage enseigne</strong>
                                    }
                                </div>
                            </div>
                            {
                                UserStore.isCommercant() &&
                                <form className="card-body row">
                                    <div className="col">
                                        {Object.keys(errors).length > 0 &&
                                            <Alert color="danger" isOpen={true}>
                                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2"></FontAwesomeIcon>
                                            Veuillez vérifier les informations saisies
                                        </Alert>
                                        }
                                    </div>
                                    <div className="col-md-12">
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => { toggleTab('1'); }}>
                                                    <span className="h6">
                                                        Informations contact
                                            </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '2' })}
                                                    onClick={() => { toggleTab('2'); }}>
                                                    <span className="h6">
                                                        Informations commerçant
                                            </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '3' })}
                                                    onClick={() => { toggleTab('3'); }}>
                                                    <span className="h6">
                                                        Entête de ticket
                                            </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '4' })}
                                                    onClick={() => { toggleTab('4'); }}>
                                                    <span className="h6">
                                                        Bas de ticket
                                            </span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent className="p-0" activeTab={activeTab}>

                                            <TabPane tabId="1" className="animated fadeIn">
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComContactCivilite">Civilité</label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComContactCivilite" type="select"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            list={[
                                                                {
                                                                    id: 1,
                                                                    label: 'M.'

                                                                },
                                                                {
                                                                    id: 2,
                                                                    label: 'Mme'
                                                                },
                                                                {
                                                                    id: 3,
                                                                    label: 'Mle'
                                                                }
                                                            ]}
                                                            value={values['ComContactCivilite']}
                                                            error={errors['ComContactCivilite']}
                                                            touched={touched['ComContactCivilite']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComNomContact">Nom du contact : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComNomContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComNomContact']}
                                                            error={errors['ComNomContact']}
                                                            touched={touched['ComNomContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComPrenomContact">Prénom du contact : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComPrenomContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComPrenomContact']}
                                                            error={errors['ComPrenomContact']}
                                                            touched={touched['ComPrenomContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComFonctionContact">Fonction : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComFonctionContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComFonctionContact']}
                                                            error={errors['ComFonctionContact']}
                                                            touched={touched['ComFonctionContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComGsmContact">Tél. portable: </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComGsmContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComGsmContact']}
                                                            error={errors['ComGsmContact']}
                                                            touched={touched['ComGsmContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComTelContact">Tél. fixe: </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComTelContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComTelContact']}
                                                            error={errors['ComTelContact']}
                                                            touched={touched['ComTelContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComEmailContact">Email : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComEmailContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComEmailContact']}
                                                            error={errors['ComEmailContact']}
                                                            touched={touched['ComEmailContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2" className="animated fadeIn">
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="NomCommercant">Nom du commercant</label>
                                                    <div className="col-md-8">
                                                        <MyField name="NomCommercant" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['NomCommercant']}
                                                            error={errors['NomCommercant']}
                                                            touched={touched['NomCommercant']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComNomExpediteur">Nom Expéditeur (campagnes sms):</label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComNomExpediteur" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComNomExpediteur']}
                                                            error={errors['ComNomExpediteur']}
                                                            touched={touched['ComNomExpediteur']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="NumCommercant">N° Commerçant:</label>
                                                    <div className="col-md-8">
                                                        <span>{values['NumCommercant']}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComAdresse1">Adresse 1:</label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComAdresse1" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComAdresse1']}
                                                            error={errors['ComAdresse1']}
                                                            touched={touched['ComAdresse1']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComAdresse2">Adresse 2:</label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComAdresse2" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComAdresse2']}
                                                            error={errors['ComAdresse2']}
                                                            touched={touched['ComAdresse2']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComCodePostal">Code postal:</label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComCodePostal" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComCodePostal']}
                                                            error={errors['ComCodePostal']}
                                                            touched={touched['ComCodePostal']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ComVille">Ville:</label>
                                                    <div className="col-md-8">
                                                        <MyField name="ComVille" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ComVille']}
                                                            error={errors['ComVille']}
                                                            touched={touched['ComVille']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3" className="animated fadeIn">
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comEnteteTicket1">Entête 1 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comEnteteTicket1" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comEnteteTicket1']}
                                                            error={errors['comEnteteTicket1']}
                                                            touched={touched['comEnteteTicket1']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comEnteteTicket2">Entête 2 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comEnteteTicket2" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comEnteteTicket2']}
                                                            error={errors['comEnteteTicket2']}
                                                            touched={touched['comEnteteTicket2']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comEnteteTicket3">Entête 3 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comEnteteTicket3" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comEnteteTicket3']}
                                                            error={errors['comEnteteTicket3']}
                                                            touched={touched['comEnteteTicket3']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comEnteteTicket4">Entête 4 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comEnteteTicket4" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comEnteteTicket4']}
                                                            error={errors['comEnteteTicket4']}
                                                            touched={touched['comEnteteTicket4']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comEnteteTicket5">Entête 5 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comEnteteTicket5" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comEnteteTicket5']}
                                                            error={errors['comEnteteTicket5']}
                                                            touched={touched['comEnteteTicket5']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comEnteteTicket6">Entête 6 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comEnteteTicket6" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comEnteteTicket6']}
                                                            error={errors['comEnteteTicket6']}
                                                            touched={touched['comEnteteTicket6']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comEnteteTicket7">Entête 7 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comEnteteTicket7" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comEnteteTicket7']}
                                                            error={errors['comEnteteTicket7']}
                                                            touched={touched['comEnteteTicket7']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comEnteteTicket8">Entête 8 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comEnteteTicket8" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comEnteteTicket8']}
                                                            error={errors['comEnteteTicket8']}
                                                            touched={touched['comEnteteTicket8']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="4" className="animated fadeIn">
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comBasTicket1">Bas de ticket 1 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comBasTicket1" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comBasTicket1']}
                                                            error={errors['comBasTicket1']}
                                                            touched={touched['comBasTicket1']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comBasTicket2">Bas de ticket 2 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comBasTicket2" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comBasTicket2']}
                                                            error={errors['comBasTicket2']}
                                                            touched={touched['comBasTicket2']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comBasTicket3">Bas de ticket 3 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comBasTicket3" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comBasTicket3']}
                                                            error={errors['comBasTicket3']}
                                                            touched={touched['comBasTicket3']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comBasTicket4">Bas de ticket 4 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comBasTicket4" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comBasTicket4']}
                                                            error={errors['comBasTicket4']}
                                                            touched={touched['comBasTicket4']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comBasTicket5">Bas de ticket 5 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comBasTicket5" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comBasTicket5']}
                                                            error={errors['comBasTicket5']}
                                                            touched={touched['comBasTicket5']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comBasTicket6">Bas de ticket 6 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comBasTicket6" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comBasTicket6']}
                                                            error={errors['comBasTicket6']}
                                                            touched={touched['comBasTicket6']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comBasTicket7">Bas de ticket 7 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comBasTicket7" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comBasTicket7']}
                                                            error={errors['comBasTicket7']}
                                                            touched={touched['comBasTicket7']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="comBasTicket8">Bas de ticket 8 : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="comBasTicket8" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['comBasTicket8']}
                                                            error={errors['comBasTicket8']}
                                                            touched={touched['comBasTicket8']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                    <div className="col-md-12 mt-3">
                                        <div className="col d-flex justify-content-end px-0">
                                            <button onClick={handleSubmit} className="btn btn-success">Enregister</button>
                                        </div>
                                    </div>
                                </form>
                            }
                            {
                                UserStore.isReseau() &&
                                <form className="card-body row">
                                    {
                                        console.log(errors)
                                    }
                                    {Object.keys(errors).length > 0 &&
                                        <div className="col-md-12">
                                            <Alert color="danger" isOpen={true}>
                                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2"></FontAwesomeIcon>
                                                Veuillez vérifier les informations saisies
                                            </Alert>
                                        </div>
                                    }
                                    <div className="col-md-12">
                                        <Nav tabs>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '1' })}
                                                    onClick={() => { toggleTab('1'); }}>
                                                    <span className="h6">
                                                        Réseau
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '2' })}
                                                    onClick={() => { toggleTab('2'); }}>
                                                    <span className="h6">
                                                        Mutualisation
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    className={classnames({ active: activeTab === '3' })}
                                                    onClick={() => { toggleTab('3'); }}>
                                                    <span className="h6">
                                                        Personnalisation
                                                    </span>
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent className="p-0" activeTab={activeTab}>
                                            <TabPane tabId="1" className="animated fadeIn">
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="IdPartenaire">Partenaire</label>
                                                    <div className="col-md-8">
                                                        <MyField name="IdPartenaire" type="select"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            list={SettingsStore.props.listPartenaires}
                                                            value={values['IdPartenaire']}
                                                            error={errors['IdPartenaire']}
                                                            touched={touched['IdPartenaire']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="NomReseau">Nom du réseau : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="NomReseau" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['NomReseau']}
                                                            error={errors['NomReseau']}
                                                            touched={touched['NomReseau']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ResNomExpediteur">Nom Expéditeur (campagnes sms) : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ResNomExpediteur" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ResNomExpediteur']}
                                                            error={errors['ResNomExpediteur']}
                                                            touched={touched['ResNomExpediteur']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="NumReseau">N° Réseau:</label>
                                                    <div className="col-md-8">
                                                        <span>{values['NumReseau']}</span>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ResContactCivilite">Civilité</label>
                                                    <div className="col-md-8">
                                                        <MyField name="ResContactCivilite" type="select"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            list={[
                                                                {
                                                                    id: 1,
                                                                    label: 'M.'

                                                                },
                                                                {
                                                                    id: 2,
                                                                    label: 'Mme'
                                                                },
                                                                {
                                                                    id: 3,
                                                                    label: 'Mle'
                                                                }
                                                            ]}
                                                            value={values['ResContactCivilite']}
                                                            error={errors['ResContactCivilite']}
                                                            touched={touched['ResContactCivilite']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ResNomContact">Nom du contact : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ResNomContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ResNomContact']}
                                                            error={errors['ResNomContact']}
                                                            touched={touched['ResNomContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ResPrenomContact">Prénom du contact : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ResPrenomContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ResPrenomContact']}
                                                            error={errors['ResPrenomContact']}
                                                            touched={touched['ResPrenomContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ResFonctionContact">Fonction : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ResFonctionContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ResFonctionContact']}
                                                            error={errors['ResFonctionContact']}
                                                            touched={touched['ResFonctionContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ResGsmContact">Tél. portable: </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ResGsmContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ResGsmContact']}
                                                            error={errors['ResGsmContact']}
                                                            touched={touched['ResGsmContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ResTelContact">Tél. fixe: </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ResTelContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ResTelContact']}
                                                            error={errors['ResTelContact']}
                                                            touched={touched['ResTelContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ResEmailContact">Email : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ResEmailContact" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ResEmailContact']}
                                                            error={errors['ResEmailContact']}
                                                            touched={touched['ResEmailContact']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 mt-3">
                                                    <div className="col d-flex justify-content-end px-0">
                                                        <button onClick={handleSubmit} className="btn btn-success">Enregister</button>
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="2" className="animated fadeIn">
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="estAlliance">Ce réseau est une mutualisation : </label>
                                                    <div className="col-md-8">
                                                        {values['estAlliance'] == '1' ? 'Oui' : 'Non'}
                                                    </div>
                                                </div>
                                            </TabPane>
                                            <TabPane tabId="3" className="animated fadeIn">
                                                <div className="form-group col-12 mb-1 d-flex center-v">
                                                    <label className="col-md-4 mb-0" for="ResLabelCustomFieldClient">Champ personnalisé (clients) : </label>
                                                    <div className="col-md-8">
                                                        <MyField name="ResLabelCustomFieldClient" type="text"
                                                            className="form-control"
                                                            onChange={handleChange}
                                                            customOnChange={setFieldValue}
                                                            value={values['ResLabelCustomFieldClient']}
                                                            error={errors['ResLabelCustomFieldClient']}
                                                            touched={touched['ResLabelCustomFieldClient']}>
                                                        </MyField>
                                                    </div>
                                                </div>
                                            </TabPane>

                                        </TabContent>

                                    </div>
                                </form>
                            }
                        </BlockUi>
                    </div >
                </div>
            </div>
        </div >

    )
})

export default SettingsView
