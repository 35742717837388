
import React from 'react'
import { observable, toJS } from 'mobx'
import api from '../api'
import UserStore from './UserStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faMinusCircle, faPlusSquare, faMinusSquare } from '@fortawesome/free-solid-svg-icons'
import { number } from '../utils/numeral'
import moment from 'moment'

const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours ...'
    },
    columns: [
        {
            Header: ' ',
            accessor: '-',
            isFilterable: false,
            Cell: ({ row, cell: { value } }) => (
                row.values['nb_credit'] > 0 ?
                    <FontAwesomeIcon icon={faPlusSquare} size="lg" className="text-success"></FontAwesomeIcon> :
                    <FontAwesomeIcon icon={faMinusSquare} size="lg" className="text-danger"></FontAwesomeIcon>

            )
        },
        {
            Header: 'Réseau',
            accessor: 'NomReseau',
        },
        {
            Header: 'Motif',
            accessor: 'motif',
        },
        {
            Header: 'Nb de crédits',
            accessor: 'nb_credit',
            Cell: ({ cell: { value } }) => number(((value < 0) ? -1 * value : value) / 10),
        },
        {
            Header: 'Solde précédent',
            accessor: 'solde_precedent',
            Cell: ({ cell: { value } }) => number(value / 10),
        },
        {
            Header: 'Nouveau solde',
            accessor: 'nouveau_solde',
            Cell: ({ cell: { value } }) => number(value / 10),
        },
        {
            Header: 'ID Tarif',
            accessor: 'idTarif',
            isHidden: true
        },
        {
            Header: 'Mode',
            accessor: 'mode',
            Cell: ({ cell: { value } }) => {
                switch (value) {
                    case '0':
                        return 'Via paiement CB';
                    case '1':
                        return 'Manuel';
                    case '2':
                        return 'Automatique';
                    default:
                        return value;
                }
            }
        },
        {
            Header: 'Durée',
            accessor: 'DureeAvantExpiration',
        },
        {
            Header: 'Utilisateur',
            accessor: 'user',
            isHidden: true
        },
        {
            Header: 'Enregistré le',
            accessor: 'date_insertion',
            Cell: ({ cell: { value } }) => {
                // console.log(value)
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY [à] HH[h]mm')
                else
                    return "-"
            },
        },
        {
            Header: 'Modifié le',
            accessor: 'date_modification',
            isHidden: true,
            Cell: ({ cell: { value } }) => {
                // console.log(value)
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY [à] HH[h]mm')
                else
                    return "-"
            },
        },
    ],
    data: [],
    filters: [],
    pageStart: 0,
    pageIndex: 0,
    pageLimit: 50,
    pageTotal: 0,
    pageCount: 0,
    filter: {
        search: '',
        mode: -1,
        type: 0
    },
    sortBy: 'date_insertion',
    sortOrder: 'DESC',
}

const MouvementsCreditsStore = {
    /***** Props ******/
    props: props,

    /***** Methods ******/
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    async init() {
        this.reset()
        this.doLoading(true, 'Chargement en cours ...')
        await this.getMouvements()
        this.doLoading(false)
    },
    async getMouvements() {

        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        this.props.sortBy = this.props.sortBy || 'date_insertion'
        this.props.sortOrder = this.props.sortOrder || 'DESC'

        let where = ''
        if (UserStore.isReseau())
            where = this.getWhere('r.IdReseauFid = ' + idRes)
        else
            where = this.getWhere('r.idcommercantFid = ' + idCom)

        let data = {
            'objName': 'mouvements_credits',
            'cmd': 'getRows',
            'where': where,
            'start': this.props.pageStart,
            'limit': this.props.pageLimit,
            'sort': this.props.sortBy,
            'dir': this.props.sortOrder,
        }

        const res = await api.post('/scripts/histopaiements/mouvements_credits.php', data)

        if (res.data.success) {
            if (res.data.rows) {
                this.props.data = res.data.rows
                this.props.pageTotal = res.data.totalCount
                this.props.pageCount = Math.ceil(res.data.totalCount / this.props.limit)

            } else {
                this.props.data = []
            }
        }

        return this.props.data
    },
    async updateFilter(field, value) {
        this.props.filter[field] = value
        this.doLoading(true, 'Chargement en cours ...')
        await this.getMouvements()
        this.doLoading(false)

    },
    getWhere(where) {

        Object.keys(this.props.filter).map(key => {
            if (key == 'mode' && this.props.filter[key] != -1) {
                where += ' AND mode=' + this.props.filter[key]
            }
            if (key == 'type' && this.props.filter[key] != 0) {
                where += ' AND ' + (this.props.filter[key] == 1 ? 'nb_credit > 0' : 'nb_credit < 0')
            }
            if (key == 'search' && this.props.filter[key] != '') {
                where += " AND  ( lower(user) like '%" + this.props.filter[key] + "%' OR lower(motif) like '%" + this.props.filter[key] + "%' )"
            }
        })

        return where
    },
    async nextPage() {
        this.props.pageIndex = this.props.pageIndex + 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getMouvements()
        this.doLoading(false)
    },
    async prevPage() {
        this.props.pageIndex = this.props.pageIndex - 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getMouvements()
        this.doLoading(false)
    },
    async goToPage(page) {
        this.props.pageIndex = page
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getMouvements()
        this.doLoading(false)
    },
    async setSort(value) {
        this.doLoading(true, 'Chargement en cours ...')
        if (this.props.sortBy != value) {
            this.props.sortBy = value
            this.props.sortOrder = 'ASC'
        }
        else {
            if (this.props.sortOrder == 'ASC')
                this.props.sortOrder = 'DESC'
            else if (this.props.sortOrder == 'DESC') {
                this.props.sortOrder = false
                this.props.sortBy = null

            }
        }

        await this.getMouvements()
        this.doLoading(false)
        // console.log(value, this.props.sortOrder)
    },
    reset() {
        this.props = props
    }
}

export default observable(MouvementsCreditsStore)