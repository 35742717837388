import React, { useEffect } from 'react'
import SegmentationStore from '../../stores/SegmentationStore'
import { BarLoader } from '../Loader/Loading'
import BlockUi from 'react-block-ui'
import { observer } from 'mobx-react'
import { SegmentsInfo, SegmentsText, SegmentsDataSets } from '../../utils/constants'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle, faUsers } from '@fortawesome/free-solid-svg-icons'
import { number } from '../../utils/numeral'
import { RadarSegmentation } from '../../utils/chartUtils'
import { Radar } from 'react-chartjs-2'
import pluralize from 'pluralize'
import { withRouter } from 'react-router-dom'

const SegmentItem = observer((props) => {

    const { item, index, history, getClientsFromSegment } = props
    const bg_colors = ['bg-secondary', 'bg-navy', 'bg-danger', 'bg-orange', 'bg-warning', 'bg-lightblue', 'bg-info', 'bg-olive', 'bg-teal', 'bg-success']
    return (
        <div className="col-md-10">
            <div class={"card text-white " + bg_colors[index]}>
                <div className="card-header">
                    <h5>
                        {SegmentsInfo[item.label]}
                    </h5>
                </div>
                <div class="card-body d-flex flex-column">
                    <div className="row">
                        <div className="col-md-6">
                            <div style={{ minHeight: '80px' }}>
                                <p>
                                    <FontAwesomeIcon className="mr-2" icon={faInfoCircle}></FontAwesomeIcon>
                                    {SegmentsText[item.label]}
                                </p>
                            </div>
                            {/* <hr class="w-100" style={{ background: '#fff'}} /> */}
                            <div className="d-flex flex-column center-v mb-5">
                                <FontAwesomeIcon className="mr-2" size="2x" icon={faUsers}></FontAwesomeIcon>
                                <span className="h4">
                                    {number(item.data[0])}{pluralize(' client', item.data[0])}
                                </span>
                            </div>
                            <div className="d-flex flex-column center-v">
                                <span className="h4">
                                    {number(item.ratio[0])}{'%'}
                                </span>
                                <div className="progress w-100" style={{ height: '0.5rem' }}>
                                    <div className="progress-bar bg-white" style={{ width: item.ratio + '%' }}
                                        role="progressbar" style={{ width: item.ratio + '%' }}
                                        aria-valuenow="56" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>

                            </div>
                            <div className="d-flex flex-column center-v mt-4">
                                <button className="btn btn-lg btn-outline-white" onClick={() => getClientsFromSegment(item.label, history)}>Voir les clients</button>
                            </div>
                        </div>
                        <div className="col-md-6" style={{ height: '300px' }}>
                            <Radar
                                data={{
                                    labels: ['Montant', 'Récence', 'Fréquence'],
                                    datasets: [
                                        {
                                            backgrounColor: "rgba(0, 0, 0, 0.8)",
                                            label: "Forme du client",
                                            data: SegmentsDataSets[item.label]
                                        }
                                    ]
                                }}
                                options={RadarSegmentation()}
                            />

                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

})

const SegmentationView = (props) => {

    const { history } = props

    useEffect(() => {
        SegmentationStore.init()
    }, [])

    const loadingState = SegmentationStore.props.loading.status
    const loadingMsg = SegmentationStore.props.loading.msg
    const segments = SegmentationStore.props.data

    const getClientsFromSegment = (segment, history) => SegmentationStore.getClientsFromSegment(segment, history)

    return (
        <div className="container-fluid mb-3 mt-3">
            <div className="animated fadeIn">
                <BlockUi tag="div" blocking={loadingState}
                    message={loadingMsg}
                    loader={<BarLoader></BarLoader>}
                    style={{ minHeight: 'calc(100vh - 120px)' }}>
                    <div className="col">
                        <div className="card">
                            <div className="card-body">
                                <div className="row center-h">
                                    {
                                        segments.map((item, index) =>
                                            <SegmentItem
                                                item={item}
                                                getClientsFromSegment={getClientsFromSegment}
                                                index={index}
                                                history={history}
                                            >

                                            </SegmentItem>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                </BlockUi>
            </div>
        </div>
    )
}

export default withRouter(observer(SegmentationView))