import React, { useEffect } from 'react'
import { Link } from 'react-router-dom';
import BlockUi from 'react-block-ui';
import StatsStore from '../../stores/StatsStore';
import { BarLoader } from '../Loader/Loading';
import MyField from '../../reusable/MyField';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import { Line } from 'react-chartjs-2';
import { generateDatasetsMultiple, generateOptionsMultiple } from '../../utils/chartUtils';
import { number } from '../../utils/numeral';
import UserStore from '../../stores/UserStore';



const StatsFilter = observer((props) => {
    const {
        validationSchema,
        initialValues,
        getStats,
        listEnseignes,
        isReseau,
        isLoading
    } = props

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit, validateForm, handleReset } = useFormik({
        enableReinitialize: true,
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => getStats(values),
        validateOnChange: false,
        validateOnBlur: false
    })

    return (
        <div className="col-12">
            <BlockUi tag="div" className="card" blocking={isLoading} message="Chargement en cours ..." loader={<BarLoader></BarLoader>}>
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <h4 className="card-title">Filtre</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 d-flex">
                            <div className="mx-2">
                                <label className="text-nowrap">Date début</label>
                                <MyField name="startDate"
                                    type="date"
                                    className="form-control"
                                    customOnChange={setFieldValue}
                                    value={values['startDate']}
                                    error={errors['startDate']}
                                >
                                </MyField>
                            </div>
                            <div className="mx-2">
                                <label className="text-nowrap">Date fin</label>
                                <MyField name="endDate"
                                    type="date"
                                    className="form-control"
                                    customOnChange={setFieldValue}
                                    value={values['endDate']}
                                    error={errors['endDate']}
                                >
                                </MyField>
                            </div>
                            {/* <div className="mx-2">
                                <label className="text-nowrap">Grouper par</label>
                                <MyField name="groupBy"
                                    type="select"
                                    list={[
                                        { id: 'day', label: 'Jour' },
                                        { id: 'month', label: 'Mois' },
                                        { id: 'year', label: 'Année' },
                                    ]}
                                    className="form-control"
                                    onChange={handleChange}
                                    value={values['groupBy']}
                                    error={errors['groupBy']}
                                >
                                </MyField>
                            </div> */}
                        </div>
                        {isReseau &&

                            <div className="col-md-6 row">
                                <label className="text-nowrap">Enseignes</label>
                                <MyField name="enseignes"
                                    type="multipleSimple"

                                    list={listEnseignes}
                                    className="col-4"
                                    customOnChange={setFieldValue}
                                    value={values['enseignes']}
                                    error={errors['enseignes']}
                                >
                                </MyField>
                            </div>
                        }
                    </div>
                    <div className="row mt-3">
                        <div className="col-12 d-flex">
                            <button className="btn btn-success ml-auto" onClick={() => handleSubmit()}>
                                Valider
                            </button>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </div>
    )
})

const GeneralStats = observer((props) => {

    const {
        data
    } = props

    return (


        Object.keys(StatsStore.props.stats).map(key => {
            let item = StatsStore.props.stats[key]

            return (
                <>
                    {
                        (item.display_general || item.loading) &&

                        <div className="col-md-3 col-sm-12">
                            <BlockUi tag="div" className="card" blocking={item.loading} loader={<BarLoader></BarLoader>}>
                                <div className="card-body  d-flex w-100 align-items-center">
                                    <div className="w-100">
                                        <span className="info-box-text">{item.name}</span>
                                        <div className="text-muted font-weight-bold ">
                                            <span className="text-fid h1">
                                                {number(item.total)}{' '}{item.unites}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </BlockUi>
                        </div>
                    }
                </>
            )
        })


    )
})

const ChartLine = observer((props) => {
    const {
        name,
        loading,
        data,
        total,
        unites,
        max,
        min,
        average,
        options
    } = props

    return (
        <div className="col-12">
            <BlockUi tag="div" className="card" blocking={loading} loader={<BarLoader></BarLoader>}>
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <h4 className="card-title">{name}</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12" style={{ height: '300px', paddingTop: '0px' }}>
                            <Line data={data} style={{ height: 300 }} options={options} />
                        </div>
                    </div>
                    <div className="row">
                        {
                            name != 'Panier moyen' &&

                            <div className="col-md-3 col-sm-12">
                                <div className="card">
                                    <div className="card-body  d-flex w-100 align-items-center">
                                        <div className="w-100">
                                            <span className="info-box-text">Total</span>
                                            <div className="text-muted font-weight-bold ">
                                                <span className="text-fid h1">
                                                    {number(total)}{' '}{unites}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        <div className="col-md-3 col-sm-12">
                            <div className="card">
                                <div className="card-body  d-flex w-100 align-items-center">
                                    <div className="w-100">
                                        <span className="info-box-text">Minimum</span>
                                        <div className="text-muted font-weight-bold ">
                                            <span className="text-fid h1">
                                                {number(min)}{' '}{unites}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="card">
                                <div className="card-body  d-flex w-100 align-items-center">
                                    <div className="w-100">
                                        <span className="info-box-text">Maximum</span>
                                        <div className="text-muted font-weight-bold ">
                                            <span className="text-fid h1">
                                                {number(max)}{' '}{unites}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                            <div className="card">
                                <div className="card-body  d-flex w-100 align-items-center">
                                    <div className="w-100">
                                        <span className="info-box-text">Moyenne</span>
                                        <div className="text-muted font-weight-bold ">
                                            <span className="text-fid h1">
                                                {number(average)}{' '}{unites}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </BlockUi>
        </div>
    )
})

const StatsView = observer(() => {

    useEffect(() => {
        StatsStore.init()
    }, [])

    return (
        <div className="container-fluid mt-3">
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <BlockUi tag="div" blocking={StatsStore.props.loading.status}
                            message={StatsStore.props.loading.msg}
                            loader={<BarLoader></BarLoader>}
                            style={{ minHeight: 'calc(100vh - 150px)' }}>
                            <div className="row">
                                <StatsFilter
                                    listEnseignes={StatsStore.props.listEnseignes}
                                    validationSchema={StatsStore.props.validationSchema}
                                    initialValues={StatsStore.props.initialValues}
                                    getStats={(values) => StatsStore.getStats(values)}
                                    isReseau={UserStore.isReseau()}
                                    isLoading={StatsStore.isLoading()}
                                ></StatsFilter>
                            </div>
                            <div className="row">
                                <GeneralStats
                                    data={StatsStore.props.stats}
                                >
                                </GeneralStats>
                            </div>

                            {
                                Object.keys(StatsStore.props.stats).map(key => {
                                    let item = StatsStore.props.stats[key]

                                    return (
                                        <div className="row">
                                            {
                                                (item.display_chart || item.loading) &&
                                                <ChartLine
                                                    name={item.name}
                                                    data={generateDatasetsMultiple(item.data, item.labels, item.unites, item.groupedBy)}
                                                    total={item.total}
                                                    unites={item.unites}
                                                    average={item.average}
                                                    min={item.min}
                                                    max={item.max}
                                                    loading={item.loading}
                                                    options={generateOptionsMultiple(item.groupedBy)}
                                                ></ChartLine>
                                            }
                                        </div>
                                    )
                                })
                            }

                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default StatsView