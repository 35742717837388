import React, { useState } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import classnames from 'classnames';
import { unmountComponentAtNode, render } from 'react-dom';
import parse from 'html-react-parser';

const ConfirmModal = (props) => {

    const { title, password, info, close } = props

    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(true);


    const handleClick = () => {
        if (value == password) {
            setOpen(false)
            close(true)
        }
        else
            setError(true)
    }

    const handleChange = (e) => {
        setError(false)
        setValue(e.target.value)
    }

    return (
        <Modal isOpen={open} backdrop="static" toggle={() => setOpen(!open)}>
            <ModalHeader>
                Confirmation {title}
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <p>{info}</p>
                            <p>Veuillez saisir le mot : <strong>{password}</strong></p>
                        </div>
                        <div className="form-group col">
                            <input type="text" onChange={handleChange} className={classnames('form-control', { 'is-invalid': error })} />
                            {error && <div className="invalid-feedback">La valeur saisie est incorrecte</div>}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn-danger btn" onClick={() => setOpen(!open)}>Fermer</button>
                <button className="btn-success btn" onClick={handleClick}>Confirmer</button>
            </ModalFooter>
        </Modal>
    )
}

const ConfirmModalNoPassword = (props) => {

    const { title, text, close } = props

    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(true);


    const handleClick = () => {
        setOpen(false)
        close(true)
    }

    return (
        <Modal isOpen={open} backdrop="static" toggle={() => setOpen(!open)}>
            <ModalHeader>
                Confirmation {title}
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col">
                        <div className="form-group">
                            <p>{parse(text)}</p>
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn-danger btn" onClick={() => { setOpen(!open); close(false) }}>Annuler</button>
                <button className="btn-success btn" onClick={handleClick}>Confirmer</button>
            </ModalFooter>
        </Modal>
    )
}

const ConfirmAffectModal = (props) => {

    const { selectedRow, choice, typeAffect, close } = props

    const [open, setOpen] = useState(true);


    const handleClick = () => {
        close(true)
    }

    return (
        <Modal isOpen={open} backdrop="static" toggle={() => setOpen(!open)}>
            <ModalHeader>
                Confirmation affectation
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col">
                        {typeAffect == 'cardToFiche' &&
                            <>
                                <p>Êtes vous sûr associer cette carte : </p>
                                <p><strong>{choice.clientNumCard}</strong></p>
                                <p>A cette fiche : </p>

                                <table className="table border rounded table-borderless table-sm">
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Nom</td>
                                            <td><strong>{selectedRow.cltNom}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Prénom</td>
                                            <td><strong>{selectedRow.cltPrenom}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Date de naissance</td>
                                            <td><strong>{selectedRow.cltNeLe}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td><strong>{selectedRow.cltEmail}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>N° de tél mobile</td>
                                            <td><strong>{selectedRow.cltGsm}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        }
                        {
                            typeAffect == 'ficheToCard' &&
                            <>
                                <p>Êtes vous sûr associer cette fiche : </p>
                                <table className="table border rounded  table-sm table-borderless">
                                    <thead>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Nom</td>
                                            <td><strong>{choice.clientNom}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Prénom</td>
                                            <td><strong>{choice.clientPrenom}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Date de naissance</td>
                                            <td><strong>{choice.clientNeLe}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>Email</td>
                                            <td><strong>{choice.clientEmail}</strong></td>
                                        </tr>
                                        <tr>
                                            <td>N° de tél mobile</td>
                                            <td><strong>{choice.clientGsm}</strong></td>
                                        </tr>
                                    </tbody>
                                </table>
                                <p>A cette carte : </p>
                                <p><strong>{selectedRow.cltNumCard}</strong></p>

                            </>
                        }
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn-danger btn" onClick={() => { setOpen(!open); close(false) }}>Fermer</button>
                <button className="btn-success btn" onClick={handleClick}>Confirmer</button>
            </ModalFooter>
        </Modal >
    )
}

const ConfirmDleteClientModal = (props) => {

    const { title, password, client, close } = props

    const [value, setValue] = useState('');
    const [error, setError] = useState(false);
    const [open, setOpen] = useState(true);


    const handleClick = () => {
        if (value == password) {
            setOpen(false)
            close(true)
        }
        else
            setError(true)
    }

    const handleChange = (e) => {
        setError(false)
        setValue(e.target.value)
    }

    return (
        <Modal isOpen={open} backdrop="static" toggle={() => setOpen(!open)}>
            <ModalHeader>
                Confirmation {title}
            </ModalHeader>
            <ModalBody>
                <div className="row">
                    <div className="col">
                        <div className="form-group col">
                            {client &&
                                <>
                                    <p>Cette suppression va entrainer la destruction définitive du client :</p>
                                    <p>{client}</p>
                                    <p>L'historique de toutes ses transactions sera aussi perdu.</p>
                                </>
                            }
                            {!client &&
                                <>
                                    <p>Cette suppression va entrainer la destruction définitive des clients</p>
                                </>
                            }
                            <p>Afin de confirmer cette suppression, veuillez saisir le mot : <strong>{password}</strong></p>
                        </div>
                        <div className="form-group col">
                            <input type="text" onChange={handleChange} className={classnames('form-control', { 'is-invalid': error })} />
                            {error && <div className="invalid-feedback">La valeur saisie est incorrecte</div>}
                        </div>
                    </div>
                </div>
            </ModalBody>
            <ModalFooter>
                <button className="btn-danger btn" onClick={() => { setOpen(!open); close(false); }}>Fermer</button>
                <button className="btn-success btn" onClick={handleClick}>Confirmer</button>
            </ModalFooter>
        </Modal>
    )
}

export const confirmation = (props) => {
    return new Promise(resolve => {
        let el = document.createElement('div');

        const handleResolve = result => {
            unmountComponentAtNode(el);
            el = null;
            resolve(result);
        };
        if (props.type == 'deleteClient')
            render(<ConfirmDleteClientModal {...props} close={handleResolve} />, el);
        else if (props.type == 'confirmAffect')
            render(<ConfirmAffectModal {...props} close={handleResolve} />, el);
        else if (props.type == 'confirmNoPass')
            render(<ConfirmModalNoPassword {...props} close={handleResolve} />, el);
        else
            render(<ConfirmModal {...props} close={handleResolve} />, el);
    });
};

export default confirmation