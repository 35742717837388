import React from 'react'
import { observable, toJS } from 'mobx'
import { Civilite, Operators } from '../utils/constants'
import UserStore from './UserStore'
import api from '../api'

const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours'
    },
    savedPattern: {},
    pattern: {
        gagnants: {
            active: false,
            title: 'Gagnants',
            name: 'gagnants',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                name: 'gagnants',
                type: 'multiple',
                list: [
                    { id: "lot_gagnant <> 0", label: "Gagnant(s) uniquement" },
                ]
            },
            value: [{
                name: 'gagnants',
                custom: true,
                field: 'gagnants',
                operator: 'in',
                type: 'text',
                value: []
            }],
            defaultValue: {
                name: 'gagnants',
                custom: true,
                field: 'gagnants',
                type: 'array',
                operator: 'in',
                value: []
            },
        },
        idcommercantFid: {
            active: false,
            title: 'Enseigne(s)',
            name: 'idcommercantFid',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                name: 'idcommercantFid',
                type: 'multiple',
                list: [
                    { id: 1, label: '__--FILTRE NON CHARGE--__' },
                    { id: 2, label: '__--FILTRE NON CHARGE--__' },
                    { id: 3, label: '__--FILTRE NON CHARGE--__' },
                    { id: 4, label: '__--FILTRE NON CHARGE--__' },
                    { id: 5, label: '__--FILTRE NON CHARGE--__' },
                    { id: 6, label: '__--FILTRE NON CHARGE--__' },
                ],
            },
            value: [{
                name: 'idcommercantFid',
                field: 'clientfid.idcommercantFid',
                campField: 'c.idcommercantFid',
                operator: 'in',
                type: 'text',
                value: []
            }],
            defaultValue: {
                name: 'idcommercantFid',
                field: 'clientfid.idcommercantFid',
                campField: 'c.idcommercantFid',
                type: 'array',
                operator: 'in',
                value: []
            },
        },
        clientNumCard: {
            active: false,
            quickSearch: true,
            title: 'N° de carte',
            name: 'clientNumCard',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientNumCard',
                type: 'text'
            },
            value: [{
                name: 'clientNumCard',
                field: 'clientNumCard',
                campField: 'c.clientNumCard',
                operator: 'like',
                type: 'text',
                value: ''
            }],
            defaultValue: {
                name: 'clientNumCard',
                field: 'clientNumCard',
                campField: 'c.clientNumCard',
                operator: 'like',
                type: 'text',
                value: ''
            }
        },
        clientCivilite: {
            active: false,
            title: 'Civilité',
            name: 'clientCivilite',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                type: 'multiple',
                list: Civilite.map((item, index) => ({ id: index, label: item })),
            },
            value: [{
                name: 'clientCivilite',
                field: 'clientCivilite',
                campField: 'c.clientCivilite',
                operator: 'in',
                value: Civilite.map((item, index) => index)
            }],
            defaultValue: {
                name: 'clientCivilite',
                field: 'clientCivilite',
                campField: 'c.clientCivilite',
                type: 'array',
                operator: 'in',
                value: Civilite.map((item, index) => index)
            }

        },
        clientNom: {
            active: false,
            quickSearch: true,
            title: 'Nom',
            name: 'clientNom',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientNom',
                type: 'text'
            },
            value: [{
                name: 'clientNom',
                field: 'clientNom',
                campField: 'c.clientNom',
                operator: 'like',
                type: 'text',
                value: ''
            }],
            defaultValue: {
                name: 'clientNom',
                field: 'clientNom',
                campField: 'c.clientNom',
                operator: 'like',
                type: 'text',
                value: ''
            }
        },
        clientPrenom: {
            active: false,
            quickSearch: true,
            title: 'Prénom',
            name: 'clientPrenom',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientNom',
                type: 'text'
            },
            value: [{
                name: 'clientPrenom',
                field: 'clientPrenom',
                campField: 'c.clientPrenom',
                operator: 'like',
                type: 'text',
                value: ''
            }],
            defaultValue: {
                name: 'clientPrenom',
                field: 'clientPrenom',
                campField: 'c.clientPrenom',
                type: 'text',
                operator: 'like',
                value: ''
            }
        },
        clientTel: {
            active: false,
            title: 'Tél. fixe',
            canAddOr: false,
            name: 'clientTel',
            operator: {
                type: 'select',
                list: ['like'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientTel',
                type: 'text'
            },
            value: [{
                name: 'clientTel',
                field: 'clientTel',
                campField: 'c.clientTel',
                type: 'text',
                operator: 'like',
                value: null
            }],
            defaultValue: {
                name: 'clientTel',
                field: 'clientTel',
                campField: 'c.clientTel',
                type: 'text',
                operator: 'like',
                value: null
            }
        },
        clientGsm: {
            active: false,
            quickSearch: true,
            title: 'Tél. mobile',
            canAddOr: false,
            name: 'clientGsm',
            operator: {
                type: 'select',
                list: ['like'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientGsm',
                type: 'text'
            },
            value: [{
                name: 'clientGsm',
                field: 'clientGsm',
                campField: 'c.clientGsm',
                type: 'text',
                operator: 'like',
                value: null
            }],
            defaultValue: {
                name: 'clientGsm',
                field: 'clientGsm',
                campField: 'c.clientGsm',
                type: 'text',
                operator: 'like',
                value: null
            }
        },
        encartes: {
            active: false,
            title: 'Clients encartés',
            name: 'encartes',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                name: 'encartes',
                type: 'multiple',
                list: [
                    { id: "(clientNumCard <> '' or clientNumCard is not null)", label: 'Ayant une carte' },
                    { id: "(clientNumCard = '' or clientNumCard is null)", label: "N'ayant pas de carte" },
                ]
            },
            value: [{
                name: 'encartes',
                custom: true,
                field: 'encartes',
                operator: 'in',
                type: 'text',
                value: []
            }],
            defaultValue: {
                name: 'encartes',
                custom: true,
                field: 'encartes',
                type: 'array',
                operator: 'in',
                value: []
            },
        },
        clientOrigine: {
            active: false,
            title: 'Origine client',
            name: 'clientOrigine',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                name: 'clientOrigine',
                type: 'multiple',
                list: [
                    { id: 1, label: '__--FILTRE NON CHARGE--__' }
                ],
            },
            value: [{
                name: 'clientOrigine',
                field: 'clientfid.clientOrigine',
                operator: 'in',
                type: 'text',
                value: []
            }],
            defaultValue: {
                name: 'clientOrigine',
                field: 'clientfid.clientOrigine',
                type: 'array',
                operator: 'in',
                value: []
            },
        },
    }

}
const ParticipantsFilterStore = {

    /**** PROPS ****/

    props: props,

    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    async initFilterParticipants(pattern) {
        if (pattern)
            this.props.pattern = pattern
        console.log('initfilter')
        if (UserStore.isReseau()) {
            if (!UserStore.isCampagne())
                await this.getEnseignes()
        }
        else {
            delete this.props.pattern.idcommercantFid
        }

        if (UserStore.isCampagne()) {
            delete this.props.pattern.idcommercantFid
            delete this.props.pattern.ruleId
            delete this.props.pattern.segment
            delete this.props.pattern.clientNumCard
            delete this.props.pattern.nbJrsExpirationPoint
            delete this.props.pattern.nbJrsExpirationEuro
            delete this.props.pattern['1erAchat']
            delete this.props.pattern.achatEff
            delete this.props.pattern.nbPoint
            delete this.props.pattern.nbEuro
            delete this.props.pattern.cumulEuro
            delete this.props.pattern.cumulPoint
        }

        if (UserStore.isWallet() || UserStore.isFidSimple()) {
            delete this.props.pattern.nbPoint
            delete this.props.pattern.cumulPoint
            delete this.props.pattern.nbJrsExpirationPoint
        }

        if (!UserStore.isCampagne())
            await this.getOrigines()
        // await this.getSavedFilters()

        Object.keys(this.props.pattern)
            .map(item => {
                if (UserStore.isWallet()) {
                    if (this.props.pattern[item].name == 'clientNumCard') {
                        this.props.pattern[item].title = 'N° Client'
                    }
                }
                this.props.pattern[item].title = this.props.pattern[item].title.replace('$$point$$', UserStore.props.user.unitesPME1)
                this.props.pattern[item].title = this.props.pattern[item].title.replace('$$euro$$', UserStore.props.user.unitesPME2)
            }
            )
        // await CardsStore.getClients()
        this.props.savedPattern = toJS(this.props.pattern)

    },
    async getOrigines() {
        // this.props.loading.msg = "Chargement en cours"
        // this.props.loading.status = true
        let data = {
            'cmd': 'getRows',
            'objName': 'origine_clients',
            'fields': JSON.stringify(["origine"]),
            'query': ''
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            const data = !res.data.rows ? [] : res.data.rows
            // data.unshift({ id: '', origine: 'Tous' })
            // console.log(data)
            this.props.pattern.clientOrigine.field.list = data.map((item) => ({ id: item.id, label: item.origine }))

        }
        // this.props.loading.status = false
    },
    getQuickSerachItems() {
        let results = []
        Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].quickSearch)
            .map(item => {
                if (UserStore.isWallet()) {
                    if (this.props.pattern[item].name == 'clientNumCard') {
                        this.props.pattern[item].title = 'N° Client'
                    }
                }

                results.push({
                    id: this.props.pattern[item].name, label: this.props.pattern[item].title
                })
            })

        return results
    },
    toggleItemFilter(key) {
        if (!this.props.pattern[key].isNotToggable)
            this.props.pattern[key].active = !this.props.pattern[key].active
    },
    async getEnseignes() {

        const idRes = UserStore.props.user.idRes
        let data = {
            sort: 'NomCommercant',
            dir: 'DESC',
            cmd: 'getRows',
            objName: 'listeCommercants',
            where: 'iDreseauFid=' + idRes
        }

        const res = await api.post('/scripts/commerce/commercant.php', data)
        if (res.data.success) {
            const data = !res.data.rows ? [] : res.data.rows
            this.props.pattern.idcommercantFid.field.list = data.map((item) => ({ id: item.idcommercantFid, label: item.NomCommercant }))

            if (!this.props.pattern.idcommercantFid.value.length)
                this.props.pattern.idcommercantFid.value.forEach((item) => {
                    item.value = data.map((item) => item.idcommercantFid)
                })

        }

    },
    activateItemFilter(key) {
        if (!this.props.pattern[key].isNotToggable)
            this.props.pattern[key].active = true
    },
    changeOperator(key, val, index) {
        console.log(val)
        this.props.pattern[key].value[index].operator = val
        if (val == 'between') {
            this.props.pattern[key].value[index].value = [null, null]
        } else {
            this.props.pattern[key].value[index].value = ''
        }

    },
    changeValue(key, val, index, order) {
        if (this.props.pattern[key].value[index].operator == 'between') {
            this.props.pattern[key].value[index].value[order] = val
        }
        else if (this.props.pattern[key].value[index].operator == 'in') {
            if (val != 'all') {
                let position = this.props.pattern[key].value[index].value.findIndex(o => o == val);
                console.log('position :', position)
                if (position === -1)
                    this.props.pattern[key].value[index].value = [...this.props.pattern[key].value[index].value, val]
                else
                    this.props.pattern[key].value[index].value = [...this.props.pattern[key].value[index].value.filter(item => item != val)]
            } else {
                if (this.props.pattern[key].field.list.length != this.props.pattern[key].value[index].value.length) {
                    this.props.pattern[key].value[index].value = [...this.props.pattern[key].field.list.map(item => item.id)]
                } else {
                    this.props.pattern[key].value[index].value = []
                }
            }
        }
        else {
            this.props.pattern[key].value[index].value = val
        }
        console.log(toJS(this.props.pattern[key].value[index].value))
    },
    addItemFilter(key, type) {
        let newVal = { ...this.props.pattern[key].defaultValue }
        newVal.logic = type
        this.props.pattern[key].value = [...this.props.pattern[key].value, newVal]
    },
    removeItemFilter(key, idx) {
        this.props.pattern[key].value = [...this.props.pattern[key].value.filter((item, index) => index != idx)]
    },
    submitFilter() {
        this.props.savedPattern = toJS(this.props.pattern)
        // this.getCountActiveFilter()
    },
    getWhere(where = '') {

        const main = [...Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].active)
            .map(item => toJS(this.props.pattern[item].value))]

        console.log(where)
        for (let key in main) {
            if (main[key].isHidden)
                continue
            if (where.length) {
                if (main[key].length)
                    where += ' AND ( '
            }
            else
                where += '( '
            let pass = 0
            for (let id in main[key]) {
                if (pass)
                    where += " " + main[key][id].logic + " "
                pass = 1
                where += this.getClause(main[key][id], main[key][id].custom)

            }

            if (where.length) {
                if (main[key].length)
                    where += ')'
            }
        }

        return where

    },
    getClause(clause, isCustom = false) {
        let where = '-+--+-+ ERR -++-+-+'
        if (clause.operator == 'in') {
            if (isCustom) {
                console.log('custom in ', clause.value)
                where = ''
                for (let i = 0; i < clause.value.length; i++) {
                    where += clause.value[i]
                    if ((i + 1) < clause.value.length)
                        where += ' OR '
                }
            }
            else {

                where = clause.field + " IN (" + clause.value.join(',') + ") "
            }
            console.log(where)
            return where
        }
        else if (clause.operator == 'between') {
            if (clause.type == 'date') {
                where = clause.field + " BETWEEN '" + clause.value[0] + "' AND '" + clause.value[1] + "' "
            }
            else if (clause.type == 'number') {
                where = clause.field + " BETWEEN " + clause.value[0] + " AND " + clause.value[1] + " "
            } else {
                where = '__ERROR__1'
            }
        }
        else if (clause.operator == 'like' || clause.operator == 'not like') {
            if (clause.type == 'text') {
                where = clause.field + " " + clause.operator + " '%" + clause.value + "%' "
            }
            else {
                where = '__ERROR__2'
            }
        }
        else if (
            clause.operator == '>' ||
            clause.operator == '<' ||
            clause.operator == '>=' ||
            clause.operator == '<=' ||
            clause.operator == '<>' ||
            clause.operator == '='
        ) {

            if (clause.type == 'date' || clause.type == 'text') {
                where = clause.field + " " + clause.operator + " '" + clause.value + "' "
            }
            else if (clause.type == 'number') {
                where = clause.field + " " + clause.operator + " " + clause.value + " "
            }
            else {
                where = '__ERROR__3'
            }
        }
        else {
            where = '__ERROR__4'
        }
        if (isCustom)
            return clause.field.replace('_$$$_', where.replace(clause.field, ''))
        return where
    },
    resetFilter() {
        Object.keys(this.props.pattern)
            .map(item => {
                this.props.pattern[item].value = props.pattern[item].value
                this.props.savedPattern[item].value = props.pattern[item].value
                this.props.pattern[item].active = false
                this.props.savedPattern[item].active = false
            })
    },
    undoPattern() {
        this.props.pattern = toJS(this.props.savedPattern)
    },
    reset() {
        this.props = props
    }
}

export default observable(ParticipantsFilterStore)