import React, { useState } from 'react'
import moment from 'moment'
import classnames from 'classnames'
// import DatePicker from 'react-date-picker';
import { toJS } from 'mobx';
import { Portal } from 'react-overlays'
import DatePicker from 'react-datepicker'
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import fr from 'date-fns/locale/fr'
import InputMask from "react-input-mask";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import Switch from "react-switch";
import { CirclePicker, SwatchesPicker } from 'react-color';
import ReactQuill from 'react-quill'; // ES6
import 'react-quill/dist/quill.snow.css'; // ES6
import ImageUploader from "react-images-upload";
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';

registerLocale('fr', fr)

const CalendarContainer = ({ children }) => {
    const el = document.getElementById('calendar-portal')

    return (
        <Portal container={el}>
            {children}
        </Portal>
    )
}

const MyField = (props) => {
    const { input,
        type,
        name,
        className,
        placeholder,
        label,
        onChange,
        guide,
        showMask,
        mask,
        value,
        touched,
        error,
        checkboxLabel,
        groupedOptions,
        fetch,
        highlight,
        customOnChange,
        maskChar,
        loadOptions,
        maskPlaceholder,
        defaultValue,
        minDate,
        onKeyDown,
        list,
        readOnly,
        rows,
        defaultImage
    } = props


    const [dropdownOpenColor, setDropdownOpenColor] = useState(false);
    const toggleColor = () => setDropdownOpenColor(prevState => !prevState);




    if (type == 'text' || type == 'email') {
        return (
            <>
                {label && <label>{label}</label>}
                <input
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    onKeyDown={onKeyDown}
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    type={type} />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'password') {
        return (
            <>
                {label && <label>{label}</label>}
                <input
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    onKeyDown={onKeyDown}
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    type={type} />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'textarea') {
        return (
            <>
                {label && <label>{label}</label>}
                <textarea
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    onKeyDown={onKeyDown}
                    rows={rows}
                    className={className + classnames({ " is-invalid": (error || highlight) })}>
                    {value}
                </textarea>
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'number') {
        return (
            <>
                {label && <label>{label}</label>}
                <input
                    name={name}
                    placeholder={placeholder}
                    onChange={onChange}
                    value={value}
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    type={type} />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'date') {
        let valueInput = null
        let valueDatePicker = null

        if (moment(value).isValid()) {
            valueDatePicker = moment(value).toDate()
            valueInput = moment(value).format('YYYY-MM-DD')
        }

        const onChangeDate = (date) => {
            if (customOnChange)
                return customOnChange(name, moment(date).format('YYYY-MM-DD'))
            return onChange(moment(date).format('YYYY-MM-DD'))
        }

        return (
            <>
                <input
                    type="hidden"
                    placeholder={placeholder}
                    value={valueInput}
                    name={name}>

                </input>
                {label && <label>{label}</label>}
                <DatePicker
                    popperContainer={CalendarContainer}
                    selected={valueDatePicker}
                    onChange={date => onChangeDate(date)}
                    locale="fr"
                    minDate={minDate}
                    dateFormat="dd/MM/yyyy"
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'datetime') {
        let valueInput = null
        let valueDatePicker = null

        if (moment(value).isValid()) {
            valueDatePicker = moment(value).toDate()
            valueInput = moment(value).format('YYYY-MM-DD HH:mm')
        }

        const onChangeDate = (date) => {
            console.log(date)
            if (customOnChange)
                return customOnChange(name, moment(date).format('YYYY-MM-DD HH:mm'))
            return onChange(moment(date).format('YYYY-MM-DD HH:mm'))
        }

        return (
            <>
                <input
                    type="hidden"
                    placeholder={placeholder}
                    value={valueInput}
                    name={name}>

                </input>
                {label && <label>{label}</label>}
                <DatePicker
                    popperContainer={CalendarContainer}
                    selected={valueDatePicker}
                    onChange={date => onChangeDate(date)}
                    locale="fr"
                    minDate={minDate}
                    dateFormat="dd/MM/yyyy"
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    timeCaption="time"
                    dateFormat="dd/MM/yyyy HH:mm"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'time') {

        let valueTimePicker = ''

        if (moment(value, 'HH:mm').isValid()) {
            valueTimePicker = moment(value, 'HH:mm').toDate()
        }

        const onChangeTime = (time) => {
            if (moment(time, 'HH:mm').isValid()) {
                if (customOnChange)
                    return customOnChange(name, moment(time).format('HH:mm'))
                return onChange(moment(time).format('HH:mm'))
            }
            else {
                return customOnChange(name, '')
            }
        }

        return (
            <>
                {label && <label>{label}</label>}

                <DatePicker
                    popperContainer={CalendarContainer}
                    selected={valueTimePicker}
                    locale="fr"
                    onChange={time => onChangeTime(time)}
                    showTimeSelect
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    showTimeSelectOnly
                    onFocus={e => e.target.blur()}
                    timeIntervals={15}
                    timeCaption="Heure"
                    dateFormat="HH:mm"
                />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )
    }

    if (type == 'select') {
        return (
            <>
                {label && <label>{label}</label>}
                <select
                    name={name}
                    onChange={onChange}
                    value={value}
                    readOnly={readOnly}
                    className={className + classnames({ " is-invalid": (error || highlight) })}>
                    {
                        (list && list.map((item) => <option value={item.id}>{item.label}</option>))
                    }
                </select>
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }

            </>
        )

    }

    if (type == 'reactSelect') {
        // console.log(error)
        return (
            <>
                {label && <label>{label}</label>}
                <Select
                    value={value}
                    options={list}
                    defaultValue={defaultValue}
                    classNamePrefix={className}

                    getOptionValue={item => item.id}
                    onChange={(e) => customOnChange(name, e)}
                    className={classnames('react-select', { " is-invalid": (error || highlight) })}
                />
                {
                    ((typeof error == "string") && <div className="invalid-feedback">{error}</div>)

                }
                {
                    ((typeof error == "object") && <div className="invalid-feedback">{error.id}</div>)
                }
            </>
        )

    }

    if (type == 'asyncSelect') {

        // const [search,setSearch] = useState('')

        return (
            <>
                {label && <label>{label}</label>}
                <AsyncSelect
                    // value={value}
                    cacheOptions
                    // defaultValue={defaultValue}
                    classNamePrefix={className}
                    loadOptions={fetch}
                    // onInputChange={this.handleInputChange}
                    // onChange={(e) => customOnChange(name, e)}
                    className={classnames('react-select', { " is-invalid": (error || highlight) })}
                />
                {
                    ((typeof error == "string") && <div className="invalid-feedback">{error}</div>)

                }
                {
                    ((typeof error == "object") && <div className="invalid-feedback">{error.id}</div>)
                }
            </>
        )
    }

    if (type == 'label') {


        return (
            <>
                {label && <label>{label} : </label>}
                <br />
                <span>{value}</span>
            </>
        )
    }

    if (type == 'grouped') {
        const formatGroupLabel = data => (
            <div >
                <span className="text-fid">{data.label}</span>
            </div>
        );
        const formatOptionLabel = data => (
            <div className="d-flex justify-content-between">
                <span className="text-old">{data.label}</span>
                {/* <span>({data.heure})</span> */}
            </div>
        );
        return (
            <>

                {label && <label>{label}</label>}
                <Select
                    value={value}
                    options={groupedOptions}
                    formatGroupLabel={formatGroupLabel}
                    classNamePrefix={className}
                    onChange={(e) => customOnChange(name, e)}
                    formatOptionLabel={formatOptionLabel}
                    className={classnames('react-select', { " is-invalid": (error || highlight) })}
                />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }
            </>
        )
    }

    if (type == 'checkbox') {
        return (
            <>
                <div className={"form-check abc-checkbox " + (className != "form-control" ? className : "")}>
                    <input id={name} name={name} checked={value > 0}
                        onChange={(e) => customOnChange(name, e.target.checked ? 1 : 0)}
                        className="form-check-input" type="checkbox" />
                    <label for={name} className="form-check-label">{checkboxLabel}</label>
                </div>
            </>
        )
    }

    if (type == 'multiple') {
        return (
            <>
                <div className={"form-check abc-checkbox abc-checkbox-old-light checkbox-sm col-12"}>
                    <input id={name + '_all'} name={name + '_all'} checked={value.length == list.length}
                        onChange={() => onChange('all')}
                        className="form-check-input" type="checkbox" />
                    <label for={name + '_all'} className="form-check-label">
                        {(value.length == list.length) ? 'Tout  désélectionner' : 'Tout sélectionner'}
                    </label>
                </div>
                {list && list.map(item => (

                    <div className={"form-check abc-checkbox abc-checkbox-old-light checkbox-sm " + className}>
                        <input id={name + '_' + item.id} name={name + '_' + item.id} checked={value.includes(item.id)}
                            onChange={() => onChange(item.id)}
                            className="form-check-input" type="checkbox" />
                        <label for={name + '_' + item.id} className="form-check-label">{item.label}</label>
                    </div>
                ))}
            </>
        )

    }

    if (type == 'multipleSimple') {

        const multipleOnChange = (val) => {
            console.log('onChangeMultiple', name, val, toJS(value))
            if (val != 'all') {
                let position = value.findIndex(o => o == val);
                console.log('position :', position)
                if (position === -1)
                    customOnChange(name, [...value, val])
                else
                    customOnChange(name, [...value.filter(item => item != val)])
            } else {
                if (list.length != value.length) {
                    customOnChange(name, [...list.map(item => item.id)])
                } else {
                    customOnChange(name, [])
                }
            }

        }

        return (
            <>
                <div className={"form-check abc-checkbox abc-checkbox-old-light checkbox-sm col-12"}>
                    <input id={name + '_all'} name={name + '_all'} checked={value.length == list.length}
                        onChange={() => multipleOnChange('all')}
                        className="form-check-input" type="checkbox" />
                    <label for={name + '_all'} className="form-check-label">
                        {(value.length == list.length) ? 'Tout  désélectionner' : 'Tout sélectionner'}
                    </label>
                </div>
                {list && list.map(item => (

                    <div className={"form-check abc-checkbox abc-checkbox-old-light checkbox-sm " + className}>
                        <input id={name + '_' + item.id} name={name + '_' + item.id} checked={value.includes(item.id)}
                            onChange={() => multipleOnChange(item.id)}
                            className="form-check-input" type="checkbox" />
                        <label for={name + '_' + item.id} className="form-check-label">{item.label}</label>
                    </div>
                ))}

                <div className="col-12">
                    {
                        (error && <div className="invalid-feedback d-block">{error}</div>)
                    }
                </div>
            </>
        )

    }

    if (type == 'masked') {
        return (
            <>
                {label && <label>{label}</label>}
                <InputMask
                    mask={mask}
                    name={name}
                    maskPlaceholder={maskPlaceholder}
                    placeholder={placeholder}
                    onChange={onChange}
                    className={className + classnames({ " is-invalid": (error || highlight) })}
                    value={value} />
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }
            </>
        )

    }

    if (type == 'switch') {

        return (
            <Switch
                name={name}
                checked={value}
                onChange={(val) => customOnChange(name, val ? 1 : 0)}
                onColor="#4dbd74"
                onHandleColor="#fff"
                offColor="#f86c6b"
                offHandleColor="#fff"
                handleDiameter={20}
                uncheckedIcon={
                    <div className="d-flex center-h text-white center-v h-100">
                        <FontAwesomeIcon icon={faTimes} size="sm"></FontAwesomeIcon>
                    </div>
                }
                checkedIcon={
                    <div className="d-flex center-h text-white center-v h-100">
                        <FontAwesomeIcon icon={faCheck} size="sm"></FontAwesomeIcon>
                    </div>
                }
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                height={22}
                width={40}
                className="react-switch"
            />
        )
    }

    if (type == 'color') {


        return (
            <>
                <div className={className + classnames(" d-flex", { " is-invalid": (error || highlight) })}>
                    <div className="border rounded w-50 d-flex center-v center-h mx-1">
                        <span>{value}</span>
                    </div>
                    <div className="border rounded w-50 d-flex center-v center-h mx-1" style={{ background: value }}>

                    </div>
                    <div className="mx-1 center-v center-h">
                        <Dropdown isOpen={dropdownOpenColor} toggle={toggleColor}>
                            <DropdownToggle
                                tag="button"
                                className="btn btn-sm btn-old w-100"
                                style={{ padding: "1px 5px" }}
                                data-toggle="dropdown"

                            >
                                Changer
                        </DropdownToggle>
                            <DropdownMenu right className="p-0 border">
                                <SwatchesPicker
                                    color={value}
                                    onChangeComplete={(val) => { customOnChange(name, val.hex); toggleColor() }}
                                />
                            </DropdownMenu>
                        </Dropdown>
                    </div>
                </div>
                {
                    (error && <div className="invalid-feedback">{error}</div>)
                }
            </>

        )
    }

    if (type == 'editor') {

        return (
            <ReactQuill
                value={value}
                onChange={(val) => customOnChange(name, val)}
            />
        )
    }

    if (type == 'image') {

        return (
            <ImageUploader
                name={name}
                withIcon={false}
                buttonText={''}
                buttonClassName={'btn btn-sm btn-old'}
                withPreview={true}
                label={''}
                defaultImages={[process.env['REACT_APP_API_URL'] + defaultImage]}
                singleImage={true}
                buttonText="Choisir une image"
                onChange={(val) => { customOnChange(name, val); console.log(val) }}
                imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                maxFileSize={5242880}
            />
        )
    }
    // if (type == 'image') {

    //     return (

    //         <Avatar
    //             width={390}
    //             height={295}
    //             onCrop={(val) => { customOnChange(name, val) }}
    //             // onClose={this.onClose}
    //             onBeforeFileLoad={(elem) => {
    //                 if (elem.target.files[0].size > 71680) {
    //                     alert("File is too big!");
    //                     elem.target.value = "";
    //                 }
    //             }}
    //             // src={this.state.src}
    //         />
    //     )
    // }




    return (<div>Non reconnu</div>)

}

export default MyField