import React,{useEffect} from 'react'
import Logo from '../../assets/img/logo.png'
import UserStore from '../../stores/UserStore'
import { observer } from "mobx-react"
import { Redirect,useLocation, Link } from 'react-router-dom'
import BlockUi from 'react-block-ui';
import classNames from 'classnames'
import Loading from '../Loading/Loading';
import parse from 'html-react-parser';

const VerifyMailView = () => {
    const location=useLocation();

    const param=new URLSearchParams(location.search)

    const selector=param.get('selector')
    const token=param.get('token')

    // async function verify(param){
    //     return await UserStore.verify(param)
    // }

    useEffect(()=>{
        UserStore.verify({selector:selector,token:token})
    },[selector,token])


    if (UserStore.props.user.isAuthenticated) {
        let to = null
        if (!UserStore.canDashboard() || UserStore.isSaisie()) {
            to = {
                pathname: '/cards',
                state: 'Liste des clients'
            }
        }
        else {
            to = {
                pathname: '/dashboard',
                state: 'Tableau de bord'
            }
        }

        // to = {
        //     pathname: '/geolocation',
        //     state: 'CUSTOM'
        // }

        return (
            <Redirect to={to}></Redirect>
        )
    }

    return (

        <div className="app flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <BlockUi tag="div" className="card-group rounded login-loader" loader={Loading}
                            message={UserStore.props.loading.msg}
                            blocking={UserStore.props.loading.status}>
                            <div className="card p-4">
                                
                                    < div className="card-body">
                                        <div class={classNames("alert",{'alert-success' : UserStore.props.verified.success,'alert-danger' : !UserStore.props.verified.success})} role="alert">
                                            {parse(UserStore.props.verified.error)}
                                            </div>
                                        <div className="row">
                                            <div className="col-6">
                                                <Link to="/login" className="btn btn-old px-4">Se connecter</Link>
                                            </div>
                                        </div>

                                    </div>
                                
                            </div>
                            <div className="card py-5 d-md-down-none">
                                <div className="card-body text-center">
                                    <div>
                                        <img className="login-image" src={Logo} alt="Fidlink Logo" />
                                    </div>
                                    <strong>NOVACARD devient <span className="text-fid">FIDLINK</span></strong>
                                </div>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div >
        </div >

    )
}




export default observer(VerifyMailView)