/* eslint-disable no-unused-expressions */

import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faEdit, faTicketAlt, faUsers, faEnvelope, faCommentDots, faPencilAlt, faArrowRight, faArrowLeft, faCreditCard, faCoins, faPlus, faDesktop, faMobile, faMobileAlt, faBalanceScale, faArrowsAltH, faBell, faCaretDown, faComment, faInfoCircle, faTrashAlt, faTrashRestore, faRedo } from '@fortawesome/free-solid-svg-icons'
import CampagneStore from '../../stores/CampagneStore'
import classnames from 'classnames'
import { DropdownToggle, DropdownItem, DropdownMenu, Dropdown, Tooltip, UncontrolledTooltip, Modal, ModalHeader, } from 'reactstrap'
import BlockUi from 'react-block-ui'
import { BarLoader, RingLoader } from '../Loader/Loading'
import { useTable } from 'react-table/dist/react-table.development'
import DataTable, { ColumnsToggle, Filter, DropdownPortal, Portal } from '../../reusable/DataTable'
import { toJS } from 'mobx'
import Switch from "react-switch";
import { number, currency } from '../../utils/numeral'
import { useFormik } from 'formik'
import MyField from '../../reusable/MyField'
import PrivateLink from '../../reusable/PrivateLink'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { modeEnvoiMean } from '../../utils/constants'
import CampagneWizardStore from '../../stores/CampagneWizardStore'
import { AddCredit } from './CampagneWizard'
import ModalBody from 'reactstrap/lib/ModalBody'
import ModalFooter from 'reactstrap/lib/ModalFooter'

export const EditDropDownCampagne = observer((props) => {
    const {
        row,
        toggleVisibleCampagne,
        toggleModalStatutCampagne,
    } = props
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => {
        setDropdownOpen(dropdownOpen => !dropdownOpen);
    }

    const rootSelector = document.getElementById('root');
    const div = document.createElement("div")
    div.className = "h-0"
    let portalContainer = useRef(div).current

    useEffect(() => {
        // let portalContainer = document.createElement("div");
        rootSelector.appendChild(portalContainer)
        return () => {
            rootSelector.removeChild(portalContainer)
        }
    }, [])



    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);


    return (
        <div className="d-flex">
            {row.etat == 7 &&
                <button className="btn btn-xs btn-outline-old btn-action mr-2" onClick={() => toggleModalStatutCampagne(row.idcampagnesms)}>Voir statut</button>
            }
            {
            row.api_version == 2 &&
                <DropdownPortal isOpen={dropdownOpen} toggle={toggle} portalContainer={portalContainer}>
                    <DropdownToggle
                        tag="button"
                        className="btn btn-xs btn-outline-success btn-action"
                        data-toggle="dropdown"
                        aria-expanded={dropdownOpen}
                    > Actions
                        <FontAwesomeIcon className="ml-2" icon={faCaretDown}></FontAwesomeIcon>
                    </DropdownToggle>
                    <Portal target={portalContainer}>
                        <DropdownMenu right className="p-0">
                            {/* <a className="dropdown-item" tab="1" >
                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2 text-old"></FontAwesomeIcon>
                            Voir le statut</a> */}
                            {
                                (parseInt(row['etat']) == -1 || parseInt(row['etat']) == 1) &&
                                <Link className="dropdown-item" to={{ pathname: "/campaigns/new/" + modeEnvoiMean[row.modeEnvoi], obj: row }} >
                                    <FontAwesomeIcon icon={faPlus} className="mr-2 text-old"></FontAwesomeIcon>
                                    Créer une campagne à partir de celle-ci
                                </Link>
                            }
                            {
                                (parseInt(row['etat']) != -1 && parseInt(row['etat']) != 1) &&
                                <Link className="dropdown-item" to={{ pathname: "/campaigns/edit/" + modeEnvoiMean[row.modeEnvoi], obj: row }} >
                                    <FontAwesomeIcon icon={faPencilAlt} className="mr-2 text-old"></FontAwesomeIcon>
                                        Modifier cette campagne
                                </Link>
                            }
                            {
                                (parseInt(row['etat']) != 5) && (parseInt(row['etat']) != 6) &&
                                <>
                                    {
                                        (parseInt(row['etat']) != -1) &&
                                        <a className="dropdown-item" onClick={() => toggleVisibleCampagne(row['idcampagnesms'], row['etat'])} >
                                            <FontAwesomeIcon icon={faTrashAlt} className="mr-2 text-danger"></FontAwesomeIcon>
                                            Supprimer cette campagne
                                        </a>

                                    }
                                    {
                                        (parseInt(row['etat']) == -1) &&
                                        <a className="dropdown-item" onClick={() => toggleVisibleCampagne(row['idcampagnesms'], row['etat'])}>
                                            <FontAwesomeIcon icon={faTrashRestore} className="mr-2 text-success"></FontAwesomeIcon>
                                            Restaurer cette campagne
                                        </a>

                                    }
                                </>

                            }

                        </DropdownMenu>,
                    </Portal>
                </DropdownPortal>

            }
            {row.api_version != 2 &&
                <>
                    <div className="w-100 text-center">
                        <FontAwesomeIcon icon={faInfoCircle} id="infoCamp" size="lg" className="text-old" ></FontAwesomeIcon>
                    </div>
                    <UncontrolledTooltip placement="left" target="infoCamp">
                        Cette campagne a été crée avec l'ancienne version de FIDLINK. Elle ne peut être modifer ici.
                    </UncontrolledTooltip>
                </>
            }
        </div>
    )


})

const Credits = observer((props) => {

    const {
        loadingMsg,
        loadingStatus,
        credits,
        values,
        errors,
        touched,
        handleChange,
        customOnChange,
        handleSubmit,
        submitForm,
        toggleModalPayment
    } = props

    return (
        <>
            <BlockUi tag="div" blocking={loadingStatus}
                message={loadingMsg}
                loader={<RingLoader></RingLoader>}
            >
                <button onClick={() => toggleModalPayment()} className="btn btn-sm btn-old">
                    <FontAwesomeIcon icon={faCreditCard} className="mr-2"></FontAwesomeIcon>
                Ajouter des crédits</button>
                <div className="border rounded px-2 mt-3">
                    <h6 className="mt-3">
                        {/* <FontAwesomeIcon icon={faCoins} className="mr-2"></FontAwesomeIcon> */}
                    Crédits
                    </h6>
                    <div className="row mx-0">
                        <div className="col-12 px-0">
                            <div className="row mx-0 mb-2">
                                <div className="col-7 px-0">Actuels</div>
                                <div className="col px-0">
                                    <span class="badge bg-success">
                                        {currency(credits.nbSMS * 0.1)}
                                    </span>
                                </div>
                            </div>
                            <div className="row mx-0 mb-2">
                                <div className="col-7 px-0">Cumul dépensés</div>
                                <div className="col px-0">
                                    <span class="badge bg-old">{currency(credits.cumulSMS * 0.1)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border rounded px-2 mt-3">
                    <h6 className="mt-3">
                        {/* <FontAwesomeIcon icon={faArrowsAltH} className="mr-2"></FontAwesomeIcon> */}
                    Equivalences
                    </h6>
                    <div className="row mx-0">
                        <div className="col-12 px-0">
                            <div className="row mx-0 mb-2">
                                <div className="col-7 px-0">Email restants</div>
                                <div className="col px-0">
                                    <span class="badge bg-success">{number(parseInt((credits.nbSMS) / credits.pMail))}</span>
                                </div>
                            </div>
                            <div className="row mx-0 mb-2">
                                <div className="col-7 px-0">SMS restants</div>
                                <div className="col px-0">
                                    <span class="badge bg-success">{number(parseInt((credits.nbSMS) / credits.pSms))}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border rounded px-2 mt-3">
                    <h6 className="mt-3">
                        {/* <FontAwesomeIcon icon={faBell} className="mr-2"></FontAwesomeIcon> */}
                    Seuil Alerte Fin de Crédit
                    </h6>
                    <form onSubmit={handleSubmit} className="row mx-0">
                        <div className="col-12 px-0">
                            <div className="row mx-0 mb-2">
                                <div className="col-7 px-0"> M'alerter ?</div>
                                <div className="col px-0">
                                    <Switch
                                        checked={values['alertActive']}
                                        onChange={(e) => console.log(e)}
                                        onColor="#4dbd74"
                                        onHandleColor="#fff"
                                        offColor="#f86c6b"
                                        offHandleColor="#fff"
                                        handleDiameter={20}
                                        onChange={(checked) => { customOnChange('alertActive', checked); submitForm() }}
                                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                                        activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                        height={15}
                                        width={33}
                                        className="react-switch"
                                    />
                                </div>
                            </div>
                            {values['alertActive'] == 1 &&
                                <>
                                    <div className="row mx-0 mb-2">
                                        <div className="col-7 px-0">Seuil d'alerte</div>
                                        <div className="col px-0">
                                            <MyField name="seuil"
                                                type="number"
                                                className="form-control form-control-sm"
                                                onChange={handleChange}
                                                value={values['seuil']}
                                                error={errors['seuil']}
                                                touched={touched['seuil']}>
                                            </MyField>
                                        </div>
                                    </div>
                                    <div className="row mx-0 mb-2">
                                        <div className="col-7 px-0"></div>
                                        <div className="col px-0">
                                            <button class="btn-sm btn btn-success w-100" type="submit">Valider</button>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </form>
                </div>
            </BlockUi>
        </>
    )
})

const ModalStatutCampagne = observer((props) => {

    const {
        isOpenModalStatutCampagne,
        toggleModalStatutCampagne,
        loadingStatus,
        getStatutCampagne,
        currentCamp,
        loadingMsg,
        campStatut
    } = props

    useEffect(() => {
        console.log(currentCamp)
        let poll = getStatutCampagne(currentCamp)
        return () => clearInterval(poll)
    }, [])

    return (
        <Modal isOpen={isOpenModalStatutCampagne} backdrop="static" toggle={() => toggleModalStatutCampagne()}>
            <BlockUi tag="div" blocking={loadingStatus} message={loadingMsg} loader={<BarLoader></BarLoader>}>
                <ModalHeader toggle={() => toggleModalStatutCampagne()}>
                    Statut campagne
            </ModalHeader>

                <ModalBody>
                    <div className="d-flex pb-2 mb-2">
                        <div className="col d-flex flex-column">
                            <span className={classnames({ 'text-success': ((campStatut.envoye / campStatut.total) * 100) == 100 })}><b>{campStatut.titre}</b></span>
                            <small>Progression : ({currency((campStatut.envoye / campStatut.total) * 100)}%)</small>
                            <div className="progress-group-bars" style={{ width: "100%" }}>
                                <div className="progress">
                                    <div className={classnames('progress-bar', {
                                        'bg-fid': ((campStatut.envoye / campStatut.total) * 100) != 100,
                                        'bg-success': ((campStatut.envoye / campStatut.total) * 100) == 100,
                                    })}
                                        role="progressbar" style={{ width: ((campStatut.envoye / campStatut.total) * 100) + '%' }}
                                        aria-valuenow="56" aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <button type="button" onClick={() => toggleModalStatutCampagne()} className="btn btn-old">Fermer</button>
                </ModalFooter>
            </BlockUi>
        </Modal >
    )
})

const CampagneView = observer(() => {


    useEffect(() => {
        CampagneStore.init()
        // CampagneWizardStore.getTarifs()
    }, [])

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    const campTable = useTable(
        {
            columns: CampagneStore.props.columnsCampagne,
            data: CampagneStore.props.dataCampagne,
            initialState: {
                hiddenColumns: CampagneStore.props.columnsCampagne.filter((item) => (item.isHidden)).map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: CampagneStore.props.pageIndex,
                pageSize: CampagneStore.props.pageLimit,
                pageCount: CampagneStore.props.pageCount,
                pageTotal: CampagneStore.props.pageTotal,
            },
            toggleVisibleCampagne: (id, etat) => CampagneStore.toggleVisibleCampagne(id, etat),
            toggleModalStatutCampagne: (id) => CampagneStore.toggleModalStatutCampagne(id)
        }
    )

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit, submitForm } = useFormik({
        // enableReinitialize: true,
        initialValues: CampagneStore.props.seuilAlertInititalValues,
        validate: (values) => CampagneStore.validateSeuilAlerte(values),
        onSubmit: (values) => CampagneStore.submitSeuilAlerte(values),
        validateOnChange: false,
        validateOnBlur: false
    })



    return (
        <div className="container-fluid mt-3">
            {/* <h2 className="card-title">Campagnes</h2> */}
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <BlockUi tag="div" blocking={CampagneStore.props.loading.status}
                            message={CampagneStore.props.loading.msg}
                            loader={<BarLoader></BarLoader>}
                            className="card" style={{ minHeight: 'calc(100vh - 150px)' }}>
                            <div className="card-body">
                                <div className="row mt-3">
                                    <div className="col-md-2">
                                        <Credits
                                            loadingMsg={CampagneStore.props.loadingCredits.msg}
                                            loadingStatus={CampagneStore.props.loadingCredits.status}
                                            credits={CampagneStore.props.credits}
                                            toggleModalPayment={() => CampagneWizardStore.toggleModalPayment()}
                                            values={values}
                                            errors={errors}
                                            customOnChange={setFieldValue}
                                            touched={touched}
                                            handleChange={handleChange}
                                            handleSubmit={handleSubmit}
                                            submitForm={submitForm}
                                        ></Credits>
                                    </div>
                                    <div className="col">
                                        <div className="d-flex">
                                            <ColumnsToggle
                                                className="btn btn-sm btn-old"
                                                columns={campTable.allColumns}
                                                toggleColumn={(col) => CampagneStore.toggleColumn(col)}
                                            >
                                            </ColumnsToggle>
                                            <Filter
                                                className="btn btn-sm btn-old"
                                                columnsToFilter={CampagneStore.props.filtersCampagne}
                                                setFilterOperator={(index, operator) => CampagneStore.setFilterOperator(index, operator)}
                                                setFilterValue={(index, value) => CampagneStore.setFilterValue(index, value)}
                                                toggleItemFilter={(index) => CampagneStore.toggleItemFilter(index)}
                                                toggleSubItem={(index, subIndex) => CampagneStore.toggleSubItem(index, subIndex)}
                                                resetFilter={() => CampagneStore.resetFilter()}
                                                getFilteredClients={() => CampagneStore.getFilteredCampagnes()}
                                            >
                                            </Filter>
                                            {/* <button className="btn btn-sm btn-old ml-2" onClick={() => CampagneStore.reloadCampagneList()}>
                                                <FontAwesomeIcon icon={faRedo} className="mr-2"></FontAwesomeIcon>
                                                Actualiser la liste</button> */}
                                            <Dropdown isOpen={dropdownOpen} className=" ml-auto" toggle={toggle}>
                                                <DropdownToggle
                                                    tag="button"
                                                    className="btn btn-sm btn-success"
                                                    data-toggle="dropdown"
                                                    aria-expanded={dropdownOpen}
                                                >
                                                    <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>
                                                    Nouvelle Campagne
                                                    <FontAwesomeIcon className="ml-2" icon={faCaretDown}></FontAwesomeIcon>
                                                </DropdownToggle>
                                                <DropdownMenu right className="p-0">
                                                    <Link to="/campaigns/new/sms" className="dropdown-item">
                                                        <FontAwesomeIcon icon={faComment} className="mr-2 text-success"></FontAwesomeIcon>
                                                        Campagne SMS
                                                    </Link>
                                                    <Link to="/campaigns/new/email" className="dropdown-item">
                                                        <FontAwesomeIcon icon={faEnvelope} className="mr-2 text-success"></FontAwesomeIcon>
                                                        Campagne Email
                                                    </Link>
                                                </DropdownMenu>
                                            </Dropdown>

                                        </div>
                                        <div className="row">
                                            <div className="col">
                                                <DataTable
                                                    headerGroups={campTable.headerGroups}
                                                    getTableBodyProps={campTable.getTableBodyProps}
                                                    rows={campTable.rows}
                                                    prepareRow={campTable.prepareRow}
                                                    getTableProps={campTable.getTableProps}
                                                    columns={CampagneStore.props.columnsCampagne}
                                                    loadingMsg={CampagneStore.props.loading.msg}
                                                    loadingStatus={CampagneStore.props.loading.status}
                                                    prevPage={() => CampagneStore.prevPage()}
                                                    nextPage={() => CampagneStore.nextPage()}
                                                    setSort={(sortBy) => CampagneStore.setSort(sortBy)}
                                                    sortOrder={CampagneStore.props.sortOrder}
                                                    sortBy={CampagneStore.props.sortBy}
                                                    goToPage={(page) => CampagneStore.goToPage(page)}
                                                    pageCount={CampagneStore.props.pageCount}
                                                    pageIndex={CampagneStore.props.pageIndex}
                                                    pageLimit={CampagneStore.props.pageLimit}
                                                    pageTotal={CampagneStore.props.pageTotal}
                                                    maxHeight="calc(100vh - 260px)"
                                                    btnClassName="btn btn-outline-success btn-sm"
                                                ></DataTable>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
            {/* {console.log(toJS(CampagneWizardStore.props.tarifs))} */}
            {CampagneWizardStore.props.isOpenModalPayment &&
                <AddCredit

                    toggleModalPayment={() => CampagneWizardStore.toggleModalPayment()}
                    submitAddCreditForm={(values) => CampagneWizardStore.submitAddCreditForm(values)}
                    getPrix={(prix, loading) => CampagneWizardStore.getPrix(prix, loading)}
                    isOpenModalPayment={CampagneWizardStore.props.isOpenModalPayment}
                    loadingMsg={CampagneWizardStore.props.loadingPayment.msg}
                    loadingStatus={CampagneWizardStore.props.loadingPayment.status}
                    tarifs={CampagneWizardStore.props.tarifs}
                    prix={CampagneWizardStore.props.prix}
                    modeEnvoi={values['modeEnvoi']}
                    coefficientSMSMail={CampagneWizardStore.coefficientSMSMail()}
                />
            }
            {CampagneStore.props.isOpenModalStatutCampagne &&
                <ModalStatutCampagne
                    isOpenModalStatutCampagne={CampagneStore.props.isOpenModalStatutCampagne}
                    toggleModalStatutCampagne={() => CampagneStore.toggleModalStatutCampagne()}
                    getStatutCampagne={(id) => CampagneStore.getStatutCampagne(id)}
                    loadingStatus={CampagneStore.props.loading.status}
                    loadingMsg={CampagneStore.props.loading.msg}
                    campStatut={CampagneStore.props.campStatut}
                    currentCamp={CampagneStore.props.currentCamp}
                />
            }
        </div>
    )
})

export default CampagneView

