import { observable } from 'mobx'
import api from '../api'
import UserStore from './UserStore'
import { generateOptionsDouble, generateDatasetsDouble } from '../utils/chartUtils'
import MainStore from './MainStore'

const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours'
    },
    legend: [],
    legendLabel: {},
    charts: [],
    chiffre_affaire: ['', ''],
    options: {},
    rfm: [],
    frequentation: {
        global: [],
        genre: [],
        total: {
            current: null,
            old: null
        }
    },
    campagnes: [],
    clients: [],
    origines: [],
    derniersAchats: [],
    classement: [],
}

const DashboardStore = {
    /***** Props ******/
    props : props,

    /***** Methods ******/

    async getWeekStats(creds) {
        this.props.loading.msg = "Chargement des données"
        this.props.loading.status = true

        const res = await api.get('/scripts/dashboard/dashboard.php')
        if (res && res.data.success) {
            this.props.charts = generateDatasetsDouble(res.data.data.charts, res.data.legend)
            this.props.chiffre_affaire = res.data.data.chiffre_affaire
            this.props.options = generateOptionsDouble(res.data.data.charts,res.data.data.group_by)
            this.props.rfm = res.data.data.rfm
            this.props.legendLabel = res.data.legendLabel
            this.props.legend = res.data.legend
            this.props.frequentation = res.data.data.frequentation
            this.props.campagnes = res.data.data.campagnes
            this.props.origines = res.data.data.origines
            this.props.clients = res.data.data.clients
            this.props.derniersAchats = res.data.data.derniersAchats
            this.props.classement = res.data.data.classement

        }
        this.props.loading.status = false
    },
    reset() {
        this.props = props
    }

}


export default observable(DashboardStore)