import React,{useEffect, useState} from 'react'
import Logo from '../../assets/img/logo.png'
import UserStore from '../../stores/UserStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock,faClone, faCopy,faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { observer } from "mobx-react"
import { Redirect,useLocation, Link } from 'react-router-dom'
import BlockUi from 'react-block-ui';
import Schema from 'validate'
import { mapValues, keyBy } from 'lodash';
import { useFormik } from 'formik';
import classNames from 'classnames'
import MainStore from '../../stores/MainStore';
import Loading from '../Loading/Loading';
import parse from 'html-react-parser';
import myYup from '../../utils/myYup';
import OtpInput from '../../reusable/OtpInput';
import backbtn from '../../assets/img/backblue.png'

const Setup2fa = () => {
    const location=useLocation();
    const [otp, setOtp] = useState('');
    var qrcode='';
    const param=new URLSearchParams(location.search)
    const token=param.get('token')
    const selector=param.get('selector')

    useEffect(()=>{
        UserStore.props.verified.error = ''
        async function cansetup(param){
            return await UserStore.setup2faVerifLink(param)
        }
        
        const param=new URLSearchParams(location.search)
            
        cansetup({
            token:param.get('token'),
            selector:param.get('selector')
        })
        qrcode="data:image/png;base64,"+UserStore.props.setup2fa.qrcode_base64;
        console.log(qrcode)
    },[])


    const verify = async (otpvalue) => {
        var creds={one_time_password:otpvalue,token:token,selector:selector}
        await UserStore.setup_twoo_method(creds)
    }

    const setValue=async (value)=>{
        const newvalue=value;
        setOtp(a=>value)
        if(newvalue.trim().length==6){
            console.log("otp",newvalue)
            await verify(newvalue)
        }
    }

    const onMouseOut=(tooltipId)=>{      
        var tooltip = document.getElementById("tooltip_1"); 
        tooltip.innerHTML =  "Copier";
    }

    const copyToClipBoard=()=> {    
        var tooltip = document.getElementById("tooltip_1");       
        
        // Copy the text inside the text field
        window.navigator.clipboard.writeText(UserStore.props.setup2fa.secret).then(() => {
          tooltip.innerHTML =  "Copié !"; 
        },() => {
          tooltip.innerHTML =  "Echec copie";
        });
    }

    if (UserStore.props.user.isAuthenticated) {
        let to=''
        if (!UserStore.canDashboard() || UserStore.isSaisie()) {
            to = {
                pathname: '/cards',
                state: 'Liste des clients'
            }
        }
        else {
            to = {
                pathname: '/dashboard',
                state: 'Tableau de bord'
            }
        }

        // to = {
        //     pathname: '/geolocation',
        //     state: 'CUSTOM'
        // }

        return (
            <Redirect to={to}></Redirect>
        )
    }

    return (

        <div className="app flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <BlockUi tag="div" className="card-group rounded login-loader" loader={Loading}
                            message={UserStore.props.loading.msg}
                            blocking={UserStore.props.loading.status}>
                            <div className="card p-4">

                            {(UserStore.props.setup2fa.success&&UserStore.props.verified.error=='')&&
                                    <>
                                        <img src={"data:image/png;base64,"+UserStore.props.setup2fa.qrcode_base64}/>
                                        <hr></hr>
                                        {UserStore.props.fail.status && <div className="invalid-feedback d-inline-block text-center">Code incorrect</div>}
                                        <form method="post">
                                            <label>Saisissez le code à 6 chiffres</label>
                                            <OtpInput 
                                                length={6} 
                                                onchange={setValue} 
                                                value={otp} 
                                                containerStyle='d-flex'
                                                renderInput={(p)=>{
                                                    return <div className="m-1">
                                                            <input {...p}/>
                                                        </div>
                                                }}
                                            />
                                            
                                            
                                            
                                        </form>
                                        </>
                                    }
                                
                                
                                {
                                    (!UserStore.props.setup2fa.success&& UserStore.props.setup2fa.error!='') &&
                                    <>
                                        <div className='row'>
                                            <div className="col text-left">
                                                <Link to="/login" className="px-4 d-flex"><img src={backbtn}/>Se connecter</Link>
                                            </div>
                                        </div>
                                        < div className="card-body">
                                            <h3>Authentification à 2 facteurs</h3>
                                            <div className={classNames("alert",{'alert-success' : UserStore.props.setup2fa.success,'alert-danger' : !UserStore.props.setup2fa.success})} role="alert">
                                                {parse(UserStore.props.setup2fa.error)}
                                                </div>
                                            

                                        </div>
                                    </>
                                }
                                {
                                    UserStore.props.verified.error!='' &&
                                    <>
                                        <div className='row'>
                                            <div className="col text-left">
                                                <Link to="/login" className="px-4 d-flex"><img src={backbtn}/>Se connecter</Link>
                                            </div>
                                        </div>
                                        < div className="card-body">
                                            <h3>Authentification à 2 facteurs {UserStore.props.verified.success &&'verifée!'}</h3>
                                            <div className={classNames("alert",{'alert-success' : UserStore.props.verified.success,'alert-danger' : !UserStore.props.verified.success})} role="alert">
                                                {UserStore.props.verified.success &&
                                                <div className='text-center'>
                                                    <FontAwesomeIcon icon={faCheckCircle} color="green" size='12'/>
                                                    <p style={{fontWeight : 'normal'}}> Vous devrez entrer le code à usage unique disponible dans l'application à chaque nouvelle connexion .</p>
                                                </div>
                                                }
                                                {
                                                    !UserStore.props.verified.success &&
                                                
                                                parse(UserStore.props.verified.error)
                                                }
                                                </div>
                                            

                                        </div>
                                    </>
                                }
                                
                            </div>
                            <div className="card py-5 d-md-down-none">
                                <div className="card-body text-center">
                                    {(!UserStore.props.setup2fa.success||UserStore.props.verified.error!='') &&
                                    <>
                                        <div>
                                            <img className="login-image" src={Logo} alt="Fidlink Logo" />
                                        </div>
                                        <strong>NOVACARD devient <span className="text-fid">FIDLINK</span></strong>
                                    </>
                                    }
                                    {(UserStore.props.setup2fa.success &&UserStore.props.verified.error=='')&&
                                < div className="card-body text-center">
                                    <h3>Authentification à 2 facteurs</h3>
                                    <p style={{textAlign: "left",fontWeight: "bold"}}>1. Scannez le QRCode avec une app compatible:</p>
                                    
                                    <ul style={{textAlign: "left"}}>
                                        <li>Google Authenticator: <a href="https://apps.apple.com/app/google-authenticator/id388497605" target="_blank">iOS</a> , <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2">Android</a> </li>
                                        <li>Authy: <a href="https://authy.com/download/" target="_blank">iOS, Android, Chrome et OS X</a></li>
                                        <li>LastPass: <a href="https://lastpass.com/misc_download2.php" target="_blank"> iOS, Android, OS X, Linux et Windows </a> </li>
                                    </ul> 
                                    
                                    <p>Configuration manuelle</p>
                                    <textarea 
                                        disabled 
                                        style={{minWidth: '90%',maxWidth: '90%',resize: 'none'}} 
                                        rows="3" 
                                        defaultValue={UserStore.props.setup2fa.secret} 
                                        type="text">
                                    </textarea>
                                    <div>
                                        <div className="">
                                            <button onClick={copyToClipBoard}>
                                            <FontAwesomeIcon icon={faClone} />
                                                <span className="tooltiptext" id="tooltip_1">Copier</span>
                                            </button>
                                        </div>
                                    </div>

                                    
                                </div>
                                }
                                </div>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div >
        </div >

    )
}




export default observer(Setup2fa)