import React from 'react'
import { observable, toJS } from 'mobx'
import api from '../api'
import UserStore from './UserStore'
import moment from 'moment'
import { number } from '../utils/numeral'
import Schema from 'validate'
import { keyBy, mapValues } from 'lodash'
import { toast } from 'react-toastify'
import { PieChart } from 'react-minimal-pie-chart'
import { EditDropDownCampagne } from '../components/Campagne/CampagneView'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import CampagneWizardStore from './CampagneWizardStore'
import { set } from 'date-fns'

const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours'
    },
    loadingCredits: {
        status: false,
        msg: 'Chargement en cours ...'
    },
    columnsCampagne: [
        {
            Header: 'Mode',
            accessor: 'modeEnvoi',
            field: 'modeEnvoi',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isEditable: false,
            isSortable: true,
            Cell: ({ cell: { value } }) => {
                switch (value) {
                    case "1":
                        {
                            return <div className="d-flex center-h">
                                <FontAwesomeIcon icon={faComment} className="text-old m-auto"></FontAwesomeIcon>
                            </div>;
                        }
                        break;
                    case "2":
                        {
                            return <div className="d-flex center-h">
                                <FontAwesomeIcon icon={faEnvelope} className="text-old m-auto"></FontAwesomeIcon>
                            </div>;
                        }
                        break;
                    case "3":
                        {
                            return 'Courrier';
                        }
                        break;
                    case "4":
                        {
                            return 'PUSH';
                        }

                }
            },
        },
        {
            Header: 'Titre de la campagne',
            accessor: 'titre',
            field: 'titre',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'Etat',
            accessor: 'etat',
            field: 'etat',
            typeFilter: 'select',
            typeInput: 'select',
            list: [
                { id: 7, label: "Campagnes en cours d'envoi" },
                { id: 1, label: "Campagnes envoyées" },
                { id: 2, label: "Campagnes en attente" },
                { id: -1, label: "Campagnes supprimées" },
                { id: 4, label: "Campagnes en erreur" },
                { id: 5, label: "Campagnes d'activation" },
                { id: 6, label: "Campagnes d'inscription jeu" }
            ],
            isEditable: false,
            isSortable: true,
            Cell: ({ row, cell: { value } }) => {
                switch (value) {
                    case "-1":
                        {
                            return <span style={{ fontSize: '75%' }} className="bg-danger badge font-weight-bold">
                                Annulée
                            </span>
                        }
                        break;
                    case "4":
                        {
                            return <span style={{ fontSize: '75%' }} className="bg-danger badge font-weight-bold">
                                En erreur
                            </span>
                        }
                        break;
                    case "5":
                        {
                            return <span style={{ fontSize: '75%' }} className="bg-secondary badge font-weight-bold">
                                Activation
                            </span>
                        }
                        break;
                    case "6":
                        {
                            return <span style={{ fontSize: '75%' }} className="bg-warning badge font-weight-bold">
                                Campagne jeux
                        </span>
                        }
                        break;
                    case "7":
                        {
                            return <span style={{ fontSize: '75%' }} className="bg-info badge font-weight-bold">
                                En cours
                            </span>
                        }
                    case "1":
                        {
                            return <span style={{ fontSize: '75%' }} className="bg-success badge font-weight-bold">
                                Envoyée
                            </span>
                        }
                        break;
                    case "2":
                        {
                            return <span style={{ fontSize: '75%' }} className="bg-warning badge font-weight-bold">
                                En attente
                            </span>
                        }
                        break;
                    case "3":
                        {
                            return <span style={{ fontSize: '75%' }} className="bg-secondary badge font-weight-bold">
                                Désactivé
                            </span>
                        }
                        break;
                    default:
                        {
                            return value;
                        }
                }
            }
        },
        {
            Header: 'Nb. envoyés',
            accessor: 'nbSMS',
            field: 'nbSMS',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isEditable: false,
            isSortable: false,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: 'Reçu',
            accessor: 'envoyes',
            field: 'envoyes',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isEditable: false,
            isSortable: false,
            Cell: ({ cell: { value } }) => {
                return number(value)
            }
        },
        {
            Header: 'Non reçus',
            accessor: 'erreurs',
            field: 'erreurs',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isEditable: false,
            isSortable: false,
            Cell: ({ cell: { value } }) => {
                return number(value)
            }
        },
        {
            Header: () => {
                return (

                    <>
                        <span className="text-success">% Reçu</span>
                        {' - '}
                        <span className="text-old">% Non reçu</span>
                    </>
                )
            },
            accessor: 'envoyes_percent',
            field: 'envoyes_percent',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: false,
            isEditable: false,
            isSortable: false,
            Cell: ({ cell: { value }, row }) => {
                let total = parseInt(row.values.erreurs) + parseInt(row.values.envoyes)
                let percent = total == 0 ? total : parseInt(row.values.envoyes) * 100 / total

                return (
                    <>
                        <span className="text-success">{number(percent)}%</span>
                        {' - '}
                        <span className="text-old">{number(100 - percent)}%</span>
                    </>
                )
            }
        },
        {
            Header: 'Non reçus (%)',
            accessor: 'erreurs_percent',
            field: 'erreurs_percent',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: false,
            Cell: ({ cell: { value }, row }) => {
                let total = parseInt(row.values.erreurs) + parseInt(row.values.envoyes)
                if (total == 0)
                    return '0%'
                return number(parseInt(row.values.erreurs) * 100 / total) + '%'
            }
        },
        {
            Header: 'cumulSMS',
            accessor: 'cumulSMS',
            field: 'cumulSMS',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'ID Campagne',
            accessor: 'idcampagnesms',
            field: 'idcampagnesms',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'Id com',
            accessor: 'idcommercantFid',
            field: 'idcommercantFid',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'Id Res',
            accessor: 'iDreseauFid',
            field: 'iDreseauFid',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'Créer le',
            accessor: 'creerLe',
            field: 'creerLe',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isEditable: false,
            isSortable: true,
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY')
                else
                    return "-"
            },
        },
        {
            Header: 'Date d\'envoi',
            accessor: 'dateEnvoi',
            field: 'dateEnvoi',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isEditable: false,
            isSortable: true,
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY [à] HH[h]mm')
                else
                    return "-"
            },
        },
        {
            Header: 'Timezone',
            accessor: 'timezone',
            field: 'timezone',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'idEnvoi',
            accessor: 'idEnvoi',
            field: 'idEnvoi',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'typeEnvoi',
            accessor: 'typeEnvoi',
            field: 'typeEnvoi',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'Message',
            accessor: 'message',
            field: 'message',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'Type Erreur',
            accessor: 'typeErreur',
            field: 'typeErreur',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'Plateforme',
            accessor: 'plateforme',
            field: 'plateforme',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'Récurrent',
            accessor: 'recurrent',
            field: 'recurrent',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row, data, toggleVisibleCampagne, toggleModalStatutCampagne }) => {
                // console.log(toJS(data[row.index]))
                return (
                    <EditDropDownCampagne
                        row={data[row.index]}
                        toggleVisibleCampagne={toggleVisibleCampagne}
                        toggleModalStatutCampagne={toggleModalStatutCampagne}
                    ></EditDropDownCampagne>

                )
            }

        },
    ],
    currStep: 0,
    showEditor: false,
    data: {
        email: {
            json: {},
            html: ""
        }
    },
    filtersCampagne: [
        {
            name: 'Titre de la campagne',
            id: 'titre',
            field: 'titre',
            active: false,
            type: 'text',
            value: '',
            operator: ' like ',
            label: 'Contient',
            typeCompare: 'text'
        },
        {
            name: 'Etat',
            id: 'etat',
            field: 'etat',
            active: false,
            type: 'number',
            options: [
                { id: '', label: "Tous" },
                { id: '1', label: "Campagnes envoyées" },
                { id: '7', label: "Campagnes en cours d'envoi" },
                { id: '2', label: "Campagnes en attente" },
                { id: '-1', label: "Campagnes supprimées" },
                { id: '4', label: "Campagnes en erreur" },
                { id: '5', label: "Campagnes d'activation" },
                { id: '6', label: "Campagnes d'inscription jeu" }
            ],
            value: "",
            operator: '=',
            label: 'Filtrer par ',
            typeCompare: 'select'
        },
        {
            name: 'Type',
            id: 'reccurent',
            field: 'reccurent',
            active: false,
            type: 'custom',
            options: [
                { id: 'recurrent not in (0,2)', label: "Récurrent" },
                { id: 'recurrent in (0)', label: "Ponctuelle" },
                { id: 'recurrent in (2)', label: "Programmé" },
            ],
            value: "",
            operator: 'in',
            label: 'égale à ',
            typeCompare: 'select'
        }
    ],
    seuilAlertInititalValues: {
        seuil: 0,
        alertActive: 0
    },
    activeCamp: [],
    pageStart: 0,
    pageIndex: 0,
    pageLimit: 30,
    pageTotal: 0,
    pageCount: 0,
    builder: null,
    credits: {},
    pageLimit: 30,
    pageTotal: 0,
    pageCount: 0,
    sortBy: 'creerLe',
    sortOrder: 'DESC',
    dataCampagne: [],
    message: '',
    seuilAlerte: null,
    alertActive: null,
    campStatut: {},
    currentCamp: null,
    isOpenModalStatutCampagne: false,
    firstOpenStatut : false

}

const CampagneStore = {
    /***** Props ******/
    props: props,
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    doLoadingCredits(state, msg = '') {
        this.props.loadingCredits.msg = msg
        this.props.loadingCredits.status = state
    },
    /***** Methods ******/
    async init() {
        this.reset()
        this.doLoading(true, 'Initialisation en cours')
        await this.getCampagnes()
        await this.getCreditsAmount()
        await CampagneWizardStore.getPrixCom()
        this.doLoading(false)
    },
    async initRealTime() {
        if (UserStore.props.user.isAuthenticated) {

            const url = new URL(process.env['REACT_APP_MERCURE_HUB'] + '/.well-known/mercure');
            url.searchParams.append('topic', 'http://local.novacorp.fr/campagne/' + UserStore.props.user.idCompteSMS);

            const eventSource = new EventSource(url);

            // The callback will be called every time an update is published
            eventSource.onmessage = e => {
                let data = null
                try {
                    data = JSON.parse(e.data)
                    console.log(data)
                }
                catch (e) {

                }
                console.log(e.data)
                if (data && data.type) {

                    switch (data.type) {
                        case "statutCampagne":
                            let infos = data.data
                            let index = this.props.activeCamp.findIndex(item => item.id == infos.id)
                            console.log(index)
                            if (index == -1) {
                                this.props.activeCamp.push(infos)
                            } else {
                                this.props.activeCamp[index] = infos
                            }

                            break;

                        default:
                            break;
                    }
                }

            }
        }
    },
    async getCampagnes() {

        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        this.props.sortBy = this.props.sortBy || 'idcampagnesms'
        this.props.sortOrder = this.props.sortOrder || 'ASC'

        let where = ''
        if (UserStore.isReseau())
            where = this.getWhere('iDreseauFid = ' + idRes + ' ')
        else
            where = this.getWhere('idcommercantFid = ' + idCom + ' ')
        let data = {
            // 'objName': 'listeCampagnes',
            'cmd': 'getRowsWithAverage',
            'where': where,
            'start': this.props.pageStart,
            'limit': this.props.pageLimit,
            'sort': this.props.sortBy,
            'dir': this.props.sortOrder,
            // 'query': JSON.stringify(where)
        }


        const res = await api.post('/scripts/sms/smss.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.dataCampagne = !res.data.rows ? [] : res.data.rows
                this.props.pageTotal = res.data.totalCount
                this.props.pageCount = Math.ceil(res.data.totalCount / this.props.pageLimit)

            } else {
                this.props.dataCampagne = []
            }
        }
    },
    async reloadCampagneList() {
        this.doLoading(true, 'Chargement en cours')
        await this.getCampagnes()
        this.doLoading(false)
    },
    async getCreditsAmount() {
        this.doLoadingCredits(true, 'Chargement ...')
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let where = ''
        if (UserStore.isReseau())
            where = 'iDreseauFid = ' + idRes
        else
            where = 'idcommercantFid = ' + idCom

        let data = {
            start: 0,
            limit: 1,
            sort: 'nbSMS',
            dir: 'DESC',
            cmd: 'getRows',
            objName: 'leCompteSMS',
            where: where,
        }

        const res = await api.post('/scripts/sms/smss.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.credits = !res.data.rows.length ? {} : res.data.rows[0]
                this.props.seuilAlertInititalValues.seuil = !res.data.rows.length ? 0 : parseFloat(res.data.rows[0].seuilAlerte)
                this.props.seuilAlertInititalValues.alertActive = !res.data.rows.length ? 0 : parseInt(res.data.rows[0].alertActive) ? true : false

            }
        }
        this.doLoadingCredits(false)
    },
    async setSort(value) {
        this.doLoading(true, 'Chargement en cours ...')
        if (this.props.sortBy != value) {
            this.props.sortBy = value
            this.props.sortOrder = 'ASC'
        }
        else {
            if (this.props.sortOrder == 'ASC')
                this.props.sortOrder = 'DESC'
            else if (this.props.sortOrder == 'DESC') {
                this.props.sortOrder = false
                this.props.sortBy = null

            }
        }

        await this.getCampagnes()
        this.doLoading(false)
        // console.log(value, this.props.sortOrder)
    },
    async toggleVisibleCampagne(id, etat) {
        this.doLoading(true, 'Chargement en cours ...')
        const data = {
            'cmd': 'toggleVisibleV2',
            'id': id,
        }


        const res = await api.post('/scripts/campagne/utils.php', data)
        if (res.data.success) {
            if (etat != -1)
                toast.success('La campagne a bien été supprimée')
            else
                toast.success('La campagne a bien été restaurée')
            await this.getCampagnes()
        }
        else {
            toast.error('Une erreur est survenue.')
        }
        this.doLoading(false)
    },
    toggleModalStatutCampagne(id) {
        console.log(id)
        this.props.isOpenModalStatutCampagne = !this.props.isOpenModalStatutCampagne
        if (this.props.isOpenModalStatutCampagne) {
            this.props.firstOpenStatut = true
            this.props.currentCamp = id
        }
    },
    getStatutCampagne(id) {

        const idComptesms = UserStore.props.user.idCompteSMS
        if (this.props.firstOpenStatut)
            this.doLoading(true, "Chargement en cours ...")
        const data = {
            'cmd': 'getStatutCampagneV2',
            'idComptesms': idComptesms,
            'idCampagne': id
        }

        let poll = setInterval(async () => {
            api.post('/scripts/sms/smss.php', data)
                .then((res) => {
                    if (this.props.firstOpenStatut) {
                        this.props.firstOpenStatut = false
                        this.doLoading(false)
                    }
                    if (res.data.success) {
                        console.log(res.data)
                        this.props.campStatut = res.data.rows.length ? res.data.rows[0] : {}
                    }

                })
                .catch((err) => {
                    if (this.props.firstOpenStatut) {
                        this.props.firstOpenStatut = false
                        this.doLoading(false)
                    }
                    toast.error('Une erreur est survenue lors du chargment du statut')
                    clearInterval(poll)
                })
        }, 5000)

        return poll
    },
    setSeuilAlerte(value) {
        this.props.credits.seuilAlerte = value
    },
    getWhere(where) {
        if (!where)
            where += ' WHERE '

        this.props.filtersCampagne
            .filter((item) => item.active)
            .map((item, index) => {
                // console.log(toJS(item))
                let and = ' AND '
                if (index != 0 && !where)
                    and = ' '
                if (item.value || item.type == 'multiple') {
                    if (item.type == 'text')
                        where += and + item.field + " " + item.operator + " '%" + item.value + "%'"
                    if (item.type == 'number')
                        where += and + item.field + " " + item.operator + " " + item.value
                    if (item.type == 'date')
                        where += and + item.field + " " + item.operator + " '" + item.value + "'"
                    if (item.type == 'custom' && item.value)
                        where += and + item.value + " "
                    if (item.type == 'multiple') {
                        let andAdded = false
                        item.choices.map((item2) => {
                            if (item2.checked)
                                if (!andAdded) {
                                    if (item2.id == 'non_classes')
                                        where += and + "(" + item.field + " is null "
                                    else
                                        where += and + "(" + item.field + " like " + " '%" + item2.id + "%'"
                                    andAdded = true
                                }
                                else
                                    if (item2.id == 'non_classes')
                                        where += " OR " + item.field + " is null "
                                    else
                                        where += " OR " + item.field + " like " + " '%" + item2.id + "%'"
                        })
                        if (andAdded)
                            where += ")"
                    }
                }

            })

        return where

    },
    setFilterValue(index, value) {
        this.props.filtersCampagne[index].value = value
    },
    setFilterOperator(index, operator) {
        this.props.filtersCampagne[index].operator = operator
    },
    async getFilteredCampagnes() {
        this.doLoading(true, 'Chargement en cours ...')
        this.props.pageStart = 0
        this.props.pageIndex = 0
        this.props.pageLimit = 30
        await this.getCampagnes()
        this.doLoading(false)
    },
    setSelectedRow(id) {
        this.props.selectedRow = toJS(this.props.data[id])
    },
    toggleItemFilter(index) {
        if (!this.props.filtersCampagne[index].isNotToggable)
            this.props.filtersCampagne[index].active = !this.props.filtersCampagne[index].active
    },
    toggleSubItem(index, subIndex) {
        this.props.filtersCampagne[index].choices[subIndex].checked = !this.props.filtersCampagne[index].choices[subIndex].checked
    },
    resetFilter() {
        this.props.filter = this.props.filtersCampagne.map((item) => {
            item.active = false
            item.value = ''
            return item
        })
    },
    validateSeuilAlerte(values) {
        const toValidate = { ...values }

        const form = new Schema({
            seuil: {
                type: Number,
                required: true,
                size: { min: 1 },
                message: {
                    size: 'La valeur ne doit pas etre nulle'
                }
            },
        })

        const errors = form.validate(toValidate)
        // console.log(errors)
        return mapValues(keyBy(errors, 'path'), 'message')
    },
    async submitSeuilAlerte(values) {
        this.doLoadingCredits(true, 'Chargement ...')
        let idRes = parseInt(UserStore.props.user.idRes)
        let idCom = parseInt(UserStore.props.user.idCom)

        let where = ''
        if (UserStore.isReseau())
            idCom = 0
        else
            idRes = 0

        const data = {
            cmd: 'majCompteSms',
            objName: 'comptesms',
            data: JSON.stringify([{ "seuilAlerte": values.seuil, "alertActive": values.alertActive ? 1 : 0, "idRes": idRes, "idCom": idCom }])
        }

        const res = await api.post('/scripts/reseau/reseau.php', data)
        if (res.data.success) {
            toast.success("Le seuil d'alerte a bien été modifié");
        }
        else
            toast.error("Une erreur est survenue<br>" + res.data.error);

        this.doLoadingCredits(false)
    },
    async nextPage() {
        this.props.pageIndex = this.props.pageIndex + 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getCampagnes()
        this.doLoading(false)
    },
    async prevPage() {
        this.props.pageIndex = this.props.pageIndex - 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getCampagnes()
        this.doLoading(false)
    },
    async goToPage(page) {
        this.props.pageIndex = page
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getCampagnes()
        this.doLoading(false)
    },
    reset() {
        this.props = props
    }
}

export default observable(CampagneStore)