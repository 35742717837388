
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import LoginLayout from './components/Layouts/LoginLayout'
import LoginView from "./components/Login/LoginView";
import ForgotView from "./components/Forgot/ForgotView";
import DashBoardView from "./components/Dashboard/DashboardView";
import MainLayout from './components/Layouts/MainLayout'
import LogoutView from './components/Logout/LogoutView';
import CampagneView from './components/Campagne/CampagneView';
import CampagneWizard from './components/Campagne/CampagneWizard';
import IsLogged from './components/IsLogged/IsLogged'
import CardsView from './components/Cards/CardsView';
import NotificationView from './components/Notification/NotificationView';
import SegmentationView from './components/Segmentation/SegmentationView';
import TransacView from './components/Transacs/TransacView';
import GamesView from './components/Game/GamesView';
import confirmation from './components/Alerts/Confirmation';
import NovacardView from './components/Novacard/NovacardView';
import MouvementsCreditsView from './components/Campagne/MouvementsCreditsView';
import CouponsView from './components/Coupons/CouponsView';
import Settings from './components/Settings/SettingsView';
import NotFoundView from './components/NotFound/NotFoundView';
import StatsView from './components/Stats/StatsView';
import GeolocationView from './components/Geolocation/GeolocationView';
import VerifyMailView from './components/VerifyMail/VerifyMailView';
import VerifyOtpView from './components/VerifyOtp/VerifyOtpView';
import Setup2fa from './components/VerifyOtp/Setup2fa';


function Routes() {
    return (
        <Router  basename="/"
            getUserConfirmation={(message, callback) => {
                // this is the default behavior
                const doConfirm = async () => {
                    console.log('TRANSITION')
                    const allowTransition = await confirmation({
                        type: 'confirmNoPass',
                        text: 'Etes-vous sûr(e) de vouloir quitter ?<br> Toute modification sera perdue.'
                    })
                    callback(allowTransition);
                }

                doConfirm()
            }}>
            <Switch>
                <Route path="/login">
                    <IsLogged>
                        <LoginLayout component={LoginView} />
                    </IsLogged>
                </Route>
                <Route path="/twoo_factors_verification">
                    
                        <LoginLayout component={VerifyOtpView} />
                    
                </Route>
                <Route path="/forgot">
                    <IsLogged>
                        <LoginLayout component={ForgotView} />
                    </IsLogged>
                </Route>
                <Route path="/setup_2fa">
                    <IsLogged>
                        <LoginLayout component={Setup2fa} />
                    </IsLogged>
                </Route>
                <Route path="/verify">
                    <IsLogged>
                        <LoginLayout component={VerifyMailView} />
                    </IsLogged>
                </Route>
                <Route path="/dashboard">
                    <IsLogged>
                        <MainLayout component={DashBoardView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/segmentation">
                    <IsLogged>
                        <MainLayout component={SegmentationView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/notifications">
                    <IsLogged>
                        <MainLayout component={NotificationView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/logout">
                    <IsLogged>
                        <MainLayout component={LogoutView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route exact path="/campaigns">
                    <IsLogged>
                        <MainLayout component={CampagneView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route exact path="/campaigns/:action(new|edit)/:mode(sms|email)">
                    <IsLogged>
                        <MainLayout component={CampagneWizard}></MainLayout>
                    </IsLogged>
                </Route>
                <Route exact path="/campaigns/credits">
                    <IsLogged>
                        <MainLayout component={MouvementsCreditsView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/campaigns/edit">
                    <IsLogged>
                        <MainLayout component={CampagneWizard}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/cards">
                    <IsLogged>
                        <MainLayout component={CardsView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/transactions">
                    <IsLogged>
                        <MainLayout component={TransacView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/geolocation">
                    <IsLogged>
                        <MainLayout component={GeolocationView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/games">
                    <IsLogged>
                        <MainLayout component={GamesView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/old">
                    <IsLogged>
                        <MainLayout component={NovacardView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/coupons">
                    <IsLogged>
                        <MainLayout component={CouponsView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/settings">
                    <IsLogged>
                        <MainLayout component={Settings}></MainLayout>
                    </IsLogged>
                </Route>
                <Route path="/stats">
                    <IsLogged>
                        <MainLayout component={StatsView}></MainLayout>
                    </IsLogged>
                </Route>
                <Route exact path="/">
                    <IsLogged>
                        <Redirect to='/logout' />
                    </IsLogged>
                </Route>
                <Route component={NotFoundView} />


            </Switch>
        </Router>
    )

}

export default Routes