import { observable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../api'
import UserStore from './UserStore'

const props = {

    currentRoute: '',
    loading: {
        status: false,
        msg: 'Chargement en cours ...'
    },
    mapLoading: {
        status: false,
        msg: 'Chargement de la carte ...'
    },
    couponsUrl: null,
    tree: [],
    stats: [],
    currEnseigne: {},
    currStats: []
}

const GeolocationStore = {
    /***** Props ******/
    props: props,

    /***** Methods ******/
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    doMapLoading(state) {
        this.props.mapLoading.status = state
    },
    async init() {
        this.reset()
        this.doLoading(true, 'Chargement en cours ...')
        await this.getTree()
        // await this.getGeoStats()

        this.doLoading(false)
    },
    getBorderColor(val) {
        if (val < 20)
            return '#ffffb9'
        if (val < 40)
            return '#ffe884'
        if (val < 80)
            return '#fdcb58'
        if (val < 100)
            return '#fc9835'
        if (val < 120)
            return '#f96d29'
        if (val < 140)
            return '#f72e1e'
        if (val < 160)
            return '#d40017'



    },
    getBorderColor2(val) {

        if (val < 20)
            return '#ecda9a'
        if (val < 40)
            return '#efc47e'
        if (val < 80)
            return '#f3ad6a'
        if (val < 100)
            return '#f7945d'
        if (val < 120)
            return '#f97b57'
        if (val < 140)
            return '#f66356'
        if (val < 160)
            return '#ee4d5a'

        return '#eb4a40'

    },
    async getTree() {

        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

    
        let to_send = {
            params: {
                cmd: 'getDataResComJson',
            }
        }

        if(UserStore.isReseau()){
            to_send.params.idReseauFid = idRes
        }
        if(UserStore.isCommercant()){
            to_send.params.idCommercantFid = idCom
        }


        const res = await api.get('/scripts/reseau/dataResComHierarchique.php', to_send)

        if (res.data.success) {
            this.props.tree = res.data.rows
            await this.selectEnseigne(res.data.rows[0])
        } else {
            this.props.tree = []
        }

    },
    async getGeoStats() {
        
        const idRes = UserStore.props.user.idRes
        const idCom = this.props.currEnseigne.id

        let to_send = {
            idReseau: idRes,
            idCommercant: idCom,
        }

        const res = await api.post('/scripts/geolocalisation/geostatistiques_json.php', to_send)

        if (res.data.success) {
            // this.props.stats = 
            this.props.currStats = res.data.rows
        } else {
            this.props.stats = []
        }
    },
    async selectEnseigne(enseigne) {
        
        this.props.currEnseigne = enseigne
        this.doMapLoading(true)
        await this.getGeoStats()
        this.doMapLoading(false)
        // this.currStats.find(item => item.id = enseigne.id)
    },
    reset() {
        this.props = props
    }

}


export default observable(GeolocationStore)