import axios from 'axios'
import qs from 'qs'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import UserStore from './stores/UserStore'
import MainStore from './stores/MainStore'
import createAuthRefreshInterceptor from 'axios-auth-refresh';



const MySwal = withReactContent(Swal)

const instance = axios.create({
    baseURL: process.env['REACT_APP_API_URL'],
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Api_version':'v2'
        // 'Access-Control-Allow-Origin':"*"
    },
    transformRequest: (data, headers) => {
        // transform the data
        if (headers['Content-Type'] != 'multipart/form-data')
            return qs.stringify(data)
        return data
    },
    withCredentials:true
});

// instance.interceptors.request.use(
//     config => {
//         // const session = localStorage.getItem('fidlink_api')
//         // // console.log(JSON.parse(user))
//         // if (session) {
//         //     const user = JSON.parse(session)
//         //     if (user.access_token) {
//         //         config.headers['Authorization'] = 'Bearer ' + user.access_token
//         //     }
//         // }
//         config.headers['Api_version'] = 'v2'
//         return config;
//     },
//     error => {
//         Promise.reject(error)
//     }
// );



// let isRefreshing = false;
// let failedQueue = [];

// const processQueue = (error, token = null) => {
//     console.log('faildsd', failedQueue)
//     failedQueue.forEach(prom => {
//         if (error) {
//             prom.reject(error);
//         } else {
//             prom.resolve(token);
//         }
//     })

//     failedQueue = [];
// }

// instance.interceptors.response.use(function (response) {
//     return response;
// }, (error) => {

//     // const originalRequest = error.config;

//     // if (error.response.status === 401 && !originalRequest._retry) {

//     //     if (isRefreshing) {
//     //         return new Promise(function (resolve, reject) {
//     //             failedQueue.push({ resolve, reject })
//     //         }).then(token => {
//     //             originalRequest.headers['Authorization'] = 'Bearer ' + token;
//     //             return instance(originalRequest);
//     //         }).catch(err => {
//     //             return Promise.reject(err);
//     //         })
//     //     }

//     //     originalRequest._retry = true;
//     //     isRefreshing = true;


//     //     return new Promise(function (resolve, reject) {

//     //         let session = localStorage.getItem('fidlink_api')
//     //         let headers = {}
//     //         let user = {}
//     //         if (session) {
//     //              user = JSON.parse(session)
//     //             if (user.access_token && user.refresh_token) {
//     //                 headers = {
//     //                     'Authorization': 'Bearer ' + user.access_token,
//     //                     'x-Refresh-Token': 'Bearer ' + user.refresh_token,
//     //                     'Api_version': 'v2'
//     //                 }
//     //             } else {
//     //                 headers = {
//     //                     'Api_version': 'v2'
//     //                 }
//     //             }
//     //         }

//     //         axios.post(process.env['REACT_APP_API_URL'] + 'scripts/refresh_token.php', null, { headers })
//     //             .then((res) => {
//     //                 console.log(res)
//     //                 user.access_token = res.data.access_token
//     //                 user.refresh_token = res.data.refresh_token
//     //                 localStorage.setItem('fidlink_api', JSON.stringify(user));

//     //                 originalRequest.headers['Authorization'] = 'Bearer ' + res.data.access_token;
//     //                 console.log(qs.parse(originalRequest.data))
//     //                 originalRequest.data = qs.parse(originalRequest.data)
//     //                 processQueue(null, res.data.token);

//     //                 resolve(instance(originalRequest));
//     //             })
//     //             .catch((err) => {
//     //                 failedQueue = [];
//     //                 MySwal.fire({
//     //                     title: "Session Expirée",
//     //                     text: "Votre session a expiré. Veuillez vous reconnecter.",
//     //                     allowOutsideClick: false,
//     //                     confirmButtonColor: "#DD6B55",
//     //                     confirmButtonText: "Se reconnecter",
//     //                 }).then(() => {
//     //                     UserStore.logout()
//     //                 })
//     //                 // processQueue(err, null);
//     //                 reject(err);
//     //             })
//     //             .then(() => { console.log('then'); isRefreshing = false })
//     //     })
//     // }

//     return Promise.reject(error);
// });



export default instance;