import React, { useEffect, useState } from 'react'
import rfm_cube from '../../assets/img/rfm_cube.png'
import { observer } from 'mobx-react'
import { Nav, NavItem, NavLink, TabPane, TabContent, ModalBody, Modal } from 'reactstrap'
import { Line } from 'react-chartjs-2'
import { toJS } from 'mobx'
import DashboardStore from '../../stores/DashboardStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCaretUp, faCaretDown, faRedo, faClock, faEuroSign, faEnvelope, faShoppingBasket, faCoins, faUsers, faTicketAlt, faReceipt, faCheck, faStore, faMale, faFemale, faCircle, faInfoCircle, faWallet } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import moment from 'moment'
import 'moment/locale/fr';
import BlockUi from 'react-block-ui'
import MainStore from '../../stores/MainStore'
import { number, currency } from '../../utils/numeral'
import Loading from '../Loading/Loading'
import Loader from '../Loader/Loader'
import { BarLoader } from '../Loader/Loading'
import UserStore from '../../stores/UserStore'
import CampagneStore from '../../stores/CampagneStore'
import 'chartjs-plugin-datalabels';
import imgWelcome from '../../assets/img/welcome.png'

moment().locale('fr')

/**
 * Legend
 */

const Legend = observer((props) => {
    return (
        <>
            {console.log(props.legend)}
            {props.legend.map((item) => (
                <span>
                    TEST
                    <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon> {item[0]}
                    <FontAwesomeIcon icon={faCircle}></FontAwesomeIcon> {item[1]}
                </span>
            ))}
        </>
    )
})

/**
 * Charts
 */

const LineChart = observer((props) => {
    return (
        <Line data={props.data} style={{ height: 300 }} options={props.options} />
    )
})

const ChartSection = observer(() => {
    const options = toJS(DashboardStore.props.options)
    const [activeTab, setActiveTab] = useState(0);

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }
    return (
        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-sm-5">
                        <h4 className="card-title mb-0">Récapitulatif</h4>
                    </div>
                    {/* <div className="col-sm-7 d-none d-md-block">
                        <div className="btn-group btn-group-toggle float-right mr-3" data-toggle="buttons">
                            <label className="btn btn-outline-secondary">
                                <input id="option1" type="radio" name="options" autocomplete="off" /> Jour
                        </label>
                            <label className="btn btn-outline-secondary active">
                                <input id="option2" type="radio" name="options" autocomplete="off" checked="" /> Mois
                        </label>
                            <label className="btn btn-outline-secondary">
                                <input id="option3" type="radio" name="options" autocomplete="off" /> Année
                        </label>
                        </div>
                    </div> */}
                    <div className="chart-wrapper col-sm-12 mt-2">
                        <Nav tabs>
                            {DashboardStore.props.charts.map((item, index) => (<NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === index })}
                                    onClick={() => { toggle(index); }}
                                >
                                    {item.title}
                                </NavLink>
                            </NavItem>
                            ))}
                        </Nav>
                        <TabContent activeTab={activeTab}>
                            {DashboardStore.props.charts.map((item, index) => (
                                <TabPane key={'chart-' + index} tabId={index} style={{ height: '300px', paddingTop: '0px' }}>
                                    <LineChart data={toJS(item.data)} options={options[item.key]}></LineChart>
                                </TabPane>
                            ))}
                        </TabContent>
                    </div>
                </div>
            </div>
        </div>

    )

})

/**
 * RFM Cards
 */

const RfmSection = observer(() => {
    const icons = {
        "Récence": 'icon-clock',
        "Fréquence": 'icon-loop',
        "Montant": 'icon-basket-loaded'
    }

    const bgColors = {
        "Récence": 'bg-danger',
        "Fréquence": 'bg-warning',
        "Montant": 'bg-success'
    }

    return (
        <div className="row">
            {DashboardStore.props.rfm.map((item) => (
                <div className="col-lg-4 col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col h4">
                                    {item.title}
                                </div>
                                <div className="col ml-auto text-right h1">
                                    <i className={"text-muted " + icons[item.title]}></i>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col d-flex flex-column">
                                    <h3 className={classnames({ 'text-success': item.percent > 0, 'text-danger': item.percent < 0 })}>
                                        {(item.percent > 0 && <FontAwesomeIcon icon={faCaretUp}></FontAwesomeIcon>)}
                                        {(item.percent < 0 && <FontAwesomeIcon icon={faCaretDown}></FontAwesomeIcon>)}
                                        <span className="ml-2">{item.percent ? number(item.percent) : ''}%</span>
                                    </h3>
                                    <span className="d-flex flex-row">
                                        <span className="h3 text-old d-flex flex-column">
                                            <span>{item.values.length ? number(item.values[0]) : ''} <span className="text-unit">{item.unites}</span></span>
                                            {
                                                DashboardStore.props.legend &&
                                                <small className="text-old text-xs">{DashboardStore.props.legend[0][0]} au {DashboardStore.props.legend[0][1]}</small>
                                            }
                                        </span>
                                        <span className="pt-2"><FontAwesomeIcon icon={faArrowRight} className="mx-3"></FontAwesomeIcon></span>
                                        <span className="h3 text-fid d-flex flex-column">
                                            <span>{item.values.length ? number(item.values[1]) : ''} <span className="text-unit">{item.unites}</span></span>
                                            {
                                                DashboardStore.props.legend &&
                                                <small className="text-fid text-xs">{DashboardStore.props.legend[1][0]} au {DashboardStore.props.legend[1][1]}</small>
                                            }
                                        </span>
                                    </span>
                                    <span className="d-inline-block">{item.info}</span>
                                </div>
                            </div>
                            <div className="progress progress-xs mt-3 mb-0">
                                <div className={'progress-bar ' + bgColors[item.title]} role="progressbar" style={{ width: '100%' }} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </div >
    )
})

/**
* FEQUENTATION Card
*/

const FrequentationSection = observer(() => {

    const iconGenre = {
        'Hommes': faMale,
        'Femmes': faFemale
    }

    return (
        <div className="col-lg-6 col-12">
            <div className="card">
                <div className="card-body mb-2">
                    <div className="row">
                        <div className="col">
                            <h4 className="card-title">Nombre de transactions</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 d-flex mb-3">
                            <div className="circle-legend bg-old"></div>
                            <span className="ml-2 progress-group-text">{DashboardStore.props.legendLabel.old}</span>
                        </div>
                        <div className="col-6 d-flex mb-3">
                            <div className="circle-legend bg-fid"></div>
                            <span className="ml-2 progress-group-text">{DashboardStore.props.legendLabel.current}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="callout callout-old mt-0 ml-1"><small className="text-muted">Total transactions sur la période</small>
                                <br />
                                <strong className="h4">
                                    {number(DashboardStore.props.frequentation.total.old)}
                                </strong>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="callout callout-fid mt-0 ml-1"><small className="text-muted">Total transactions sur la période</small>
                                <br />
                                <strong className="h4">
                                    {number(DashboardStore.props.frequentation.total.current)}
                                </strong>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-0" />
                    {DashboardStore.props.frequentation.global.map((item) => (
                        <div className="progress-group mb-2">
                            <div className="progress-group-prepend">
                                <span className="progress-group-text" style={{ fontSize: 'inherit' }}>
                                    {item.jour}
                                </span>
                            </div>
                            <div className="progress-group-bars">
                                <div className="progress progress-xs">
                                    <div className="progress-bar bg-old" role="progressbar"
                                        style={{ width: item.percentage_old + '%' }} aria-valuenow={item.percentage_old} aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                                <div className="progress progress-xs">
                                    <div className="progress-bar bg-fid" role="progressbar"
                                        style={{ width: item.percentage + '%' }} aria-valuenow={item.percentage} aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="progress-group-append ml-3">
                                <span className="progress-group-text">
                                    <div className="row px-2">
                                        <div className="col-sm-3 px-0">
                                            <span className="text-old"><strong>{number(item.percentage_old)}%</strong></span>
                                        </div>
                                        <div className="col-sm-1 px-0">
                                            <FontAwesomeIcon icon={faArrowRight} size="sm"></FontAwesomeIcon>
                                        </div>
                                        <div className="col-sm-3 px-0">
                                            <span className="text-fid"><strong>{number(item.percentage)}%</strong></span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>

                    ))}
                    <hr></hr>
                    {DashboardStore.props.frequentation.genre.map((item) => (

                        <div className="progress-group mb-2">
                            <div className="progress-group-prepend">
                                <span className="progress-group-text" style={{ fontSize: 'inherit' }}>
                                    <FontAwesomeIcon icon='lg' className="mr-2" icon={iconGenre[item.genre]}></FontAwesomeIcon>
                                    {item.genre}
                                </span>
                            </div>
                            <div className="progress-group-bars">
                                <div className="progress progress-xs">
                                    <div className="progress-bar bg-old" role="progressbar"
                                        style={{ width: item.ratio_old + '%' }} aria-valuenow={item.ratio_old} aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                                <div className="progress progress-xs">
                                    <div className="progress-bar bg-fid" role="progressbar"
                                        style={{ width: item.ratio + '%' }} aria-valuenow={item.ratio} aria-valuemin="0"
                                        aria-valuemax="100"></div>
                                </div>
                            </div>
                            <div className="progress-group-append ml-3">
                                <span className="progress-group-text">
                                    <div className="row px-2">
                                        <div className="col-sm-3 px-0">
                                            <span className="text-old"><strong>{number(item.ratio_old)}%</strong></span>
                                        </div>
                                        <div className="col-sm-1 px-0">
                                            <FontAwesomeIcon icon={faArrowRight} size="sm"></FontAwesomeIcon>
                                        </div>
                                        <div className="col-sm-3 px-0">
                                            <span className="text-fid"><strong>{number(item.ratio)}%</strong></span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div >
    )
})

/**
* TOTAL Card
*/

const TotalSection = observer(() => {
    const bg_colors = ['bg-lightblue', 'bg-success', 'bg-warning', 'bg-danger', 'bg-pink', 'bg-purple', 'bg-orange', 'bg-indigo', 'bg-navy']
    const icons = [faWallet, faShoppingBasket, faCoins, faUsers, faTicketAlt, faReceipt]
    return (
        <div className="col-lg-6 col-12">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <h4 className="card-title">Totaux</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 d-flex mb-3">
                            <div className="circle-legend bg-old"></div>
                            <span className="ml-2 progress-group-text">{DashboardStore.props.legendLabel.old}</span>
                        </div>
                        <div className="col-6 d-flex mb-3">
                            <div className="circle-legend bg-fid"></div>
                            <span className="ml-2 progress-group-text">{DashboardStore.props.legendLabel.current}</span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="callout callout-old mt-0 ml-1"><small className="text-muted">Chiffre d'affaires</small>
                                <br />
                                <strong className="h4">
                                    {currency(DashboardStore.props.chiffre_affaire[0]) + ' ' + UserStore.props.user.unitesPME2court}
                                </strong>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="callout callout-fid mt-0 ml-1"><small className="text-muted">Chiffre d'affaires</small>
                                <br />
                                <strong className="h4">
                                    {currency(DashboardStore.props.chiffre_affaire[1]) + ' ' + UserStore.props.user.unitesPME2court}
                                </strong>
                            </div>
                        </div>
                    </div>
                    <hr className="mt-0" />
                    <div className="row">
                        {DashboardStore.props.charts.filter(item => item).map((item, index) => (
                            <div className="col-md-6 col-sm-6 col-12">
                                <div className="card">
                                    <div className="card-body p-0 d-flex w-100 align-items-center">
                                        <div className={"col-4 d-flex font-2xl justify-content-center align-items-center rounded-left " + bg_colors[index]}
                                            style={{ minHeight: "74px" }}>
                                            <FontAwesomeIcon className="text-white" icon={icons[index]}></FontAwesomeIcon>
                                        </div>
                                        <FontAwesomeIcon className="text-white" icon={icons[index]}></FontAwesomeIcon>
                                        <div className="col-8">
                                            <span className="info-box-text">{item.title}</span>
                                            <div className="text-muted font-weight-bold">
                                                <span className="text-old font-weight-bold">
                                                    {number(item.total_period[0])}{' '}{item.unites}
                                                </span>
                                                <FontAwesomeIcon icon={faArrowRight} size="sm" className="mx-2"></FontAwesomeIcon>
                                                <span className="text-fid font-weight-bold">
                                                    {number(item.total_period[1])}{' '}{item.unites}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
})

/**
* TOTAL Card
*/

const ClassementRFM = observer(() => {
    const bg_colors = ['bg-secondary', 'bg-navy', 'bg-danger', 'bg-orange', 'bg-warning', 'bg-lightblue', 'bg-info', 'bg-olive', 'bg-teal', 'bg-success']
    return (


        <div className="col-lg-6 col-12">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <h4 className="card-title">Segmentation prédictive des achats</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {DashboardStore.props.classement.map((item, index) => (

                                <div className="progress-group">
                                    <div className="progress-group-header align-items-end">
                                        <span className={'badge p-1 mr-2 rounded ' + bg_colors[index]}>{index + 1}</span>
                                        <div className="pt-1">{item.segment}</div>
                                        <div className="ml-auto font-weight-bold mr-2">{number(item.count)} clients</div>
                                        <div className="text-muted small">({number(item.ratio) + ' %'})</div>
                                    </div>
                                    <div className="progress-group-bars">
                                        <div className="progress progress-xs">
                                            <div className={'progress-bar ' + bg_colors[index]} style={{ width: item.ratio + '%' }}
                                                role="progressbar" style={{ width: item.ratio + '%' }}
                                                aria-valuenow="56" aria-valuemin="0"
                                                aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>

                            ))}
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="alert alert-info mt-4">
                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2"></FontAwesomeIcon>
                                        La segmentation client est effectuée sur les 12 derniers mois.
                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
})

const RepresentationRFM = observer(() => {
    return (
        <div className="col-lg-6 col-12">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <h4 className="card-title">Représentation 3D de vos segments</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col text-center">
                            <img src={rfm_cube} className="img-fluid mb-3 mx-auto" style={{ maxWidth: '90%' }}></img>

                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <div className="alert alert-info mt-3">
                                <FontAwesomeIcon icon={faInfoCircle} className="mr-2"></FontAwesomeIcon>
                                La combinaison des trois critères d'appréciation des clients :
                                montant, récence et fréquence, permet la segmentation prédictive des comportements d'achats.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
})

const CampagnesSection = observer(() => {

    return (
        <>
            {
                DashboardStore.props.campagnes.length > 0 &&
                <div className="col-lg-4 col-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col">
                                    <h4 className="card-title">Activité</h4>
                                </div>
                            </div>
                            <div className="row">
                                {DashboardStore.props.campagnes.map((item, index) => (
                                    <div className="col-12 border-top py-2">
                                        <div className="row">
                                            <div className="col-auto">
                                                <div className="bg-info campagne-square rounded">
                                                    <FontAwesomeIcon icon={faEnvelope}></FontAwesomeIcon>
                                                </div>
                                            </div>
                                            <div className="col d-flex flex-column px-0">
                                                <span className="text-sm">{item.titre}</span>
                                                <span className="small text-muted">{moment(item.date_envoi).fromNow()}</span>
                                            </div>
                                            <div className="col-3 d-flex flex-column">
                                                <span className="small text-muted">Reçu(s)</span>
                                                <span><strong>{number(item.recus)}</strong></span>
                                            </div>
                                        </div>
                                    </div>

                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
})

const OriginesActivationsSection = observer(() => {
    const bg_colors = ['bg-lightblue', 'bg-success', 'bg-warning', 'bg-danger', 'bg-pink', 'bg-purple', 'bg-orange', 'bg-indigo', 'bg-navy']
    const icons = [faStore, faShoppingBasket, faCoins, faUsers, faTicketAlt, faReceipt]
    return (
        <div className="col-lg-6 col-12">
            <div className="card">
                <div className="card-header">
                    <h5 className="card-title">Origines activations</h5>
                </div>
                <div className="card-body row">
                    <div className="col-md-12 col-sm-12 col-12">
                        <div className="progress-group">
                            <FontAwesomeIcon icon={faMale} size="sm" className="text-muted mr-2"></FontAwesomeIcon>Homme
                            <span className="float-right"><b>160</b>/200</span>
                            <div className="progress progress-sm rounded mt-1">
                                <div className="progress-bar bg-orange" style={{ width: '80%' }}></div>
                            </div>
                        </div>
                        <div className="progress-group">
                            <FontAwesomeIcon icon={faFemale} size="sm" className="text-muted mr-2"></FontAwesomeIcon>Femme
                            <span className="float-right"><b>160</b>/200</span>
                            <div className="progress progress-sm rounded mt-1">
                                <div className="progress-bar bg-orange" style={{ width: '80%' }}></div>
                            </div>
                        </div>
                        {DashboardStore.props.origines.map((item, index) => (
                            <div className="progress-group">
                                <FontAwesomeIcon icon={icons[index]} size="sm" className="text-muted mr-2"></FontAwesomeIcon>
                                {item.origine}
                                <span className="float-right"><b>{item.ratio}</b>/100</span>
                                <div className="progress progress-sm rounded mt-1">
                                    <div className={'progress-bar ' + bg_colors[1]} style={{ width: item.ratio + '%' }}></div>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>
        </div>
    )
})

const ClientsSection = observer(() => {
    return (
        <div className="col-12">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            <h4 className="card-title">Meilleurs clients</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <table className="table table-sm table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Rang</th>
                                        <th>Client</th>
                                        <th>Enseigne</th>
                                        <th className="text-right">Score</th>
                                        <th></th>
                                        <th>Activité</th>
                                        {
                                            UserStore.isWallet() &&
                                            <th>Total crédits ({UserStore.props.user.unitesPME2court})</th>
                                        }
                                        {
                                            !UserStore.isWallet() &&
                                            <th>Total achat ({UserStore.props.user.unitesPME2court})</th>
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {DashboardStore.props.clients.map((item, index) => (
                                        <tr>
                                            <td className="align-middle">
                                                <div className="font-weight-bold score-circle bg-success d-flex align-items-center justify-content-center">
                                                    {index + 1}
                                                </div>
                                            </td>
                                            <td className="align-middle">{item.nom}</td>
                                            <td className="align-middle">{item.enseigne}</td>
                                            <td className="align-middle">
                                                <div className="progress progress-xs progress-striped active rounded">
                                                    <div className="progress-bar bg-success" style={{ width: (item.score * 100) + '%' }}></div>
                                                </div>
                                            </td>
                                            <td className="align-middle"><div>{number(item.score)} / 10</div></td>
                                            <td className="align-middle">{moment(item.activite).fromNow()}</td>
                                            <td className="align-middle">{currency(item.total)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
})

const DerniersAchats = observer(() => {

    return (
        <div className="col">
            <div className="card">
                <div className="card-body">
                    <div className="row">
                        <div className="col">
                            {
                                !UserStore.isWallet() &&
                                < h4 className="card-title">Derniers achats</h4>
                            }
                            {
                                UserStore.isWallet() &&
                                < h4 className="card-title">Dernières transactions</h4>
                            }
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            <table className="table table-sm table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Ordre</th>
                                        <th>Client</th>
                                        <th>Enseigne</th>
                                        <th>Date</th>
                                        <th>Heure</th>
                                        <th>Montant ({UserStore.props.user.unitesPME2court})</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {DashboardStore.props.derniersAchats.map((item, index) => (
                                        <tr>
                                            <td className="align-middle">
                                                <div className="font-weight-bold score-circle bg-lightblue d-flex align-items-center justify-content-center">
                                                    {index + 1}
                                                </div>
                                            </td>
                                            <td>{item.nom}</td>
                                            <td>{item.enseigne}</td>
                                            <td>{moment(item.date).format('ll')}</td>
                                            <td>{moment(item.date).format('LT')}</td>
                                            <td>{currency(item.montant)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )

})

const DashboardView = () => {

    useEffect(() => {
        DashboardStore.getWeekStats()
        // CampagneStore.initRealTime()
        // CampagneStore.getAllStatutCampagne()
    }, [])

    // if (MainStore.loading.status)
    //     return (<Loader msg="Chargement des donnés ..."></Loader>)


    return (
        <div className="container-fluid mb-3  mt-3">
            {/* <h2 className="card-title">Tableau de bord</h2> */}
            <div className="animated fadeIn">
                <BlockUi tag="div" blocking={DashboardStore.props.loading.status}
                    message={DashboardStore.props.loading.msg}
                    loader={<BarLoader></BarLoader>}
                    style={{ minHeight: 'calc(100vh - 120px)' }}>
                    {
                        !DashboardStore.props.loading.status &&
                        <>
                            <ChartSection></ChartSection>
                            <RfmSection></RfmSection>
                            <div className="row">
                                <FrequentationSection></FrequentationSection>
                                <TotalSection></TotalSection>
                            </div>
                            <div className="row">
                                <CampagnesSection></CampagnesSection>
                                <DerniersAchats></DerniersAchats>
                            </div>
                            <div className="row">
                                <ClientsSection></ClientsSection>
                            </div>
                            {
                                UserStore.canSegmentation() &&
                                <div className="row">
                                    <ClassementRFM></ClassementRFM>
                                    <RepresentationRFM></RepresentationRFM>
                                </div>
                            }
                        </>
                    }
                    {
                        !DashboardStore.props.loading.status &&
                        <Modal isOpen={UserStore.props.user.firstTimeV2} backdrop="static" centered={true}
                            tag="div"

                        >
                            <ModalBody className="p-5">
                                <img className="img-welcome" src={imgWelcome}></img>
                                <div className="col-12 mb-4">
                                    <h2 className="text-center text-old">Bienvenue sur <span className="text-fid">FIDLINK</span></h2>
                                </div>
                                <div className="col-12 text-center mb-4">
                                    <p>Bienvenue sur la nouvelle version de <span className="text-fid">FIDLINK.</span></p>
                                    <p>Profitez d'une nouvelle interface ergonomique et fluide afin  de mieux analyser et gérer votre système de fidélité client.</p>
                                    <p>N'hésitez pas à communiquer grâce à notre nouveau système de campagne Email et SMS.</p>
                                </div>
                                <div className="col-12 d-flex center-h">
                                    <button
                                        onClick={() => UserStore.toggleFirstTimev2()}
                                        className="btn btn-lg btn-fid rounded">J'ai compris</button>
                                </div>

                            </ModalBody>
                        </Modal>

                    }
                </BlockUi>

            </div>
        </div>
    )
}

export default observer(DashboardView)