import { observable, toJS } from "mobx"
import { Civilite, Operators, Segments } from '../utils/constants'
import moment from 'moment';
import api from '../api'
import UserStore from './UserStore'
import CardsStore from "./CardsStore";
import { toast } from "react-toastify";

const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours'
    },
    pattern: {
        idcommercantFid: {
            active: false,
            title: 'Enseigne(s)',
            name: 'idcommercantFid',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                name: 'idcommercantFid',
                type: 'multiple',
                list: [
                    { id: 1, label: '__--FILTRE NON CHARGE--__' },
                    { id: 2, label: '__--FILTRE NON CHARGE--__' },
                    { id: 3, label: '__--FILTRE NON CHARGE--__' },
                    { id: 4, label: '__--FILTRE NON CHARGE--__' },
                    { id: 5, label: '__--FILTRE NON CHARGE--__' },
                    { id: 6, label: '__--FILTRE NON CHARGE--__' },
                ],
            },
            value: [{
                name: 'idcommercantFid',
                field: 'clientfid.idcommercantFid',
                campField: 'c.idcommercantFid',
                exportField: 'idcommercantFid',
                operator: 'in',
                type: 'text',
                value: []
            }],
            defaultValue: {
                name: 'idcommercantFid',
                field: 'clientfid.idcommercantFid',
                campField: 'c.idcommercantFid',
                exportField: 'idcommercantFid',
                type: 'array',
                operator: 'in',
                value: []
            },
        },
        ruleId: {
            active: false,
            title: 'Catégorie(s)',
            name: 'ruleId',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                name: 'ruleId',
                type: 'multiple',
                list: [
                    { id: 1, label: '__--FILTRE NON CHARGE--__' },
                    { id: 2, label: '__--FILTRE NON CHARGE--__' },
                    { id: 3, label: '__--FILTRE NON CHARGE--__' },
                    { id: 4, label: '__--FILTRE NON CHARGE--__' },
                    { id: 5, label: '__--FILTRE NON CHARGE--__' },
                    { id: 6, label: '__--FILTRE NON CHARGE--__' },
                ],
            },
            value: [{
                name: 'ruleId',
                field: 'clientfid.ruleId',
                campField: 'c.ruleId',
                exportField: 'Ruleid',
                operator: 'in',
                value: []
            }],
            defaultValue: {
                name: 'ruleId',
                field: 'clientfid.ruleId',
                campField: 'c.ruleId',
                exportField: 'Ruleid',
                type: 'array',
                operator: 'in',
                value: []
            },
        },
        segment: {
            active: false,
            title: 'Segments',
            name: 'segment',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                name: 'segment',
                type: 'multiple',
                list: Object.keys(Segments).map(key => ({ id: "'" + key + "'", label: Segments[key] })),
            },
            value: [{
                name: 'segment',
                field: 'clientfid.RFM_Segment',
                campField: 'c.RFM_Segment',
                exportField: 'RFM_Segment',
                operator: 'in',
                value: Object.keys(Segments).map(key => "'" + key + "'")
            }],
            defaultValue: {
                name: 'segment',
                field: 'clientfid.RFM_Segment',
                campField: 'c.RFM_Segment',
                exportField: 'RFM_Segment',
                type: 'array',
                operator: 'in',
                value: Object.keys(Segments).map(key => "'" + key + "'")
            },
        },
        clientNumCard: {
            active: false,
            quickSearch: true,
            title: 'N° de carte',
            name: 'clientNumCard',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientNumCard',
                type: 'text'
            },
            value: [{
                name: 'clientNumCard',
                field: 'clientNumCard',
                campField: 'c.clientNumCard',
                exportField: 'clientNumCard',
                operator: 'like',
                type: 'text',
                value: ''
            }],
            defaultValue: {
                name: 'clientNumCard',
                field: 'clientNumCard',
                campField: 'c.clientNumCard',
                exportField: 'clientNumCard',
                operator: 'like',
                type: 'text',
                value: ''
            }
        },
        clientCivilite: {
            active: false,
            title: 'Civilité',
            name: 'clientCivilite',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                type: 'multiple',
                list: Civilite.map((item, index) => ({ id: index, label: item })),
            },
            value: [{
                name: 'clientCivilite',
                field: 'clientCivilite',
                campField: 'c.clientCivilite',
                operator: 'in',
                value: Civilite.map((item, index) => index)
            }],
            defaultValue: {
                name: 'clientCivilite',
                field: 'clientCivilite',
                campField: 'c.clientCivilite',
                type: 'array',
                operator: 'in',
                value: Civilite.map((item, index) => index)
            }

        },
        clientNom: {
            active: false,
            quickSearch: true,
            title: 'Nom',
            name: 'clientNom',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientNom',
                type: 'text'
            },
            value: [{
                name: 'clientNom',
                field: 'clientNom',
                campField: 'c.clientNom',
                operator: 'like',
                type: 'text',
                value: ''
            }],
            defaultValue: {
                name: 'clientNom',
                field: 'clientNom',
                campField: 'c.clientNom',
                operator: 'like',
                type: 'text',
                value: ''
            }
        },
        clientPrenom: {
            active: false,
            quickSearch: true,
            title: 'Prénom',
            name: 'clientPrenom',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientNom',
                type: 'text'
            },
            value: [{
                name: 'clientPrenom',
                field: 'clientPrenom',
                campField: 'c.clientPrenom',
                operator: 'like',
                type: 'text',
                value: ''
            }],
            defaultValue: {
                name: 'clientPrenom',
                field: 'clientPrenom',
                campField: 'c.clientPrenom',
                type: 'text',
                operator: 'like',
                value: ''
            }
        },
        clientAdresse1: {
            active: false,
            title: 'Adresse 1',
            name: 'clientAdresse1',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientAdresse1',
                type: 'text'
            },
            value: [{
                name: 'clientAdresse1',
                field: 'clientAdresse1',
                campField: 'c.clientAdresse1',
                operator: 'like',
                type: 'text',
                value: ''
            }],
            defaultValue: {
                name: 'clientAdresse1',
                field: 'clientAdresse1',
                campField: 'c.clientAdresse1',
                operator: 'like',
                type: 'text',
                value: ''
            }
        },
        clientAdress2: {
            active: false,
            title: 'Adresse 2',
            name: 'clientAdress2',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientAdress2',
                type: 'text'
            },
            value: [{
                name: 'clientAdress2',
                field: 'clientAdress2',
                campField: 'c.clientAdress2',
                type: 'text',
                operator: 'like',
                value: ''
            }],
            defaultValue: {
                name: 'clientAdress2',
                field: 'clientAdress2',
                campField: 'c.clientAdress2',
                type: 'text',
                operator: 'like',
                value: ''
            }
        },
        clientNeLe: {
            active: false,
            title: 'Date de naissance',
            canAddOr: false,
            name: 'clientNeLe',
            operator: {
                type: 'select',
                list: ['=', '<>', 'between', '>', '>=', '<', '<='].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientNeLe',
                type: 'date'
            },
            value: [{
                name: 'clientNeLe',
                field: 'clientNeLe',
                campField: 'c.clientNeLe',
                type: 'date',
                operator: '=',
                value: moment().format('YYYY-MM-DD')
            }],
            defaultValue: {
                name: 'clientNeLe',
                field: 'clientNeLe',
                campField: 'c.clientNeLe',
                type: 'date',
                operator: '=',
                value: moment().format('YYYY-MM-DD')
            }
        },
        nbJrsExpirationPoint: {
            active: false,
            title: 'Nombre jours avant expiration $$point$$',
            canAddOr: false,
            name: 'nbJrsExpirationPoint',
            operator: {
                type: 'select',
                list: ['=', '<>', 'between', '>', '>=', '<', '<='].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'nbJrsExpirationPoint',
                type: 'number'
            },
            value: [{
                name: 'nbJrsExpirationPoint',
                field: 'datediff(dateValidPME1,date(now()))',
                campField: 'datediff(c.dateValidPME1,date(now()))',
                type: 'number',
                operator: '=',
                value: null
            }],
            defaultValue: {
                name: 'nbJrsExpirationPoint',
                field: 'datediff(dateValidPME1,date(now()))',
                campField: 'datediff(c.dateValidPME1,date(now()))',
                type: 'number',
                operator: '=',
                value: null
            }
        },
        nbJrsExpirationEuro: {
            active: false,
            title: 'Nombre jours avant expiration  $$euro$$',
            canAddOr: false,
            name: 'nbJrsExpirationEuro',
            operator: {
                type: 'select',
                list: ['=', '<>', 'between', '>', '>=', '<', '<='].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'nbJrsExpirationEuro',
                type: 'number'
            },
            value: [{
                name: 'nbJrsExpirationEuro',
                field: 'datediff(dateValidPME2,date(now()))',
                campField: 'datediff(c.dateValidPME2,date(now()))',
                type: 'number',
                operator: '=',
                value: null
            }],
            defaultValue: {
                name: 'nbJrsExpirationEuro',
                field: 'datediff(dateValidPME2,date(now()))',
                campField: 'datediff(c.dateValidPME2,date(now()))',
                type: 'number',
                operator: '=',
                value: null
            }
        },
        clientCP: {
            active: false,
            title: 'Code postal',
            canAddOr: false,
            name: 'clientCP',
            operator: {
                type: 'select',
                list: ['=', '<>', 'like', 'not like'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientCP',
                type: 'text'
            },
            value: [{
                name: 'clientCP',
                field: 'clientCP',
                campField: 'c.clientCP',
                type: 'text',
                operator: '=',
                value: null
            }],
            defaultValue: {
                name: 'clientCP',
                field: 'clientCP',
                campField: 'c.clientCP',
                type: 'text',
                operator: '=',
                value: null
            }
        },
        clientVille: {
            active: false,
            title: 'Ville',
            canAddOr: false,
            name: 'clientVille',
            operator: {
                type: 'select',
                list: ['like', 'not like'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientVille',
                type: 'text'
            },
            value: [{
                name: 'clientVille',
                field: 'clientVille',
                campField: 'c.clientVille',
                type: 'text',
                operator: 'like',
                value: null
            }],
            defaultValue: {
                name: 'clientVille',
                field: 'clientVille',
                campField: 'c.clientVille',
                type: 'text',
                operator: 'like',
                value: null
            }
        },
        clientEmail: {
            active: false,
            quickSearch: true,
            title: 'Email',
            canAddOr: false,
            name: 'clientEmail',
            operator: {
                type: 'select',
                list: ['=', 'like'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientEmail',
                type: 'text'
            },
            value: [{
                name: 'clientEmail',
                field: 'clientEmail',
                campField: 'c.clientEmail',
                type: 'text',
                operator: 'like',
                value: null
            }],
            defaultValue: {
                name: 'clientEmail',
                field: 'clientEmail',
                campField: 'c.clientEmail',
                type: 'text',
                operator: 'like',
                value: null
            }
        },
        clientTel: {
            active: false,
            title: 'Tél. fixe',
            canAddOr: false,
            name: 'clientTel',
            operator: {
                type: 'select',
                list: ['like'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientTel',
                type: 'text'
            },
            value: [{
                name: 'clientTel',
                field: 'clientTel',
                campField: 'c.clientTel',
                type: 'text',
                operator: 'like',
                value: null
            }],
            defaultValue: {
                name: 'clientTel',
                field: 'clientTel',
                campField: 'c.clientTel',
                type: 'text',
                operator: 'like',
                value: null
            }
        },
        clientGsm: {
            active: false,
            quickSearch: true,
            title: 'Tél. mobile',
            canAddOr: false,
            name: 'clientGsm',
            operator: {
                type: 'select',
                list: ['like'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientGsm',
                type: 'text'
            },
            value: [{
                name: 'clientGsm',
                field: 'clientGsm',
                campField: 'c.clientGsm',
                type: 'text',
                operator: 'like',
                value: null
            }],
            defaultValue: {
                name: 'clientGsm',
                field: 'clientGsm',
                campField: 'c.clientGsm',
                type: 'text',
                operator: 'like',
                value: null
            }
        },
        "1erAchat": {
            active: false,
            title: 'Date 1er achat',
            canAddOr: false,
            name: '1erAchat',
            operator: {
                type: 'select',
                list: ['=', '<>', 'between', '>', '>=', '<', '<='].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: '1erAchat',
                type: 'date'
            },
            value: [{
                name: '1erAchat',
                field: '1erAchat',
                campField: 'c.1erAchat',
                type: 'date',
                operator: '=',
                value: moment().format('YYYY-MM-DD')
            }],
            defaultValue: {
                name: '1erAchat',
                field: '1erAchat',
                campField: 'c.1erAchat',
                type: 'date',
                operator: '=',
                value: moment().format('YYYY-MM-DD')
            }
        },
        achatEff: {
            active: false,
            title: 'Achat effectué le',
            canAddOr: false,
            name: 'achatEff',
            operator: {
                type: 'select',
                list: ['=', '<>', 'between', '>', '>=', '<', '<='].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'achatEff',
                type: 'date'
            },
            value: [{
                name: 'achatEff',
                field: "idclientFid in (select `transacfid`.`idClientFid` from `transacfid`  where `transacfid`.`DateTransac`_$$$_)",
                campField: "c.idclientFid in (select `transacfid`.`idClientFid` from `transacfid`  where `transacfid`.`DateTransac`_$$$_)",
                type: 'date',
                custom: true,
                operator: '=',
                value: moment().format('YYYY-MM-DD')
            }],
            defaultValue: {
                name: 'achatEff',
                field: "idclientFid in (select `transacfid`.`idClientFid` from `transacfid`  where `transacfid`.`DateTransac`_$$$_)",
                campField: "c.idclientFid in (select `transacfid`.`idClientFid` from `transacfid`  where `transacfid`.`DateTransac`_$$$_)",
                type: 'date',
                custom: true,
                operator: '=',
                value: moment().format('YYYY-MM-DD')
            }
        },
        nbPoint: {
            active: false,
            title: '$$point$$ sur la carte',
            canAddOr: false,
            name: 'nbPoint',
            operator: {
                type: 'select',
                list: ['=', '<>', 'between', '>', '>=', '<', '<='].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'nbPoint',
                type: 'number'
            },
            value: [{
                name: 'nbPoint',
                field: 'CAST((clientPme1 * 0.01) AS UNSIGNED)',
                exportField: 'CAST(Pme1 AS UNSIGNED)',
                campField: 'CAST((c.clientPme1 * 0.01) AS UNSIGNED)',
                type: 'number',
                operator: '=',
                value: null
            }],
            defaultValue: {
                name: 'nbPoint',
                field: 'CAST((clientPme1 * 0.01) AS UNSIGNED)',
                exportField: 'CAST(Pme1 AS UNSIGNED)',
                campField: 'CAST((c.clientPme1 * 0.01) AS UNSIGNED)',
                type: 'number',
                operator: '=',
                value: null
            }
        },
        cumulPoint: {
            active: false,
            title: 'Cumul $$point$$',
            canAddOr: false,
            name: 'cumulPoint',
            operator: {
                type: 'select',
                list: ['=', '<>', 'between', '>', '>=', '<', '<='].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'cumulPoint',
                type: 'number'
            },
            value: [{
                name: 'cumulPoint',
                field: 'CAST((cumulCreditPme1 * 0.01) AS UNSIGNED)',
                exportField: 'CAST(cumCredPme1 AS UNSIGNED)',
                campField: 'CAST((c.cumulCreditPme1 * 0.01) AS UNSIGNED)',
                type: 'number',
                operator: '=',
                value: null
            }],
            defaultValue: {
                name: 'cumulPoint',
                field: 'CAST((cumulCreditPme1 * 0.01) AS UNSIGNED)',
                exportField: 'CAST(cumCredPme1 AS UNSIGNED)',
                campField: 'CAST((c.cumulCreditPme1 * 0.01) AS UNSIGNED)',
                type: 'number',
                operator: '=',
                value: null
            }
        },
        nbEuro: {
            active: false,
            title: '$$euro$$ sur la carte',
            canAddOr: false,
            name: 'nbEuro',
            operator: {
                type: 'select',
                list: ['=', '<>', 'between', '>', '>=', '<', '<='].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'nbEuro',
                type: 'number'
            },
            value: [{
                name: 'nbEuro',
                field: 'CAST((clientPme2 * 0.01) AS UNSIGNED)',
                exportField: 'CAST(Pme2 AS UNSIGNED)',
                campField: 'CAST((c.clientPme2 * 0.01) AS UNSIGNED)',
                type: 'number',
                operator: '=',
                value: null
            }],
            defaultValue: {
                name: 'nbEuro',
                field: 'CAST((clientPme2 * 0.01) AS UNSIGNED)',
                exportField: 'CAST(Pme2 AS UNSIGNED)',
                campField: 'CAST((c.clientPme2 * 0.01) AS UNSIGNED)',
                type: 'number',
                operator: '=',
                value: null
            }
        },
        cumulEuro: {
            active: false,
            title: 'Cumul $$euro$$',
            canAddOr: false,
            name: 'cumulEuro',
            operator: {
                type: 'select',
                list: ['=', '<>', 'between', '>', '>=', '<', '<='].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'cumulEuro',
                type: 'number'
            },
            value: [{
                name: 'cumulEuro',
                field: 'CAST((cumulCreditPme2 * 0.01) AS UNSIGNED)',
                exportField: 'CAST(cumCredPme2 AS UNSIGNED)',
                campField: 'CAST((c.cumulCreditPme2 * 0.01) AS UNSIGNED)',
                type: 'number',
                operator: '=',
                value: null
            }],
            defaultValue: {
                name: 'cumulEuro',
                field: 'CAST((cumulCreditPme2 * 0.01) AS UNSIGNED)',
                exportField: 'CAST(cumCredPme2 AS UNSIGNED)',
                campField: 'CAST((c.cumulCreditPme2 * 0.01) AS UNSIGNED)',
                type: 'number',
                operator: '=',
                value: null
            }
        },



    },
    countActiveFilter: 0,
    savedPattern: {},
    savedFilters: [],
    loadedFilter: null,
    isOpenModalSave: false,
    fromSegments: false
}


const ClientFilterStore = {

    props: props,
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    async getClients(mainWhere) {

        this.props.sortBy = this.props.sortBy || 'clientNom'
        this.props.sortOrder = this.props.sortOrder || 'ASC'

        let where = ClientFilterStore.getWhere(mainWhere)
        let data = {
            'objName': 'gridCartes',
            'cmd': 'getRows',
            'where': where,
            'start': this.props.pageStart,
            'limit': this.props.pageLimit,
            'sort': this.props.sortBy,
            'dir': this.props.sortOrder,
            // 'query': JSON.stringify(where)
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.data = !res.data.rows ? [] : res.data.rows
                this.props.pageTotal = res.data.totalCount
                this.props.pageCount = Math.ceil(res.data.totalCount / this.props.pageLimit)

            } else {
                this.props.data = []
            }
        }
        // this.props.loading.status = false
    },
    getQuickSerachItems() {
        let results = []
        Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].quickSearch)
            .map(item => {
                if (UserStore.isWallet()) {
                    if (this.props.pattern[item].name == 'clientNumCard') {
                        this.props.pattern[item].title = 'N° Client'
                    }
                }

                results.push({
                    id: this.props.pattern[item].name, label: this.props.pattern[item].title
                })
            })

        return results
    },
    toggleModalSave() {
        this.props.isOpenModalSave = !this.props.isOpenModalSave
    },
    async initFilterClient(pattern) {
        if (pattern)
            this.props.pattern = pattern
        console.log('initfilter')
        if (UserStore.isReseau()) {
            if (!UserStore.isCampagne())
                await this.getEnseignes()
        }
        else {
            delete this.props.pattern.idcommercantFid
        }

        if (UserStore.isCampagne()) {
            delete this.props.pattern.idcommercantFid
            delete this.props.pattern.ruleId
            delete this.props.pattern.segment
            delete this.props.pattern.clientNumCard
            delete this.props.pattern.nbJrsExpirationPoint
            delete this.props.pattern.nbJrsExpirationEuro
            delete this.props.pattern['1erAchat']
            delete this.props.pattern.achatEff
            delete this.props.pattern.nbPoint
            delete this.props.pattern.nbEuro
            delete this.props.pattern.cumulEuro
            delete this.props.pattern.cumulPoint
        }

        if (UserStore.isWallet() || UserStore.isFidSimple()) {
            delete this.props.pattern.nbPoint
            delete this.props.pattern.cumulPoint
            delete this.props.pattern.nbJrsExpirationPoint
        }

        if (!UserStore.canSegmentation()) {
            delete this.props.pattern.segment
        }

        if (!UserStore.isCampagne())
            await this.getRules()
        await this.getSavedFilters()

        Object.keys(this.props.pattern)
            .map(item => {
                if (UserStore.isWallet()) {
                    if (this.props.pattern[item].name == 'clientNumCard') {
                        this.props.pattern[item].title = 'N° Client'
                    }
                }
                this.props.pattern[item].title = this.props.pattern[item].title.replace('$$point$$', UserStore.props.user.unitesPME1)
                this.props.pattern[item].title = this.props.pattern[item].title.replace('$$euro$$', UserStore.props.user.unitesPME2)
            }
            )
        // await CardsStore.getClients()
        this.props.savedPattern = toJS(this.props.pattern)

    },
    toggleItemFilter(key) {
        if (!this.props.pattern[key].isNotToggable)
            this.props.pattern[key].active = !this.props.pattern[key].active
    },
    activateItemFilter(key) {
        if (!this.props.pattern[key].isNotToggable)
            this.props.pattern[key].active = true
    },
    changeOperator(key, val, index) {
        console.log(val)
        this.props.pattern[key].value[index].operator = val
        if (val == 'between') {
            this.props.pattern[key].value[index].value = [null, null]
        } else {
            this.props.pattern[key].value[index].value = ''
        }

    },
    changeValue(key, val, index, order) {
        if (this.props.pattern[key].value[index].operator == 'between') {
            this.props.pattern[key].value[index].value[order] = val
        }
        else if (this.props.pattern[key].value[index].operator == 'in') {
            if (val != 'all') {
                let position = this.props.pattern[key].value[index].value.findIndex(o => o == val);
                console.log('position :', position)
                if (position === -1)
                    this.props.pattern[key].value[index].value = [...this.props.pattern[key].value[index].value, val]
                else
                    this.props.pattern[key].value[index].value = [...this.props.pattern[key].value[index].value.filter(item => item != val)]
            } else {
                if (this.props.pattern[key].field.list.length != this.props.pattern[key].value[index].value.length) {
                    this.props.pattern[key].value[index].value = [...this.props.pattern[key].field.list.map(item => item.id)]
                } else {
                    this.props.pattern[key].value[index].value = []
                }
            }
        }
        else {
            this.props.pattern[key].value[index].value = val
        }
        console.log(toJS(this.props.pattern[key].value[index].value))
    },
    addItemFilter(key, type) {
        let newVal = { ...this.props.pattern[key].defaultValue }
        newVal.logic = type
        this.props.pattern[key].value = [...this.props.pattern[key].value, newVal]
    },
    removeItemFilter(key, idx) {
        this.props.pattern[key].value = [...this.props.pattern[key].value.filter((item, index) => index != idx)]
    },
    submitFilter() {
        this.props.savedPattern = toJS(this.props.pattern)
        this.getCountActiveFilter()
    },
    resetFilter() {
        Object.keys(this.props.pattern)
            .map(item => {
                this.props.pattern[item].value = props.pattern[item].value
                this.props.savedPattern[item].value = props.pattern[item].value
                this.props.pattern[item].active = false
                this.props.savedPattern[item].active = false
            })
    },
    undoPattern() {
        this.props.pattern = toJS(this.props.savedPattern)
    },
    getWhere(where = '', type = null) {

        const main = [...Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].active)
            .map(item => toJS(this.props.pattern[item].value))]

        console.log(where)
        for (let key in main) {
            if (main[key].isHidden)
                continue
            if (where.length) {
                if (main[key].length)
                    where += ' AND ( '
            }
            else
                where += '( '
            let pass = 0
            for (let id in main[key]) {
                if (pass)
                    where += " " + main[key][id].logic + " "
                pass = 1

                if (type == "campaign")
                    where += this.getClauseCampaign(main[key][id], main[key][id].custom)
                else if (type == "export")
                    where += this.getClauseExport(main[key][id], main[key][id].custom)
                else
                    where += this.getClause(main[key][id], main[key][id].custom)


            }

            if (where.length) {
                if (main[key].length)
                    where += ')'
            }
        }

        return where

    },
    getClause(clause, isCustom = false) {
        let where = '-+--+-+ ERR -++-+-+'
        if (clause.operator == 'in') {
            where = clause.field + " IN (" + clause.value.join(',') + ") "
            console.log(where)
        }
        else if (clause.operator == 'between') {
            if (clause.type == 'date') {
                where = clause.field + " BETWEEN '" + clause.value[0] + "' AND '" + clause.value[1] + "' "
            }
            else if (clause.type == 'number') {
                where = clause.field + " BETWEEN " + clause.value[0] + " AND " + clause.value[1] + " "
            } else {
                where = '__ERROR__1'
            }
        }
        else if (clause.operator == 'like' || clause.operator == 'not like') {
            if (clause.type == 'text') {
                where = clause.field + " " + clause.operator + " '%" + clause.value + "%' "
            }
            else {
                where = '__ERROR__2'
            }
        }
        else if (
            clause.operator == '>' ||
            clause.operator == '<' ||
            clause.operator == '>=' ||
            clause.operator == '<=' ||
            clause.operator == '<>' ||
            clause.operator == '='
        ) {

            if (clause.type == 'date' || clause.type == 'text') {
                where = clause.field + " " + clause.operator + " '" + clause.value + "' "
            }
            else if (clause.type == 'number') {
                where = clause.field + " " + clause.operator + " " + clause.value + " "
            }
            else {
                where = '__ERROR__3'
            }
        }
        else {
            where = '__ERROR__4'
        }
        if (isCustom)
            return clause.field.replace('_$$$_', where.replace(clause.field, ''))
        return where
    },
    getClauseCampaign(clause, isCustom = false) {
        let where = '-+--+-+ ERR -++-+-+'
        if (clause.operator == 'in') {
            where = clause.campField + " IN (" + clause.value.join(',') + ") "
            console.log(where)
        }
        else if (clause.operator == 'between') {
            if (clause.type == 'date') {
                where = clause.campField + " BETWEEN '" + clause.value[0] + "' AND '" + clause.value[1] + "' "
            }
            else if (clause.type == 'number') {
                where = clause.campField + " BETWEEN " + clause.value[0] + " AND " + clause.value[1] + " "
            } else {
                where = '__ERROR__1'
            }
        }
        else if (clause.operator == 'like' || clause.operator == 'not like') {
            if (clause.type == 'text') {
                where = clause.campField + " " + clause.operator + " '%" + clause.value + "%' "
            }
            else {
                where = '__ERROR__2'
            }
        }
        else if (
            clause.operator == '>' ||
            clause.operator == '<' ||
            clause.operator == '>=' ||
            clause.operator == '<=' ||
            clause.operator == '<>' ||
            clause.operator == '='
        ) {

            if (clause.type == 'date' || clause.type == 'text') {
                where = clause.campField + " " + clause.operator + " '" + clause.value + "' "
            }
            else if (clause.type == 'number') {
                where = clause.campField + " " + clause.operator + " " + clause.value + " "
            }
            else {
                where = '__ERROR__3'
            }
        }
        else {
            where = '__ERROR__4'
        }
        console.log(clause)
        if (isCustom)
            return clause.campField.replace('_$$$_', where.replace(clause.campField, ''))
        return where
    },
    getClauseExport(clause, isCustom = false) {
        let field = null

        if (clause.exportField)
            field = clause.exportField
        else
            field = clause.field

            let where = '-+--+-+ ERR -++-+-+'
        if (clause.operator == 'in') {
            where = field + " IN (" + clause.value.join(',') + ") "
            console.log(where)
        }
        else if (clause.operator == 'between') {
            if (clause.type == 'date') {
                where = field + " BETWEEN '" + clause.value[0] + "' AND '" + clause.value[1] + "' "
            }
            else if (clause.type == 'number') {
                where = field + " BETWEEN " + clause.value[0] + " AND " + clause.value[1] + " "
            } else {
                where = '__ERROR__1'
            }
        }
        else if (clause.operator == 'like' || clause.operator == 'not like') {
            if (clause.type == 'text') {
                where = field + " " + clause.operator + " '%" + clause.value + "%' "
            }
            else {
                where = '__ERROR__2'
            }
        }
        else if (
            clause.operator == '>' ||
            clause.operator == '<' ||
            clause.operator == '>=' ||
            clause.operator == '<=' ||
            clause.operator == '<>' ||
            clause.operator == '='
        ) {

            if (clause.type == 'date' || clause.type == 'text') {
                where = field + " " + clause.operator + " '" + clause.value + "' "
            }
            else if (clause.type == 'number') {
                where = field + " " + clause.operator + " " + clause.value + " "
            }
            else {
                where = '__ERROR__3'
            }
        }
        else {
            where = '__ERROR__4'
        }
        console.log(clause)
        if (isCustom)
            return field.replace('_$$$_', where.replace(field, ''))
        return where
    },
    async getEnseignes() {

        const idRes = UserStore.props.user.idRes
        let data = {
            sort: 'NomCommercant',
            dir: 'DESC',
            cmd: 'getRows',
            objName: 'listeCommercants',
            where: 'iDreseauFid=' + idRes
        }

        const res = await api.post('/scripts/commerce/commercant.php', data)
        if (res.data.success) {
            const data = !res.data.rows ? [] : res.data.rows
            this.props.pattern.idcommercantFid.field.list = data.map((item) => ({ id: item.idcommercantFid, label: item.NomCommercant }))

            if (!this.props.pattern.idcommercantFid.value.length)
                this.props.pattern.idcommercantFid.value.forEach((item) => {
                    item.value = data.map((item) => item.idcommercantFid)
                })

        }

    },
    async getRules() {

        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let objName = null
        let where = null
        let url = null
        if (UserStore.isReseau()) {
            objName = "listeRegleRes"
            where = " reglereseau.Status=1 and reglereseau.Reseauid=" + idRes
            url = "/scripts/reseau/reseau.php"
        }
        else {
            objName = "listeRegleCom"
            where = " reglecommercant.Status=1 and reglecommercant.Commercantid=" + idCom
            url = "/scripts/commerce/commercant.php"
        }

        let data = {
            cmd: 'getRows',
            objName: objName,
            fields: '["Label"]',
            where: where
        }

        const res = await api.post(url, data)
        if (res.data.success) {
            const data = !res.data.rows ? [] : res.data.rows
            this.props.pattern.ruleId.field.list = data.map((item) => ({ id: item.RuleKey, label: item.Label }))

            this.props.pattern.ruleId.value.forEach((item) => {
                item.value = data.map((item) => item.RuleKey)
            })

        }
    },
    getCountActiveFilter() {
        this.props.countActiveFilter = Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].active).length
    },
    //FILTER ACTIONS
    async loadFilter(index) {
        this.doLoading(true, 'Chargement du filtre')
        this.props.pattern = props.pattern
        this.props.savedPattern = props.pattern

        await this.initFilterClient()

        Object.keys(this.props.savedFilters[index].filtre)
            .map(item => {
                console.log(item)
                this.props.pattern[item].value = this.props.savedFilters[index].filtre[item]
                this.props.savedPattern[item].value = this.props.savedFilters[index].filtre[item]

                this.props.pattern[item].active = true
                this.props.savedPattern[item].active = true

            })

        this.props.loadedFilter = this.props.savedFilters[index].name
        this.getCountActiveFilter()
        toast.success('Le filtre a bien été chargé')
        this.doLoading(false)

    },
    async getSavedFilters() {
        let data = {
            objName: 'listeFiltres',
            cmd: 'getFiltreClient',
            where: ' idUsersFid = ' + UserStore.props.user.idUser
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            const data = !res.data.rows ? [] : res.data.rows
            const filtre = data[0]['filtreClient'] ? JSON.parse(data[0]['filtreClient']) : []
            this.props.savedFilters = filtre

        }
    },
    async deleteFilter(index) {
        this.doLoading(true, 'Suppression du filtre')
        let newSavedFilters = toJS(this.props.savedFilters)

        const position = newSavedFilters.indexOf(index);
        if (index > -1) {
            newSavedFilters.splice(position, 1);
        }

        let toSend = {
            idusersFid: UserStore.props.user.idUser,
            filtreClient: JSON.stringify(newSavedFilters)
        }


        let data = {
            objName: 'listeFiltres',
            cmd: 'saveRows',
            data: JSON.stringify([toSend])
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            this.props.savedFilters = newSavedFilters
            setTimeout(() => {
                this.doLoading(false)
                toast.success('Le filtre a bien été supprimer')
            }, 2000)

        }
        else {
            setTimeout(() => {
                this.doLoading(false)
                toast.error('Une erreur est survenue')
            }, 2000)

        }

    },
    async saveFilter(name) {
        this.doLoading(true, 'Sauvegarde en cours ...')
        let filter = {}

        Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].active)
            .map(item => { filter[item] = this.props.pattern[item].value })

        let toSave = {
            name: name,
            date: moment().format('YYYY-MM-DD'),
            filtre: toJS(filter)
        }

        this.props.savedFilters.unshift(toSave)

        let toSend = {
            idusersFid: UserStore.props.user.idUser,
            filtreClient: JSON.stringify(toJS(this.props.savedFilters))
        }

        let data = {
            objName: 'listeFiltres',
            cmd: 'saveRows',
            data: JSON.stringify([toSend])
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            toast.success('Le filtre a bien été sauvegardé')
        }
        else {
            toast.error('Une erreur est survenue')
        }
        this.doLoading(false)
    },
    getCurrentFilterJson() {
        let filter = {}

        Object.keys(this.props.pattern)
            .filter(item => this.props.pattern[item].active)
            .map(item => { filter[item] = this.props.pattern[item].value })

        return toJS(filter)
    },
    async loadCriteresJson(filtre) {
        this.props.pattern = props.pattern
        this.props.savedPattern = props.pattern

        await this.initFilterClient()

        Object.keys(filtre)
            .map(item => {
                console.log(item)
                this.props.pattern[item].value = filtre[item]
                this.props.savedPattern[item].value = filtre[item]

                this.props.pattern[item].active = true
                this.props.savedPattern[item].active = true

            })

        // this.props.loadedFilter = this.props.savedFilters[index].name
        this.getCountActiveFilter()

    },
    reset() {
        this.props = props
    }
}


export default observable(ClientFilterStore)