import React from 'react'
import { observable, toJS } from 'mobx'
import api from '../api'
import UserStore from './UserStore'
import moment from 'moment'
import { Civilite, Operators } from '../utils/constants'
import { EditDropDown } from '../components/Game/GamesView'
import { toast } from 'react-toastify'
import { faImages } from '@fortawesome/free-solid-svg-icons'
import ParticipantsFilterStore from './ParticipantsFilterStore'

const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours ...'
    },
    columns: [
        {
            Header: 'Libellé',
            accessor: 'nomJeu',
            field: 'nomJeu',
        },
        {
            Header: 'Statut',
            accessor: 'statut',
            field: 'statut',
            Cell: ({ row, cell: { value } }) => {
                if (row.values['date_tirage'] != '') {
                    return <span style={{ fontSize: '80%' }} className="bg-danger badge font-weight-bold">
                        Clôturé
                    </span>
                }
                else if (parseInt(row.values['statut']) == 1) {
                    return <span style={{ fontSize: '75%' }} className="bg-success badge font-weight-bold">
                        Actif
                    </span>
                }
                else {
                    return <span style={{ fontSize: '75%' }} className="bg-danger badge font-weight-bold">
                        Inactif
                    </span>
                }

            }
        },
        {
            Header: 'Réseau',
            accessor: 'NomReseau',
            field: 'NomReseau',
        },
        {
            Header: 'Date début',
            accessor: 'date_debut',
            field: 'date_debut',
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY')
                else
                    return "-"
            },
        },
        {
            Header: 'Date fin',
            accessor: 'date_fin',
            field: 'date_fin',
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY')
                else
                    return "-"
            },
        },
        {
            Header: 'Date tirage',
            accessor: 'date_tirage',
            field: 'date_tirage',
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY')
                else
                    return "-"
            },
        },
        {
            Header: 'Nbr. participants',
            accessor: 'total_participants',
            field: 'total_participants',
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row, data, toggleModalGame, toggleModalParticipants }) => {
                // console.log(toJS(data[row.index]))
                return (
                    <EditDropDown
                        row={data[row.index]}
                        toggleModalGame={toggleModalGame}
                        toggleModalParticipants={toggleModalParticipants}
                    ></EditDropDown>

                )
            }

        },
    ],
    columnsParticipants: [
        {
            Header: 'Gagnant',
            accessor: 'lot_gagnant',
            field: 'lot_gagnant',
            Cell: ({ cell: { value } }) => {
                if (value == 0)
                    return "-"
                else if (value == 1)
                    return value + " er"
                else
                    return value + " ème"

            },

        },
        {
            Header: 'Lot',
            accessor: 'lot',
            field: 'lot',
        },
        {
            Header: 'Nom',
            accessor: 'cltNom',
            field: 'cltNom',
        },
        {
            Header: 'Prénom',
            accessor: 'cltPrenom',
            field: 'cltPrenom',
        },
        {
            Header: 'N° Carte',
            accessor: 'cltNumCard',
            field: 'cltNumCard',
        },
        {
            Header: 'Tél. mobile',
            accessor: 'cltGsm',
            field: 'cltGsm',
        },
        {
            Header: 'Email',
            accessor: 'cltEmail',
            field: 'cltEmail',
        },
        {
            Header: 'Enseigne',
            accessor: 'NomCom',
            field: 'NomCom',
        },
        {
            Header: 'Date inscription',
            accessor: 'date_inscription',
            field: 'date_inscription',
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY')
                else
                    return "-"
            },
        },
    ],
    initialValuesGame: {
        "nomJeu": "",
        "descJeu": "",
        "avatar": "",
        "couleur": "",
        "date_debut": moment().format('YYYY-MM-DD HH:mm'),
        "date_fin": moment().add('1', 'hours').format('YYYY-MM-DD HH:mm'),
        "date_tirage": "",
        "statut": "",
        "cgu": "",
        "total_participants": null,
        "mode_confirmation": null,
        "nb_lots": null,
        "lots": [],
        "ModeFonctionnement": null,
        "autoriser_participation_sans_carte": false
    },
    data: [],
    dataParticipants: [],
    typeLots: [],
    pageStart: 0,
    pageIndex: 0,
    pageLimit: 30,
    pageTotal: 0,
    pageCount: 0,
    pageStartPart: 0,
    pageIndexPart: 0,
    pageLimitPart: 30,
    pageTotalPart: 0,
    pageCountPart: 0,
    events: [],
    sortBy: null,
    sortByPart: null,
    modalEditTitle: "",
    isOpenModalGame: false,
    isOpenModalParticipants: false,
    sortOrder: 'ASC',
    sortOrderPart: 'ASC',
    currentIdJeu: null,
    currentNomJeu: null

}
const GamesStore = {

    /**** PROPS ****/

    props: props,


    /**** METHODS ****/
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    async init() {
        this.reset()
        this.doLoading(true, 'Chargement en cours ...')
        await this.getGames()
        await this.getTypeLots()
        this.doLoading(false)
    },
    async getTypeLots() {

        const to_send = {
            cmd: 'getRowz',
            objName: 'tombola_typelot',
            fields: '["idtypelot","libelle"]',
        }

        const res = await api.post("/scripts/tombola/tombola.php", to_send)
        if (res.data.success) {
            if (res.data.rows.length) {
                this.props.typeLots = res.data.rows.map(item => ({ id: item.idtypelot, label: item.libelle }))
            }
        }
    },
    formatLots(to_convert, nb_lots, idJeu) {
        console.log('Format Lots')
        const lots = []
        try {
            to_convert = JSON.parse(to_convert)
        }
        catch {
            return lots
        }
        console.log(to_convert, nb_lots)

        // console.log(typeof to_convert == "Object")

        if (typeof to_convert == "object")
            for (let i = 1; i <= nb_lots; i++) {
                lots.push({
                    typelot: to_convert['typelot' + i],
                    nom: to_convert['nom' + i],
                    description: to_convert['description' + i],
                    valpme1: to_convert['valpme1' + i],
                    valpme2: to_convert['valpme2' + i],
                    defaultImage: "reseaux/games/game_" + idJeu + "_lot_" + i + ".png?random=" + new Date().getTime(),
                    dureevalidite: to_convert['dureevalidite' + i],
                })
            }


        return lots

    },
    unFormatLots(to_convert, idJeu) {
        console.log('Unformat Lots')
        let lots = []

        for (let i = 0; i < to_convert.length; i++) {
            lots.push({
                "newRecord": true,
                "idJeu": idJeu,
                'ranglot': i + 1,
                typelot: to_convert[i]['typelot'],
                nomlot: to_convert[i]['nom'],
                description_lot: to_convert[i]['description'],
                valpme1: to_convert[i]['valpme1'] ? to_convert[i]['valpme1'] : 0,
                valpme2: to_convert[i]['valpme2'] ? to_convert[i]['valpme2'] : 0,
                dureevalidite: to_convert[i]['dureevalidite'] ? to_convert[i]['dureevalidite'] : 0,

            })
        }

        // console.log(lots)

        return lots

    },
    // onDoubleClickRow(title,row){
    //     this.toggleModalGame(title,row)
    // },
    toggleModalGame(title, row) {
        console.log(row)
        this.props.modalEditTitle = title

        if (!this.props.isOpenModalGame && row) {

            this.props.initialValuesGame = {
                "idJeu": row.idJeu,
                "idReseau": row.idReseau,
                "avatar": "reseaux/games/game_" + row.idJeu + ".png?random=" + new Date().getTime(),
                "nomJeu": row.nomJeu,
                "descJeu": row.descJeu,
                "couleur": row.couleur,
                "date_debut": row.date_debut,
                "date_fin": row.date_fin,
                "date_tirage": row.date_tirage,
                "statut": row.statut,
                "cgu": row.cgu,
                "mode_confirmation": row.mode_confirmation,
                "nb_lots": row.nb_lots,
                "lots": this.formatLots(row.lots, parseInt(row.nb_lots), row.idJeu),
                // "ModeFonctionnement": null,
                "autoriser_participation_sans_carte": row.autoriser_participation_sans_carte
            }
        }
        else {
            this.props.initialValuesGame = props.initialValuesGame
        }
        // console.log(toJS(this.props.initialValuesGame))
        this.props.isOpenModalGame = !this.props.isOpenModalGame

    },
    async toggleModalParticipants(title, row) {
        this.props.isOpenModalParticipants = !this.props.isOpenModalParticipants
        if (this.props.isOpenModalParticipants) {
            this.doLoading(true, 'Chargement en cours ...')
            this.props.currentIdJeu = row.idJeu
            this.props.currentNomJeu = row.nomJeu
            this.getParticipants()
            this.doLoading(false)
        }
    },
    addLot(setFieldValue, lots) {

        // console.log(setFieldValue)
        let newLots = toJS(lots)

        const newLot = {
            typelot: this.props.typeLots[this.props.typeLots.length - 1].id,
            nomlot: "",
            descriptionlot: "TEST",
        }

        newLots.push(newLot)

        console.log(newLots)

        setFieldValue('lots', newLots)

        // this.props.initialValuesGame.lots.push(newLot)
    },
    deleteLot(setFieldValue, lots, index) {

        // console.log(setFieldValue)
        let newLots = toJS(lots)


        console.log(newLots)

        newLots = newLots.filter((item, id) => index != id)

        setFieldValue('lots', newLots)

    },
    validateGame() {
        return true
    },
    sendGameInfo(data) {
        console.log(data)
        test = {
            cmd: 'saveRows',
            objName: 'tombola_insert',
            idName: 'idJeu',
            ref: 'maj_calendar',
            data: [{
                "date_debut": "2020-07-08T09:38:00",
                "date_fin": "2020-07-08T10:38:00",
                "IsNew": true,
                "nomJeu": "TEST JEU",
                "descJeu": "TEST ENVOI",
                "couleur": "#9AFEFF",
                "idReseau": 392,
                "statut": true,
                "mode_confirmation": 1,
                "autoriser_participation_sans_carte": true,
                "newRecord": true,
                "_weekIndex": 1,
                "_renderAsAllDay": false,
                "spanLeft": false,
                "spanRight": false,
                "spanCls": "",
                "idJeu": null
            }]
        }

    },
    async submitImg(avatar, images, idJeu) {

        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }

        console.log(images)

        if (avatar) {
            //Envoi du fichier
            console.log(avatar)
            // return
            console.log('Envoi image jeu')
            this.doLoading(true, 'Envoi image jeu en cours ...')
            const formData = new FormData();

            formData.append('cmd', "recFiles")
            formData.append('imgRsx', avatar[0])
            formData.append('_basedir', "reseaux/games")
            formData.append('_filename', "game_" + idJeu)
            formData.append('is_a_game', 1)
            formData.append('reseauId', UserStore.props.user.idRes)
            formData.append('userId', UserStore.props.user.idUser)
            // formData.append('com', idCom)

            const res = await api.post('/scripts/reseau/impImgRSX.php', formData, config)
            // if (res.data.success) {
            //     return true
            // }
            // else {
            //     return false
            // }

        }

        images.map(async (image, index) => {

            if (image) {
                //Envoi du fichier
                console.log(image)
                // return
                console.log('Envoi lots')
                this.doLoading(true, 'Envoi des lots en cours ...')
                const formData = new FormData();

                formData.append('cmd', "recFiles")
                formData.append('imgRsx', image[0])
                formData.append('_basedir', "reseaux/games")
                formData.append('_filename', "game_" + idJeu + "_lot_" + (index + 1))
                formData.append('is_a_game', 2)
                formData.append('reseauId', UserStore.props.user.idRes)
                formData.append('userId', UserStore.props.user.idUser)
                // formData.append('com', idCom)

                const res = await api.post('/scripts/reseau/impImgRSX.php', formData, config)
                // if (res.data.success) {
                //     return true
                // }
                // else {
                //     return false
                // }

            }

        })

        // this.doLoading(false)



    },
    async submitLots(values, idJeu) {
        console.log(values)
        let to_send = {
            cmd: 'maj_lots',
            objName: 'tombola_lots',
            idJeu: idJeu,
            data: JSON.stringify(this.unFormatLots(values, idJeu)),
            data_nb_lots: values.length
        }


        console.log('nb_lots', toJS(values.length))
        // console.log(toJS(this.unFormatLots(values)))
        // return
        const res = await api.post('/scripts/tombola/tombola.php', to_send)
        if (res.data.success) {
            return true
        }
        else {
            return false
        }
    },
    async submitGame(values) {
        this.doLoading(true, 'Chargement en cours ...')

        let data = toJS(values)
        let idJeu = null
        data['idReseau'] = UserStore.props.user.idRes

        if (!data['idJeu']) {
            data['isNew'] = true
            data['newRecord'] = true
        } else {
            idJeu = data['idJeu']
        }


        const to_send = {
            objName: 'tombola_insert',
            cmd: 'saveRows',
            idName: 'idJeu',
            ref: 'maj_calendar',
            data: JSON.stringify([data])
        }

        // console.log(toJS(values))
        // return

        const res = await api.post('/scripts/tombola/tombola.php', to_send)
        if (res.data.success) {
            if (res.data.insertIds)
                idJeu = res.data.insertIds[0]
            this.doLoading(true, 'Sauvegarde des lots ...')
            const res1 = await this.submitLots(data['lots'], idJeu)
            // if (res1.data.success)
            await this.submitImg(data['avatar'], data.lots.map(item => item.image), idJeu)


            await this.getGames()
            this.toggleModalGame()
            if (!data['idJeu']) {
                toast.success('Le jeu à bien été créée')
            }
            else{
                toast.success('Le jeu à bien été modifié')
            }
        }
        else {
            toast.error('Une erreur est survenue')
        }

        this.doLoading(false)
    },
    async getGames() {
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        this.props.sortBy = this.props.sortBy || 'nomJeu'
        this.props.sortOrder = this.props.sortOrder || 'ASC'

        let where = ''
        if (UserStore.isReseau())
            where = 'iDreseauFid = ' + idRes
        else
            where = 'idcommercantFid = ' + idCom
        let data = {
            'objName': 'tombola',
            'cmd': 'getRows',
            'where': where,
            'start': this.props.pageStart,
            'limit': this.props.pageLimit,
            'sort': this.props.sortBy,
            'dir': this.props.sortOrder,
            'groupBy': 'j.idJeu '
            // 'query': JSON.stringify(where)
        }


        const res = await api.post('/scripts/tombola/tombola.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.data = !res.data.rows ? [] : res.data.rows
                this.props.events = !res.data.rows ? [] : res.data.rows.map((item, index) => ({
                    id: index,
                    title: item.nomJeu,
                    start: moment(item.date_debut),
                    end: moment(item.date_fin),
                    couleur: item.couleur,
                    row: item
                }))
                this.props.pageTotal = res.data.totalCount
                this.props.pageCount = Math.ceil(res.data.totalCount / this.props.pageLimit)

            } else {
                this.props.data = []
            }
        }
    },
    async getFilteredParticipants() {
        this.doLoading(true, 'Chargement en cours ...')
        this.props.pageStartPart = 0
        this.props.pageIndexPart = 0
        this.props.pageLimitPart = 30
        await this.getParticipants()
        this.doLoading(false)
    },
    async getParticipants() {

        this.props.sortByPart = this.props.sortByPart || 'idClientFid'
        this.props.sortOrderPart = this.props.sortOrderPart || 'ASC'
        let where = ParticipantsFilterStore.getWhere('idJeu = ' + this.props.currentIdJeu)

        let data = {
            'objName': 'gridCartesParticipantsJeu',
            'cmd': 'getRows',
            'where': where,
            'start': this.props.pageStart,
            'limit': this.props.pageLimit,
            'sort': this.props.sortByPart,
            'dir': this.props.sortOrderPart,
            // 'query': JSON.stringify(where)
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.dataParticipants = !res.data.rows ? [] : res.data.rows
                this.props.pageTotalPart = res.data.totalCount
                this.props.pageCountPart = Math.ceil(res.data.totalCount / this.props.pageLimitPart)

            } else {
                this.props.dataParticipants = []
            }
        }
    },
    async nextPage() {
        this.props.pageIndex = this.props.pageIndex + 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getGames()
        this.doLoading(false)
    },
    async prevPage() {
        this.props.pageIndex = this.props.pageIndex - 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getGames()
        this.doLoading(false)
    },
    async goToPage(page) {
        this.props.pageIndex = page
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getGames()
        this.doLoading(false)
    },
    async nextPagePart() {
        this.props.pageIndexPart = this.props.pageIndexPart + 1
        this.props.pageStartPart = this.props.pageIndexPart * this.props.pageLimitPart
        this.doLoading(true, 'Chargement en cours ...')
        await this.getParticipants()
        this.doLoading(false)
    },
    async prevPagePart() {
        this.props.pageIndexPart = this.props.pageIndexPart - 1
        this.props.pageStartPart = this.props.pageIndexPart * this.props.pageLimitPart
        this.doLoading(true, 'Chargement en cours ...')
        await this.getParticipants()
        this.doLoading(false)
    },
    async goToPagePart(page) {
        this.props.pageIndexPart = page
        this.props.pageStartPart = this.props.pageIndexPart * this.props.pageLimitPart
        this.doLoading(true, 'Chargement en cours ...')
        await this.getParticipants()
        this.doLoading(false)
    },
    reset() {
        this.props = props
    }
}

export default observable(GamesStore)