import React, { useEffect } from 'react'
import BlockUi from 'react-block-ui'
import NotificationStore from '../../stores/NotificationStore'
import { BarLoader } from '../Loader/Loading'
import MyField from '../../reusable/MyField';
import { notificationFrequenceEnvoi, notificationComparaison } from '../../utils/constants';
import { useFormik } from 'formik';
import { observer } from 'mobx-react';
import { toJS } from 'mobx';

/*

comparison period 1 a 12 (janvier a decembre)
Frequence toujours inférieur a la période de comparaison


*/


// ,"fields"=>array(
//     "id",
//     "userId",
//     "frequence",
//     "active",
//     "stats",
//     "last_notified_on",
//     "comparison_period",
//     "detail_by_enseigne",
// )

const NotificationView = observer(() => {

    useEffect(() => {
        NotificationStore.init()
    }, [])

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit } = useFormik({
        enableReinitialize: true,
        initialValues: NotificationStore.props.initialValues,
        onSubmit: (values) => NotificationStore.submit(values),
        validateOnChange: false,
        validateOnBlur: false
    })

    const data = NotificationStore.props.data
    const frequenceList = notificationFrequenceEnvoi
    const comparaisonList = notificationComparaison
    const loadingStatus = NotificationStore.props.loading.status
    const loadingMsg = NotificationStore.props.loading.msg

    console.log(loadingMsg,loadingStatus)

    return (
        <div className="container-fluid  mt-3">
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <BlockUi tag="div" blocking={loadingStatus}
                            message={loadingMsg}
                            loader={<BarLoader></BarLoader>}
                            className="card" style={{ minHeight: 'calc(100vh - 150px)' }}>
                            <div className="card-body">

                                <div className="row mx-0 ">
                                    <div class="col">
                                        <h4 class="text-old mb-3">Paramètres d'envoi</h4>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Fréquence des envois</label>
                                            <MyField name="frequence" type="select"
                                                className="form-control"
                                                onChange={handleChange}
                                                customOnChange={setFieldValue}
                                                value={values['frequence']}
                                                list={frequenceList}
                                                error={errors['frequence']}
                                                touched={touched['frequence']}>
                                            </MyField>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mx-0">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Période de comparaison</label>
                                            <MyField name="comparison_period" type="select"
                                                className="form-control"
                                                onChange={handleChange}
                                                customOnChange={setFieldValue}
                                                value={values['comparison_period']}
                                                list={comparaisonList}
                                                error={errors['comparison_period']}
                                                touched={touched['comparison_period']}>
                                            </MyField>
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row mx-0 mb-3">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <div className="d-block  pt-1 " style={{ marginLeft: '-0.8rem' }}>
                                                <MyField name="detail_by_enseigne"
                                                    type="checkbox"
                                                    checkboxLabel="Détailler par enseigne"
                                                    className="abc-checkbox-fid"
                                                    customOnChange={setFieldValue}
                                                    value={values['detail_by_enseigne']}
                                                    error={errors['detail_by_enseigne']}
                                                    touched={touched['detail_by_enseigne']}>
                                                </MyField>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}

                                <div className="row mx-0  mb-3">
                                    <div class="col">
                                        <h4 class="text-old">Liste des notifications</h4>
                                    </div>
                                </div>
                                {
                                    data.map(item => (

                                        <div className="row mx-0 ">
                                            <div class="col-md-2">
                                                {item.label}
                                            </div>
                                            <div class="col-md-2">
                                                <MyField
                                                    id={'stats.' + item.code}
                                                    name={'stats.' + item.code}
                                                    type="switch"
                                                    customOnChange={setFieldValue}
                                                    value={values['stats'][item.code]}
                                                // error={errors['stats'].[item.code]}
                                                // touched={touched['stats'][item.code]}
                                                >
                                                </MyField>
                                            </div>
                                            <div class="col-md-8">
                                                {item.description}
                                            </div>
                                            <div class="col-md-12">
                                                <hr />
                                            </div>

                                        </div>
                                    ))

                                }

                                <div className="row mt-3 mx-0">
                                    <div className="col d-flex justify-content-end">
                                        <button onClick={handleSubmit} className="btn btn-success">Enregister</button>
                                    </div>
                                </div>
                            </div>
                        </BlockUi >
                    </div >
                </div >
            </div >
        </div >
    )
})

export default NotificationView