import React,{useEffect, useState} from 'react'
import Logo from '../../assets/img/logo.png'
import UserStore from '../../stores/UserStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { observer } from "mobx-react"
import { Redirect,useLocation, Link } from 'react-router-dom'
import BlockUi from 'react-block-ui';
import Schema from 'validate'
import { mapValues, keyBy } from 'lodash';
import { useFormik } from 'formik';
import classNames from 'classnames'
import MainStore from '../../stores/MainStore';
import Loading from '../Loading/Loading';
import parse from 'html-react-parser';
import myYup from '../../utils/myYup';
import OtpInput from '../../reusable/OtpInput';
import backbtn from '../../assets/img/backblue.png'

const VerifyOtpView = () => {
    const location=useLocation();
    const [otp, setOtp] = useState('');

    useEffect(()=>{
        const param=new URLSearchParams(location.search)
        if(param.get('action')){
            UserStore.setAction(param.get('action'))
        }else{
            UserStore.setAction('verify_otp')
        }
        console.log("action",UserStore.props.action)
        UserStore.init()
    },[])


    const verify = async (otpvalue) => {
        var creds={one_time_password:otpvalue,user_id:UserStore.props.user.user_id}
        await UserStore.check_twoo_method(creds)
    }

    const setValue=async (value)=>{
        const newvalue=value;
        setOtp(a=>value)
        if(newvalue.trim().length==6){
            console.log("otp",newvalue)
            await verify(newvalue)
        }
    }



    if (UserStore.props.user.isAuthenticated) {
        let to=''
        if (!UserStore.canDashboard() || UserStore.isSaisie()) {
            to = {
                pathname: '/cards',
                state: 'Liste des clients'
            }
        }
        else {
            to = {
                pathname: '/dashboard',
                state: 'Tableau de bord'
            }
        }

        // to = {
        //     pathname: '/geolocation',
        //     state: 'CUSTOM'
        // }

        return (
            <Redirect to={to}></Redirect>
        )
    }

    if(!localStorage.getItem("two_factors_method")){
                
            return (
                <Redirect to="/login"></Redirect>
            )
        
    }

    return (

        <div className="app flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <BlockUi tag="div" className="card-group rounded login-loader" loader={Loading}
                            message={UserStore.props.loading.msg}
                            blocking={UserStore.props.loading.status}>
                            <div className="card p-4">
                                        <div className='row'>
                                            <div className="col text-left">
                                                <Link to="/login" className="px-4 d-flex"><img src={backbtn}/>Se connecter</Link>
                                            </div>
                                        </div>
                                {
                                    UserStore.props.action == 'verify_otp' &&
                                    < div className="card-body">
                                        <h1>Sécurisation de votre compte</h1>
                                        <p className="text-muted">Pour continuer vous devez saisir le code de sécurité de votre application d'authentification à 2 facteurs.</p>
                                        {UserStore.props.fail.status && <div className="invalid-feedback d-inline-block text-center">Code incorrect</div>}
                                        <form method="post">
                                            <label>Saisissez le code à 6 chiffres</label>
                                            <OtpInput length={6} onchange={setValue} value={otp} containerStyle='d-flex'
                                                renderInput={(p)=>{
                                                    return <div className="m-1">
                                                            <input {...p}/>
                                                        </div>
                                                }}
                                            />
                                            
                                            
                                            
                                        </form>
                                    </div>
                                }
                                {
                                    UserStore.props.action == 'valid_otp' &&
                                    < div className="card-body">
                                        <p style={{"color": "orangered"}}><span style={{"fontSize": "20px"}}>&#9888;</span> L'authentification à 2 facteurs n'a pas encore été vérifiée pour votre compte, les instructions se trouvent dans l'un de vos emails avec l'objet "Votre compte est activé" OU "Authentification à 2 facteurs".<br/> Pensez également à vérifier dans vos spams.</p>
                                        
                                    </div>
                                }
                                {
                                    UserStore.props.action == 'successForgot' &&
                                    < div className="card-body">
                                        <h2>Mot de passe oublié</h2>
                                        <div class={classNames("alert",{'alert-success' : UserStore.props.forgot.success,'alert-danger' : !UserStore.props.forgot.success})} role="alert">
                                            {parse(UserStore.props.forgot.error)}
                                            </div>
                                        

                                    </div>
                                }
                                {
                                    UserStore.props.action=='successChange' &&
                                    < div className="card-body">
                                        <h2>Mot de passe oublié</h2>
                                        <div class={classNames("alert",{'alert-success' : UserStore.props.forgot.success,'alert-danger' : !UserStore.props.forgot.success})} role="alert">
                                            {parse(UserStore.props.forgot.error)}
                                            </div>
                                        

                                    </div>
                                }
                            </div>
                            <div className="card py-5 d-md-down-none">
                                <div className="card-body text-center">
                                    <div>
                                        <img className="login-image" src={Logo} alt="Fidlink Logo" />
                                    </div>
                                    <strong>NOVACARD devient <span className="text-fid">FIDLINK</span></strong>
                                </div>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div >
        </div >

    )
}




export default observer(VerifyOtpView)