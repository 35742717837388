import React from 'react'
import Logo from '../../assets/img/logo.png'
import UserStore from '../../stores/UserStore'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faLock } from '@fortawesome/free-solid-svg-icons';
import { observer } from "mobx-react"
import { Redirect,Link,withRouter } from 'react-router-dom'
import BlockUi from 'react-block-ui';
import Schema from 'validate'
import { mapValues, keyBy } from 'lodash';
import { useFormik } from 'formik';
import classNames from 'classnames'
import MainStore from '../../stores/MainStore';
import Loading from '../Loading/Loading';
import parse from 'html-react-parser';


//Form Field
const MyField = ({ input, type, name, className, placeholder, onChange, value, touched, error, highlight }) => {
    console.log(input)
    return (
        <>
            <input
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                className={className + classNames({ " is-invalid": (touched && error || highlight) })}
                type={type} />

        </>
    )
}


const LoginView = (props) => {

    const login = async (creds) => {
        // console.log(creds)
        UserStore.props.loading.status = true
        await UserStore.login(creds,()=>{
            let to=''
            if(!UserStore.props.user.isAuthenticated){
                if(UserStore.props.user.isPreAuthenticated){
                
                    if(UserStore.props.user.two_factors_method==1){
                        if(UserStore.props.user.two_factors_verified==1)
                            to="/twoo_factors_verification"
                        else
                            to="/twoo_factors_verification?action=valid_otp"
                    }
                    props.history.push(to)
                }
            }
            
        })
        UserStore.props.loading.status = false
    }

    const forgotPassword = async (creds) => {
        // console.log(creds)
        // UserStore.props.loading.status = true
        await UserStore.forgot(creds)
        // UserStore.props.loading.status = false
    }

    const check = (values) => {

        // console.log('rerer')

        const form = new Schema({
            username: {
                type: String,
                required: true,
                length: { min: 3 },
                message: {
                    required: 'Ce champs est requis',
                    length: 'La taille doit être supérieur à 3 caracteres'
                }
            },
            password: {
                type: String,
                required: true,
                length: { min: 3 },
                message: {
                    required: 'Ce champs est requis',
                    length: 'La taille doit être supérieur à 3 caracteres'
                }
            }
        })

        const errors = form.validate(values)
        // console.log(errors)
        return mapValues(keyBy(errors, 'path'), 'message')
    }

    const checkForgot = (values) => {

        // console.log('rerer')

        const form = new Schema({
            username: {
                type: String,
                required: true,
                message: {
                    required: 'Ce champs est requis',
                }
            }
        })

        const errors = form.validate(values)
        // console.log(errors)
        return mapValues(keyBy(errors, 'path'), 'message')
    }

    const formik = useFormik({
        initialValues: {
            username: '',
            password: '',
        },
        onSubmit: login,
        validate: check
    })

    const forgot = useFormik({
        initialValues: {
            username: '',
        },
        onSubmit: forgotPassword,
        validate: checkForgot
    })

    if (UserStore.props.user.isAuthenticated) {
        let to = null
        if (!UserStore.canDashboard() || UserStore.isSaisie()) {
            to = {
                pathname: '/cards',
                state: 'Liste des clients'
            }
        }
        else {
            to = {
                pathname: '/dashboard',
                state: 'Tableau de bord'
            }
        }

        // to = {
        //     pathname: '/geolocation',
        //     state: 'CUSTOM'
        // }

        return (
            <Redirect to={to}></Redirect>
        )
    }
    // else{
    //     if(UserStore.props.user.isPreAuthenticated){
    //         return (
    //             <Redirect to='/logout'></Redirect>
    //         )
    //     }
    // }

    return (

        <div className="app flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-8">
                        <BlockUi tag="div" className="card-group rounded login-loader" loader={Loading}
                            message={UserStore.props.loading.msg}
                            blocking={UserStore.props.loading.status}>
                            <div className="card p-4">
                                
                                    < div className="card-body">
                                        <h1>Connexion</h1>
                                        <p className="text-muted">Saisissez vos identifiants de connexion</p>
                                        {UserStore.props.fail.status && <div className="invalid-feedback d-inline-block text-center">Identifiants incorrects</div>}
                                        <form onSubmit={formik.handleSubmit} method="post">
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend"><span className="input-group-text"><i className="icon-user"></i></span></div>
                                                <MyField name="username" type="text" className="form-control" placeholder="Login"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.username}
                                                    error={formik.errors.username}
                                                    highlight={UserStore.props.fail.status}
                                                    touched={formik.touched.username}>
                                                </MyField>
                                                {formik.touched.username && (formik.errors.username && <div className="invalid-feedback">{formik.errors.username}</div>)}
                                            </div>
                                            <div className="input-group mb-4">
                                                <div className="input-group-prepend"><span className="input-group-text"><i className="icon-lock"></i></span></div>
                                                <MyField name="password" type="password" className="form-control" placeholder="Password"
                                                    onChange={formik.handleChange}
                                                    value={formik.values.password}
                                                    error={formik.errors.password}
                                                    highlight={UserStore.props.fail.status}
                                                    touched={formik.touched.password}>
                                                </MyField>
                                                {formik.touched.password && (formik.errors.password && <div className="invalid-feedback">{formik.errors.password}</div>)}
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <button type="submit" className="btn btn-old px-4">Se connecter</button>
                                                </div>
                                                <div className="col-6 text-right">
                                                    <Link to="/forgot" className="btn btn-link px-0">Mot de passe oublié?</Link>
                                                    
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                
                                
                            </div>
                            <div className="card py-5 d-md-down-none">
                                <div className="card-body text-center">
                                    <div className="d-flex flex-column justify-content-center">
                                        <div>
                                            <img className="login-image" src={Logo} alt="Fidlink Logo" />
                                        </div>
                                        <strong>NOVACARD devient <span className="text-fid">FIDLINK</span></strong>
                                        <div>
                                            <small>Version 1.1.0</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div >
        </div >

    )
}




export default withRouter(observer(LoginView))