import React, { useEffect, useState } from 'react'
import GeolocationStore from '../../stores/GeolocationStore'
import { observer } from 'mobx-react'
import BlockUi from 'react-block-ui'
import { BarLoader } from '../Loader/Loading'
import GoogleMapReact from 'google-map-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheckCircle, faChild, faMapMarker, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames';
import { Style } from "react-style-tag";

const GeolocationView = observer((props) => {

    const mapKey = 'AIzaSyCr4KMmK5HAi76ZWCcdsB7HU9NWakg5QAQ'
    const mapOptions = {
        center: {
            lat: 16.244507,
            lng: -61.532192
        },
        zoom: 10
    };
    const tree = GeolocationStore.props.tree
    const currStats = GeolocationStore.props.currStats
    const currEnseigne = GeolocationStore.props.currEnseigne
    const selectEnseigne = (item) => GeolocationStore.selectEnseigne(item)

    useEffect(() => {
        GeolocationStore.init()
    }, [])

    return (
        <div className="container-fluid mt-3">
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <BlockUi tag="div" blocking={GeolocationStore.props.loading.status}
                            message={GeolocationStore.props.loading.msg}
                            loader={<BarLoader></BarLoader>}
                            className="card" >
                            <div className="card-body positon-relative">
                                <div className="row">
                                    <div className="col-md-3">
                                        <h5>Enseignes</h5>
                                        <div className="w-100 border rounded p-3" style={{ overflowY: 'auto', maxHeight: 'calc(100vh - 150px)' }}>
                                            {
                                                tree.map((item) =>
                                                    <div className={classnames("w-100 border rounded mb-2 p-2 card-enseigne pointer", { "selected": currEnseigne.id == item.id })}

                                                        onClick={() => selectEnseigne(item)}>
                                                        <h5 className="font-weight-bold text-old">
                                                            {
                                                                currEnseigne.id == item.id &&
                                                                <FontAwesomeIcon icon={faCheckCircle} size={'sm'} className={"text-fid mr-2"}></FontAwesomeIcon>
                                                            }
                                                            {item.nom}</h5>
                                                        <ul className="m-0 pl-2">
                                                            <li className="row">
                                                                <div className="col-1">
                                                                    <FontAwesomeIcon icon={faMapMarkerAlt} size={'lg'} className="text-fid"></FontAwesomeIcon>
                                                                </div>
                                                                <div className="col-8">
                                                                    {item.code_postal ? item.code_postal : 'N/A'}
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-9" >
                                        <h5>Map</h5>
                                        <BlockUi tag="div" blocking={GeolocationStore.props.mapLoading.status}
                                            message={GeolocationStore.props.mapLoading.msg}
                                            loader={<BarLoader></BarLoader>}
                                             style={{ height: 'calc(100vh - 150px)' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{key :mapKey}}
                                                defaultCenter={mapOptions.center}
                                                defaultZoom={mapOptions.zoom}
                                            >
                                                {
                                                    currStats.map((item, index) =>
                                                        <MapMarker index={'ville-' + index} lat={item.Latitude} lng={item.Longitude} enseigne={item}></MapMarker>
                                                    )
                                                }
                                            </GoogleMapReact>
                                        </BlockUi>
                                    </div>
                                </div>
                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div>
        </div>

    )
})

const MapMarker = observer((props) => {

    const currColor = GeolocationStore.getBorderColor2(props.enseigne.StatNbClients)

    return (
        <>
            <div className={"card map-pin map-pin-after-" + props.index} style={{ borderColor: currColor, background: currColor, WebkitFilter: currColor }}>
                <div className="p-2">
                    <div className="row">
                        <div className="col-md-12">
                            <span className="h5 text-white d-block mb-1">{props.enseigne.NomMin}</span>
                        </div>
                        <div className="col-md-12">
                            <FontAwesomeIcon icon={faChild} className="mr-1 text-white" size={'lg'}></FontAwesomeIcon>
                            <span className="text-white text-pin">{props.enseigne.StatNbClients} client{props.enseigne.StatNbClients > 0 ? 's' : ''}</span>
                        </div>
                    </div>
                </div>
            </div>
            <Style>{`
                .map-pin-after-${props.index}:after {
                    border-top-color: ${currColor};
                }
            `}</Style>
        </>
    )
})

export default GeolocationView