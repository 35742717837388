import React from 'react'
import { observer } from 'mobx-react'
import SimpleBar from 'simplebar-react'
import CampagneStore from '../stores/CampagneStore'
import { toJS } from 'mobx'
import { currency } from '../utils/numeral'

const ActionButton = observer((props) => {

    const items = CampagneStore.props.campStatut
    console.log(toJS(items))

    return (
        <>
            {items.length > 0 &&
                <div className="row action-button animated fadeIn">
                    <div className="col">
                        <div className="card bg-old">
                            <div style={{ width: '400px' }} className="card-body py-3 px-3">
                                <SimpleBar style={{ maxHeight: '200px' }} className="">
                                    {
                                        items.map(item =>
                                            <div className="d-flex border-bottom pb-2 mb-2">
                                                <div className="col d-flex flex-column">
                                                    <span><b>{item.titre}</b></span>
                                                    <small>Campagne en cours ... ({currency((item.envoye/item.total)*100)}%)</small>
                                                </div>
                                                <div className="col-3 d-flex align-items-center">
                                                    <button className="btn btn-sm btn-fid">Détails</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                </SimpleBar>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>


    )
})
export default ActionButton