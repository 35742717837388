import React, { useEffect, useState, useRef } from 'react'
import TransacStore from '../../stores/TransacStore'
import TransacFilterStore from '../../stores/TransacFilterStore'
import { faPlus, faCaretDown, faTrashAlt, faPen, faPlusCircle, faTimes, faCheck, faUsers, faFilter } from '@fortawesome/free-solid-svg-icons'
import { DropdownToggle, DropdownMenu, Modal, ModalHeader, ModalBody, } from 'reactstrap'
import { observer } from 'mobx-react'
import { useTable } from 'react-table'
import { BarLoader } from '../Loader/Loading'
import DataTable, { ColumnsToggle, DropdownPortal, Portal } from '../../reusable/DataTable'
import BlockUi from 'react-block-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MyField from '../../reusable/MyField'
import moment from 'moment'
import TransacFilter, { QuickSearchDate } from '../../reusable/TransacFilter'
import UserStore from '../../stores/UserStore'
import GamesStore from '../../stores/GamesStore'
import { useFormik } from 'formik'
import { mode_confiramtion } from '../../utils/constants'
import SimpleBar from 'simplebar-react'
import ModalFooter from 'reactstrap/lib/ModalFooter'
import { toJS } from 'mobx'
import myYup from '../../utils/myYup'
import { Calendar, momentLocalizer } from 'react-big-calendar'
import classnames from 'classnames'
import confirmation from '../Alerts/Confirmation'
import ParticipantsFilter, { QuickSearch } from '../../reusable/ParticipantsFilter'
import ParticipantsFilterStore from '../../stores/ParticipantsFilterStore'


const localizer = momentLocalizer(moment)

export const EditDropDown = observer((props) => {
    const {
        row,
        toggleModalGame,
        toggleModalParticipants
    } = props

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => {
        setDropdownOpen(dropdownOpen => !dropdownOpen);
    }

    const rootSelector = document.getElementById('root');
    const div = document.createElement("div")
    div.className = "h-0"
    let portalContainer = useRef(div).current

    useEffect(() => {
        // let portalContainer = document.createElement("div");
        rootSelector.appendChild(portalContainer)
        return () => {
            rootSelector.removeChild(portalContainer)
        }
    }, [])



    const [tooltipOpen, setTooltipOpen] = useState(false);
    const toggleTooltip = () => setTooltipOpen(!tooltipOpen);


    return (

        <DropdownPortal isOpen={dropdownOpen} toggle={toggle} portalContainer={portalContainer}>
            <DropdownToggle
                tag="button"
                className="btn btn-xs btn-outline-success btn-action"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
            > Actions
                        <FontAwesomeIcon className="ml-2" icon={faCaretDown}></FontAwesomeIcon>
            </DropdownToggle>
            <Portal target={portalContainer}>
                <DropdownMenu right className="p-0">
                    <a className="dropdown-item" onClick={() => { toggle(); toggleModalParticipants('Participants', row) }}>
                        <FontAwesomeIcon icon={faUsers} className="mr-2 text-old"></FontAwesomeIcon>
                                    Liste des participants
                                </a>
                    <a className="dropdown-item" onClick={() => { toggle(); toggleModalGame('Editer jeu', row) }}>
                        <FontAwesomeIcon icon={faPen} className="mr-2 text-old"></FontAwesomeIcon>
                                    Modifier
                                </a>
                    <a className="dropdown-item">
                        <FontAwesomeIcon icon={faTrashAlt} className="mr-2 text-old"></FontAwesomeIcon>
                                    Supprimer
                                </a>
                </DropdownMenu>,
                    </Portal>
        </DropdownPortal>

    )


})

const EditModalGame = observer((props) => {

    const {
        nomReseau,
        typeLots,
        modeConfirmation,
        loadingStatus,
        loadingMsg,
        isOpenModalGame,
        toggleModalGame,
        title,
        validateGame,
        submitGame,
        addLot,
        deleteLot,
        initialValuesGame

    } = props



    const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
        initialValues: initialValuesGame,
        // enableReinitialize: true,
        validationSchema: myYup.object().shape({
            nomJeu: myYup.string().required(),
            couleur: myYup.string().required(),
            // mode_confirmation: myYup.number(),
            date_debut: myYup.string().required(),
            date_fin: myYup.string().required(),
            lots: myYup
                .array()
                .of(
                    myYup.object().shape({
                        typelot: myYup.number().required(),
                        nom: myYup.string().required(),
                        valpme1: myYup.string()
                            .when('typelot', {
                                is: (val) => (val == 1 || val == 3),
                                then: myYup.string().required(),
                                otherwise: null,
                            }),
                        valpme2: myYup.string()
                            .when('typelot', {
                                is: (val) => (val == 2 || val == 3),
                                then: myYup.string().required(),
                                otherwise: null,
                            }),
                        dureevalidite: myYup.string()
                            .when('typelot', {
                                is: (val) => (val == 1 || val == 2 || val == 3),
                                then: myYup.string().required(),
                                otherwise: null,
                            }),
                        description: myYup.string()
                            .when('typelot', {
                                is: (val) => (val == 4),
                                then: myYup.string().required(),
                                otherwise: null,
                            })
                    })
                )

        }),
        onSubmit: (values) => submitGame(values),
        validateOnChange: false,
        validateOnBlur: false
    })

    const confirmChangeStatut = async () => {

        if (values['statut'] == 0) {
            const next = await confirmation({
                type: 'confirmNoPass',
                text: "L'activation du jeu aura pour effet d'autoriser les participations et de programmer un tirage au lendemain de la date de fin prévue, la-confirmer vous ?"
            })

            if (next)
                setFieldValue('statut', 1)

        }
        else {
            setFieldValue('statut', 0)
        }

    }

    // console.log(toJS(initialValuesGame))


    return (
        <Modal isOpen={isOpenModalGame} backdrop="static"
            unmountOnClose={false}
            tag="div"
            toggle={() => toggleModalGame()}
            className="modal-xxl">
            <BlockUi tag="div" blocking={loadingStatus} message={loadingMsg} loader={<BarLoader></BarLoader>}>
                <ModalHeader toggle={toggleModalGame}>
                    {title} {' | '} {'Réseau :' + nomReseau}
                </ModalHeader>
                <ModalBody className="pt-5">
                    <div className={classnames("w-100 d-flex center-v center-h py-1", { 'bg-success': values['statut'] == 1, 'bg-danger': values['statut'] == 0 })} style={{
                        position: 'absolute',
                        top: 0,
                        left: 0
                    }}>
                        {
                            values['statut'] == 1 &&
                            <b>Jeu activé</b>
                        }
                        {
                            values['statut'] == 0 &&
                            <b>Jeu désactivé</b>
                        }
                    </div>
                    {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                    <div className="row">
                        <div className="col-md-5">
                            <div className="row">
                                <div className="form-group col-md-4">
                                    <MyField
                                        name={"avatar"}
                                        type="image"
                                        className="form-control"
                                        customOnChange={setFieldValue}
                                        value={values['avatar']}
                                        defaultImage={values['avatar']}
                                        error={errors['avatar']}
                                    // touched={touched['lots'][index].image']}
                                    >
                                    </MyField>
                                </div>
                                <div className="form-group col-md-8">
                                    <div className="row">
                                        <div className="form-group col-md-12">
                                            <label>Libellé</label>
                                            <MyField name={'nomJeu'}
                                                type={'text'}
                                                className={"form-control form-control-sm"}
                                                placeholder={"Libellé"}
                                                onChange={handleChange}
                                                value={values['nomJeu']}
                                                error={errors['nomJeu']}
                                            >
                                            </MyField>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Couleur</label>
                                            <MyField name={'couleur'}
                                                type={'color'}
                                                className={"form-control form-control-sm"}
                                                customOnChange={setFieldValue}
                                                value={values['couleur']}
                                                error={errors['couleur']}
                                            />

                                        </div>
                                        <div className="form-group col-md-12">
                                            <label>Mode confirmation</label>
                                            <MyField name="mode_confirmation"
                                                type="select"
                                                className="form-control form-control-sm"
                                                onChange={handleChange}
                                                value={values['mode_confirmation']}
                                                error={errors['mode_confirmation']}
                                                list={modeConfirmation}
                                                touched={touched['mode_confirmation']}>
                                            </MyField>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Du</label>
                                            <MyField
                                                name={'date_debut'}
                                                type={'datetime'}
                                                className={"form-control form-control-sm"}
                                                customOnChange={setFieldValue}
                                                value={values['date_debut']}
                                                error={errors['date_debut']}
                                            >
                                            </MyField>
                                        </div>
                                        <div className="form-group col-md-6">
                                            <label>Au</label>
                                            <MyField name={'date_fin'}
                                                type={'datetime'}
                                                className={"form-control form-control-sm"}
                                                customOnChange={setFieldValue}
                                                value={values['date_fin']}
                                                error={errors['date_fin']}
                                            >
                                            </MyField>
                                        </div>
                                        <div className="form-group col-md-12">
                                            <button
                                                className={classnames('btn btn-sm', { 'btn-success': values['statut'] == 0, 'btn-danger': values['statut'] == 1 })}
                                                onClick={() => confirmChangeStatut()}
                                            >
                                                {values['statut'] == 0 ?
                                                    <>
                                                        <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                                                        Activer jeu
                                                    </>

                                                    :

                                                    <>
                                                        <FontAwesomeIcon icon={faTimes} className="mr-2"></FontAwesomeIcon>
                                                        Désactiver jeu
                                                    </>

                                                }
                                            </button>

                                        </div>
                                        <div className="form-group col-md-12">
                                            <MyField
                                                name="autoriser_participation_sans_carte"
                                                type="checkbox"
                                                checkboxLabel="Carte Non Obligatoire"
                                                className="abc-checkbox-fid"
                                                customOnChange={setFieldValue}
                                                value={values['autoriser_participation_sans_carte']}
                                                error={errors['autoriser_participation_sans_carte']}
                                                touched={touched['autoriser_participation_sans_carte']}>
                                            </MyField>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>Description</label>
                                    <MyField name={'descJeu'}
                                        type={'editor'}
                                        className={"h-editor"}
                                        customOnChange={setFieldValue}
                                        value={values['descJeu']}
                                        error={errors['descJeu']}
                                    >
                                    </MyField>
                                </div>
                                <div className="form-group col-md-12">
                                    <label>CGU</label>
                                    <MyField name={'cgu'}
                                        type={'editor'}
                                        className={"h-editor"}
                                        customOnChange={setFieldValue}
                                        value={values['cgu']}
                                        error={errors['cgu']}
                                    >
                                    </MyField>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-7">
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-12 d-flex center-v center-h mb-2">
                                        <label className="mb-0">Lots</label>
                                        <button className="btn btn-success btn-sm ml-auto" onClick={() => addLot(setFieldValue, values['lots'])}>
                                            <FontAwesomeIcon className="mr-2" icon={faPlusCircle}></FontAwesomeIcon>
                                                Ajouter un lot
                                            </button>
                                    </div>
                                </div>
                            </div>
                            {/* {console.log('lots', toJS(values['lots']))} */}
                            <SimpleBar autoHide={false} className="pt-3" style={{ height: '100%', width: "100%", padding: '0px 20px', display: 'flex' }}>

                                <div className="row">
                                    {values['lots'].map((item, index) =>
                                        <div className="col-md-12 pb-2">
                                            <div style={{ position: 'absolute', top: '10px', right: '20px', zIndex: '1000' }}>
                                                <button onClick={() => deleteLot(setFieldValue, values['lots'], index)}
                                                    className="btn btn-sm btn-danger btn-circle ml-auto">
                                                    <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>
                                                </button>
                                            </div>
                                            <div class="border rounded">
                                                <div class="card-body row px-3 p-2">
                                                    <div className="col-md-4">
                                                        <div className="form-group col-md-12 mb-0">
                                                            <label className="mb-0">Lot n°{index + 1}</label>
                                                        </div>
                                                        <div className="form-group col-md-12">
                                                            {/* {console.log(toJS(index))} */}
                                                            {/* <label>Image du lot</label> */}
                                                            <MyField
                                                                name={"lots[" + index + "].image"}
                                                                type="image"
                                                                className="form-control"
                                                                customOnChange={setFieldValue}
                                                                value={values['lots'][index].image}
                                                                defaultImage={values['lots'][index].defaultImage}
                                                                error={errors['lots'] && errors['lots'][index] ? errors['lots'][index].image : null}
                                                            // touched={touched['lots'][index].image']}
                                                            >
                                                            </MyField>
                                                            {/* <p>Formats acceptés : jpg, gif, png | Taille max. 5mb</p> */}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="form-group col-md-12">
                                                            <div className="form-group w-100">
                                                                <label>Type</label>
                                                                <MyField
                                                                    name={"lots[" + index + "].typelot"}
                                                                    type="select"
                                                                    className="form-control form-control-sm"
                                                                    onChange={handleChange}
                                                                    value={values['lots'][index].typelot}
                                                                    error={errors['lots'] && errors['lots'][index] ? errors['lots'][index].typelot : null}
                                                                    list={typeLots}
                                                                // touched={touched['lots'][index].typelot}
                                                                >
                                                                </MyField>
                                                            </div>
                                                            <div className="form-group w-100">
                                                                <label>Nom</label>
                                                                <MyField
                                                                    name={"lots[" + index + "].nom"}
                                                                    type="text"
                                                                    className="form-control form-control-sm"
                                                                    onChange={handleChange}
                                                                    value={values['lots'][index].nom}
                                                                    error={errors['lots'] && errors['lots'][index] ? errors['lots'][index].nom : null}
                                                                // touched={touched['lots'][index].typelot]}
                                                                >
                                                                </MyField>
                                                            </div>
                                                            {(values['lots'][index].typelot == 1 ||
                                                                values['lots'][index].typelot == 3) &&
                                                                <div className="form-group w-100">
                                                                    <label>Valeur PME1</label>
                                                                    <MyField
                                                                        name={"lots[" + index + "].valpme1"}
                                                                        type="text"
                                                                        className="form-control form-control-sm"
                                                                        onChange={handleChange}
                                                                        value={values['lots'][index].valpme1}
                                                                        error={errors['lots'] && errors['lots'][index] ? errors['lots'][index].valpme1 : null}
                                                                    // touched={touched['lots'][index].valpme1]}
                                                                    >
                                                                    </MyField>
                                                                </div>
                                                            }
                                                            {(values['lots'][index].typelot == 2 ||
                                                                values['lots'][index].typelot == 3) &&
                                                                <div className="form-group w-100">
                                                                    <label>Valeur PME2</label>
                                                                    <MyField
                                                                        name={"lots[" + index + "].valpme2"}
                                                                        type="text"
                                                                        className="form-control form-control-sm"
                                                                        onChange={handleChange}
                                                                        value={values['lots'][index].valpme2}
                                                                        error={errors['lots'] && errors['lots'][index] ? errors['lots'][index].valpme2 : null}
                                                                    // touched={touched['lots'][index].valpme1]}
                                                                    >
                                                                    </MyField>
                                                                </div>
                                                            }
                                                            {(
                                                                values['lots'][index].typelot == 1 ||
                                                                values['lots'][index].typelot == 2 ||
                                                                values['lots'][index].typelot == 3) &&
                                                                <div className="form-group w-100">
                                                                    <label>Durée validité (en jours)</label>
                                                                    <MyField
                                                                        name={"lots[" + index + "].dureevalidite"}
                                                                        type="text"
                                                                        className="form-control form-control-sm"
                                                                        onChange={handleChange}
                                                                        value={values['lots'][index].dureevalidite}
                                                                        error={errors['lots'] && errors['lots'][index] ? errors['lots'][index].dureevalidite : null}
                                                                    // touched={touched['lots'][index].valpme1]}
                                                                    >
                                                                    </MyField>
                                                                </div>
                                                            }
                                                            {(values['lots'][index].typelot == 4) &&
                                                                <div className="form-group w-100">
                                                                    <label>Description</label>
                                                                    <MyField
                                                                        name={"lots[" + index + "].description"}
                                                                        type="textarea"
                                                                        className="form-control form-control-sm"
                                                                        rows={5}
                                                                        onChange={handleChange}
                                                                        value={values['lots'][index].description}
                                                                        error={errors['lots'] && errors['lots'][index] ? errors['lots'][index].description : null}
                                                                    // touched={touched['lots'][index].descriptionlot}
                                                                    >
                                                                    </MyField>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    )}
                                </div>
                            </SimpleBar>

                        </div>
                    </div>


                </ModalBody>
                <ModalFooter>
                    <button onClick={handleSubmit} className="btn btn-old">Enregistrer</button>
                </ModalFooter>
            </BlockUi>
        </Modal >

    )
})

const ModalParticipants = observer((props) => {

    const {
        isOpenModalParticipants,
        idJeu,
        nomJeu,
        pageIndexPart,
        pageLimitPart,
        pageCountPart,
        pageTotalPart,
        columnsParticipants,
        dataParticipants,
        loadingStatus,
        loadingMsg,
        toggleModalParticipants,
        getFilteredParticipants,
        prevPagePart,
        nextPagePart,
        setSortPart,
        goToPagePart,
        sortOrderPart,
        sortByPart,
    } = props

    const participantsTable = useTable(
        {
            columns: columnsParticipants,
            data: dataParticipants,
            initialState: {
                hiddenColumns: columnsParticipants.filter((item) => (item.isHidden)).map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: pageIndexPart,
                pageSize: pageLimitPart,
                pageCount: pageCountPart,
                pageTotal: pageTotalPart,
            }
        }
    )

    return (
        <Modal isOpen={isOpenModalParticipants} backdrop="static"
            unmountOnClose={false}
            tag="div"
            toggle={() => toggleModalParticipants()}
            className="modal-xxl">
            <BlockUi tag="div" blocking={loadingStatus} message={loadingMsg} loader={<BarLoader></BarLoader>}>
                <ModalHeader toggle={() => toggleModalParticipants()}>
                    {'Participants'} {' | '} {'Jeu :' + nomJeu}
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex  align-items-end mb-2">
                        <div>
                            <ParticipantsFilter
                                onSubmitFilter={() => getFilteredParticipants()}
                                className="btn btn-old btn-sm  mx-1 btn-sm-flex-h"
                                innerButton={
                                    <>
                                        <FontAwesomeIcon icon={faFilter} className="mr-2"></FontAwesomeIcon>
                                            Recherche avancée
                                        </>
                                }
                            >

                            </ParticipantsFilter>
                        </div>
                        <div className="d-flex ml-auto">
                            <QuickSearch
                                onSubmitFilter={() => getFilteredParticipants()}
                                className="btn btn-old btn-sm"
                            ></QuickSearch>
                        </div>
                    </div>
                    <div className="row h-100" >
                        <div className="col-12" style={{ minHeight: 'calc(100vh - 150px)' }}>
                            <DataTable
                                headerGroups={participantsTable.headerGroups}
                                getTableBodyProps={participantsTable.getTableBodyProps}
                                rows={participantsTable.rows}
                                prepareRow={participantsTable.prepareRow}
                                getTableProps={participantsTable.getTableProps}
                                columns={columnsParticipants}
                                loadingMsg={loadingMsg}
                                loadingStatus={loadingStatus}
                                prevPage={() => prevPagePart()}
                                nextPage={() => nextPagePart()}
                                setSort={(sortBy) => setSortPart(sortBy)}
                                sortOrder={sortOrderPart}
                                sortBy={sortByPart}
                                goToPage={(page) => goToPagePart(page)}
                                pageCount={pageCountPart}
                                pageIndex={pageIndexPart}
                                pageLimit={pageLimitPart}
                                pageTotal={pageTotalPart}
                                maxHeight="calc(100vh - 260px)"
                                btnClassName="btn btn-outline-success btn-sm"
                            ></DataTable>
                        </div>
                    </div>
                </ModalBody>
            </BlockUi>
        </Modal>
    )

})

const GamesView = observer((props) => {

    useEffect(() => {
        GamesStore.init()
        ParticipantsFilterStore.initFilterParticipants()
    }, [])

    // const cols = toJS(CardsStore.props.columns).filter(item => !item.isNotDisplay)
    const gamesTable = useTable(
        {
            columns: GamesStore.props.columns,
            data: GamesStore.props.data,
            initialState: {
                hiddenColumns: GamesStore.props.columns.filter((item) => (item.isHidden)).map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: GamesStore.props.pageIndex,
                pageSize: GamesStore.props.pageLimit,
                pageCount: GamesStore.props.pageCount,
                pageTotal: GamesStore.props.pageTotal,
            },
            toggleModalGame: (title, row) => GamesStore.toggleModalGame(title, row),
            toggleModalParticipants: (title, row) => GamesStore.toggleModalParticipants(title, row),
        }
    )


    return (
        <div className="container-fluid mt-3">
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <BlockUi tag="div" blocking={GamesStore.props.loading.status}
                            message={GamesStore.props.loading.msg}
                            loader={<BarLoader></BarLoader>}
                            className="card" style={{ minHeight: 'calc(100vh - 150px)' }}>
                            <div className="card-body h-100">
                                <div className="d-flex  align-items-end mb-2">
                                    <div>

                                    </div>
                                    <div className="d-flex ml-auto">
                                        <button onClick={() => GamesStore.toggleModalGame('Nouveau jeu')} className="btn btn-sm btn-success ml-2">
                                            <FontAwesomeIcon icon={faPlus} className="mr-2"></FontAwesomeIcon>
                                            Nouveau jeu
                                        </button>
                                    </div>
                                </div>
                                <div className="row h-100" >
                                    <div className="col-8" style={{ minHeight: 'calc(100vh - 150px)' }}>
                                        <DataTable
                                            headerGroups={gamesTable.headerGroups}
                                            getTableBodyProps={gamesTable.getTableBodyProps}
                                            onDoubleClickRow={(index, row) => GamesStore.toggleModalGame('Editer jeu', row)}
                                            rows={gamesTable.rows}
                                            prepareRow={gamesTable.prepareRow}
                                            getTableProps={gamesTable.getTableProps}
                                            columns={GamesStore.props.columns}
                                            loadingMsg={GamesStore.props.loading.msg}
                                            loadingStatus={GamesStore.props.loading.status}
                                            prevPage={() => GamesStore.prevPage()}
                                            nextPage={() => GamesStore.nextPage()}
                                            setSort={(sortBy) => GamesStore.setSort(sortBy)}
                                            sortOrder={GamesStore.props.sortOrder}
                                            sortBy={GamesStore.props.sortBy}
                                            goToPage={(page) => GamesStore.goToPage(page)}
                                            pageCount={GamesStore.props.pageCount}
                                            pageIndex={GamesStore.props.pageIndex}
                                            pageLimit={GamesStore.props.pageLimit}
                                            pageTotal={GamesStore.props.pageTotal}
                                            maxHeight="calc(100vh - 260px)"
                                            btnClassName="btn btn-outline-success btn-sm"
                                        ></DataTable>
                                    </div>
                                    <div className="col-md-4" style={{ maxHeight: '400px' }}>
                                        <Calendar
                                            localizer={localizer}
                                            eventPropGetter={(
                                                event,
                                                start,
                                                end,
                                                isSelected
                                            ) => ({ style: { backgroundColor: event.couleur } })}
                                            views={['month']}
                                            onDoubleClickEvent={(event, e) => GamesStore.toggleModalGame('Editer jeu', event.row)}
                                            events={GamesStore.props.events}
                                            startAccessor="start"
                                            endAccessor="end"
                                        />
                                    </div>
                                </div>
                            </div>
                        </BlockUi>
                    </div >
                </div>
            </div>
            {GamesStore.props.isOpenModalGame &&
                <EditModalGame
                    modeConfirmation={mode_confiramtion}
                    nomReseau={UserStore.props.user.nom_reseau}
                    initialValuesGame={GamesStore.props.initialValuesGame}
                    title={GamesStore.props.modalEditTitle}
                    loadingStatus={GamesStore.props.loading.status}
                    loadingMsg={GamesStore.props.loading.msg}
                    isOpenModalGame={GamesStore.props.isOpenModalGame}
                    toggleModalGame={() => GamesStore.toggleModalGame()}
                    addLot={(setFieldValue, lots) => GamesStore.addLot(setFieldValue, lots)}
                    deleteLot={(setFieldValue, lots, index) => GamesStore.deleteLot(setFieldValue, lots, index)}
                    validateGame={() => GamesStore.validateGame()}
                    submitGame={(values) => GamesStore.submitGame(values)}
                    submitImgLot={(image) => GamesStore.submitImgLot(image)}
                    typeLots={GamesStore.props.typeLots}
                ></EditModalGame>

            }
            {
                <ModalParticipants
                    isOpenModalParticipants={GamesStore.props.isOpenModalParticipants}
                    nomJeu={GamesStore.props.currentNomJeu}
                    getFilteredParticipants={() => GamesStore.getFilteredParticipants()}
                    idJeu={GamesStore.props.currentIdJeu}
                    pageIndexPart={GamesStore.props.pageIndexPart}
                    pageLimitPart={GamesStore.props.pageLimitPart}
                    pageCountPart={GamesStore.props.pageCountPart}
                    pageTotalPart={GamesStore.props.pageTotalPart}
                    columnsParticipants={GamesStore.props.columnsParticipants}
                    dataParticipants={GamesStore.props.dataParticipants}
                    loadingStatus={GamesStore.props.loading.status}
                    loadingMsg={GamesStore.props.loading.msg}
                    toggleModalParticipants={() => GamesStore.toggleModalParticipants()}
                    prevPagePart={() => GamesStore.prevPagePart()}
                    nextPagePart={() => GamesStore.nextPagePart()}
                    setSortPart={(sortBy) => GamesStore.setSortPart(sortBy)}
                    goToPagePart={(page) => GamesStore.goToPagePart(page)}
                >

                </ModalParticipants>
            }
        </div >

    )
})

export default GamesView