export const TypeFid = {
    FID_SIMPLE: 1,
    FID_PORTE_MONNAIE: 2,
    FID_COUPONS: 3,
    COMPTE_SEUL: 4,
    WALLET: 5,
}

export const labelFid = [
    { label: 'Fidélité simple', id: 1 },
    { label: "Porte-monnaie électronique", id: 2 },
    { label: 'Fidélité coupons', id: 3 },
    { label: "Client en compte seul", id: 4 },
    { label: "Wallet", id: 5 },
    { label: "Campagne", id: 6 }
]

export const Roles = {
    "1": "Superadmin",
    "2": "Superviseur",
    "3": "Technicien",
    "4": "Gestionnaire de réseau",
    "5": "Commerçant",
    "6": "Saisie"
}

export const Jours = [
    'Lundi',
    'Mardi',
    'Mercredi',
    'Jeudi',
    'Vendredi',
    'Samedi',
    'Dimanche',
]

export const Civilite = [
    'Non renseignée',
    'Mr.',
    'Mme',
    'Mlle.'
]

export const modeEnvoiDetails = {
    "sms": {
        key: "SMS",
        index: 1,
        label: "SMS",
        prixLabel: "SMS",
        show: true
    },
    "email": {
        key: "e-mail",
        index: 2,
        label: "Email",
        prixLabel: "e-mail",
        show: true
    },
    "courrier": {
        key: "courrier",
        index: 3,
        label: "Courrier",
        prixLabel: "courrier"
    },
    "push": {
        key: "PUSH",
        index: 4,
        label: "Push",
        prixLabel: "PUSH"
    }
}

export const modeEnvoiMean = [
    "",
    "sms",
    "email",
    "courrier",
    "PUSH"
]

export const modeEnvoiIndex = {
    "": "",
    "sms": 1,
    "email": 2,
    "courrier": 3,
    "PUSH": 4
}

export const modeEnvoiPrixLabel = {
    "": null,
    "sms": "pSms",
    "email": "pMail",
    "courrier": "pCourrier",
    "PUSH": "pPush"
}

export const modeEnvoiLabel = {
    "": "",
    "sms": "SMS",
    "email": "Email",
    "courrier": "courrier",
    "PUSH": "PUSH"
}

export const Segments = {
    "clients_perdus_depuis_longtemps": "Perdus depuis longtemps",
    "clients_non_confirmes": "Non confirmés",
    "clients_reguliers_perdus_recemment": "Réguliers perdus récemment",
    "clients_recents_a_petit_ca": "Récents à petit C.A",
    "clients_recents_a_fort_ca": "Récents à fort C.A",
    "clients_reguliers_en_decroissance": "Réguliers en décroissance",
    "clients_reguliers_a_petit_ca": "Réguliers à petit C.A",
    "clients_reguliers_en_developpement": "Réguliers en développement",
    "tres_bons_clients_reguliers": "Très bons et réguliers",
    // "": "Non classés"
}

export const SegmentsInfo = {
    "clients_perdus_depuis_longtemps": "Clients en hibernation",
    "clients_non_confirmes": "Clients non-confirmés",
    "clients_reguliers_perdus_recemment": "Clients réguliers perdus récemment",
    "clients_recents_a_petit_ca": "Clients récents à petit CA",
    "clients_recents_a_fort_ca": "Clients récents à fort CA",
    "clients_reguliers_en_decroissance": "Clients réguliers en décroissance",
    "clients_reguliers_a_petit_ca": "Clients réguliers à petit CA",
    "clients_reguliers_en_developpement": "Clients réguliers en développement",
    "tres_bons_clients_reguliers": "Très bon clients réguliers",
    "": "Non classés"
}

export const SegmentsText = {
    "clients_perdus_depuis_longtemps": "Dernier achat il y a longtemps, clients peu dépensiers, achats peu fréquents.",
    "clients_non_confirmes": "Premier achat, l'analyse doit se poursuivre lors des prochaines consommations suite au réengagement.",
    "clients_reguliers_perdus_recemment": "Clients encore volatils hésitant à acheter dans un court délai à la concurrence. La certitude qu'ils rachètent prochainement est faible.",
    "clients_recents_a_petit_ca": "Clients récents ayant peu dépensés.",
    "clients_recents_a_fort_ca": "Clients récent au-dessus de la moyenne des achats, de la récence et de la fréquence.",
    "clients_reguliers_en_decroissance": "Achats récents et fréquents. La confiance existe et doit être consolidée. Taux de renouvellement potentiel de commandes inférieur à celui des très bons clients.",
    "clients_reguliers_a_petit_ca": "Bons clients en terme de récence et de fréquence, une valeur monétaire en-dessous de la moyenne.",
    "clients_reguliers_en_developpement": "Clients réguliers en développement. Ils ont potentiellement une plus forte prévision de commandes.",
    "tres_bons_clients_reguliers": "Ambassadeurs de la marque, ils consolident l'image et le CA. Ils ont potentiellement une plus forte prévision de commandes.",
    "": "Non classés"
}

export const SegmentsDataSets = {
    "clients_perdus_depuis_longtemps": [2, 2, 2],
    "clients_non_confirmes": [2, 4, 2],
    "clients_reguliers_perdus_recemment": [4, 4, 4],
    "clients_recents_a_petit_ca": [4, 2, 2],
    "clients_recents_a_fort_ca": [6, 6, 6],
    "clients_reguliers_en_decroissance": [10, 10, 2],
    "clients_reguliers_a_petit_ca": [4, 8, 6],
    "clients_reguliers_en_developpement": [8, 8, 8],
    "tres_bons_clients_reguliers": [10, 10, 10],
    "": "Non classés"
}

export const statutRecharge = [
    'En attente',
    'Consommée',
    'Périmée'
]

export const Operators = {
    '=': 'Egale à',
    'like': 'Contient',
    'not like': 'Ne contient pas',
    'between': 'Intervalle',
    '<': ' Inférieur à',
    '>': ' Supérieur à',
    '>=': ' Supérieur ou égale à',
    '<=': ' Inférieur ou égale à',
    '<>': ' Différent de',
}

export const notificationFrequenceEnvoi = [
    { id: 7, label: "Toutes les semaines" },
    { id: 14, label: "Tous les 2 semaines" },
    { id: 30, label: "Tous les mois" },
]

export const notificationComparaison = [
    { id: 0, label: "Aucune comparaison" },
    { id: 1, label: "Comparer au mois précédent" },
    { id: 2, label: "Comparer à l'année précédente" },
]

export const notificationComparaisonNew = [
    { id: 1, label: "1 mois" },
    { id: 2, label: "2 mois" },
    { id: 3, label: "3 mois" },
    { id: 4, label: "4 mois" },
    { id: 5, label: "5 mois" },
    { id: 6, label: "6 mois" },
    { id: 7, label: "7 mois" },
    { id: 8, label: "8 mois" },
    { id: 9, label: "9 mois" },
    { id: 10, label: "10 mois" },
    { id: 11, label: "11 mois" },
    { id: 12, label: "12 mois" },
]

export const frequenceEnvoi = [

    { id: "Non", label: "Aucune" },
    { id: "Anniversaires", label: "A chaque anniversaires" },
    { id: 4, label: "Alerte fin de validité" },
    { id: 1007, label: "Tous les 7 jours" },
    { id: 1014, label: "Toutes les 2 semaines" },
    { id: 1030, label: "Tous les mois" },
    { id: 1060, label: "Tous les 2 mois" },
    { id: 1090, label: "Tous les 3 mois" },
    { id: 1180, label: "Tous les 6 mois" },
    { id: 1365, label: "Tous les 1 an" }

]

export const labelTransac = {
    0: "Aucune",
    1: "Crédit PME1",
    2: "Débit PME1",
    3: "Crédit PME2",
    4: "Débit PME2",
    5: "Crédit PME1 + spot PME2",
    6: "Débit CEC PME1",
    7: "Débit CEC PME2",
    8: "Modif Date Val",
    9: "Recharge web",
    10: "Crédit Smartphone PME1",
    11: "Crédit Smartphone PME2",
    91: "Init STD Commande",
    92: "Init ID",
    93: "Init Filleul test",
    94: "Init STD test",
    95: "Init CEC test",
    96: "Init Filleul",
    97: "Init STD",
    90: "Init Copie STD",
    98: "Init CEC",
    1000: "Menu principal"
};

export const mode_confiramtion = [
    { id: 1, label: 'Aucun' },
    { id: 2, label: 'SMS' },
    { id: 3, label: 'Mail' },
    { id: 4, label: 'SMS et email' }
]