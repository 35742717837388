import * as yup from 'yup'
import moment from 'moment';

const myYup = yup

myYup.setLocale({
    mixed: {
        default: 'Champ incorrect',
        required: 'Ce champ est requis'
    },
    number: {
        default: 'La valeur doit être un nombre',
        min: 'La valeur doit être supérieur à ${min}',
        max: 'La valeur doit être inférieur à ${max}',
    },
    string: {
        default: 'La valeur doit être une cahîne de caratère',
        min: '${min} caractères minimum',
        max: '${max} caractères maximum',
        email: 'Email non valide'
    }
});

myYup.addMethod(myYup.string, 'expiration', function test(value) {

    return this.test('expiration', 'Date invalide', function (value) {
        return moment(value, "MM/YY").isValid()
    });
})

myYup.addMethod(myYup.number, 'notZero', function test(value) {

    return this.test('notZero', 'Le nombre doit être différent de 0', function (value) {
        return value != 0
    });
})


myYup.addMethod(myYup.string, 'date', function test(value) {

    return this.test('date', 'Date invalide', function (value) {
        return moment(value).isValid()
    });
})

myYup.addMethod(myYup.string, 'recurrent', function test(value) {
    return this.test('recurrent', 'Ce champs est requis', function (value) {
        console.log(value)
        return value != "Non"
    });
})

myYup.addMethod(myYup.string, 'dateIsGreater', function test(value) {
    return this.test("dateIsGreater", "La date de fin doit être supérieur à la date de début", function(value) {
        return moment(value).isSameOrAfter(moment(this.parent.startDate));
      })
})

myYup.addMethod(myYup.string, 'maxDaysDiff', function test(diff) {
    return this.test("maxDaysDiff", "Le nombre de jours entre la date de début et la date de fin ne doit pas être supérieur à "+diff, function(value) {
        return moment(value).diff(moment(this.parent.startDate), 'days') <= diff;
      })
})

myYup.addMethod(myYup.string, "hasNumber", function (errormessage) {
    // const { message } = errormessage;
    return this.test("hasNumber", errormessage, function(value) {
        const { path, createError } = this;
        const regex=/[0-9]+/
        
        return regex.test(value) || createError({
                path,
                message: errormessage
            });
    });
});
myYup.addMethod(myYup.string, "hasMaJ", function (errormessage) {
    // const { message } = errormessage;
    return this.test("hasMaJ", errormessage, function(value) {
        const { path, createError } = this;
        const regex=/[A-Z]+/
        
        return regex.test(value) || createError({
                path,
                message: errormessage
            });
    });
});
myYup.addMethod(myYup.string, "hasSpecial", function (errormessage) {
    // const { message } = errormessage;
    return this.test("hasSpecial", errormessage, function(value) {
        const { path, createError } = this;
        const regex=/[!"\#$%&'()*+,-./:;<=>?@\[\\\]^_`{|}~]+/
        
        return regex.test(value) || createError({
                path,
                message: errormessage
            });
    });
});

export default myYup