import React from 'react'
import { observable, toJS } from 'mobx'
import api from '../api'
import { Jours, Civilite } from '../utils/constants'
import UserStore from './UserStore'
import moment from 'moment'
import { keyBy, mapValues } from 'lodash'
import { Segments } from '../utils/constants'
import { score, number } from '../utils/numeral'
import { EditDropDown } from '../components/Cards/CardsView'
import Schema from 'validate'
import confirmation from '../components/Alerts/Confirmation'
import { toast } from 'react-toastify'
import { saveAs } from 'file-saver';
import modalAlert from '../components/Alerts/ModalAlert'
import ClientFilterStore from './ClientFilterStore'

moment.locale('fr');

// const unitesPME1 = toJS(UserStore.props.user.unitesPME1)
// const unitesPME2 = toJS(UserStore.props.user.unitesPME2)

const props = {
    /***** Props ******/
    loading: {
        status: false,
        msg: 'Chargement en cours'
    },
    columnsTransac: [
        {
            Header: 'Date/Heure',
            accessor: 'DateTransaction',
            Cell: ({ cell: { value } }) => {
                // console.log(value)
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY [à] HH[h]mm')
                else
                    return "-"
            },
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'NouveauPME1',
            field: 'NouveauPME1',
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'NouveauPME2',
            field: 'NouveauPME2',
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'creditPme1',
            field: 'creditPme1',
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'debitPme1',
            field: 'debitPme1',
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'creditPme2',
            field: 'creditPme2',
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'debitPme2',
            field: 'debitPme2',
            Cell: ({ cell: { value } }) => number(value)
        },
        {
            Header: 'Enseigne',
            accessor: 'NomCommercant',
            field: 'NomCommercant',
        },

    ],
    columns: [
        {
            Header: 'Etat',
            accessor: 'cltCardOFF',
            field: 'clientCardDesactive',
            show: false,
            isVisible: false,
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: false,
            isEditable: false,
            Cell: ({ row, cell: { value } }) => {
                console.log(row.values);
                if (value === "1")
                    return <span style={{ fontSize: "0.8rem" }} className="text-danger font-weight-bold">
                        Désactivé
                    </span>

                if (row.values.cltNumCard === "")
                    return <span style={{ fontSize: "0.8rem" }} className="text-secondary font-weight-bold">Inexistante</span>

                if (row.values.dateActiv === "")
                    return <span style={{ fontSize: "0.8rem" }} className="text-non-active font-weight-bold">Non activée</span>

                return <span style={{ fontSize: "0.8rem" }} className="text-success font-weight-bold">Active</span>
            }
        },
        {
            Header: 'ID Client',
            accessor: 'idcli',
            field: 'idclientFid',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isEditable: false,
            isSortable: true,
        },
        {
            Header: 'N° de carte',
            accessor: 'cltNumCard',
            field: 'clientNumCard',
            isFilterable: true,
            typeFilter: 'text',
            isEditable: true,
            typeInput: 'label',
            isHidden: false,
            isSortable: true,
            validation: {
                type: String,
                use: {
                    carte: val => (val.length === 0 || val.length >= 17)
                }
            }
        },
        {
            Header: 'Civilité',
            accessor: 'cltCiv',
            field: 'clientCivilite',
            isFilterable: true,
            typeFilter: 'select',
            typeInput: 'select',
            list: Civilite.map((item, index) => ({ id: index, label: item })),
            isHidden: true,
            isEditable: true,
            Cell: ({ cell: { value } }) => (value ? Civilite[value] : '-')
        },
        {
            Header: 'Nom',
            accessor: 'cltNom',
            field: 'clientNom',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: false,
            isSortable: true,
            isEditable: true,
        },
        {
            Header: 'Prenom',
            accessor: 'cltPrenom',
            field: 'clientPrenom',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: false,
            isSortable: true,
            isEditable: true,
        },
        {
            Header: 'Mot de passe',
            accessor: 'cltPassword',
            field: 'clientPassword',
            isFilterable: true,
            typeFilter: 'text',
            typeInput: 'text',
            isHidden: true,
            isSortable: false,
            isEditable: true,
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'Pme1',
            field: 'Pme1',
            isSortable: true,
            isHidden: false,
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'Pme2',
            field: 'Pme2',
            isSortable: true,
            isHidden: false,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumCredPme1',
            field: 'cumCredPme1',
            isSortable: true,
            isHidden: false,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumCredPme2',
            field: 'cumCredPme2',
            isSortable: true,
            isHidden: false,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumDebPme2',
            field: 'cumDebPme2',
            isSortable: true,
            isHidden: false,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: 'Dernier Achat',
            accessor: 'derAchat',
            field: 'dernierAchat',
            isSortable: true,
            isHidden: false,
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).fromNow()
                else
                    return "-"
            },
        },
        {
            Header: 'Date de naissance',
            accessor: 'cltNeLe',
            field: 'clientNeLe',
            isSortable: true,
            typeInput: 'date',
            isHidden: false,
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('L')
                else
                    return "-"
            },
            isEditable: true,
        },
        {
            Header: 'Date activation',
            accessor: 'dateActiv',
            field: 'date_activation',
            isSortable: true,
            typeInput: 'date',
            isHidden: true,
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('L')
                else
                    return "-"
            },
            isEditable: true,
        },
        {
            Header: 'Segment',
            accessor: 'Segment',
            isSortable: true,
            field: 'clientNom',
            isFilterable: true,
            typeFilter: 'text',
            Cell: ({ getBadgeColor, row, cell: { value } }) => (
                <span style={{ fontSize: '75%' }} className={'font-weight-bold badge ' + getBadgeColor(parseFloat(row.values.RFM_Score))}>
                    {(Segments[value])}
                </span>
            ),
            isHidden: false,
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumDebPme1',
            field: 'cumDebPme1',
            isSortable: true,
            isHidden: true,
            Cell: ({ cell: { value } }) => number(value),
        },

        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumBonus1',
            isSortable: true,
            isHidden: true,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumBonus2',
            isSortable: true,
            isHidden: true,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumSpot',
            field: 'cumulSpot',
            isSortable: true,
            isHidden: true,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'Expiration_Pme1',
            isSortable: true,
            isHidden: true,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'Expiration_Pme2',
            isSortable: true,
            isHidden: true,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: 'Tél. mobile',
            accessor: 'cltGsm',
            field: 'clientGsm',
            isSortable: true,
            isHidden: true,
            isEditable: true,
            typeInput: 'text',
        },
        {
            Header: 'Tél Fixe',
            accessor: 'cltTel',
            field: 'clientTel',
            isSortable: true,
            isHidden: true,
            isEditable: true,
            typeInput: 'text',
        },
        {
            Header: 'Score',
            accessor: 'RFM_Score',
            Cell: ({ getBadgeColor, cell: { value } }) => (<span className={'badge ' + getBadgeColor(parseFloat(value))}>
                {score(value)}
            </span>),
            isHidden: true

        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumRech1',
            field: 'cumRech1',
            isSortable: true,
            isHidden: true,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumRech2',
            field: 'cumRech2',
            isSortable: true,
            isHidden: true,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: 'Email',
            accessor: 'cltEmail',
            field: 'clientEmail',
            isSortable: true,
            isEditable: true,
            typeInput: 'email',
            isHidden: true
        },
        {
            Header: 'Enseigne',
            accessor: 'NomCom',
            isSortable: true,
            isHidden: true
        },
        {
            Header: 'Enseigne_id',
            accessor: 'idcom',
            isSortable: false,
            isFilterable: false,
            isNotDisplay: true,
            isHidden: true
        },
        {
            Header: 'Adresse 1',
            accessor: 'cltAdress1',
            field: 'clientAdresse1',
            isSortable: true,
            isHidden: true,
            isEditable: true,
            typeInput: 'text',
        },
        {
            Header: 'Adresse 2',
            accessor: 'cltAdress2',
            field: 'clientAdress2',
            isSortable: true,
            isHidden: true,
            isEditable: true,
            typeInput: 'text',
        },
        {
            Header: 'Code postal',
            accessor: 'cltCP',
            field: 'clientCP',
            isSortable: true,
            isHidden: true,
            isEditable: true,
            typeInput: 'text',
        },
        {
            Header: 'Ville',
            accessor: 'cltVille',
            field: 'clientVille',
            isSortable: true,
            isHidden: true,
            isEditable: true,
            typeInput: 'text',
        },
        {
            Header: 'N° RIB',
            accessor: 'cltRIB',
            field: 'clientRIB',
            isSortable: true,
            isHidden: true,
            isEditable: true,
            typeInput: 'text',
        },
        {
            Header: 'Champs perso.',
            accessor: 'cltPersonnalise',
            field: 'clientPersonnalise',
            isSortable: true,
            isHidden: true,
            isEditable: true,
            typeInput: 'text',
        },
        {
            Header: 'Campagne',
            accessor: 'cltAdrssKO',
            field: 'clientAdresseIncorrect',
            isSortable: true,
            show: false,
            isHidden: false,
            isEditable: true,
            className: "abc-checkbox-fid",
            label: 'Ne souhaite pas recevoir de campagne de communication',
            typeInput: 'checkbox',
            Cell: ({ row, cell: { value } }) => (
                value === "0" ?
                    <span style={{ fontSize: "0.8rem" }} className="text-success font-weight-bold">
                        Abonné
                    </span> :
                    <span style={{ fontSize: "0.8rem" }} className="text-danger font-weight-bold">
                        Désabonné
                    </span>
            )
        },
        {
            Header: 'Actions',
            accessor: 'actions',
            Cell: ({ row, isWallet, isCampagne, openModal, toggleModalAffect, canRechargementUnique, setSelectedRow, setActiveTab, disableEnableCard, deleteClient, toggleModalEditClient }) => {

                return (
                    <EditDropDown
                        rowId={row.id}
                        isWallet={isWallet}
                        isCampagne={isCampagne}
                        setSelectedRow={setSelectedRow}
                        canRechargementUnique={canRechargementUnique}
                        openModal={openModal}
                        setActiveTab={setActiveTab}
                        toggleModalEditClient={toggleModalEditClient}
                        toggleModalAffect={toggleModalAffect}
                        disableEnableCard={disableEnableCard}
                        deleteClient={deleteClient}
                        rowValues={row.values}
                    ></EditDropDown>
                )
            }

        },

    ],
    modalOpen: false,
    selectedRow: {},
    listOrigines: [],
    listEnseignes: [],
    filters: [
        {
            name: 'N° de carte',
            id: 'cltNumCard',
            field: 'clientNumCard',
            active: false,
            type: 'text',
            value: '',
            operator: ' like ',
            label: 'Contient',
            typeCompare: 'text'
        },
        {
            name: 'Nom',
            id: 'cltNom',
            field: 'clientNom',
            active: false,
            type: 'text',
            value: '',
            operator: ' like ',
            label: 'Contient',
            typeCompare: 'text'
        },
        {
            name: 'Prenom',
            id: 'cltPrenom',
            field: 'clientPrenom',
            active: false,
            type: 'text',
            value: '',
            operator: ' like ',
            label: 'Contient',
            typeCompare: 'text'
        },
        {
            name: 'Segments RFM',
            id: 'RFM_Segment',
            field: 'RFM_Segment',
            active: false,
            type: 'multiple',
            choices: [
                { id: "clients_perdus_depuis_longtemps", label: "Perdus depuis longtemps", checked: false },
                { id: "clients_non_confirmes", label: "Non confirmés", checked: false },
                { id: "clients_reguliers_perdus_recemment", label: "Réguliers perdus récemment", checked: false },
                { id: "clients_recents_a_petit_ca", label: "Récents à petit C.A", checked: false },
                { id: "clients_recents_a_fort_ca", label: "Récents à fort C.A", checked: false },
                { id: "clients_reguliers_en_decroissance", label: "Réguliers en décroissance", checked: false },
                { id: "clients_reguliers_a_petit_ca", label: "Réguliers à petit C.A", checked: false },
                { id: "clients_reguliers_en_developpement", label: "Réguliers en développement", checked: false },
                { id: "tres_bons_clients_reguliers", label: "Très bons et réguliers", checked: false },
                { id: "non_classes", label: "Non classés" }
            ],
            value: true,
            operator: ' like ',
            label: '',
            typeCompare: 'multiple'
        },
        {
            name: 'Enseignes',
            id: 'enseignes',
            field: 'clientfid.idcommercantFid',
            active: false,
            type: 'number',
            options: [],
            value: "",
            operator: '=',
            label: 'Chez',
            typeCompare: 'select',
            isHidden: true,
        },
        {
            name: 'Email',
            id: 'cltEmail',
            field: 'clientEmail',
            active: false,
            type: 'text',
            value: '',
            operator: ' like ',
            label: 'Contient',
            typeCompare: 'text'
        },
        {
            name: 'Tél. mobile',
            id: 'cltTel',
            field: 'clientTel',
            active: false,
            type: 'text',
            value: '',
            operator: ' like ',
            label: 'Contient',
            typeCompare: 'text'
        },
        {
            name: 'Clients encartés ?',
            id: 'encartes',
            field: '',
            active: false,
            // isNotToggable: true,
            type: 'custom',
            value: "",
            options: [
                { id: '', label: 'Possédant une carte ou non' },
                { id: "(clientNumCard <> '' or clientNumCard is not null)", label: 'Ayant une carte' },
                { id: "(clientNumCard = '' or clientNumCard is null)", label: "N'ayant pas de carte" },
            ],
            operator: '',
            label: 'Clients',
            typeCompare: 'select'
        },
        {
            name: 'Origine fiches',
            id: 'origine_fiches',
            field: 'clientOrigine',
            active: false,
            type: 'number',
            options: [],
            value: "",
            operator: '=',
            label: 'Client inscrit via',
            typeCompare: 'select'
        },
    ],
    lineDataset: {},
    daysDataset: {},
    RadarDataset: [],
    data: [],
    dataTransac: [],
    dataRecharge: [],
    cardsToAffect: [],
    fichesToAffect: [],
    pageStart: 0,
    pageIndex: 0,
    pageLimit: 30,
    pageTotal: 0,
    pageCount: 0,
    pageTransacStart: 0,
    pageTransacIndex: 0,
    pageTransacLimit: 30,
    pageTransacTotal: 0,
    pageTransacCount: 0,
    sortBy: null,
    sortOrder: null,
    activeTab: '1',
    ImportIsOpen: false,
    importProgress: 0,
    urlImport: '',
    importLoading: false,
    importLoadingMsg: '',
    ModalEditRechargeIsOpen: false,
    selectedRowRecharge: {},
    ModalEditClientIsOpen: false,
    typeAffect: 'ficheToCard',
    ModalAffectIsOpen: false,
    titleAffect: null,
    selectedEnseigne: 0,

}

const CardsStore = {
    props: props,
    /***** Methods ******/

    async init() {
        if (!ClientFilterStore.props.fromSegments) {
            ClientFilterStore.reset()
        }
        ClientFilterStore.props.fromSegments = false
        this.doLoading(true, 'Initialisation en cours ...')
        await ClientFilterStore.initFilterClient()
        await this.initColumns()
        await this.initColumnsTransacs()
        await this.getClients()
        await this.getOrigines()
        if (UserStore.isReseau())
            await this.getEnseignes()
        if (!UserStore.canSegmentation()) {
            this.props.activeTab = '2'
        }
        this.doLoading(false)
    },
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    async getOrigines() {
        // this.props.loading.msg = "Chargement en cours"
        // this.props.loading.status = true
        let data = {
            'cmd': 'getRows',
            'objName': 'origine_clients',
            'fields': JSON.stringify(["origine"]),
            'query': ''
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            const data = !res.data.rows ? [] : res.data.rows
            data.unshift({ id: '', origine: 'Tous' })
            // console.log(data)
            const index = this.props.filters.map(function (e) { return e.id; }).indexOf('origine_fiches')
            this.props.filters[index].options = data.map((item) => ({ id: item.id, label: item.origine }))

        }
        // this.props.loading.status = false
    },
    async getEnseignes() {

        const idRes = UserStore.props.user.idRes
        let data = {
            sort: 'NomCommercant',
            dir: 'DESC',
            cmd: 'getRows',
            objName: 'listeCommercants',
            where: 'iDreseauFid=' + idRes
        }

        const res = await api.post('/scripts/commerce/commercant.php', data)
        if (res.data.success) {
            this.props.listEnseignes = !res.data.rows ? [] : res.data.rows
            if (!UserStore.isReseau())
                return
            const data = !res.data.rows ? [] : res.data.rows
            data.unshift({ idcommercantFid: '', NomCommercant: 'Tous' })
            // console.log(data)
            const index = this.props.filters.map(function (e) { return e.id; }).indexOf('enseignes')
            this.props.filters[index].options = data.map((item) => ({ id: item.idcommercantFid, label: item.NomCommercant }))
            this.props.filters[index].isHidden = false

        }

    },
    async initColumns() {
        return new Promise(resolve => {
            this.props.columns = this.props.columns.map(item => {

                if (UserStore.isWallet()) {
                    if (item.accessor == 'cltNele'
                        // || item.accessor == 'Segment'
                        || item.accessor == 'Pme1'
                        || item.accessor == 'cltNeLe'
                        || item.accessor == 'cumCredPme1'
                        || item.accessor == 'cumRech1'
                        || item.accessor == 'cumBonus1'
                        || item.accessor == 'cumDebPme1'
                        || item.accessor == 'NouveauPME1'
                        || item.accessor == 'Expiration_Pme1'
                        || item.accessor == 'creditPme1'
                        || item.accessor == 'debitPme1'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true
                    }

                    if (item.accessor == 'derAchat') {
                        item.Header = "Dernière transaction"
                    }

                    if (item.accessor == 'cltNumCard') {
                        item.Header = "N° client"
                    }
                }

                if (UserStore.isNovacash()) {
                    if (
                        item.accessor == 'Pme1'
                        || item.accessor == 'cumCredPme1'
                        || item.accessor == 'cumRech1'
                        || item.accessor == 'cumBonus1'
                        || item.accessor == 'cumDebPme1'
                        || item.accessor == 'NouveauPME1'
                        || item.accessor == 'Expiration_Pme1'
                        || item.accessor == 'creditPme1'
                        || item.accessor == 'debitPme1'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true

                    }
                }

                if (UserStore.isFidSimple()) {
                    if (item.accessor == 'Pme2'
                        || item.accessor == 'cumDebPme2'
                        || item.accessor == 'cumRech2'
                        || item.accessor == 'cumCredPme2'
                        || item.accessor == 'cumBonus2'
                        || item.accessor == 'NouveauPME2'
                        || item.accessor == 'creditPme2'
                        || item.accessor == 'debitPme2'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true

                    }
                }
                if (UserStore.isCampagne()) {
                    if (
                        item.accessor == 'Pme2'
                        || item.accessor == 'cltNumCard'
                        || item.accessor == 'cltCardOFF'
                        || item.accessor == 'cltCardOFF'
                        || item.accessor == 'cumDebPme2'
                        || item.accessor == 'cumRech2'
                        || item.accessor == 'cumCredPme2'
                        || item.accessor == 'cumBonus2'
                        || item.accessor == 'derAchat'
                        || item.accessor == 'NouveauPME2'
                        || item.accessor == 'creditPme2'
                        || item.accessor == 'debitPme2'
                        || item.accessor == 'Pme1'
                        || item.accessor == 'cumCredPme1'
                        || item.accessor == 'cumRech1'
                        || item.accessor == 'cumBonus1'
                        || item.accessor == 'cumDebPme1'
                        || item.accessor == 'NouveauPME1'
                        || item.accessor == 'Expiration_Pme1'
                        || item.accessor == 'Expiration_Pme2'
                        || item.accessor == 'creditPme1'
                        || item.accessor == 'debitPme1'
                        || item.accessor == 'Segment'
                        || item.accessor == 'RFM_Score'
                        || item.accessor == 'cltRIB'
                        || item.accessor == 'cumSpot'
                        || item.accessor == 'cumSpot'
                        || item.accessor == 'cltPersonnalise'
                        || item.accessor == 'NomCom'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true
                    }
                    if (
                        item.accessor == 'cltCP'
                        || item.accessor == 'cltVille'
                    ) {
                        item.isHidden = false
                    }
                }
                if (!UserStore.canSegmentation()) {
                    if (
                        item.accessor == 'Segment' ||
                        item.accessor == 'RFM_Score'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true
                    }
                }

                return item
            })

            resolve(true)
        })

    },
    async initColumnsTransacs() {
        return new Promise(resolve => {
            this.props.columnsTransac = this.props.columnsTransac.map(item => {
                if (UserStore.isWallet() || UserStore.isNovacash()) {

                    if (
                        item.accessor == 'NouveauPME1'
                        || item.accessor == 'creditPme1'
                        || item.accessor == 'debitPme1'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true
                    }

                }

                if (UserStore.isFidSimple()) {

                    if (
                        item.accessor == 'NouveauPME2'
                        || item.accessor == 'creditPme2'
                        || item.accessor == 'debitPme2'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true
                    }

                }


                return item

            })

            resolve(true)
        })
    },
    getBadgeColor(value) {
        const bg_colors = ['bg-danger', 'bg-warning', 'bg-lightblue', 'bg-info', 'bg-success', 'bg-secondary']

        if (value < 2)
            return bg_colors[0]
        if (value < 4)
            return bg_colors[1]
        if (value < 6)
            return bg_colors[2]
        if (value < 8)
            return bg_colors[3]
        if (value <= 10)
            return bg_colors[4]

        return bg_colors[5];
    },
    async setSort(value) {
        this.doLoading(true, 'Chargement en cours ...')
        if (this.props.sortBy != value) {
            this.props.sortBy = value
            this.props.sortOrder = 'ASC'
        }
        else {
            if (this.props.sortOrder == 'ASC')
                this.props.sortOrder = 'DESC'
            else if (this.props.sortOrder == 'DESC') {
                this.props.sortOrder = false
                this.props.sortBy = null

            }
        }

        await this.getClients()
        this.doLoading(false)
        // console.log(value, this.props.sortOrder)
    },
    setUnitsHeader(colId) {

        if (colId == 'Pme1')
            return 'Solde (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'Pme2')
            return 'Solde (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumDebPme2')
            return 'Debits (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumRech2')
            return 'Cumul Recharge (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumCredPme1')
            return 'Crédits (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'cumCredPme2')
            return 'Crédits (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumRech1')
            return 'Cumul Recharge (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'cumSpot')
            return 'Gains (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumBonus2')
            return 'Bonus (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumBonus1')
            return 'Bonus (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'cumDebPme1')
            return 'Debits (' + UserStore.props.user.unitesPME1court + ')'
        //-----//
        if (colId == 'NouveauPME1')
            return 'Solde (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'NouveauPME2')
            return 'Solde (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'creditPme1')
            return 'Crédit (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'debitPme1')
            return 'Débit (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'creditPme2')
            return 'Crédit (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'debitPme2')
            return 'Débit (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'Expiration_Pme1')
            return 'Expiration (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'Expiration_Pme2')
            return 'Expiration (' + UserStore.props.user.unitesPME2court + ')'

        return 'Vide'

    },
    getWhere(where) {
        if (!where)
            where += ' WHERE '

        this.props.filters
            .filter((item) => item.active)
            .map((item, index) => {
                console.log(toJS(item))
                let and = ' AND '
                if (index != 0 && !where)
                    and = ' '
                if (item.value || item.type == 'multiple') {
                    if (item.type == 'text')
                        where += and + item.field + " " + item.operator + " '%" + item.value + "%'"
                    if (item.type == 'number')
                        where += and + item.field + " " + item.operator + " " + item.value
                    if (item.type == 'date')
                        where += and + item.field + " " + item.operator + " '" + item.value + "'"
                    if (item.type == 'custom' && item.value)
                        where += and + item.value + " "
                    if (item.type == 'multiple') {
                        let andAdded = false
                        item.choices.map((item2) => {
                            if (item2.checked)
                                if (!andAdded) {
                                    if (item2.id == 'non_classes')
                                        where += and + "(" + item.field + " is null "
                                    else
                                        where += and + "(" + item.field + " like " + " '%" + item2.id + "%'"
                                    andAdded = true
                                }
                                else
                                    if (item2.id == 'non_classes')
                                        where += " OR " + item.field + " is null "
                                    else
                                        where += " OR " + item.field + " like " + " '%" + item2.id + "%'"
                        })
                        if (andAdded)
                            where += ")"
                    }
                }

            })

        return where

    },
    getWhereExport(where) {
        if (!where)
            where += ' WHERE '

        this.props.filters
            .filter((item) => item.active && item.id != 'origine_fiches')
            .map((item, index) => {
                let and = ' AND '
                if (index != 0 && !where)
                    and = ' '
                if (item.value || item.type == 'multiple') {
                    if (item.type == 'text')
                        return where += and + item.field + " " + item.operator + " '%" + item.value + "%'"
                    if (item.type == 'enseignes')
                        return where += and + item.field.split('.')[1] + " " + item.operator + " " + item.value
                    if (item.type == 'number' && item.id == 'enseignes')
                        return where += and + item.field.split('.')[1] + " " + item.operator + " " + item.value
                    if (item.type == 'number')
                        return where += and + item.field + " " + item.operator + " " + item.value
                    if (item.type == 'date')
                        return where += and + item.field + " " + item.operator + " '" + item.value + "'"
                    if (item.type == 'custom' && item.value)
                        return where += and + item.value + " "
                    if (item.type == 'multiple') {
                        let andAdded = false
                        item.choices.map((item2) => {
                            if (item2.checked)
                                if (!andAdded) {
                                    if (item2.id == 'non_classes')
                                        where += and + "(" + item.field + " is null "
                                    else
                                        where += and + "(" + item.field + " like " + " '%" + item2.id + "%'"
                                    andAdded = true
                                }
                                else
                                    if (item2.id == 'non_classes')
                                        where += " OR " + item.field + " is null "
                                    else
                                        where += " OR " + item.field + " like " + " '%" + item2.id + "%'"
                        })
                        if (andAdded)
                            where += ")"

                        return
                    }
                }

            })

        return where

    },
    getLineDatasets(dataset) {

        let coords = []
        let key = 'creditPme1'
        let legend = UserStore.props.user.unitesPME2 + ' dépensées '
        let labels = []
        let typeTransac = 5
        if (UserStore.isNovacash() || UserStore.isWallet()) {
            key = 'debitPme2'
            typeTransac = 4
        }
        let data = dataset.reverse()
        // console.log(data)
        for (let i = 0; i < data.length; i++) {
            // console.log(key)
            if (data[i]['TypeTransac'] == typeTransac) {
                labels.push(moment(data[i]['DateTransaction']).format('DD MMM YYYY'))
                coords.push(parseFloat(data[i][key]))
            }
        }

        console.log(coords)
        this.props.lineDataset = {
            labels: labels,
            datasets: [{
                label: legend,
                // backgroundColor: "#fd7e14",
                // borderColor: "#fd7e14",
                // borderWidth: 2,
                backgroundColor: 'transparent',
                borderColor: '#fff',
                borderWidth: 2,
                pointBackgroundColor: '#3399ff',
                tension: 0,
                fill: false,
                hoverBackgroundColor: '#2983cf',
                hoverBorderColor: '#ED8936',
                data: coords
            }]
        }

        const days = [0, 0, 0, 0, 0, 0, 0]

        data.map((item) => {
            let day = moment(item['DateTransaction']).startOf('day').day()
            if (day == 0)
                days[6]++
            else
                days[day - 1]++
        })

        this.props.daysDataset = {
            labels: Jours,
            datasets: [{
                // backgroundColor: "#fd7e14",
                // borderColor: "#fd7e14",
                // borderWidth: 2,
                backgroundColor: '#fff',
                borderColor: '#fff',
                borderWidth: 2,
                pointBackgroundColor: '#3399ff',
                tension: 0,
                fill: true,
                // hoverBackgroundColor: '#2983cf',
                // hoverBorderColor: '#ED8936',
                data: days
            }]
        }

    },
    /*********** DETAILS ***********/
    async toggleModalDetails() {
        this.doLoading(true, 'Chargement des données ...')
        this.props.modalOpen = !this.props.modalOpen
        if (this.props.modalOpen) {

            this.props.pageTransacStart = 0
            this.props.pageTransacIndex = 0
            this.props.pageTransacLimit = 30
            this.props.pageTransacTotal = 0
            this.props.pageTransacCount = 0

            let data = await this.getTransacs()
            await this.getRecharges()
            this.getLineDatasets(data)
        }
        this.doLoading(false)
    },
    async getTransacs() {
        let idClient = this.props.selectedRow.idcli
        let where = 'TypeTransac NOT IN (6, 7) AND idclientFid=' + idClient + ' '
        let data = {
            'objName': 'listeTransac',
            'cmd': 'getRowsP',
            'where': where,
            'start': this.props.pageTransacStart,
            'limit': this.props.pageTransacLimit,
            'sort': 'DateTransaction',
            'dir': 'DESC',
        }

        const res = await api.post('/scripts/transac/transac.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.dataTransac = !res.data.rows ? [] : res.data.rows
                this.props.pageTransacTotal = res.data.totalCount
                this.props.pageTransacCount = Math.ceil(res.data.totalCount / this.props.pageTransacLimit)

            } else {
                this.props.dataTransac = []
            }
        }

        return !res.data.rows ? [] : res.data.rows

    },
    setActiveTab(index) {
        console.log('setActive', index)
        this.props.activeTab = index
    },
    nextPageTransac() {
        this.props.pageTransacIndex = this.props.pageTransacIndex + 1
        this.props.pageTransacStart = this.props.pageTransacIndex * this.props.pageTransacLimit
        this.getTransacs()
    },
    prevPageTransac() {
        this.props.pageTransacIndex = this.props.pageTransacIndex - 1
        this.props.pageTransacStart = this.props.pageTransacIndex * this.props.pageTransacLimit
        this.getTransacs()
    },
    goToPageTransac(page) {
        this.props.pageTransacIndex = page
        this.props.pageTransacStart = this.props.pageTransacIndex * this.props.pageTransacLimit
        this.getTransacs()
    },
    /*********** DATATABLE ***********/
    setFilterValue(index, value) {
        this.props.filters[index].value = value
    },
    setFilterOperator(index, operator) {
        this.props.filters[index].operator = operator
    },
    async getFilteredClients() {
        this.doLoading(true, 'Chargement en cours ...')
        this.props.pageStart = 0
        this.props.pageIndex = 0
        this.props.pageLimit = 30
        await this.getClients()
        this.doLoading(false)
    },
    resetFilter() {
        this.props.filter = this.props.filters.map((item) => {
            item.active = false
            item.value = ''
            return item
        })
    },
    setSelectedRow(id) {
        this.props.selectedRow = this.props.data[id]
    },
    onDoubleClickRow(index, values) {
        this.setSelectedRow(index)
        this.toggleModalDetails()
    },
    toggleItemFilter(index) {
        if (!this.props.filters[index].isNotToggable)
            this.props.filters[index].active = !this.props.filters[index].active
    },
    toggleSubItem(index, subIndex) {
        console.log(index)
        this.props.filters[index].choices[subIndex].checked = !this.props.filters[index].choices[subIndex].checked
    },
    async nextPage() {
        this.props.pageIndex = this.props.pageIndex + 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getClients()
        this.doLoading(false)
    },
    async prevPage() {
        this.props.pageIndex = this.props.pageIndex - 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getClients()
        this.doLoading(false)
    },
    async goToPage(page) {
        this.props.pageIndex = page
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getClients()
        this.doLoading(false)
    },
    async getClients() {

        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom
        // this.props.loading.msg = 'Chargement des données'
        // this.props.loading.status = true
        this.props.sortBy = this.props.sortBy || 'derAchat'
        this.props.sortOrder = this.props.sortOrder || 'DESC'
        let where = ''
        if (UserStore.isReseau())
            where = ClientFilterStore.getWhere('reseaufid.iDreseauFid = ' + idRes +
                ' AND clientTypeCarte<> 6 ')
        else
            where = ClientFilterStore.getWhere('commercantfid.idcommercantFid = ' + idCom +
                ' AND clientTypeCarte<> 6 ')

        //POUR WALLET
        if (UserStore.isWallet()) {
            if (UserStore.isReseau())
                where = ClientFilterStore.getWhere('reseaufid.iDreseauFid = ' + idRes +
                    ' AND clientTypeCarte<> 6 AND clientCardDesactive  = 0 AND clientNumCard is not null AND clientPME2 >= 0')
            else
                where = ClientFilterStore.getWhere('commercantfid.idcommercantFid = ' + idCom +
                    ' AND clientTypeCarte<> 6 AND clientCardDesactive  = 0 AND clientNumCard is not null AND clientPME2 >= 0')
        }

        let data = {
            'objName': 'gridCartes',
            'cmd': 'getRows',
            'where': where,
            'start': this.props.pageStart,
            'limit': this.props.pageLimit,
            'sort': this.props.sortBy,
            'dir': this.props.sortOrder,
            // 'query': JSON.stringify(where)
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.data = !res.data.rows ? [] : res.data.rows
                this.props.pageTotal = res.data.totalCount
                this.props.pageCount = Math.ceil(res.data.totalCount / this.props.pageLimit)

            } else {
                this.props.data = []
            }
        }
        // this.props.loading.status = false
    },
    async getNbSubscribers() {

        this.doLoading(true, "Chargement en cours")
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let where = ''
        if (UserStore.isReseau())
            where = ClientFilterStore.getWhere('reseaufid.iDreseauFid = ' + idRes +
                ' AND clientTypeCarte <> 6 ')
        else
            where = ClientFilterStore.getWhere('commercantfid.idcommercantFid = ' + idCom +
                ' AND clientTypeCarte <> 6 ')

        let data = {
            'objName': 'subcribersCampagne',
            'cmd': 'getRows',
            'where': where,
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            if (res.data.rows && res.data.rows.length) {
                // console.log(res.data.rows)
                this.props.nb_subcribers = !res.data.rows ? [] : res.data.rows
                this.doLoading(false)
                return {
                    nb_abonne: parseInt(res.data.rows[0]['nb_abonne']),
                    nb_desabonne: parseInt(res.data.rows[0]['nb_desabonne'])
                }

            } else {
                this.props.nb_subcribers = []
                toast.error('Une erreur est survenue.')
                this.doLoading(false)
                return false
            }
        }

        // this.props.loading.status = false
    },
    async getOneClient(idClient) {

        this.props.sortBy = this.props.sortBy || 'idClientFid'
        this.props.sortOrder = this.props.sortOrder || 'ASC'
        let where = ' idClientFid = ' + idClient

        let data = {
            'objName': 'gridCartes',
            'cmd': 'getRows',
            'where': where,
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.selectedRow = res.data.rows[0]

            } else {
                toast.error('Erreur refresh')
            }
        }
    },
    /*********** CLIENT ***********/
    displayClientInfo(label, value) {
        if (label == 'Civilité')
            return Civilite[value]
        if (label == 'Campagne')
            if (value == '0')
                return "Abonné"
            else
                return "Désabonné"
        return value
    },
    validateClient(values) {
        const toValidate = { ...values }
        let validationPattern = {}
        this.props.columns
            .filter((item) => item.isEditable && item.validation)
            .map((item) => { validationPattern[item.field] = toJS(item.validation) })

        const form = new Schema(validationPattern)
            .message({
                carte: 'Le n° de carte doit etre vide ou avoir une taille de 17 caractères'
            })

        const errors = form.validate(toValidate)

        const res = mapValues(keyBy(errors, 'path'), 'message')

        return res

    },
    toggleModalEditClient(state = null) {
        if (typeof state == 'boolean')
            return this.props.ModalEditClientIsOpen = state
        this.props.ModalEditClientIsOpen = !this.props.ModalEditClientIsOpen
    },
    getEditableFields() {

        const res = this.props.columns.filter((item) => item.isEditable)
        // console.log(res)
        return res
    },
    getFicheClient() {
        let fiche = []
        const res = this.props.columns.filter((item) => item.isEditable).map(
            (item) => {
                // if (this.props.selectedRow[item.accessor])
                fiche.push({ label: item.Header, value: this.props.selectedRow[item.accessor] || '' })
            }
        )
        // console.log(initialValues)
        return fiche
    },
    getInitialValuesForEditClient() {
        let initialValues = {}

        const res = this.props.columns.filter((item) => item.isEditable).map(
            (item) => { initialValues[item.field] = this.props.selectedRow[item.accessor] }
        )

        return initialValues
    },
    async deleteClient() {
        let next = false
        console.log('delete')
        this.doLoading(true, 'Suppression en cours ...')
        let data = {
            'cmd': 'DelRows',
            'ref': 'delUsers',
            'objName': 'lesClients',
            'where': 'idclientFid =' + this.props.selectedRow.idcli,
            'data': JSON.stringify([{ "idclientFid": this.props.selectedRow.idcli }])
        }

        next = await confirmation({
            'type': 'deleteClient',
            'title': 'Suppression',
            'password': 'supprimer',
            'client': this.props.selectedRow.cltNom + ' ' + this.props.selectedRow.cltPrenom
        })



        if (!next) {
            this.doLoading(false)
            return
        }
        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            toast.success("Le client a bien été supprimé");
        }
        else
            toast.error("Une erreur est survenue<br>" + res.data.error);
        await this.getClients()

        this.doLoading(false)

    },
    async deleteAllClients() {
        let next = false
        const idRes = UserStore.props.user.idRes
        if (!idRes) {
            return toast.danger("Reseau non définit");
        }
        console.log('delete')
        this.doLoading(true, 'Suppression en cours ...')
        let data = {
            'cmd': 'DelRows',
            'ref': 'delUsers',
            'objName': 'lesClients',
            'where': 'idReseauFid =' + idRes,
            'data': JSON.stringify([{ "idReseauFid": idRes }])
        }

        next = await confirmation({
            'type': 'deleteClient',
            'title': 'Suppression',
            'password': 'supprimer',
        })



        if (!next) {
            this.doLoading(false)
            return
        }
        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            toast.success("Les clients ont bien été supprimé");
        }
        else
            toast.error("Une erreur est survenue<br>" + res.data.error);
        await this.getClients()

        this.doLoading(false)

    },
    async fetchCards(value) {
        if (value.length < 4)
            return
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let where = ''
        if (UserStore.isReseau())
            where = 'iDreseauFid=' + idRes + ' and clientCardDesactive=0'
        else
            where = 'iDreseauFid=' + idRes + ' and idcommercantFid=' + idCom + ' and clientCardDesactive=0'

        const data = {
            'start': 0,
            'limit': 80,
            'sort': 'clientNumCard',
            'dir': 'ASC',
            'cmd': 'getRows',
            'objName': 'CdlisteClients',
            'fields': JSON.stringify(["clientNumCard"]),
            'where': 'iDreseauFid=' + idRes + ' and clientCardDesactive=0',
            'query': value
        }

        const res = await api.post('/scripts/clients/regclients.php', data)
        let listCards = !res.data.rows ? [] : res.data.rows
        return new Promise(resolve => resolve(listCards.map(item => ({ value: item.clientNumCard, label: item.clientNumCard }))))

    },
    async submitEditClient(values) {
        this.doLoading(true, 'Chargement en cours ...')
        console.log(values)
        const idRes = UserStore.props.user.idRes
        let isModifNumCard = false

        if (this.props.selectedRow.cltNumCard != values['clientNumCard'])
            isModifNumCard = true

        values['idclientFid'] = this.props.selectedRow.idcli
        values['clientNumCard'] = values['clientNumCard'].length == 0 ? null : values['clientNumCard']


        let data = {
            cmd: 'saveRows',
            ref: 'saveUsers',
            objName: 'lesClients',
            data: JSON.stringify([values]),
            lignesStdlistesPeriodeAutoPME1ToCreate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME1ToUpdate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME2ToCreate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME2ToUpdate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPMEToDelete: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME1ToCreate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME1ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME2ToCreate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME2ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePMEToDelete: JSON.stringify([]),
            lignesStdlistesChgtUniquePME1ToCreate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME1ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME2ToCreate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME2ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtUniquePMEToDelete: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToCreate: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToUpdate: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToDelete: JSON.stringify([]),
            lignesCeclistesAutoPMEToCreate: JSON.stringify([]),
            lignesCeclistesAutoPMEToUpdate: JSON.stringify([]),
            lignesCeclistesAutoPMEToDelete: JSON.stringify([]),
            isModifNumCard: isModifNumCard,
            oldNum: this.props.selectedRow.cltNumCard,
            idRes: idRes,
        }
        console.log(this.props.selectedRow.cltNumCard, values['clientNumCard'])


        const res = await api.post('/scripts/clients/regclients.php', data)
        if (res.data.success) {
            toast.success("Le client a bien été modifié");
        }
        else
            toast.error("Une erreur est survenue<br>" + res.data.error);
        this.toggleModalEditClient(false)
        this.getClients()
        this.doLoading(false)
    },
    /*********** AFFECTATION ***********/
    async affect(item, type) {
        this.doLoading(true, 'Affectation en cours ...')
        let next = false
        let data = {
            'cmd': 'affectation',
            'idclient': this.props.selectedRow.idcli,
            'idLigneChoisie': item.idclientFid,
            'temp': item.DateDinsertionEnBase == '' ? 0 : item.DateDinsertionEnBase
        }

        if (type == 'cardToFiche') {
            next = await confirmation({
                type: 'confirmAffect',
                typeAffect: 'cardToFiche',
                selectedRow: this.props.selectedRow,
                choice: item
            })

        }
        else if (type == 'ficheToCard')
            next = await confirmation({
                type: 'confirmAffect',
                typeAffect: 'ficheToCard',
                selectedRow: this.props.selectedRow,
                choice: item
            })
        else {
            toast.error('Erreur type action')
            return
        }
        if (!next) {
            this.doLoading(false)
            return
        }

        const res = await api.post('/scripts/clients/import_clients.php', data)
        console.log(res)
        if (res.status == 200) {
            toast.success("Affectation réussie");
        }
        else
            toast.error("Une erreur est survenue");
        this.props.ModalAffectIsOpen = false
        this.getClients()
        this.doLoading(false)


    },
    async getFichesToAffect() {
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let where = false
        if (UserStore.isReseau())
            where = "iDreseauFid= " + idRes + " and clientNumCard is null and clientPme1=0 and clientPme2=0 and 1erAchat is null and dernierAchat is null and dateValidPME1 is null and dateValidPME2 is null and cumulCreditPme1=0 and cumulCreditPme2=0 and cumulDebitPme1=0 and cumulDebitPme2=0 and PeriodeRechargePME1=0 and PeriodeRechargePME2=0 and RechargePME1=0 and RechargePME2=0 and cumulBonusPme1=0 and cumulBonusPme2=0 and cumulRechargePme1=0 and cumulRechargePme2=0 and cumulSpot=0 and cumulParrainagePme1=0 and cumulParrainagePme2=0"
        else
            where = "iDreseauFid= " + idRes + " and idcommercantFid=" + idCom + " and clientNumCard is null and clientPme1=0 and clientPme2=0 and 1erAchat is null and dernierAchat is null and dateValidPME1 is null and dateValidPME2 is null and cumulCreditPme1=0 and cumulCreditPme2=0 and cumulDebitPme1=0 and cumulDebitPme2=0 and PeriodeRechargePME1=0 and PeriodeRechargePME2=0 and RechargePME1=0 and RechargePME2=0 and cumulBonusPme1=0 and cumulBonusPme2=0 and cumulRechargePme1=0 and cumulRechargePme2=0 and cumulSpot=0 and cumulParrainagePme1=0 and cumulParrainagePme2=0"

        let data = {
            cmd: 'getRows_bi_table',
            objName: null,
            table_where: 'clientfid',
            table: 'clientfid_temporaire',
            res: UserStore.isReseau() ? idRes : -1,
            where: where
        }

        const res = await api.post('/scripts/clients/union_client_temporaire.php', data)
        console.log(res)
        if (res.data.success) {
            this.props.fichesToAffect = res.data.rows
        } else {
            toast.error('Une erreur est survenue')
        }
    },
    async getCardsToAffect() {
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let where = false
        if (UserStore.isReseau())
            where = "iDreseauFid= " + idRes + " and clientNumCard is not null and (clientNom is null or clientNom='') and (clientPrenom is null or clientPrenom='') and (clientEmail is null or clientEmail='') and (clientGsm is null or clientGsm='') and (clientAdresse1 is null or clientAdresse1='') and (clientAdress2 is null or clientAdress2='') and (clientCP is null or clientCP='') and (clientVille is null or clientVille='') and (clientTel is null or clientTel='') and (clientRIB is null or clientRIB='') and (clientCivilite is null or clientCivilite='')"
        else
            where = "iDreseauFid= " + idRes + " and idcommercantFid=" + idCom + " and clientNumCard is not null and (clientNom is null or clientNom='') and (clientPrenom is null or clientPrenom='') and (clientEmail is null or clientEmail='') and (clientGsm is null or clientGsm='') and (clientAdresse1 is null or clientAdresse1='') and (clientAdress2 is null or clientAdress2='') and (clientCP is null or clientCP='') and (clientVille is null or clientVille='') and (clientTel is null or clientTel='') and (clientRIB is null or clientRIB='') and (clientCivilite is null or clientCivilite='')"

        let data = {
            cmd: 'getRows',
            objName: 'lesClients',
            table_where: 'clientfid',
            table: 'clientfid_temporaire',
            res: -1,
            where: where
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        console.log(res)
        if (res.data.success) {
            this.props.cardsToAffect = res.data.rows
        } else {
            toast.error('Une erreur est survenue')
        }
    },
    async searchFiche(value, type) {
        this.doLoading(true, 'Recherche en cours ...')
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom
        let where = ''

        if (UserStore.isReseau())
            where = type + " like '%" + value + "%' and iDreseauFid= " + idRes + " and clientNumCard is null and clientPme1=0 and clientPme2=0 and 1erAchat is null and dernierAchat is null and dateValidPME1 is null and dateValidPME2 is null and cumulCreditPme1=0 and cumulCreditPme2=0 and cumulDebitPme1=0 and cumulDebitPme2=0 and PeriodeRechargePME1=0 and PeriodeRechargePME2=0 and RechargePME1=0 and RechargePME2=0 and cumulBonusPme1=0 and cumulBonusPme2=0 and cumulRechargePme1=0 and cumulRechargePme2=0 and cumulSpot=0 and cumulParrainagePme1=0 and cumulParrainagePme2=0"
        else
            where = type + " like '%" + value + "%' and iDreseauFid= " + idRes + " and idcommercantFid=" + idCom + " and clientNumCard is null and clientPme1=0 and clientPme2=0 and 1erAchat is null and dernierAchat is null and dateValidPME1 is null and dateValidPME2 is null and cumulCreditPme1=0 and cumulCreditPme2=0 and cumulDebitPme1=0 and cumulDebitPme2=0 and PeriodeRechargePME1=0 and PeriodeRechargePME2=0 and RechargePME1=0 and RechargePME2=0 and cumulBonusPme1=0 and cumulBonusPme2=0 and cumulRechargePme1=0 and cumulRechargePme2=0 and cumulSpot=0 and cumulParrainagePme1=0 and cumulParrainagePme2=0"

        let data = {
            'cmd': "getRows_bi_table",
            'objName': '',
            'table_where': "clientfid",
            'table': "clientfid_temporaire",
            'res': idRes + " and " + type + " like '%" + value + "%'",
            where: where
        }

        const res = await api.post('/scripts/clients/union_client_temporaire.php', data)
        console.log(res)
        if (res.data.success) {
            this.props.fichesToAffect = res.data.rows ? res.data.rows : []
        } else {
            toast.error('Une erreur est survenue')
        }

        this.doLoading(false)
    },
    async searchCard(value) {
        this.doLoading(true, 'Recherche en cours ...')
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom
        let where = false

        if (UserStore.isReseau())
            where = "clientNumCard like '%" + value.trim() + "%' and iDreseauFid= " + idRes + " and clientNumCard is not null and (clientNom is null or clientNom='') and (clientPrenom is null or clientPrenom='') and (clientEmail is null or clientEmail='') and (clientGsm is null or clientGsm='') and (clientAdresse1 is null or clientAdresse1='') and (clientAdress2 is null or clientAdress2='') and (clientCP is null or clientCP='') and (clientVille is null or clientVille='') and (clientTel is null or clientTel='') and (clientRIB is null or clientRIB='') and (clientCivilite is null or clientCivilite='')"
        else
            where = "clientNumCard like '%" + value.trim() + "%' and iDreseauFid= " + idRes + " and idcommercantFid=" + idCom + " and clientNumCard is not null and (clientNom is null or clientNom='') and (clientPrenom is null or clientPrenom='') and (clientEmail is null or clientEmail='') and (clientGsm is null or clientGsm='') and (clientAdresse1 is null or clientAdresse1='') and (clientAdress2 is null or clientAdress2='') and (clientCP is null or clientCP='') and (clientVille is null or clientVille='') and (clientTel is null or clientTel='') and (clientRIB is null or clientRIB='') and (clientCivilite is null or clientCivilite='')"

        let data = {
            cmd: "getRows",
            objName: "lesClients",
            table_where: "clientfid",
            table: "clientfid_temporaire",
            res: "467 and clientNumCard like '%gh%' ",
            where: where
        }

        const res = await api.post('/scripts/clients/clients.php', data)
        console.log(res)
        if (res.data.success) {
            this.props.cardsToAffect = res.data.rows ? res.data.rows : []
        } else {
            toast.error('Une erreur est survenue')
        }

        this.doLoading(false)
    },
    async toggleModalAffect(type = false) {
        this.props.ModalAffectIsOpen = !this.props.ModalAffectIsOpen
        this.doLoading(true, 'Chargement ...')
        if (type) {
            if (type == 'ficheToCard')
                this.props.titleAffect = 'Affectation d\'une carte'
            else if (type == 'cardToFiche')
                this.props.titleAffect = 'Affectation d\'une fiche'
            this.props.typeAffect = type
        }
        if (this.props.ModalAffectIsOpen) {
            if (type == 'ficheToCard')
                await this.getFichesToAffect()
            if (type == 'cardToFiche')
                await this.getCardsToAffect()
        }
        this.doLoading(false)
    },
    /*********** WALLET ***********/
    async submitOperationWallet(values) {
        this.doLoading(true, 'Chargement en cours ...')
        let to_send = toJS(values)
        to_send['timezone'] = (new Date).getTimezoneOffset() / (- 60)
        console.log((new Date).getTimezoneOffset())
        let data = {
            cmd: 'doTransac',
            data: JSON.stringify(to_send)
        }

        const res = await api.post('/scripts/wallet/wallet.php', data)

        if (res.data.success) {
            // console.log('success')
            await this.getClients()
            let data = await this.getTransacs()
            this.getLineDatasets(data)
            toast.success("Opération réussi")
            this.props.selectedRow = res.data.newClient

        }
        else {
            // console.log('fail')
            toast.error("Une erreur est survenue")
        }

        this.doLoading(false)

    },
    /*********** RECHARGE ***********/
    getInitialValuesForRecharge() {

        return {

            "NumeroIncrement": "0",
            "IdReseau": this.props.selectedRow.iDres,
            "IdCommercant": this.props.selectedRow.idCom,
            "IdClient": this.props.idcli,
            "NumeroPME": 1,
            "NumeroCarte": this.props.selectedRow.cltNumCard,
            "DateDebutRecharge": moment().format('YYYY-MM-DD'),
            "DateFinRecharge": moment().format('YYYY-MM-DD'),
            "PeriodeRecharge": 101,
            "MontantRecharge": 0,
            "Param_1": "",
            "Param_2": "",
            "Param_3": "",
            "Param_4": "",
            "Param_5": ""


        }
    },
    validateRecharge(values) {
        //Clone de l'objet value sinon cela cause des problèmes
        const toValidate = { ...values }
        console.log(toValidate)
        const form = new Schema({
            Param_1: {
                type: String,
                required: true,
                length: { min: 1 },
                message: {
                    required: 'Ce champs est requis',
                    length: 'La taille doit être supérieur à 3 caracteres'
                }
            },
            NumeroPME: {
                required: true,
                message: {
                    required: 'Ce champs est requis',
                }
            },
            DateDebutRecharge: {
                required: true,
                message: {
                    required: 'Ce champs est requis',
                }
            },
            DateFinRecharge: {
                use: {
                    dateCompare: () => moment(toValidate.DateDebutRecharge).isBefore(moment(toValidate.DateFinRecharge), 'day') ||
                        moment(toValidate.DateDebutRecharge).isSame(moment(toValidate.DateFinRecharge))
                }
            },
            // MontantRecharge: {
            //     use: {
            //         isNull: (val) => val > 0
            //     }
            // }
        })
            .message('dateCompare', 'La date de fin doit etre supérieur à la date de début')
            .message('isNull', 'Le montant ne doit pas etre nul')

        const errors = form.validate(toValidate)
        // console.log(errors)
        return mapValues(keyBy(errors, 'path'), 'message')

    },
    async submitRecharge(values) {
        this.doLoading(true, 'Chargement en cours ...')
        const idRes = UserStore.props.user.idRes
        await this.getClients()
        values['NumeroIncrement'] = 0
        values['IdReseau'] = this.props.selectedRow.iDres
        values['IdCommercant'] = this.props.selectedRow.idcom
        values['IdClient'] = this.props.selectedRow.idcli
        values['NumeroCarte'] = this.props.selectedRow.cltNumCard
        values['PeriodeRecharge'] = 101
        values['Param_2'] = ""
        values['Param_3'] = ""
        values['Param_4'] = ""
        values['Param_5'] = ""
        // console.log(values)

        const userData = [
            {
                clientNumCard: this.props.selectedRow.cltNumCard,
                idclientFid: this.props.selectedRow.idcli
            }
        ]

        let data = {
            cmd: 'saveRows',
            ref: 'saveUsers',
            objName: 'lesClients',
            data: JSON.stringify(userData),
            lignesStdlistesPeriodeAutoPME1ToCreate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME1ToUpdate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME2ToCreate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME2ToUpdate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPMEToDelete: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME1ToCreate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME1ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME2ToCreate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME2ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePMEToDelete: JSON.stringify([]),
            lignesStdlistesChgtUniquePME1ToCreate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME1ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME2ToCreate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME2ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtUniquePMEToDelete: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToCreate: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToUpdate: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToDelete: JSON.stringify([]),
            lignesCeclistesAutoPMEToCreate: JSON.stringify([]),
            lignesCeclistesAutoPMEToUpdate: JSON.stringify([]),
            lignesCeclistesAutoPMEToDelete: JSON.stringify([]),
            isModifNumCard: false,
            idRes: idRes,
        }

        let to_send = JSON.parse(JSON.stringify(values))

        if (to_send['numeroPME'] = 1) {
            to_send['MontantRecharge'] = to_send['MontantRecharge'] * 100
            data.lignesStdlistesChgtUniquePME1ToCreate = JSON.stringify([to_send])
        }
        else if (to_send['numeroPME'] = 2)
            data.lignesStdlistesChgtUniquePME2ToCreate = JSON.stringify([to_send])
        else {
            this.doLoading(false)
            toast.error("PME non existant");
        }


        const res = await api.post('/scripts/clients/regclients.php', data)
        if (res.data.success) {
            await this.editStdListe()
            await this.editUpdListe()
            toast.success("La recharge a bien été ajoutée");
        }
        else
            toast.error("Une erreur est survenue<br>" + res.data.error);
        await this.getRecharges()

        this.doLoading(false)
    },
    async submitEditRecharge(values) {

        const idRes = UserStore.props.user.idRes
        let toSend = { ...values }
        toSend['IdReseau'] = this.props.selectedRow.iDres
        toSend['IdCommercant'] = this.props.selectedRow.idcom
        toSend['IdClient'] = this.props.selectedRow.idcli
        toSend['NumeroCarte'] = this.props.selectedRow.cltNumCard
        toSend['PeriodeRecharge'] = 101
        toSend['Param_2'] = ""
        toSend['Param_3'] = ""
        toSend['Param_4'] = ""
        toSend['Param_5'] = ""


        const userData = [
            {
                clientNumCard: this.props.selectedRow.cltNumCard,
                idclientFid: this.props.selectedRow.idcli
            }
        ]

        let data = {
            cmd: 'saveRows',
            ref: 'saveUsers',
            objName: 'lesClients',
            data: JSON.stringify(userData),
            lignesStdlistesPeriodeAutoPME1ToCreate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME1ToUpdate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME2ToCreate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME2ToUpdate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPMEToDelete: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME1ToCreate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME1ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME2ToCreate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME2ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePMEToDelete: JSON.stringify([]),
            lignesStdlistesChgtUniquePME1ToCreate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME1ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME2ToCreate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME2ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtUniquePMEToDelete: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToCreate: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToUpdate: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToDelete: JSON.stringify([]),
            lignesCeclistesAutoPMEToCreate: JSON.stringify([]),
            lignesCeclistesAutoPMEToUpdate: JSON.stringify([]),
            lignesCeclistesAutoPMEToDelete: JSON.stringify([]),
            isModifNumCard: false,
            idRes: idRes,
        }

        if (toSend['numeroPME'] = 1) {
            toSend['MontantRecharge'] = toSend['MontantRecharge'] * 100
            data.lignesStdlistesChgtUniquePME1ToUpdate = JSON.stringify([toSend])
        }
        else if (toSend['numeroPME'] = 2)
            data.lignesStdlistesChgtUniquePME2ToUpdate = JSON.stringify([toSend])
        else
            return toast.error("PME non existant");

        //  console.log(data)
        // return console.log(toSend)

        const res = await api.post('/scripts/clients/regclients.php', data)
        if (res.data.success) {
            toast.success("La recharge a bien été modifié");

        }
        else
            toast.error("Une erreur est survenue<br>" + res.data.error);
        this.props.ModalEditRechargeIsOpen = false
        this.getRecharges()
    },
    async getRecharges() {

        const data = {
            'cmd': 'GetLignesStdlistChgtDirectByCriteria',
            'where': " NumeroCarte=" + this.props.selectedRow.cltNumCard,
            'json': true,
            'callFromClient': true,
        }

        const res = await api.post('/scripts/RepositoryPattern/Services.Recharge.php', data)
        // console.log(res)
        if (res.data.success)
            this.props.dataRecharge = !res.data.rows ? [] : res.data.rows

    },
    toggleModalEditRecharge() {
        this.props.ModalEditRechargeIsOpen = !this.props.ModalEditRechargeIsOpen
        console.log(this.props.ModalEditRechargeIsOpen)
    },
    setSelectedRowRecharge(id) {
        this.props.selectedRowRecharge = toJS(this.props.dataRecharge[id])
        this.props.selectedRowRecharge.MontantRecharge = this.props.selectedRowRecharge.MontantRecharge / 100
    },
    getInitialValuesEditRecharge() {
        return this.props.selectedRowRecharge
    },
    async deleteRecharge(values) {
        const idRes = UserStore.props.user.idRes
        let toSend = { ...this.props.selectedRowRecharge }


        const userData = [
            {
                clientNumCard: this.props.selectedRow.cltNumCard,
                idclientFid: this.props.selectedRow.idcli
            }
        ]

        let data = {
            cmd: 'saveRows',
            ref: 'saveUsers',
            objName: 'lesClients',
            data: JSON.stringify(userData),
            lignesStdlistesPeriodeAutoPME1ToCreate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME1ToUpdate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME2ToCreate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME2ToUpdate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPMEToDelete: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME1ToCreate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME1ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME2ToCreate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME2ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePMEToDelete: JSON.stringify([]),
            lignesStdlistesChgtUniquePME1ToCreate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME1ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME2ToCreate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME2ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtUniquePMEToDelete: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToCreate: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToUpdate: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToDelete: JSON.stringify([]),
            lignesCeclistesAutoPMEToCreate: JSON.stringify([]),
            lignesCeclistesAutoPMEToUpdate: JSON.stringify([]),
            lignesCeclistesAutoPMEToDelete: JSON.stringify([]),
            isModifNumCard: false,
            idRes: idRes,
        }

        data.lignesStdlistesChgtUniquePMEToDelete = JSON.stringify([toSend])

        data.lignesStdlistesChgtUniquePMEToDelete = JSON.stringify([toSend])


        //  console.log(data)
        // return console.log(toSend)

        const res = await api.post('/scripts/clients/regclients.php', data)
        if (res.data.success) {
            toast.success("La recharge a bien été supprimé");

        }
        else
            toast.error("Une erreur est survenue<br>" + res.data.error);
        this.props.ModalEditRechargeIsOpen = false
        this.getRecharges()
    },
    /************* EXPORT *************/
    async exportClients() {
        this.doLoading(true, 'Génération de l\'export en cours ...')

        const modeFid = UserStore.props.user.mode
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let where = ''
        if (UserStore.isReseau())
            where = ClientFilterStore.getWhere('iDreseauFid = ' + idRes +
                ' AND clientTypeCarte<> 6 ', 'export')
        else
            where = ClientFilterStore.getWhere('idcommercantFid = ' + idCom +
                ' AND clientTypeCarte<> 6 ', 'export')

        const data = {
            "cmd": "exportCsv",
            "wheres": JSON.stringify({ "objName": "exportCsvAFNOR", "where": where, "modeF": modeFid }),
            "isCsv": "true",
            "data": ''
        }

        const res = await api.post("/scripts/export/export_tcpdf.php", data, { responseType: 'arraybuffer' })

        const file = new Blob([res.data], { type: 'application/pdf' });
        saveAs(file, "export_clients.pdf");

        this.doLoading(false)

    },
    async exportClientsCsv() {
        this.doLoading(true, 'Génération de l\'export .CSV en cours ...')

        const modeFid = UserStore.props.user.mode
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let where = ''
        if (UserStore.isReseau())
            where = ClientFilterStore.getWhere('iDreseauFid = ' + idRes , 'export')
        else
            where = ClientFilterStore.getWhere('idcommercantFid = ' + idCom , 'export')

        const data = {
            "cmd": "exportCsv",
            "wheres": JSON.stringify({ "objName": "expCsvLstClients", "where": where, "modeF": modeFid }),
            "isCsv": "true",
            "data": ''
        }

        const res = await api.post("/scripts/export/exportscsv.php", data, { responseType: 'arraybuffer' })

        const file = new Blob([res.data], { type: 'text/x-csv' });
        saveAs(file, "export_clients.csv");

        this.doLoading(false)

    },
    setSelectedEnseigne(value) {
        console.log(value)
        this.props.selectedEnseigne = value
    },
    async disableEnableCard(value) {

        let isConfirm = null
        if (value)
            isConfirm = await confirmation({
                title: 'désactivation',
                info: 'Carte n° ' + this.props.selectedRow.cltNumCard + ' | Client : ' + this.props.selectedRow.cltNom + ' ' + this.props.selectedRow.cltPrenom,
                password: 'desactiver'
            })
        else
            isConfirm = await confirmation({
                title: 'réactivation',
                info: 'Carte n° ' + this.props.selectedRow.cltNumCard + ' | Client : ' + this.props.selectedRow.cltNom + ' ' + this.props.selectedRow.cltPrenom,
                password: 'activer'
            })

        if (!isConfirm)
            return

        // this.props.loading.msg = 'Désactivation en cours ...'
        // this.props.loading.status = true

        const userData = [
            {
                clientNumCard: this.props.selectedRow.cltNumCard,
                idclientFid: this.props.selectedRow.idcli,
                clientCardDesactive: value,
            }
        ]

        const data = {
            cmd: 'saveRows',
            ref: 'saveUsers',
            objName: 'lesClients',
            data: JSON.stringify(userData),
            lignesStdlistesPeriodeAutoPME1ToCreate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME1ToUpdate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME2ToCreate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPME2ToUpdate: JSON.stringify([]),
            lignesStdlistesPeriodeAutoPMEToDelete: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME1ToCreate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME1ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME2ToCreate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePME2ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtPeriodiquePMEToDelete: JSON.stringify([]),
            lignesStdlistesChgtUniquePME1ToCreate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME1ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME2ToCreate: JSON.stringify([]),
            lignesStdlistesChgtUniquePME2ToUpdate: JSON.stringify([]),
            lignesStdlistesChgtUniquePMEToDelete: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToCreate: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToUpdate: JSON.stringify([]),
            lignesCeclistesSeuilCumulPMEToDelete: JSON.stringify([]),
            lignesCeclistesAutoPMEToCreate: JSON.stringify([]),
            lignesCeclistesAutoPMEToUpdate: JSON.stringify([]),
            lignesCeclistesAutoPMEToDelete: JSON.stringify([]),
            isModifNumCard: false,
            idRes: 467,
        }


        const res = await api.post('/scripts/clients/regclients.php', data)
        if (res.data.success) {
            if (value)
                toast.success("La carte a bien été désactivée");
            else
                toast.success("La carte a bien été réactivée");
        }
        else
            toast.error("Une erreur est survenue<br>" + res.data.error);
        // this.props.loading.status = false
        await this.getClients()
    },
    async getHelpFile() {

        const data = {
            "cmd": "aide",
            "wheres": null,
            "isCsv": "",
            "data": ""
        }

        api.post('/scripts/clients/import_clients.php', data, { responseType: 'arraybuffer' })
            .then((response) => {
                const file = new Blob([response.data], { type: 'text/csv' });
                saveAs(file, "exemple_import.csv");
            })
    },
    async importClients(e) {
        const file = e.target.files[0]
        let idCom = null
        this.toggleModalImport()
        if (UserStore.isReseau()) {
            let next = false
            next = await confirmation({
                type: 'confirmNoPass',
                text: 'Etes-vous sûr(e) de vouloir importer les clients dans l\'enseigne : <br/><b>' +
                    this.props.listEnseignes[this.props.selectedEnseigne].NomCommercant + '<b>'
            })

            if (!next)
                return

            idCom = this.props.listEnseignes[this.props.selectedEnseigne].idcommercantFid
        } else {
            idCom = UserStore.props.user.idCom
        }

        this.doLoading(true, 'Imporatation clients en cours ...')


        const filename = file.name
        const idRes = UserStore.props.user.idRes



        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
        }

        //Envoi du fichier
        console.log('Envoi fichier')
        this.doLoading(true, 'Envoi du fichier en cours ...')
        const formData = new FormData();

        formData.append('cmd', "upload_fich")
        formData.append('documentURL', file)
        formData.append('res', idRes)
        formData.append('com', idCom)


        const resEnvoi = await api.post('/scripts/clients/import_clients.php', formData, config)
        // e.target.files = file[];
        if (!resEnvoi.data.success) {
            modalAlert({
                title: 'Erreur d\'import',
                text: 'Une erreur est survenue <br> Le fichier doit être au format .csv ou .txt'
            })
            this.props.importLoading = false
            this.doLoading(false)
            return
        }

        //Vérifications doublons
        console.log('Vérifications doublons')
        this.doLoading(true, 'Vérification des doublons en cours ...')
        const dataDoublons = {
            'cmd': 'verification_doublons',
            'res': idRes,
            'com': idCom,
            'path': filename,
        }

        const resDoublons = await api.post('/scripts/clients/import_clients.php', dataDoublons)
        let next = false
        if (!resDoublons.data.success) {
            // toast.error('Une erreur est survenue :<br>' + resDoublons.data.erreur)
            if (resDoublons.data.erreur)
                await modalAlert({
                    title: 'Erreur d\'import',
                    text: 'Une erreur est survenue :<br> Vos données n\'ont pas pu être intégrés : ' + resDoublons.data.erreur
                })
            else {
                next = await modalAlert({
                    type: 'doublons',
                    title: 'Erreur d\'import',
                    isFatal: resDoublons.data.nbfatal,
                    erreursLignes: resDoublons.data.listes_erreurs,
                    colonnesIgnorees: resDoublons.data.colonnes_inv,
                })
            }

        } else {
            next = true
        }

        if (!next) {
            this.doLoading(false)
            return
        }

        console.log('Importation')
        this.doLoading(true, 'Insertion des données en cours ...')
        const dataImport = {
            'cmd': 'importCSV',
            'res': idRes,
            'com': idCom,
            'path': filename,
        }

        const resImport = await api.post('/scripts/clients/import_clients.php', dataImport)
        if (!resImport.data.success) {
            modalAlert({
                title: 'Erreur d\'import',
                text: 'Une erreur est survenue :<br> Vos données n\'ont pas pu être intégrés : ' + resImport.data.resume
            })
            this.doLoading(false)
            return
        }
        else {
            this.props.ImportIsOpen = false
            modalAlert({
                title: 'Import effectué',
                text: 'Vos données ont bien été inporté.'
            })

            this.getClients()
            this.doLoading(false)
        }
    },
    toggleModalImport() {
        this.props.ImportIsOpen = !this.props.ImportIsOpen
    },
    /************* BLACKLIST *************/
    async editBlackList() {
        this.doLoading(true, 'Génération de la blakclist en cours ...')
        const idRes = UserStore.props.user.idRes
        let data = {
            'cmd': 'exporterLaBlackListe',
            'ref': 'expBlkLst',
            'idS': idRes,
            'idx': 1,
        }

        const res = await api.post('scripts/export/ExportBlackList.php', data)
        this.doLoading(false)
        if (res.data.error) {
            await modalAlert({
                title: "Génération de la blacklist vers les TPE",
                text: res.data.error
            })
        } else {
            await modalAlert({
                title: "Génération de la blacklist vers les TPE",
                text: "Une erreur est survenue"
            })
        }

    },
    async editUpdListe() {
        // this.doLoading(true, 'Génération de la blakclist en cours ...')
        const idRes = UserStore.props.user.idRes
        let data = {
            'cmd': 'exporterLaUpdListe',
            'idS': idRes,
        }

        const res = await api.post('scripts/export/ExportUpdlist.php', data)
        // this.doLoading(false)
        if (res.data.error) {
            toast.success(<>Génération de la Updlist vers les TPE <br /> {res.data.error}</>);
        } else {
            toast.error(<>Génération de la Updlist vers les TPE <br /> Une erreur est survenue</>);
        }

    },
    async editStdListe() {
        // this.doLoading(true, 'Génération de la blakclist en cours ...')
        const idRes = UserStore.props.user.idRes
        let data = {
            'cmd': 'ExportStdlist',
            'idReseau': idRes,
        }

        const res = await api.post('scripts/export/ExportStdlist.php', data)
        // this.doLoading(false)
        if (res.data.error) {
            toast.success(<>Génération de la Updlist vers les TPE <br /> {res.data.error}</>);
        } else {
            toast.error(<>Génération de la Updlist vers les TPE <br /> Une erreur est survenue</>);
        }

    },
    reset() {
        this.props = props
    }
}

export default observable(CardsStore)


