
import { observable, toJS } from 'mobx'
import api from '../api'
import ClientFilterStore from './ClientFilterStore'


const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours'
    },
    data: [],
}

const SegmentationStore = {
    /***** Props ******/
    props,

    /***** Methods ******/
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    async init() {
        this.doLoading(true, 'Chargement en cours ...')
        await this.getSegments()
        this.doLoading(false)
    },


    async getSegments() {

        const res = await api.post('/scripts/segments/segments.php')
        if (res.data.success) {
            if (res.data.success) {
                this.props.data = res.data.data
            }
        }
    },

    getClientsFromSegment(segment, history) {
        // let pattern = ClientFilterStore.props.pattern
        // ClientFilterStore.resetFilter()
        ClientFilterStore.props.fromSegments = true
        ClientFilterStore.props.pattern['segment'].value[0].value = ["'"+segment+"'"]
        ClientFilterStore.props.pattern['segment'].active = true

        history.push("/cards")

    },

    reset() {
        this.props = props
    }
}

export default observable(SegmentationStore)