

import { observable, toJS } from 'mobx'
import api from '../api'
import moment from 'moment'
import UserStore from './UserStore'
import myYup from '../utils/myYup'


const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours'
    },
    data: [],
    stats: {
        panier_moyen: {
            lodading: false,
            data: []
        },
        montants: {
            lodading: false,
            data: []
        },
        transactions: {
            lodading: false,
            data: []
        },
        clients_actifs: {
            lodading: false,
            data: []
        },
        coupons_emis: {
            lodading: false,
            data: []
        },
        coupons_encaisses: {
            lodading: false,
            data: []
        },
        coupons_restant_a_encaisser: {
            lodading: false,
            data: []
        },
        credits_emis: {
            lodading: false,
            data: []
        },
        credits_encaisses: {
            lodading: false,
            data: []
        },
        credits_restant_a_encaisser: {
            lodading: false,
            data: []
        },
        points_emis: {
            lodading: false,
            data: []
        },
        points_encaisses: {
            lodading: false,
            data: []
        },
        points_restant_a_encaisser: {
            lodading: false,
            data: []
        },
    },
    listEnseignes: [],
    initialValues: {
        startDate: moment().subtract('1', 'months').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD'),
        // groupBy: 'day',
        enseignes: []
    },
    validationSchema: myYup.object().shape({
        startDate: myYup.string().required(),
        endDate: myYup.string().required().dateIsGreater().maxDaysDiff(365),
        enseignes: myYup.array().required('Veuillez sélectionner au moins une enseigne')

    })

}

const StatsStore = {
    /***** Props ******/

    props: props,

    /***** Methods ******/
    async init() {
        if (UserStore.isReseau()) {
            this.getEnseignes()
        }
        if (UserStore.isCommercant()) {
            this.props.initialValues.enseignes = [UserStore.props.user.numCom]
        }

        if(UserStore.isFidSimple()){
            delete this.props.stats['coupons_emis']
            delete this.props.stats['coupons_encaisses']
            delete this.props.stats['coupons_restant_a_encaisser']
            delete this.props.stats['credits_emis']
            delete this.props.stats['credits_encaisses']
            delete this.props.stats['credits_restant_a_encaisser']
        }

        if(UserStore.isNovacash()){
            delete this.props.stats['points_emis']
            delete this.props.stats['points_encaisses']
            delete this.props.stats['points_restant_a_encaisser']
            delete this.props.stats['coupons_emis']
            delete this.props.stats['coupons_encaisses']
            delete this.props.stats['coupons_restant_a_encaisser']
        }
        if(UserStore.isFidCoupons()){
            delete this.props.stats['points_emis']
            delete this.props.stats['points_encaisses']
            delete this.props.stats['points_restant_a_encaisser']
            delete this.props.stats['credits_emis']
            delete this.props.stats['credits_encaisses']
            delete this.props.stats['credits_restant_a_encaisser']
        }

        // this.getStats({})
    },
    isLoading(){
        let loading = false
        Object.keys(this.props.stats).map(key => loading = loading || this.props.stats[key].loading)

        return loading
    },
    async getStats(values) {
        this.resetStats()
        const getGroupBy = this.getGroupBy(values['startDate'], values['endDate'])

        Object.keys(this.props.stats).map(key => {
            this.props.stats[key].loading = true
            let data = {
                'activite': key,
                'startDate': values['startDate'],
                'endDate': values['endDate'],
                'enseignes':values['enseignes'],
                'groupBy': getGroupBy,
            }


            api.post('/scripts/stats/stats_v2.php', data)
                .then((res) => {

                    if (res.data.success) {
                        if (res.data.success) {
                            this.props.stats[key] = { ...this.props.stats[key], ...res.data.data }
                            // console.log(res.data)
                        } else {
                            this.props.data = []
                        }
                    }
                    this.props.stats[key].loading = false
                })


        })

    },
    async getEnseignes() {

        const idRes = UserStore.props.user.idRes
        let data = {
            sort: 'NomCommercant',
            dir: 'DESC',
            cmd: 'getRows',
            objName: 'listeCommercants',
            where: 'iDreseauFid=' + idRes
        }

        const res = await api.post('/scripts/commerce/commercant.php', data)
        if (res.data.success) {
            const data = !res.data.rows ? [] : res.data.rows
            this.props.listEnseignes = data.map((item) => ({ id: item.NumCommercant, label: item.NomCommercant }))

            this.props.initialValues.enseignes = data.map((item) => item.NumCommercant)

        }

    },
    getGroupBy(startDate, endDate) {
        let groupBy = 'day'
        console.log('getGroupBy', moment(endDate).diff(moment(startDate), 'days'))
        if (moment(endDate).diff(moment(startDate), 'days') > 31) {
            groupBy = 'month'
        }

        if (moment(endDate).diff(moment(startDate), 'days') > 365) {
            groupBy = 'year'
        }

        return groupBy
    },
    async submit(values) {
        console.log(toJS(values))
    },
    resetStats(){
        Object.keys(this.props.stats).map(key => this.props.stats[key].data = [])
    },
    reset() {
        this.props = props
    }
}

export default observable(StatsStore)