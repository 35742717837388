/* eslint-disable no-unused-expressions */

import React, { useState, useEffect, useRef } from 'react'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BlockUi from 'react-block-ui'
import { BarLoader } from '../Loader/Loading'
import { useTable } from 'react-table/dist/react-table.development'
import DataTable, { ColumnsToggle } from '../../reusable/DataTable'
import { useFormik } from 'formik'
import MouvementsCreditsStore from '../../stores/MouvementsCreditsStore'
import MyField from '../../reusable/MyField'

const MouvementsCreditsView = observer(() => {


    useEffect(() => {
        MouvementsCreditsStore.init()
    }, [])

    const mouvementsTable = useTable(
        {
            columns: MouvementsCreditsStore.props.columns,
            data: MouvementsCreditsStore.props.data,
            initialState: {
                hiddenColumns: MouvementsCreditsStore.props.columns.filter((item) => (item.isHidden)).map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: MouvementsCreditsStore.props.pageIndex,
                pageSize: MouvementsCreditsStore.props.pageLimit,
                pageCount: MouvementsCreditsStore.props.pageCount,
                pageTotal: MouvementsCreditsStore.props.pageTotal,
            },
            toggleVisibleCampagne: (id, etat) => MouvementsCreditsStore.toggleVisibleCampagne(id, etat),
            toggleModalStatutCampagne: (id) => MouvementsCreditsStore.toggleModalStatutCampagne(id)
        }
    )

    const [search, setSearch] = useState('')

    return (
        <div className="container-fluid mt-3">
            {/* <h2 className="card-title">Campagnes</h2> */}
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <BlockUi tag="div" blocking={MouvementsCreditsStore.props.loading.status}
                            message={MouvementsCreditsStore.props.loading.msg}
                            loader={<BarLoader></BarLoader>}
                            className="card" style={{ minHeight: 'calc(100vh - 150px)' }}>
                            <div className="card-body">
                                <div className="d-flex justify-content-between ">
                                    <div className="d-flex center-v">
                                        <ColumnsToggle
                                            columns={mouvementsTable.allColumns}
                                            className="btn btn-old btn-sm mx-1"
                                            toggleColumn={(col) => MouvementsCreditsStore.toggleColumn(col)}
                                        // columnsFromTable={CardsStore.props.columnsFromTable}
                                        >
                                        </ColumnsToggle>
                                    </div>
                                    <div className="d-flex center-v">
                                        <div className="mx-2 d-flex center-v">
                                            <label className="mr-2 mb-0">Mode</label>
                                            <MyField name="mode"
                                                type="select"
                                                className="form-control form-control-sm"
                                                onChange={(e) => MouvementsCreditsStore.updateFilter('mode', e.target.value)}
                                                value={MouvementsCreditsStore.props.filter.mode}
                                                list={
                                                    [
                                                        { id: -1, label: 'Tous' },
                                                        { id: 0, label: 'Via paiement CB' },
                                                        { id: 1, label: 'Manuel' },
                                                        { id: 2, label: 'Automatique' },
                                                    ]
                                                }
                                            >
                                            </MyField>
                                        </div>
                                        <div className="mx-2 d-flex center-v">
                                            <label className="mr-2 mb-0">Type</label>
                                            <MyField name="type"
                                                type="select"
                                                className="form-control form-control-sm"
                                                onChange={(e) => MouvementsCreditsStore.updateFilter('type', e.target.value)}
                                                value={MouvementsCreditsStore.props.filter.type}
                                                list={
                                                    [
                                                        { id: 0, label: 'Tous' },
                                                        { id: 1, label: 'Crédit' },
                                                        { id: 2, label: 'Débit' },
                                                    ]
                                                }
                                            >
                                            </MyField>
                                        </div>
                                    </div>
                                    <div className="d-flex center-v justify-content-end">
                                        <label className="mr-2 mb-0">Recherche</label>
                                        <input
                                            name="search"
                                            type="text"
                                            className="form-control form-control-sm"
                                            placeholder={"Motif / Utilisateur"}
                                            onChange={(e) => setSearch(e.target.value)}
                                            onKeyDown={e => {
                                                if (e.key === 'Enter') {
                                                    MouvementsCreditsStore.updateFilter('search', search)
                                                }
                                            }}
                                            value={search}
                                            list={
                                                [
                                                    { id: 0, label: 'Tous' },
                                                    { id: 1, label: 'Crédit' },
                                                    { id: 2, label: 'Débit' },
                                                ]
                                            }
                                        />

                                    </div>
                                </div>

                                <DataTable
                                    headerGroups={mouvementsTable.headerGroups}
                                    getTableBodyProps={mouvementsTable.getTableBodyProps}
                                    rows={mouvementsTable.rows}
                                    prepareRow={mouvementsTable.prepareRow}
                                    getTableProps={mouvementsTable.getTableProps}
                                    columns={MouvementsCreditsStore.props.columnsCampagne}
                                    loadingMsg={MouvementsCreditsStore.props.loading.msg}
                                    loadingStatus={MouvementsCreditsStore.props.loading.status}
                                    prevPage={() => MouvementsCreditsStore.prevPage()}
                                    nextPage={() => MouvementsCreditsStore.nextPage()}
                                    setSort={(sortBy) => MouvementsCreditsStore.setSort(sortBy)}
                                    sortOrder={MouvementsCreditsStore.props.sortOrder}
                                    sortBy={MouvementsCreditsStore.props.sortBy}
                                    goToPage={(page) => MouvementsCreditsStore.goToPage(page)}
                                    pageCount={MouvementsCreditsStore.props.pageCount}
                                    pageIndex={MouvementsCreditsStore.props.pageIndex}
                                    pageLimit={MouvementsCreditsStore.props.pageLimit}
                                    pageTotal={MouvementsCreditsStore.props.pageTotal}
                                    maxHeight="calc(100vh - 260px)"
                                    btnClassName="btn btn-outline-success btn-sm"
                                ></DataTable>

                            </div>
                        </BlockUi>
                    </div>
                </div>
            </div >
        </div >
    )
})

export default MouvementsCreditsView

