
export const getImportWarnings = (value) => {
    // console.log(value)
    let items = value.split('<br/>')
    // console.log(items)
    let results = []
    for (let key in items) {
        if (items[key].includes("Le numéro de téléphone est incorrect"))
            results.push({ level: "danger", value: "Le numéro de téléphone est incorrect" })
        else if (items[key].includes("L'adresse Email est incorrect"))
            results.push({ level: "danger", value: "L'adresse Email est incorrect" })
        else if (items[key].includes("Mail déjà existant"))
            results.push({ level: "warning", value: "Mail déjà existant" })
        else if (items[key].includes("Champ contenant l'email vide"))
            results.push({ level: "warning", value: "Champ contenant l'email vide" })
        else if (items[key].includes("Numéro de téléphone déjà existant"))
            results.push({ level: "warning", value: "Numéro de téléphone déjà existant" })
        else if (items[key].includes("Champ contenant le numéro de téléphone vide"))
            results.push({ level: "warning", value: "Champ contenant le numéro de téléphone vide" })

    }

    return results


}

export const getClassOfCardTpye = (number) => {

    // Diners - Carte Blanche
    re = new RegExp("^30[0-5]");
    if (number.match(re) != null)
        return "card-type-icon show diners"

    // Diners
    re = new RegExp("^(30[6-9]|36|38)");
    if (number.match(re) != null)
        return "card-type-icon show diners"

    // JCB
    re = new RegExp("^35(2[89]|[3-8][0-9])");
    if (number.match(re) != null)
        return "card-type-icon show jcb"

    // AMEX
    re = new RegExp("^3[47]");
    if (number.match(re) != null)
        return "card-type-icon show american-express";

    // Visa Electron
    re = new RegExp("^(4026|417500|4508|4844|491(3|7))");
    if (number.match(re) != null)
        return "Visa Electron";

    // Visa
    var re = new RegExp("^4");
    if (number.match(re) != null)
        return "card-type-icon show visa"

    // Mastercard
    re = new RegExp("^5[1-5]");
    if (number.match(re) != null)
        return "card-type-icon show master-card";

    // Discover
    re = new RegExp("^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)");
    if (number.match(re) != null)
        return "card-type-icon show discover"

    return "";

}