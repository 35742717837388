import React, { useEffect, useState } from 'react'
import TransacStore from '../../stores/TransacStore'
import TransacFilterStore from '../../stores/TransacFilterStore'
import { observer } from 'mobx-react'
import { useTable } from 'react-table'
import { BarLoader } from '../Loader/Loading'
import DataTable, { ColumnsToggle, DropdownPortal, Portal } from '../../reusable/DataTable'
import BlockUi from 'react-block-ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import MyField from '../../reusable/MyField'
import moment from 'moment'
import TransacFilter, { QuickSearchDate } from '../../reusable/TransacFilter'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import UserStore from '../../stores/UserStore'
import { number } from '../../utils/numeral'


const TransacView = observer((props) => {

    useEffect(() => {
        TransacFilterStore.init()
    }, [])

    // const cols = toJS(CardsStore.props.columns).filter(item => !item.isNotDisplay)
    const transacTable = useTable(
        {
            columns: TransacFilterStore.props.columns,
            data: TransacFilterStore.props.data,
            initialState: {
                hiddenColumns: TransacFilterStore.props.columns.filter((item) => (item.isHidden)).map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: TransacFilterStore.props.pageIndex,
                pageSize: TransacFilterStore.props.pageLimit,
                pageCount: TransacFilterStore.props.pageCount,
                pageTotal: TransacFilterStore.props.pageTotal,
            },
            setUnitsHeader: TransacFilterStore.setUnitsHeader,
        }
    )


    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);


    return (
        <div className="container-fluid mt-3">
            {/* <h2 className="card-title">Liste des cartes</h2> */}
            <div className="animated fadeIn">
                <div className="row">
                    <div className="col-12">
                        <BlockUi tag="div" blocking={TransacFilterStore.props.loading.status}
                            message={TransacFilterStore.props.loading.msg}
                            loader={<BarLoader></BarLoader>}
                            className="card" style={{ minHeight: 'calc(100vh - 150px)' }}>
                            <div className="card-body">
                                <div className="d-flex  align-items-end mb-2">
                                    <div>
                                        <QuickSearchDate
                                            onSubmitFilter={() => TransacFilterStore.getFilteredTransacs()}
                                            className="btn btn-old btn-sm btn-sm-flex-h"
                                        ></QuickSearchDate>
                                    </div>
                                    <div className="d-flex ml-auto">
                                        <ColumnsToggle
                                            columns={transacTable.allColumns}
                                            className="btn btn-old btn-sm mx-1 btn-sm-flex-h"
                                            toggleColumn={(col) => TransacFilterStore.toggleColumn(col)}
                                        // columnsFromTable={CardsStore.props.columnsFromTable}
                                        >
                                        </ColumnsToggle>
                                        <TransacFilter
                                            onSubmitFilter={() => TransacFilterStore.getFilteredTransacs()}
                                            className="btn btn-old btn-sm  mx-1 btn-sm-flex-h"
                                            innerButton={
                                                <>
                                                    <FontAwesomeIcon icon={faFilter} className="mr-2"></FontAwesomeIcon>
                                            Recherche avancée
                                        </>
                                            }
                                        >

                                        </TransacFilter>
                                    </div>
                                </div>
                                <div className="d-flex  justify-content-end">
                                    {
                                        (UserStore.isFidSimple() || UserStore.isFidCoupons()) &&
                                        <>
                                            <span className="mx-2">Total crédit : <b>{number(TransacFilterStore.props.total_credit_pme1)} {' '} {UserStore.props.user.unitesPME1court}</b></span> 
                                            <span className="mx-2">Total débit : <b>{number(TransacFilterStore.props.total_debit_pme1)} {' '} {UserStore.props.user.unitesPME1court}</b></span> 
                                        </>

                                    }
                                    {
                                        (UserStore.isFidCoupons() || UserStore.isNovacash()) &&
                                        <>
                                            <span className="mx-2">Total crédit : <b>{number(TransacFilterStore.props.total_credit_pme2)} {' '} {UserStore.props.user.unitesPME2court}</b></span> 
                                            <span className="mx-2">Total débit :  <b>{number(TransacFilterStore.props.total_debit_pme2)} {' '} {UserStore.props.user.unitesPME2court}</b></span> 
                                            <span className="mx-2">Gain : <b>{number(TransacFilterStore.props.total_gain)} {' '} {UserStore.props.user.unitesPME2court}</b></span>
                                        </>
                                    }
                                </div>
                                <DataTable
                                    headerGroups={transacTable.headerGroups}
                                    getTableBodyProps={transacTable.getTableBodyProps}
                                    // onDoubleClickRow={(id) => TransacFilterStore.onDoubleClickRow(id)}
                                    rows={transacTable.rows}
                                    prepareRow={transacTable.prepareRow}
                                    getTableProps={transacTable.getTableProps}
                                    columns={TransacFilterStore.props.columns}
                                    loadingMsg={TransacFilterStore.props.loading.msg}
                                    loadingStatus={TransacFilterStore.props.loading.status}
                                    prevPage={() => TransacFilterStore.prevPage()}
                                    nextPage={() => TransacFilterStore.nextPage()}
                                    setSort={(sortBy) => TransacFilterStore.setSort(sortBy)}
                                    sortOrder={TransacFilterStore.props.sortOrder}
                                    sortBy={TransacFilterStore.props.sortBy}
                                    goToPage={(page) => TransacFilterStore.goToPage(page)}
                                    pageCount={TransacFilterStore.props.pageCount}
                                    pageIndex={TransacFilterStore.props.pageIndex}
                                    pageLimit={TransacFilterStore.props.pageLimit}
                                    pageTotal={TransacFilterStore.props.pageTotal}
                                    maxHeight="calc(100vh - 260px)"
                                    btnClassName="btn btn-outline-success btn-sm"
                                ></DataTable>
                            </div>
                        </BlockUi>
                    </div >
                </div>
            </div>
        </div >

    )
})

export default TransacView