import { observable } from 'mobx'
import { toast } from 'react-toastify'
import api from '../api'

const props = {

    currentRoute: '',
    loading: {
        status: false,
        msg: 'Chargement en cours ...'
    },
    couponsUrl : null
}

const CouponsStore = {
    /***** Props ******/
    props: props,

    /***** Methods ******/
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    async initCoupons() {
        this.reset()
        this.doLoading(true,'Chargement en cours ...')
        this.getAccount()
        this.doLoading(false)

    },
    async getAccount() {

        const res = await api.post('/oauth2/sso/couponing.php')
        if (res.data.status.status == "OK") {
            console.log(res.data.subaccount.url_whitelabel)
            this.props.couponsUrl = res.data.subaccount.url_whitelabel
            
        }else{
            toast.error('Une erreur est survenue')
        }
    },
    reset() {
        this.props = props
    }

}


export default observable(CouponsStore)