import React, { useState } from 'react'
import classnames from 'classnames'
import MyField from './MyField'
import { Collapse, Popover, PopoverBody, PopoverHeader, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faTrashAlt, faSave, faRecycle, faCheck, faTimes, faUndo, faShare, faFrown, faExclamation } from '@fortawesome/free-solid-svg-icons'
import SimpleBar from 'simplebar-react'
import ParticipantsFilterStore from '../stores/ParticipantsFilterStore'
import moment from 'moment'
import { Portal } from 'react-overlays'
import BlockUi from 'react-block-ui'
import { BarLoader } from '../components/Loader/Loading'


const ParticipantsFilter = observer((props) => {

    const {
        onSubmitFilter,
        innerButton,
        className
    } = props
    const pattern = ParticipantsFilterStore.props.pattern

    const toggleItemFilter = (key) => ParticipantsFilterStore.toggleItemFilter(key)
    const changeOperator = (key, val, index) => ParticipantsFilterStore.changeOperator(key, val, index)
    const changeValue = (key, val, index, order) => ParticipantsFilterStore.changeValue(key, val, index, order)
    const addItemFilter = (key, type) => ParticipantsFilterStore.addItemFilter(key, type)
    const removeItemFilter = (key, index) => ParticipantsFilterStore.removeItemFilter(key, index)
    const loadingStatus = ParticipantsFilterStore.props.loading.status
    const loadingMsg = ParticipantsFilterStore.props.loading.msg


    const resetFilter = () => {
        togglePopoverWithoutSave();
        ParticipantsFilterStore.resetFilter()
        onSubmitFilter()
    }

    const saveFilter = (name) => ParticipantsFilterStore.saveFilter(name)
    const undoPattern = () => ParticipantsFilterStore.undoPattern()
    const submitFilter = () => {
        ParticipantsFilterStore.submitFilter()
        onSubmitFilter()
    }

    const maxHeightFilter = "50vh"

    const [filterName, setFilterName] = useState('');
    const [errorFilterName, setErrorFilterName] = useState(null);


    const [openLoadModal, setOpenLoadModal] = useState(false);
    const [popoverOpen, setPopoverOpen] = useState(false);

    const doSaveFilter = () => {

        if (filterName != '') {
            saveFilter(filterName)
        }
        else {
            setErrorFilterName('Veuillez saisir le nom du filtre')
        }
    }


    const toggleLoadModal = () => setOpenLoadModal(!openLoadModal)

    const togglePopoverWithoutSave = () => {
        if (popoverOpen)
            undoPattern()
        setPopoverOpen(!popoverOpen)
    };

    const togglePopover = () => {
        setPopoverOpen(!popoverOpen)
    };

    return (
        <>
            <div>
                <button id="btn-filter" className={className}>
                    {innerButton}
                </button>

            </div>
            {
                popoverOpen &&
                <Portal container={document.body}>
                    <div class="modal-backdrop fade show"></div>
                </Portal>
            }
            <Popover popperClassName="popover-filter" placement="bottom"
                target="btn-filter" isOpen={popoverOpen} toggle={togglePopoverWithoutSave}>
                <BlockUi tag="div" blocking={loadingStatus} message={loadingMsg} loader={<BarLoader></BarLoader>}>
                    <PopoverHeader className="d-flex w-100 align-items-center justify-content-center border-bottom">
                        Filtrer par
                    <button onClick={() => { togglePopoverWithoutSave(); }} className="btn-sm btn btn-danger close-popover">
                            <FontAwesomeIcon icon={faTimes} className="mr-2"></FontAwesomeIcon>
                                    Fermer</button>
                    </PopoverHeader>
                    <PopoverBody className="p-0">
                        <SimpleBar style={{ maxHeight: maxHeightFilter }} className="p-3">
                            {Object.keys(pattern).map((key) => (
                                <>
                                    {!pattern[key].isHidden &&
                                        <div className="row px-3 mb-2">
                                            <div className="border border-grey rounded px-2 w-100">
                                                <div
                                                    className={classnames("form-check abc-checkbox abc-checkbox-fid-light checkbox-sm col-12 py-2 pointer", { 'border-bottom': pattern[key].active })}>
                                                    <input id={key} checked={pattern[key].active}
                                                        onClick={() => toggleItemFilter(key)} className="form-check-input d-none" type="checkbox" />
                                                    <label for={key} className="form-check-label d-flex w-100">
                                                        <b>{pattern[key].title}</b>
                                                    </label>
                                                </div>
                                                <Collapse isOpen={pattern[key].active} className="w-100">
                                                    <div className="py-2">


                                                        {
                                                            pattern[key].value.map((item, index) => (
                                                                <>
                                                                    {
                                                                        index != 0 &&
                                                                        <div className="row d-flex center-h">
                                                                            -- {item.logic == 'or' ? 'ou' : 'et'} --
                                                                        </div>
                                                                    }
                                                                    <div className="row mb-1">
                                                                        {
                                                                            ['text', 'select'].includes(pattern[item.name].field.type) &&

                                                                            <>
                                                                                <div className="col">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12 mb-2">
                                                                                            <MyField
                                                                                                name={item.operator}
                                                                                                type={pattern[item.name].operator.type}
                                                                                                list={pattern[item.name].operator.list}
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={(e) => changeOperator(key, e.target.value, index)}
                                                                                                value={item.operator}>
                                                                                            </MyField>
                                                                                        </div>
                                                                                        <div className="col-md-12 mb-2">
                                                                                            <MyField
                                                                                                name={item.name}
                                                                                                type={pattern[item.name].field.type}
                                                                                                list={pattern[item.name].field.list}
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={(e) => changeValue(key, e.target.value, index)}
                                                                                                value={item.value}>
                                                                                            </MyField>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>

                                                                        }

                                                                        {
                                                                            ['multiple'].includes(pattern[item.name].field.type) &&

                                                                            <>

                                                                                <MyField
                                                                                    name={item.name}
                                                                                    type={pattern[item.name].field.type}
                                                                                    list={pattern[item.name].field.list}
                                                                                    className="col-md-6"
                                                                                    onChange={(val) => changeValue(key, val, index)}
                                                                                    value={item.value}>
                                                                                </MyField>

                                                                            </>

                                                                        }
                                                                        {
                                                                            ['number'].includes(pattern[item.name].field.type) &&

                                                                            <>
                                                                                <div className="col">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12 d-flex align-items-end  mb-2">
                                                                                            <MyField
                                                                                                name={item.operator}
                                                                                                type={pattern[item.name].operator.type}
                                                                                                list={pattern[item.name].operator.list}
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={(e) => changeOperator(key, e.target.value, index)}
                                                                                                value={item.operator}>
                                                                                            </MyField>
                                                                                        </div>
                                                                                        {
                                                                                            ['between'].includes(item.operator) &&
                                                                                            <>
                                                                                                <div className="col-md-6 d-flex flex-column">
                                                                                                    <label className="font-sm">Entre </label>
                                                                                                    <MyField
                                                                                                        name={item.name}
                                                                                                        type={pattern[item.name].field.type}
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={(e) => changeValue(key, e.target.value, index, 0)}
                                                                                                        value={item.value[0]}>
                                                                                                    </MyField>
                                                                                                </div>
                                                                                                <div className="col-md-6 d-flex flex-column">
                                                                                                    <label className="font-sm">et </label>
                                                                                                    <MyField
                                                                                                        name={item.name}
                                                                                                        type={pattern[item.name].field.type}
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={(e) => changeValue(key, e.target.value, index, 1)}
                                                                                                        value={item.value[1]}>
                                                                                                    </MyField>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            !['between'].includes(item.operator) &&
                                                                                            <>
                                                                                                <div className="col-md-12">
                                                                                                    <MyField
                                                                                                        name={item.name}
                                                                                                        type={pattern[item.name].field.type}
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={(e) => changeValue(key, e.target.value, index)}
                                                                                                        value={item.value}>
                                                                                                    </MyField>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {
                                                                            ['date'].includes(pattern[item.name].field.type) &&

                                                                            <>
                                                                                <div className="col">
                                                                                    <div className="row">
                                                                                        <div className="col-md-12 d-flex align-items-end  mb-2">
                                                                                            <MyField
                                                                                                name={item.operator}
                                                                                                type={pattern[item.name].operator.type}
                                                                                                list={pattern[item.name].operator.list}
                                                                                                className="form-control form-control-sm"
                                                                                                onChange={(e) => changeOperator(key, e.target.value, index)}
                                                                                                value={item.operator}>
                                                                                            </MyField>
                                                                                        </div>
                                                                                        {
                                                                                            ['between'].includes(item.operator) &&
                                                                                            <>
                                                                                                <div className="col-md-6 d-flex flex-column">
                                                                                                    <label className="font-sm">Entre le :</label>
                                                                                                    <MyField
                                                                                                        name={item.name}
                                                                                                        type={pattern[item.name].field.type}
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={(date) => changeValue(key, date, index, 0)}
                                                                                                        value={item.value[0]}>
                                                                                                    </MyField>
                                                                                                </div>
                                                                                                <div className="col-md-6 d-flex flex-column">
                                                                                                    <label className="font-sm">et le :</label>
                                                                                                    <MyField
                                                                                                        name={item.name}
                                                                                                        type={pattern[item.name].field.type}
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={(date) => changeValue(key, date, index, 1)}
                                                                                                        value={item.value[1]}>
                                                                                                    </MyField>
                                                                                                </div>
                                                                                            </>
                                                                                        }
                                                                                        {
                                                                                            !['between'].includes(item.operator) &&
                                                                                            <>
                                                                                                <div className="col-md-12">
                                                                                                    <MyField
                                                                                                        name={item.name}
                                                                                                        type={pattern[item.name].field.type}
                                                                                                        className="form-control form-control-sm"
                                                                                                        onChange={(date) => changeValue(key, date, index)}
                                                                                                        value={item.value}>
                                                                                                    </MyField>
                                                                                                </div>
                                                                                            </>
                                                                                        }

                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        {
                                                                            !['multiple'].includes(pattern[item.name].field.type) &&
                                                                            index != 0 &&
                                                                            <div className="col-auto d-flex align-items-center">
                                                                                <button
                                                                                    onClick={() => removeItemFilter(key, index)}
                                                                                    className="btn btn-block btn-sm btn-danger">
                                                                                    <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                                                                                </button>
                                                                            </div>
                                                                        }

                                                                    </div>
                                                                </>
                                                            ))
                                                        }

                                                        {
                                                            (pattern[key].canAddOr ||
                                                                pattern[key].canAddAnd) &&
                                                            <div className="row mt-3 mb-1">
                                                                {pattern[key].canAddAnd &&
                                                                    <div className="col">
                                                                        <button onClick={() => addItemFilter(key, 'and')} className="btn btn-block btn-outline-old-light btn-sm btn-old">Ajouter un "ET"</button>
                                                                    </div>}
                                                                {pattern[key].canAddOr &&
                                                                    <div className="col">
                                                                        <button onClick={() => addItemFilter(key, 'or')} className="btn btn-block btn-outline-old-light btn-sm btn-old">Ajouter un "OU"</button>
                                                                    </div>}
                                                            </div>
                                                        }
                                                    </div>
                                                </Collapse>
                                            </div>
                                        </div>
                                    }
                                </>

                            ))}
                        </SimpleBar>
                    </PopoverBody>
                    <div className="border-top bg-popover rounded-bottom p-2">
                        <div className="row mb-1">
                            <div className="col-md-6 mb-2">
                                <button onClick={() => { resetFilter() }} className="btn-sm btn btn-old btn-block">
                                    <FontAwesomeIcon icon={faUndo} className="mr-2"></FontAwesomeIcon>
                                    Rénitialiser</button>
                            </div>
                            <div className="col-md-6">
                                <button onClick={() => { submitFilter(); togglePopover(); }} className="btn-sm btn btn-old btn-block">
                                    <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                                    Appliquer</button>
                            </div>
                        </div>
                    </div>

                </BlockUi>
            </Popover>
        </>
    )
})

export const QuickSearch = observer((props) => {

    const {
        onSubmitFilter,
        className
    } = props



    const activateItemFilter = (key) => ParticipantsFilterStore.activateItemFilter(key)
    const changeOperator = (key, val, index) => ParticipantsFilterStore.changeOperator(key, val, index)
    const changeValue = (key, val, index, order) => ParticipantsFilterStore.changeValue(key, val, index, order)

    const resetFilter = () => ParticipantsFilterStore.resetFilter()




    const pattern = ParticipantsFilterStore.getQuickSerachItems()
    const [typeSearch, setTypeSearch] = useState(pattern[0].id)
    const [searchValue, setSearchValue] = useState('')

    const doQuickSearch = () => {
        resetFilter()
        activateItemFilter(typeSearch)
        changeOperator(typeSearch, 'like', 0)
        changeValue(typeSearch, searchValue, 0)
        onSubmitFilter()
    }

    return (
        <div className="d-flex">
            <label className="text-nowrap mb-0">Rechercher par</label>
            <MyField name="checkboxCoupon"
                type="select"
                className="form-control form-control-sm  mx-2"
                onChange={(e) => setTypeSearch(e.target.value)}
                list={pattern}
                value={typeSearch}
            >
            </MyField>
            <label className="text-nowrap mb-0">Valeur</label>
            <MyField name="search"
                type="text"
                className="form-control form-control-sm  mx-2"
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                        doQuickSearch()
                    }
                }}
            >
            </MyField>
            <button className={className} onClick={() => doQuickSearch()}>Rechercher</button>
        </div>
    )
})

export default ParticipantsFilter