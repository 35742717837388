import React, { useEffect, useRef, useState } from 'react'
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTicketAlt, faFilter, faUsers, faEnvelope, faArrowLeft, faArrowRight, faPencilAlt, faDesktop, faMobileAlt, faPlusCircle, faTrashAlt, faSave, faRecycle, faCheck, faTimes, faCoins, faSearch, faCaretDown, faPlus, faFile, faCopy, faEdit, faInfoCircle, faExclamationCircle, faPaperPlane, faCalendarAlt, faSyncAlt, faCheckCircle, faBook, faWindowMaximize } from '@fortawesome/free-solid-svg-icons'
import classnames from 'classnames'
import EmailEditor from 'react-email-editor'
import BlockUi from 'react-block-ui'
import { BarLoader } from '../Loader/Loading'
import CampagneWizardStore from '../../stores/CampagneWizardStore'
import { Field, useFormik } from 'formik'
import MyField from '../../reusable/MyField'
import Switch from "react-switch";
import { toJS } from 'mobx'
import UserStore from '../../stores/UserStore'
import ClientFilterStore from '../../stores/ClientFilterStore'
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap'
import { DropdownToggle, DropdownItem, DropdownMenu, Dropdown } from 'reactstrap'
import { Nav, NavItem, NavLink, TabPane, TabContent } from 'reactstrap'
import { useTable, useRowSelect } from 'react-table'
import DataTable, { DropdownPortal, TemplateSet } from '../../reusable/DataTable'
import ClientFilter from '../../reusable/ClientFilter'
import { number, currency } from '../../utils/numeral'
import pluralize from 'pluralize'
import { Portal } from 'react-overlays';
import { editorOptions } from '../../utils/utils';
import SimpleBar from 'simplebar-react';
import { MentionsInput, Mention } from 'react-mentions'
import myYup from '../../utils/myYup';
import { modeEnvoiDetails, frequenceEnvoi, modeEnvoiLabel } from '../../utils/constants'
import { getClassOfCardTpye } from '../../utils/utils'
import paymentMode from '../../assets/img/payment_mode.png'
import AnimateHeight from 'react-animate-height';
import moment from 'moment-timezone';
import { count as SmsCounter } from 'sms-counter-npm'
import insertTextAtCursor from 'insert-text-at-cursor';
import { withRouter, Prompt } from 'react-router-dom'
import { Link } from 'react-router-dom/cjs/react-router-dom';
import CKEditor from 'ckeditor4-react';
import confirmation from '../Alerts/Confirmation';


export const EditModeleDropDown = observer((props) => {
    const {
        id,
        json,
        contenu,
        setPreview,
        editModele,
        useModele,
        modeEnvoi,
        deleteModele
    } = props

    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => {
        setDropdownOpen(dropdownOpen => !dropdownOpen);
    }

    const rootSelector = document.getElementById('root');
    const div = document.createElement("div")
    div.className = "h-0"

    let portalContainer = useRef(div).current

    useEffect(() => {
        // let portalContainer = document.createElement("div");
        rootSelector.appendChild(portalContainer)
        return () => {
            rootSelector.removeChild(portalContainer)
        }
    }, [])

    return (

        <Dropdown isOpen={dropdownOpen} toggle={toggle} portalContainer={portalContainer}>
            <DropdownToggle
                tag="button"
                className="btn btn-xs btn-outline-info"
                data-toggle="dropdown"
                aria-expanded={dropdownOpen}
            > Actions
                <FontAwesomeIcon className="ml-2" icon={faCaretDown}></FontAwesomeIcon>
            </DropdownToggle>
            {/* <Portal target={portalContainer}> */}
            <DropdownMenu className="p-0">
                {/* <DropdownItem onClick={() => setPreview(json, contenu)}>
                    <FontAwesomeIcon icon={faSearch} className="mr-2 text-info"></FontAwesomeIcon>
                    Aperçu</DropdownItem> */}
                {modeEnvoi == 'email' && <DropdownItem onClick={() => editModele(json, contenu)}>
                    <FontAwesomeIcon icon={faCopy} className="mr-2 text-old"></FontAwesomeIcon>
                    Créer un modèle à partir de celui-ci</DropdownItem>}
                <DropdownItem onClick={() => useModele(json, contenu)}>
                    <FontAwesomeIcon icon={faCheck} className="mr-2 text-success"></FontAwesomeIcon>
                    Utiliser ce modèle</DropdownItem>
                <DropdownItem onClick={(json) => deleteModele(id)}>
                    <FontAwesomeIcon icon={faTrashAlt} className="mr-2 text-danger"></FontAwesomeIcon>
                    Supprimer le modèle</DropdownItem>
            </DropdownMenu>
            {/* </Portal> */}
        </Dropdown >


    )
})

const ProgressBar = observer((props) => {
    const {
        action,
        currStep,
        titles,
        prevStep,
        nextStep,
        validateForm,
        submitCampagne,
        isLoading,
        success
    } = props


    const next = () => {
        validateForm().then((res) => {
            console.log('validate', res)
            if (!Object.keys(res).length)
                nextStep()
        })
    }


    return (
        <div className="d-flex progress-container w-10 pt-3 pb-2 px-3 border-bottom">

            <div className="col-2">
                {
                    currStep > 0 && !success &&
                    <button type="button" onClick={prevStep} class="btn btn-old float-left" disabled={isLoading}>
                        {
                            isLoading &&
                            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        }
                        {
                            !isLoading &&
                            <FontAwesomeIcon icon={faArrowLeft} className="mr-2"></FontAwesomeIcon>
                        }
                        Précédent
                    </button>
                }
            </div>

            <div className="col">
                <ul id="progressbar">
                    {
                        titles.map(
                            (item, index) => <li className={classnames({ 'passed': currStep > index, 'active': currStep == index })} style={{ width: (100 / titles.length) + '%' }}>
                                <div class="progress-item-circle">
                                    {/* {item.icon} */}
                                </div>
                                <strong>{item.title}</strong>
                            </li>
                        )
                    }
                </ul>
            </div>
            <div className="col-2">
                {
                    currStep < (titles.length - 1) && !success &&
                    <button type="button" onClick={() => next()} class="btn btn-old float-right" disabled={isLoading}>
                        Suivant
                        {
                            isLoading &&
                            <span class="spinner-border spinner-border-sm ml-2" role="status" aria-hidden="true"></span>
                        }
                        {
                            !isLoading &&
                            <FontAwesomeIcon icon={faArrowRight} className="ml-2"></FontAwesomeIcon>
                        }
                    </button>
                }
                {
                    currStep == (titles.length - 1) && action == 'new' && !success &&
                    <button type="button" onClick={() => submitCampagne()} class="btn btn-success float-right" disabled={isLoading}>
                        {
                            isLoading &&
                            <span class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        }
                        {
                            !isLoading &&
                            <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                        }
                        Lancer la campagne
                    </button>
                }
                {
                    currStep == (titles.length - 1) && action == 'edit' && !success &&
                    <button type="button" onClick={() => submitCampagne()} class="btn btn-success float-right">
                        <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                        Mettre à jour la campagne
                    </button>
                }
            </div>
        </div>

    )
})

const StepConception = observer((props) => {

    const {
        toggleModalBuilder,
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        editorOptions,
        setMsg,
        eraseModele,

        loadingMsgModele,
        loadingStatusModele,
        smsCount,
        toggleModalLoad,
        toggleModalSMS
    } = props

    return (
        <>


            <div className="animated fadeIn">

                {
                    errors['message'] && values['modeEnvoi'] == 'email' &&
                    <div className="row mb-1">
                        <div className="col">
                            <Alert color="danger" isOpen={true}>
                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2"></FontAwesomeIcon>
                                Le contenu de votre Email est vide. Veuillez éditer un Email.
                            </Alert>
                        </div>
                    </div>

                }
                {
                    errors['message'] && values['modeEnvoi'] == 'sms' &&
                    <div className="row mb-1">
                        <div className="col">
                            <Alert color="danger" isOpen={true}>
                                <FontAwesomeIcon icon={faExclamationCircle} className="mr-2"></FontAwesomeIcon>
                                Le contenu de votre SMS est vide. Veuillez éditer un SMS.
                                </Alert>
                        </div>
                    </div>

                }

                <div className="row ">
                    <div className="col-md-4">
                        <div className="row">
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Titre de la campagne</label>
                                    <MyField name="titre" type="text"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values['titre']}
                                        error={errors['titre']}
                                        touched={touched['titre']}>
                                    </MyField>
                                </div>
                            </div>

                            {values['modeEnvoi'] == 'email' &&
                                <div className="col-12">
                                    <div className="form-group">
                                        <label>Objet de l'email</label>
                                        <MyField name="objet" type="text"
                                            className="form-control"
                                            onChange={handleChange}
                                            value={values['objet']}
                                            error={errors['objet']}
                                            touched={touched['objet']}>
                                        </MyField>
                                    </div>
                                </div>
                            }
                        </div>
                        {values['modeEnvoi'] == 'email' &&
                            <div className="row">
                                <div className="col-12">
                                    <div className="form-group">
                                        <MyField name="saveModele"
                                            type="checkbox"
                                            checkboxLabel="Sauvegarder ce modèle pour une utilisation ultérieure"
                                            className="abc-checkbox-fid"
                                            customOnChange={setFieldValue}
                                            value={values['saveModele']}
                                            error={errors['saveModele']}
                                            touched={touched['saveModele']}>
                                        </MyField>
                                    </div>
                                </div>
                            </div>
                        }


                        {
                            values['modeEnvoi'] == 'email' &&
                            <div className="row mt-5 mb-4 ">
                                {
                                    !values['message'] &&
                                    <>
                                        <div className="col-md-6">
                                            <button onClick={() => toggleModalLoad()} className="btn-block btn btn-old card d-flex center-h center-v py-3">
                                                <FontAwesomeIcon size={"2x"} icon={faBook}></FontAwesomeIcon>
                                                <span>Bibliothèque de modèles</span>
                                            </button>
                                        </div>
                                        <div className="col-md-6">
                                            <button onClick={() => toggleModalBuilder(2)} className="btn-block btn btn-old card d-flex center-h center-v py-3">
                                                <FontAwesomeIcon size={"2x"} icon={faMobileAlt}></FontAwesomeIcon>
                                                <span>Editeur responsive</span>
                                            </button>
                                        </div>
                                        <div className="col-md-6">
                                            <button onClick={() => toggleModalBuilder(1)} className="btn-block btn btn-old card d-flex center-h center-v py-3">
                                                <FontAwesomeIcon size={"2x"} icon={faWindowMaximize}></FontAwesomeIcon>
                                                <span>Editeur HTML WYSIWYG</span>
                                            </button>
                                        </div>
                                    </>
                                }
                            </div>

                        }
                        {/* {
                            values['modeEnvoi'] == 'sms' &&
                            <div className="row">
                                <div className="col-12 mb-5">
                                    <button type="button" onClick={() => toggleModalLoad()} class="btn-block btn btn-old float-right">
                                        <FontAwesomeIcon icon={faFile} className="mr-2"></FontAwesomeIcon>
                                                Utiliser un modèle
                                    </button>
                                </div>
                            </div>
                        } */}



                    </div>

                    <div className="col-md-8" style={{ minHeight: '80vh' }}>

                        {
                            values['modeEnvoi'] == 'email' &&
                            <PreviewEmail
                                message={values['message']}
                                loadingMsgModele={loadingMsgModele}
                                loadingStatusModele={loadingStatusModele}
                                toggleModalBuilder={toggleModalBuilder}
                                eraseModele={eraseModele}
                                showEditButtons={true}
                            ></PreviewEmail>
                        }
                        {
                            values['modeEnvoi'] == 'sms' &&
                            <SMSAreaNormal
                                message={values['message']}
                                smsCount={smsCount}
                                setMsg={setMsg}
                                setFieldValue={setFieldValue}
                                loadingMsgModele={loadingMsgModele}
                                loadingStatusModele={loadingStatusModele}
                                toggleModalSMS={toggleModalSMS}
                                editorOptions={editorOptions}
                            ></SMSAreaNormal>
                        }


                    </div >
                </div>
            </div >



        </>
    )
})

const PreviewEmail = observer((props) => {

    const {
        message,
        loadingMsgModele,
        loadingStatusModele,
        showBuilder,
        toggleModalBuilder,
        eraseModele,
        showEditButtons
    } = props

    const frame_mobile = useRef(null)
    const frame_desktop = useRef(null)


    const [isMobile, setIsMobile] = useState(false)




    useEffect(() => {
        console.log('changeMob')
        if (frame_mobile.current) {
            let iframeDoc = frame_mobile.current.contentWindow ?
                frame_mobile.current.contentWindow :
                frame_mobile.current.contentDocument.document ?
                    frame_mobile.current.contentDocument.document :
                    frame_mobile.current.contentDocument;

            iframeDoc.document.open();
            iframeDoc.document.write(
                message
            );
            iframeDoc.document.close();
        }
        if (frame_desktop.current) {
            let iframeDoc = frame_desktop.current.contentWindow ?
                frame_desktop.current.contentWindow :
                frame_desktop.current.contentDocument.document ?
                    frame_desktop.current.contentDocument.document :
                    frame_desktop.current.contentDocument;

            iframeDoc.document.open();
            iframeDoc.document.write(
                message
            );
            iframeDoc.document.close();
        }
    }, [message, isMobile, showBuilder])

    return (
        <>
            <h4 className="text-center">Aperçu</h4>
            <div className="row">
                <div className="col-12">
                    <div className="d-flex justify-content-center mb-2">
                        <div className="px-3">
                            <FontAwesomeIcon icon={faDesktop} className="text-old" size="2x"></FontAwesomeIcon>
                        </div>
                        <div >
                            <Switch
                                checked={isMobile}
                                onChange={() => setIsMobile(!isMobile)}
                                onColor="#fd7e13"
                                onHandleColor="#ebedef"
                                offColor="#fd7e13"
                                offHandleColor="#ebedef"
                                uncheckedIcon={false}
                                checkedIcon={false}
                                handleDiameter={20}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
                                activeBoxShadow="0px 0px 1px 5px rgba(0, 0, 0, 0.2)"
                                height={16}
                                width={30}
                                className="react-switch"
                            />
                        </div>
                        <div className="px-3">
                            <FontAwesomeIcon icon={faMobileAlt} className="text-old" size="2x"></FontAwesomeIcon>
                        </div>
                    </div>
                </div>
                {message && showEditButtons &&
                    <div className="col-12 d-flex center-h  mb-2">
                        <button onClick={() => toggleModalBuilder()} className="btn btn-sm btn-old mx-1">Modifier</button>
                        <button onClick={() => eraseModele()} className="btn btn-sm btn-danger mx-1">Supprimer</button>
                    </div>
                }
            </div>
            {
                isMobile &&
                <div className="row center-h">
                    <div className="mobile-content">
                        <div className="mobile-preview-samsung">
                            <div class="mobile-wrapper">
                                <BlockUi tag="div" style={{ height: '100%' }} blocking={loadingStatusModele}
                                    message={loadingMsgModele}
                                    loader={<BarLoader></BarLoader>}>
                                    {
                                        !message.length &&
                                        <div className="no-preview h2 text-center">
                                            Aucun contenu
                                        </div>
                                    }
                                    <iframe onL ref={frame_mobile}>

                                    </iframe>

                                </BlockUi>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                !isMobile &&
                <div className="d-flex center-h">
                    <div className="row col-md-12">
                        <div className="desktop-header rounded-top col-12"></div>
                        <div class="desktop-wrapper col-12 rounded-bottom">
                            <BlockUi tag="div" style={{ height: '100%' }} blocking={loadingStatusModele}
                                message={loadingMsgModele}
                                loader={<BarLoader></BarLoader>}>
                                {
                                    !message.length &&
                                    <div className="no-preview h2 text-center">
                                        Aucun contenu
                                        </div>
                                }
                                <iframe ref={frame_desktop}>

                                </iframe>

                            </BlockUi>
                        </div>
                    </div>

                </div>
            }
        </>
    )
})

const PreviewSMS = observer((props) => {
    const {
        message,
        loadingMsgModele,
        loadingStatusModele,
        smsCount
    } = props

    return (
        <>
            <h4 className="text-center">Aperçu</h4>
            <div className="sms row center-h">
                <div className="mobile-content">
                    <div className="mobile-preview-samsung">
                        <div class="mobile-wrapper">
                            <SimpleBar style={{ height: '100%', padding: '0px 20px' }}>
                                <BlockUi tag="div" style={{ height: '100%' }} blocking={loadingStatusModele}
                                    message={loadingMsgModele}
                                    loader={<BarLoader></BarLoader>}>
                                    {
                                        !message.length &&
                                        <div className="no-preview h2 text-center">
                                            Aucun contenu
                                        </div>
                                    }
                                    {
                                        message.length > 0 &&
                                        <p className="preview-sms-area">{message}</p>
                                    }
                                </BlockUi>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

const StepCoupons = observer((props) => {

    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        listTypeCoupon
    } = props


    const onChangeTypeCoupon = (name, value) => {
        setFieldValue('reqPointsEcoupons', '')
        setFieldValue('validPointsEcoupons', '')
        setFieldValue('reqEUREcoupons', '')
        setFieldValue('validEUREcoupons', '')
        console.log('typeCoupn change')
        setFieldValue(name, value)
    }

    const onChangeCheckboxCoupon = (e) => {
        setFieldValue('MotifEcoupons1', '')
        handleChange(e)
    }


    return (
        <>
            <div className="row  animated fadeIn">
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <div className="form-group">
                                <label>Offrir un coupon ?</label>
                                <MyField name="checkboxCoupon"
                                    type="select"
                                    className="form-control"
                                    onChange={onChangeCheckboxCoupon}
                                    value={values['checkboxCoupon']}
                                    error={errors['checkboxCoupon']}
                                    list={[
                                        { id: "Ne pas offrir d'e-coupon", label: "Ne pas offrir d'e-coupon" },
                                        { id: "Offrir un coupon", label: "Offrir un coupon" }
                                    ]}
                                    touched={touched['checkboxCoupon']}>
                                </MyField>
                            </div>
                        </div>
                        <AnimateHeight
                            duration={500}
                            className="col-12 px-0"
                            height={values['checkboxCoupon'] == 'Offrir un coupon' ? 'auto' : 0}
                        >
                            <div className={classnames('col-12', { 'd-none': values['checkboxCoupon'] != 'Offrir un coupon' })}>
                                <div className="form-group">
                                    <label>Motif / Libellé</label>
                                    <MyField name="MotifEcoupons1" type="text"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values['MotifEcoupons1']}
                                        error={errors['MotifEcoupons1']}
                                        touched={touched['MotifEcoupons1']}>
                                    </MyField>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-group">
                                    <label>Type de coupon</label>
                                    {/* <MyField
                                        name="typeCoupon"
                                        type="select"
                                        className="form-control"
                                        onChange={onChangeTypeCoupon}
                                        list={[{ id: unite1, label: unite1 }, { id: unite2, label: unite2 }]}
                                        value={values['typeCoupon']}
                                        error={errors['typeCoupon']}
                                        touched={touched['typeCoupon']}>
                                    </MyField> */}
                                    <MyField
                                        name="typeCoupon"
                                        type="reactSelect"
                                        className="form-control"
                                        customOnChange={onChangeTypeCoupon}
                                        list={listTypeCoupon}
                                        value={values['typeCoupon']}
                                        error={errors['typeCoupon']}
                                        touched={touched['typeCoupon']}>
                                    </MyField>
                                </div>
                            </div>
                        </AnimateHeight>
                        <AnimateHeight
                            duration={500}
                            className="col-12 px-0"
                            height={values['typeCoupon'].id == 'pme1' ? 'auto' : 0}
                        >
                            <div className={classnames('col-12', { 'd-none': values['checkboxCoupon'] != 'Offrir un coupon' })}>
                                <div className="form-group">
                                    <label>Valeur en {values['typeCoupon'].label} du coupon</label>
                                    <MyField name="reqPointsEcoupons" type="number"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values['reqPointsEcoupons']}
                                        error={errors['reqPointsEcoupons']}
                                        touched={touched['reqPointsEcoupons']}>
                                    </MyField>
                                </div>
                            </div>
                            <div className={classnames('col-12', { 'd-none': values['checkboxCoupon'] != 'Offrir un coupon' })}>
                                <div className="form-group">
                                    <label>Nombre de jours avant expiration du coupon {values['typeCoupon'].label} :</label>
                                    <MyField name="validPointsEcoupons" type="number"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values['validPointsEcoupons']}
                                        error={errors['validPointsEcoupons']}
                                        touched={touched['validPointsEcoupons']}>
                                    </MyField>
                                </div>
                            </div>
                        </AnimateHeight>
                        <AnimateHeight
                            duration={500}
                            className="col-12 px-0"
                            height={values['typeCoupon'].id == 'pme2' ? 'auto' : 0}
                        >
                            <div className={classnames('col-12', { 'd-none': values['checkboxCoupon'] != 'Offrir un coupon' })}>
                                <div className="form-group">
                                    <label>Valeur en {values['typeCoupon'].label} du coupon</label>
                                    <MyField name="reqEUREcoupons" type="number"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values['reqEUREcoupons']}
                                        error={errors['reqEUREcoupons']}
                                        touched={touched['reqEUREcoupons']}>
                                    </MyField>
                                </div>
                            </div>
                            <div className={classnames('col-12', { 'd-none': values['checkboxCoupon'] != 'Offrir un coupon' })}>
                                <div className="form-group">
                                    <label>Nombre de jours avant expiration du coupon {values['typeCoupon'].label} :</label>
                                    <MyField name="validEUREcoupons" type="number"
                                        className="form-control"
                                        onChange={handleChange}
                                        value={values['validEUREcoupons']}
                                        error={errors['validEUREcoupons']}
                                        touched={touched['validEUREcoupons']}>
                                    </MyField>
                                </div>
                            </div>
                        </AnimateHeight>
                    </div>
                </div>
                <div className="col-md-6 d-flex center-h">
                    <div className="ticket">
                        <div class="ticket__content pt-2 px-3">
                            {
                                values['checkboxCoupon'] == "Ne pas offrir d'e-coupon" &&
                                <div className="w-100 h-100 d-flex center-v center-h">
                                    <h2>Pas de coupon</h2>
                                </div>

                            }
                            {
                                values['checkboxCoupon'] == 'Offrir un coupon' &&
                                <>
                                    <h2>Coupon</h2>
                                    <table className="table">
                                        <tbody>
                                            <tr>
                                                <td colspan="2" className="ticket-value border-top-0 py-5 px-0 text-wrap">
                                                    {values['MotifEcoupons1']}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="ticket-label d-flex center-v px-0">Montant</td>
                                                <td className="ticket-value d-flex center-v border-top-0 px-0">{
                                                    values['typeCoupon'].id == 'pme1' ?
                                                        number(values['reqPointsEcoupons']) + ' ' + values['typeCoupon'].label :
                                                        currency(values['reqEUREcoupons']) + ' ' + values['typeCoupon'].label
                                                }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td className="ticket-label d-flex center-v px-0">Validité</td>
                                                <td className="ticket-value d-flex center-v border-top-0 px-0">
                                                    {
                                                        values['typeCoupon'].id == 'pme1' ?
                                                            number(values['validPointsEcoupons']) + ' ' +
                                                            pluralize('jour', values['validPointsEcoupons'])
                                                            :
                                                            number(values['validEUREcoupons']) + ' ' +
                                                            pluralize('jour', values['validEUREcoupons'])
                                                    }
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})

const StepDest = observer((props) => {

    const {
        toggleModalSave,
        onSubmitFilter,
        toggleModalPayment,
        headerGroups,
        getTableBodyProps,
        countActiveFilter,
        loadedFilter,
        exclues,
        rows,
        prepareRow,
        getTableProps,
        columns,
        loadingMsg,
        loadingStatus,
        prevPage,
        nextPage,
        setSort,
        sortOrder,
        sortBy,
        goToPage,
        pageCount,
        pageIndex,
        pageLimit,
        pageTotal,
        maxHeight,
        modeEnvoi,
        credits,
        totalDestinataires,
        creditsToUse,
        creditsActuels,
        creditsAfter,
        nbSmsToSend,
        oneSmsLength,
        getFilterdClients

    } = props

    useEffect(() => {
        onSubmitFilter()
    }, [])


    return (
        <>
            <div className="row">
                <div className="col-md-4">
                    <div className="border rounded" style={{ minHeight: '150px' }}>
                        <div className="card-body">
                            <div className="row  mb-3">
                                <div className="col-auto">
                                    <span className="h4">
                                        <FontAwesomeIcon className="mr-2 text-fid-light" icon={faFilter}></FontAwesomeIcon>
                                        Filtre
                                    </span>
                                </div>
                                <div className="col d-flex justify-content-end center-v">
                                    <ClientFilter
                                        onSubmitFilter={onSubmitFilter}
                                        className="btn-sm btn btn-old btn-dest-card"
                                        innerButton={
                                            <>
                                                Modifier filtre
                                        </>
                                        }
                                    ></ClientFilter>
                                </div>
                            </div>
                            {
                                loadedFilter && <div className="row">
                                    <div className="col-md-12">
                                        Filtre chargé : {' '}
                                        <b>{loadedFilter}</b>
                                    </div>

                                </div>
                            }
                            <div className="row">
                                <div className="col-md-12">
                                    <b>{countActiveFilter}</b>
                                    {' '}
                                    {pluralize('filtre', countActiveFilter)}
                                    {' '}
                                    {pluralize('actif', countActiveFilter)}
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <b>{exclues.length}</b>
                                    {' '}
                                    {pluralize('clients', exclues.length)}
                                    {' '}
                                    {pluralize('exlcu', exclues.length)}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-4">
                    <div className="border rounded" style={{ minHeight: '150px' }}>
                        <div className="card-body">
                            <div className="row  mb-3">
                                <div className="col-auto">
                                    <span className="h4">
                                        <FontAwesomeIcon className="mr-2 text-fid-light" icon={faUsers}></FontAwesomeIcon>
                                        Destinataires
                                    </span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col">
                                    <b>{number(totalDestinataires)}</b>
                                    {' '}

                                    {pluralize('destinataire', totalDestinataires)}
                                </div>

                            </div>
                            {
                                modeEnvoi == 'sms' &&
                                <div className="row">
                                    <div className="col">
                                        <b>{number(oneSmsLength)}</b>
                                        {' '}
                                    SMS
                                    {' '}
                                    à envoyer par destinataire
                                </div>

                                </div>
                            }
                            <div className="row">
                                <div className="col">
                                    <b>{number(nbSmsToSend)}</b>
                                    {' '}
                                    {pluralize(modeEnvoiDetails[modeEnvoi].label, nbSmsToSend)}
                                    {' '}
                                    à envoyer
                                </div>

                            </div>

                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="border rounded" style={{ minHeight: '150px' }}>
                        <div className="card-body">
                            <div className="row mb-3">
                                <div className="col-auto">
                                    <span className="h4">
                                        <FontAwesomeIcon className="mr-2 text-fid-light" icon={faCoins}></FontAwesomeIcon>
                                        Solde {modeEnvoiDetails[modeEnvoi].label}
                                    </span>
                                </div>
                                <div className="col d-flex justify-content-end center-v">
                                    <button onClick={() => toggleModalPayment()} className="btn btn-old btn-sm btn-dest-card">Ajouter des crédits</button>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    Actuel
                                </div>
                                <div className="col">
                                    <b>{number(creditsActuels)}</b>
                                    {' '}
                                    {pluralize(modeEnvoiDetails[modeEnvoi].label, pageTotal)}
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    Après opération
                                </div>
                                <div className="col">
                                    <span className={classnames('font-weight-bold', { 'text-danger': creditsAfter <= 0, 'text-success': creditsAfter > 0 })}>
                                        {number(creditsAfter)}
                                    </span> {' '}{pluralize(modeEnvoiDetails[modeEnvoi].label, pageTotal)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col">
                    <span className="h4">Liste des destinataires : </span>
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <DataTable
                        headerGroups={headerGroups}
                        getTableBodyProps={getTableBodyProps}
                        rows={rows}
                        prepareRow={prepareRow}
                        getTableProps={getTableProps}
                        columns={columns}
                        loadingMsg={loadingMsg}
                        loadingStatus={loadingStatus}
                        prevPage={prevPage}
                        nextPage={nextPage}
                        setSort={setSort}
                        sortOrder={sortOrder}
                        sortBy={sortBy}
                        goToPage={goToPage}
                        pageCount={pageCount}
                        pageIndex={pageIndex}
                        pageLimit={pageLimit}
                        pageTotal={pageTotal}
                        maxHeight={maxHeight}
                        btnClassName="btn btn-outline-fid btn-sm"
                    ></DataTable>
                </div>

            </div>



        </>
    )
})

const StepParametres = observer((props) => {
    const {
        values,
        errors,
        touched,
        handleChange,
        setFieldValue,
        groupOptionsTimezones,
        frequenceEnvoi
    } = props


    const onChangeTypeEnvoi = (typeEnvoi) => {
        setFieldValue('dateEnvoi', moment().format('YYYY-MM-DD'))
        // setFieldValue('programmerHeure', '10:00')
        // setFieldValue('timezone', null)
        if (typeEnvoi == "Immédiat") {
            setFieldValue('typeEnvoi', typeEnvoi)
            setFieldValue('envoiRecurr', { id: "Non", label: "Aucune" })
        }
        if (typeEnvoi == "Programmé") {
            setFieldValue('typeEnvoi', typeEnvoi)
            setFieldValue('envoiRecurr', { id: "Non", label: "Aucune" })
        }
        if (typeEnvoi == "Récurrent") {
            setFieldValue('typeEnvoi', typeEnvoi)
            // setFieldValue('envoiRecurr', "Non")
        }
    }

    const [openProgramme, setOpenProgramme] = useState(false)

    return (
        <div className="animated fadeIn">
            <div className="row mb-3">
                <div className="col-md-12">
                    <h4>Type d'envoi</h4>
                </div>
            </div>
            {
                errors['typeEnvoi'] &&
                <div className="row mb-1">
                    <div className="col">
                        <Alert color="danger" isOpen={true}>
                            <FontAwesomeIcon icon={faExclamationCircle} className="mr-2"></FontAwesomeIcon>
                                Veuillez choisir le type d'envoi.
                            </Alert>
                    </div>
                </div>

            }
            <div className="row mb-1">
                <div className="col-md-4">
                    <div className="form-group">
                        <div style={{ minHeight: '165px' }} className={classnames('border rounded', { 'border-envoi-selected': values['typeEnvoi'] == 'Immédiat' })}>
                            <div className="card-body d-flex flex-column">
                                <h5>
                                    {values['typeEnvoi'] == 'Immédiat' ?
                                        <FontAwesomeIcon className="mr-2 text-success" icon={faCheck}></FontAwesomeIcon>
                                        :
                                        <FontAwesomeIcon className="mr-2 text-fid" icon={faPaperPlane}></FontAwesomeIcon>
                                    }
                                Envoi immédiat
                            </h5>

                                <p>
                                    Envoyer votre campagne entre maintenant et 20 minutes après son enregistrement
                            </p>
                                <AnimateHeight
                                    duration={500}
                                    height={values['typeEnvoi'] == 'Immédiat' ? 'auto' : 0} // see props documentation below
                                >
                                    <div className="form-group">
                                        <label>Fuseau horaire</label>
                                        <MyField
                                            name="timezone"
                                            type="grouped"
                                            className="form-control"
                                            customOnChange={setFieldValue}
                                            groupedOptions={groupOptionsTimezones}
                                            // defaultValue={values['timezone']}
                                            value={values['timezone']}
                                            error={errors['timezone']}
                                            touched={touched['timezone']}>
                                        </MyField>
                                    </div>
                                </AnimateHeight>
                                <button onClick={() => onChangeTypeEnvoi('Immédiat')}
                                    className={classnames('btn btn-old', { 'd-none': values['typeEnvoi'] == 'Immédiat' })}>
                                    Choisir
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div style={{ minHeight: '165px' }} className={classnames('border rounded', { 'border-envoi-selected': (values['typeEnvoi'] == 'Programmé') })}>
                            <div className="card-body d-flex flex-column">
                                <h5>
                                    {values['typeEnvoi'] == 'Programmé' ?
                                        <FontAwesomeIcon className="mr-2 text-success" icon={faCheck}></FontAwesomeIcon>
                                        :
                                        <FontAwesomeIcon className="mr-2 text-fid" icon={faCalendarAlt}></FontAwesomeIcon>
                                    }

                                Envoi programmé

                            </h5>
                                <p>
                                    Programmer votre envoi en choissant la date et l'heure souhaitées
                            </p>


                                <AnimateHeight
                                    duration={500}
                                    height={values['typeEnvoi'] == 'Programmé' ? 'auto' : 0} // see props documentation below
                                >
                                    <div className="form-group">
                                        <label>Date d'envoi</label>
                                        <MyField
                                            name="dateEnvoi"
                                            type="date"
                                            className="form-control"
                                            customOnChange={setFieldValue}
                                            value={values['dateEnvoi']}
                                            error={errors['dateEnvoi']}
                                            touched={touched['dateEnvoi']}>
                                        </MyField>
                                    </div>
                                    <div className="form-group">
                                        <label>Heure d'envoi</label>
                                        <MyField
                                            name="programmerHeure"
                                            type="time"
                                            className="form-control"
                                            minDate={new Date()}
                                            customOnChange={setFieldValue}
                                            value={values['programmerHeure']}
                                            error={errors['programmerHeure']}
                                            touched={touched['programmerHeure']}>
                                        </MyField>
                                    </div>
                                    <div className="form-group">
                                        <label>Fuseau horaire</label>
                                        <MyField
                                            name="timezone"
                                            type="grouped"
                                            className="form-control"
                                            customOnChange={setFieldValue}
                                            groupedOptions={groupOptionsTimezones}
                                            defaultValue={values['timezone']}
                                            value={values['timezone']}
                                            error={errors['timezone']}
                                            touched={touched['timezone']}>
                                        </MyField>
                                    </div>
                                </AnimateHeight>

                                <button onClick={() => onChangeTypeEnvoi('Programmé')}
                                    className={classnames('btn btn-old', { 'd-none': values['typeEnvoi'] == 'Programmé' })}>
                                    Choisir
                            </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-4">
                    <div className="form-group">
                        <div style={{ minHeight: '165px' }} className={classnames('border rounded', { 'border-envoi-selected': values['typeEnvoi'] == 'Récurrent' })}>
                            <div className="card-body d-flex flex-column">
                                <h5>
                                    {values['typeEnvoi'] == 'Récurrent' ?
                                        <FontAwesomeIcon className="mr-2 text-success" icon={faCheck}></FontAwesomeIcon>
                                        :
                                        <FontAwesomeIcon className="mr-2 text-fid" icon={faSyncAlt}></FontAwesomeIcon>
                                    }
                                Envoi récurrent
                                </h5>
                                <p>
                                    Plannifier des envois récurrents en choissant la fréquence souhaitée.
                            </p>

                                <AnimateHeight
                                    duration={500}
                                    height={values['typeEnvoi'] == 'Récurrent' ? 'auto' : 0} // see props documentation below
                                >
                                    <div className="form-group">
                                        <label>Fréquence d'envoi</label>
                                        <MyField
                                            name="envoiRecurr"
                                            type="reactSelect"
                                            className="form-control"
                                            customOnChange={setFieldValue}
                                            list={frequenceEnvoi}
                                            value={values['envoiRecurr']}
                                            error={errors['envoiRecurr']}
                                            touched={touched['envoiRecurr']}>
                                        </MyField>
                                    </div>
                                    {/* <div className="form-group">
                                        <label>Fuseau horaire</label>
                                        <MyField
                                            name="timezone"
                                            type="grouped"
                                            className="form-control"
                                            customOnChange={setFieldValue}
                                            groupedOptions={groupOptionsTimezones}
                                            value={values['timezone']}
                                            error={errors['timezone']}
                                            touched={touched['timezone']}>
                                        </MyField>
                                    </div> */}
                                </AnimateHeight>

                                <button
                                    onClick={() => onChangeTypeEnvoi('Récurrent')}
                                    className={classnames('btn btn-old', { 'd-none': values['typeEnvoi'] == 'Récurrent' })}>Choisir</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
})

const StepRecap = observer((props) => {

    const {
        values,
        isCampagne,
        totalDestinataires,
        creditsToUse,
        creditsActuels,
        creditsAfter,
        unite1,
        unite2
    } = props



    return (
        <div className="row">
            <div className="col-md-4">
                <div className="row">
                    <div className="col-12">
                        <h4>Récapitulatif</h4>
                    </div>

                    <div className="col-12">
                        <fieldset className="p-3 border rounded">
                            <legend className="text-fid w-auto px-3">Paramètres</legend>
                            <div className="row">
                                <div className="col-md-7 recap-title mb-2">Mode d'envoi : </div>
                                <div className="col-md-5 pl-0 text-right font-weight-bold">{modeEnvoiLabel[values['modeEnvoi']]}</div>
                                {/* <div className="col-md-3"></div> */}
                                <div className="col-md-7 recap-title mb-2">Type envoi : </div>
                                <div className="col-md-5 pl-0 text-right font-weight-bold">{values['typeEnvoi']}</div>
                                {/* <div className="col-md-3"></div> */}

                                {
                                    values['typeEnvoi'] == 'Programmé' &&
                                    <>
                                        <div className="col-md-7 recap-title mb-2">Date d'envoi : </div>
                                        <div className="col-md-5 pl-0 text-right font-weight-bold">{moment(values['dateEnvoi']).format('DD/MM/YYYY')}</div>
                                        <div className="col-md-7 recap-title mb-2">Heure d'envoi : </div>
                                        <div className="col-md-5 pl-0 text-right font-weight-bold">{values['programmerHeure']}</div>
                                        <div className="col-md-7 recap-title mb-2">Fuseau horaire : </div>
                                        <div className="col-md-5 pl-0 text-right font-weight-bold">{values['timezone'].label}</div>
                                    </>
                                }

                                {
                                    values['typeEnvoi'] == 'Récurrent' &&
                                    <>
                                        <div className="col-md-7 recap-title mb-2">Fréquence : </div>
                                        <div className="col-md-5 pl-0 text-nowrap text-right font-weight-bold">{values['envoiRecurr'].label}</div>
                                        <div className="col-md-7 recap-title mb-2">Fuseau horaire : </div>
                                        <div className="col-md-5 pl-0 text-right font-weight-bold">{values['timezone'].label}</div>
                                    </>
                                }
                            </div>
                        </fieldset>
                    </div>
                    {
                        !isCampagne &&

                        <div className="col-12">
                            <fieldset className="p-3 border rounded">
                                <legend className="text-fid w-auto px-3">Coupons</legend>
                                {values['checkboxCoupon'] == 'Offrir un coupon' &&
                                    <div className="row">
                                        <div className="col-md-7 recap-title mb-2">Offrir un coupon : </div>
                                        <div className="col-md-5 pl-0 text-right font-weight-bold">Oui</div>
                                        {/* <div className="col-md-3"></div> */}
                                        <div className="col-md-7 recap-title mb-2">Type coupon : </div>
                                        <div className="col-md-5 pl-0 text-right font-weight-bold">{values['typeCoupon'].label}</div>
                                        {/* <div className="col-md-3"></div> */}
                                        <div className="col-md-7 recap-title mb-2">Motif/Libellé coupon : </div>
                                        <div className="col-md-5 pl-0 text-right font-weight-bold">{values['MotifEcoupons1']}</div>
                                        {/* <div className="col-md-3"></div> */}
                                        {
                                            values['typeCoupon'].id == 'pme1' &&
                                            <>
                                                <div className="col-md-7 recap-title mb-2">Valeur du coupon : </div>
                                                <div className="col-md-5 pl-0 text-right font-weight-bold">
                                                    {values['reqPointsEcoupons']} {values['typeCoupon'].label}
                                                </div>
                                                {/* <div className="col-md-3"></div> */}
                                                <div className="col-md-7 recap-title mb-2">Validité du coupon : </div>
                                                <div className="col-md-5 pl-0 text-right font-weight-bold">
                                                    {
                                                        number(values['validPointsEcoupons']) + ' ' +
                                                        pluralize('jour', values['validPointsEcoupons'])
                                                    }
                                                </div>
                                                {/* <div className="col-md-3"></div> */}
                                            </>
                                        }
                                        {
                                            values['typeCoupon'].id == 'pme2' &&
                                            <>
                                                <div className="col-md-7 recap-title mb-2">Valeur du coupon : </div>
                                                <div className="col-md-5 pl-0 text-right font-weight-bold">
                                                    {values['reqEUREcoupons']} {values['typeCoupon'].label}
                                                </div>
                                                {/* <div className="col-md-3"></div> */}
                                                <div className="col-md-7 recap-title mb-2">Validité du coupon : </div>
                                                <div className="col-md-5 pl-0 text-right font-weight-bold">
                                                    {
                                                        number(values['validEUREcoupons']) + ' ' +
                                                        pluralize('jour', values['validEUREcoupons'])
                                                    }
                                                </div>
                                                {/* <div className="col-md-3"></div> */}
                                            </>
                                        }

                                    </div>
                                }
                                {values['checkboxCoupon'] == "Ne pas offrir d'e-coupon" &&
                                    <div className="row">
                                        <div className="col-md-7 recap-title mb-2">Offrir un coupon : </div>
                                        <div className="col-md-5 pl-0 text-right font-weight-bold">Non</div>
                                    </div>
                                }
                            </fieldset>
                        </div>
                    }
                    <div className="col-12">
                        <fieldset className="p-3 border rounded">
                            <legend className="text-fid w-auto px-3">Conception</legend>
                            <div className="row">
                                <div className="col-md-7 recap-title mb-2">Titre de la campagne : </div>
                                <div className="col-md-5 pl-0 text-right font-weight-bold">{values['titre']}</div>
                                {/* <div className="col-md-3"></div> */}
                                {
                                    values['modeEnvoi'] == 'email' &&
                                    <>
                                        <div className="col-md-7 recap-title mb-2">Objet : </div>
                                        <div className="col-md-5 pl-0 text-right font-weight-bold">{values['objet']}</div>
                                        {/* <div className="col-md-3"></div> */}
                                    </>
                                }
                            </div>
                        </fieldset>
                    </div>
                    <div className="col-12">
                        <fieldset className="p-3 border rounded">
                            <legend className="text-fid w-auto px-3">Destinataires</legend>
                            <div className="row">
                                <div className="col-md-7 recap-title mb-2">Nombre de destinataires : </div>
                                <div className="col-md-5 mb-3 font-weight-bold text-right">
                                    {number(totalDestinataires)}
                                </div>
                                {/* <div className="col-md-3"></div> */}
                                <div className="col-md-7 recap-title mb-2">Solde {modeEnvoiDetails[values['modeEnvoi']].label} actuels : </div>
                                <div className="col-md-5 pl-0 text-right font-weight-bold">
                                    {number(creditsActuels)}
                                </div>
                                {/* <div className="col-md-3"></div> */}

                                <div className="col-md-7 recap-title mb-2">Crédits {modeEnvoiDetails[values['modeEnvoi']].label} à utiliser : </div>
                                <div className="col-md-5 pl-0 text-right font-weight-bold">
                                    {number(creditsToUse)}
                                </div>
                                {/* <div className="col-md-3"></div> */}
                                <div className="col-md-12">
                                    <hr />
                                </div>
                                {/* <div className="col-md-3"></div> */}
                                <div className="col-md-7 recap-title mb-2">Solde {modeEnvoiDetails[values['modeEnvoi']].label} après opération : </div>
                                <div className="col-md-5  pl-0 text-right font-weight-bold">
                                    <span className={classnames({ 'text-danger': creditsAfter <= 0, 'text-success': creditsAfter > 0 })}>
                                        {number(creditsAfter)}
                                    </span>
                                </div>
                                {/* <div className="col-md-3"></div> */}
                            </div>
                        </fieldset>
                    </div>


                </div >
            </div >
            <div className="col-md-8">
                {
                    values['modeEnvoi'] == 'email' &&
                    <PreviewEmail
                        message={values['message']}
                    ></PreviewEmail>
                }
                {
                    values['modeEnvoi'] == 'sms' &&
                    <PreviewSMS
                        message={values['message']}
                    ></PreviewSMS>
                }
            </div>
        </div >
    )
})

export const AddCredit = observer((props) => {

    const {
        toggleModalPayment,
        isOpenModalPayment,
        loadingMsg,
        loadingStatus,
        tarifs,
        prix,
        getPrix,
        coefficientSMSMail,
        modeEnvoi,
        submitAddCreditForm
    } = props

    // console.log(toJS(tarifs))
    const formik = useFormik({
        // enableReinitialize: true,
        initialValues: {
            vNbSms: tarifs[0].id,
            modeSaisie: "CB",
            motif: "",
            montantAPayer: parseFloat(prix),
            nBank: '',
            cbMail: '',
            cripto: '',
            exp: '',
        },
        validationSchema: myYup.object().shape({
            nBank: myYup.string()
                .required()
                .min(19),
            cbMail: myYup.string()
                .required()
                .email(),
            exp: myYup.string()
                .required()
                .min(5)
                .expiration(),
            cripto: myYup.string()
                .required()
                .min(3)
        }),
        // enableReinitialize : true,
        onSubmit: values => submitAddCreditForm(values),
        validateOnBlur: false,
        validateOnChange: false

    })


    const [equivalence, setEquivalence] = useState([])
    const [mask, setMask] = useState('9999 9999 9999 9999')
    const [classIconCard, setClassIconCard] = useState('9999 9999 9999 9999')


    useEffect(() => {
        async function temp() {
            console.log('useEff')
            console.log(formik.values.vNbSms, coefficientSMSMail)

            coefficientSMSMail.map(item => {
                item.equiv = parseInt(formik.values.vNbSms / item.value)
                return item
            })
            console.log(coefficientSMSMail)
            setEquivalence(coefficientSMSMail)
            let prixApayer = await getPrix(formik.values.vNbSms, true)
            formik.setFieldValue('montantAPayer', parseFloat(prixApayer))
        }
        temp();

    }, [formik.values.vNbSms])


    useEffect(() => {
        if (formik.values.exp.length) {
            let tab = formik.values.exp.split('/')
            if (tab[0])
                formik.setFieldValue('vDay', tab[0].replace(/ /g, ''))
            if (tab[1])
                formik.setFieldValue('vMonth', tab[1].replace(/ /g, ''))
        }

    }, [formik.values.exp])

    // const onChangeTarif = async (e) => {
    //     let value = e.target.value
    //     console.log(value, formik.values)
    //     // formik.handleChange(event)
    //     formik.setFieldValue('vNbSms', parseFloat(value))
    //     let prixApayer = await getPrix(value, true)
    //     formik.setFieldValue('montantAPayer', parseFloat(prixApayer))

    //     console.log(value, formik.values)
    // }


    return (
        <Modal isOpen={isOpenModalPayment} backdrop="static" toggle={() => toggleModalPayment()}>
            <BlockUi tag="div" blocking={loadingStatus} message={loadingMsg} loader={<BarLoader></BarLoader>}>
                <ModalHeader toggle={() => toggleModalPayment()}>
                    Ajouter des credits
                    </ModalHeader>
                <form onSubmit={formik.handleSubmit}>
                    <ModalBody className="p-0">

                        <div className="px-4 pt-4" style={{ background: '#f7fbff' }}>
                            <h4>Détails crédit</h4>
                            <div className="d-flex  mb-3" >
                                <div className="col">
                                    <p>Nombre de crédits</p>
                                </div>
                                <div className="col-4">
                                    <MyField
                                        name="vNbSms"
                                        type="select"
                                        list={tarifs}
                                        className="form-control"
                                        onChange={formik.handleChange}
                                        value={formik.values.vNbSms}
                                        error={formik.errors.vNbSms}
                                        touched={formik.touched.vNbSms}>
                                    </MyField>
                                </div>
                            </div>

                            {
                                equivalence.map(item =>
                                    <div className="d-flex">
                                        <div className="col">
                                            <p>Equivalence {item.label}</p>
                                        </div>
                                        <div className="col-4 ">
                                            <span className="pl-3">
                                                {number(item.equiv)}
                                                {' '}
                                                {pluralize(item.label, (item.equiv))}
                                            </span>
                                        </div>
                                    </div>
                                )
                            }

                            <hr />
                            <div className="d-flex">
                                <div className="col">
                                    <p><b>Prix</b></p>
                                </div>
                                <div className="col-4">
                                    <span className="pl-3"><b>{currency(prix)}</b></span>
                                </div>
                            </div>
                        </div>
                        <div className="p-4">
                            <h4 class="title">Information carte de crédit</h4>
                            <div class="row m-0">
                                <div class="form-group col-sm-12">
                                    <label for="cbMail">Email</label>
                                    <MyField
                                        name="cbMail"
                                        type="text"
                                        className="form-control"
                                        onChange={formik.handleChange}
                                        value={formik.values.cbMail}
                                        error={formik.errors.cbMail}
                                        touched={formik.touched.cbMail}>
                                    </MyField>
                                </div>
                                <div class="form-group col-sm-7 ">
                                    <label for="card-number">N° de carte</label>
                                    <div className="card-number-wrapper">
                                        <MyField
                                            name="nBank"
                                            type="masked"
                                            maskPlaceholder={null}
                                            className="form-control"
                                            placeholder="Numéro de carte"
                                            mask={mask}
                                            onChange={(event) => {
                                                let value = event.target.value;
                                                let newMask = '9999 9999 9999 9999'

                                                if (/^3[47]/.test(value)) {
                                                    newMask = '9999 999999 99999';
                                                }
                                                setMask(newMask)
                                                setClassIconCard(getClassOfCardTpye(value))
                                                formik.setFieldValue('nBank', value)
                                            }}
                                            value={formik.values.nBank}
                                            error={formik.errors.nBank}
                                            touched={formik.touched.nBank}>
                                        </MyField>
                                        <div className={"card-type-icon " + classIconCard}></div>
                                    </div>
                                </div>
                                <div class="form-group col-sm-5 d-flex align-items-end">
                                    <img src={paymentMode} className="payment-mode"></img>
                                </div>
                                <div class="form-group col-sm-7">
                                    <label for="">Date d'expiration</label>
                                    <MyField
                                        name="exp"
                                        type="masked"
                                        maskPlaceholder={null}
                                        placeholder="MM / AA"
                                        className="form-control"
                                        guide={true}
                                        mask="99 / 99"
                                        onChange={formik.handleChange}
                                        value={formik.values.exp}
                                        error={formik.errors.exp}
                                        touched={formik.touched.exp}>
                                    </MyField>
                                </div>

                                <div class="form-group col-sm-5">
                                    <label for="cripto">Cryptogramme visuel</label>
                                    <MyField
                                        name="cripto"
                                        type="masked"
                                        maskPlaceholder={null}
                                        className="form-control"
                                        placeholder="CVC"
                                        mask="999"
                                        onChange={formik.handleChange}
                                        value={formik.values.cripto}
                                        error={formik.errors.cripto}
                                        touched={formik.touched.cripto}>
                                    </MyField>
                                </div>

                            </div>
                            <input type="hidden" name="montantAPayer" value={prix}></input>
                        </div>

                    </ModalBody>
                    <ModalFooter>
                        <button type="submit" className="btn-block btn btn-lg rounded btn-old"><b>Payer {currency(prix)}</b></button>
                    </ModalFooter>
                </form>
            </BlockUi>
        </Modal>
    )
})

const SMSAreaMention = observer((props) => {

    const {
        message,
        smsCount,
        setFieldValue,
        editorOptions,
        setMsg
    } = props


    const mentionsList = Object.keys(editorOptions.mergeTags).map(
        key =>

            ({ id: editorOptions.mergeTags[key].value, display: editorOptions.mergeTags[key].name })
    )


    return (
        <>

            <div className="row mb-2">
                <div className="col">
                    <div class="alert alert-info">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2"></FontAwesomeIcon>
                            La commande <b>@</b> permet d'insérer les informations relatives au client (Nom du client, prénom du client...)
                            </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-8">
                    <MentionsInput className="sms-mention" style={{ minHeight: '100%' }}
                        value={message}
                        onChange={(e, nextValue) => { setFieldValue('message', nextValue); setMsg(nextValue) }}>
                        <Mention
                            trigger="@"
                            data={mentionsList}
                            displayTransform={(id, display) => id}
                            markup="__id__"
                            regex={/(::.*?::)/}

                        />
                    </MentionsInput>
                </div>
                <div className="col-md-4">
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <td>Nombre de caractères</td>
                                <td><b>{smsCount(message).length}</b></td>
                            </tr>
                            <tr>
                                <td>Nombre de caractères restants</td>
                                <td><b>{smsCount(message).remaining}</b></td>
                            </tr>
                            <tr>
                                <td>Estimation SMS à envoyer</td>
                                <td><b>{smsCount(message).messages}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>




        </>
    )
})

const SMSAreaNormal = observer((props) => {

    const {
        message,
        smsCount,
        setFieldValue,
        editorOptions,
        setMsg
    } = props

    const el = useRef(null)

    const insert = (text) => insertTextAtCursor(el.current, text);

    const onChange = (msg) => {
        setFieldValue('message', msg)
        setMsg(msg)
    }

    const mentionsList = Object.keys(editorOptions.mergeTags).map(
        key =>

            ({ id: editorOptions.mergeTags[key].value, display: editorOptions.mergeTags[key].name })
    )


    return (
        <>

            <div className="row">
                <div className="col-md-7 d-flex flex-column">
                    <h6>Message</h6>
                    <Alert color="info">
                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2"></FontAwesomeIcon>
                        Les caractères avec <b>accents</b> (ex : à,è,á,é,ê,ç ...) ainsi que les <b>symboles monétaires</b> (ex: $, € ...) n'apparaîtront pas dans le SMS.
                    </Alert>
                    <textarea
                        value={message}
                        onChange={(e) => onChange(e.target.value)}
                        ref={el}
                        rows="10"
                        className="form-control"
                    >
                    </textarea>
                    {/* <div>
                        <h6>Aperçu</h6>
                        <div className="rounded border">
                            <p>
                                {message.replace(/[^\w\s]/gi, '')}
                            </p>
                        </div>
                    </div> */}
                    <table className="table table-sm">
                        <tbody>
                            <tr>
                                <td>Estimation du nombre de caractères</td>
                                <td><b>{smsCount(message).length}</b></td>
                            </tr>
                            <tr>
                                <td>Estimation du nombre de caractères restants</td>
                                <td><b>{smsCount(message).remaining}</b></td>
                            </tr>
                            {
                                smsCount(message).per_message &&
                                <tr>
                                    <td>Taille maximum par message</td>
                                    <td><b>{smsCount(message).per_message}</b></td>
                                </tr>
                            }
                            <tr>
                                <td>Estimation du nombre de SMS à envoyer</td>
                                <td><b>{smsCount(message).messages}</b></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="col-md-5 d-flex flex-column">
                    <h6>Cliquer pour personnaliser</h6>
                    {
                        mentionsList.map(item =>
                            <button onClick={() => insert(item.id)} className="btn btn-sm btn-old mb-2">
                                {item.display}
                            </button>
                        )
                    }
                </div>
            </div>




        </>
    )
})

const CreditInsuffisant = observer((props) => {

    const {
        isOpenCreditInsuffisant,
        toggleModalCreditInsuffisant,
        creditsAfter,
        toggleModalPayment
    } = props



    return (
        <>
            <Modal isOpen={isOpenCreditInsuffisant} toggle={toggleModalCreditInsuffisant} backdrop="static" unmountOnClose={false} >
                <ModalHeader toggle={toggleModalCreditInsuffisant}>
                    Crédits insuffisants
                </ModalHeader>
                <ModalBody>
                    <div class="d-flex">
                        <FontAwesomeIcon size="2x" icon={faExclamationCircle} className="mr-2 text-danger"></FontAwesomeIcon>
                        <div>
                            <p>
                                Il vous manque <b>{number(creditsAfter * -1)}</b> {' '} {pluralize('crédit', creditsAfter * -1)} afin d'envoyer cette campagne.
                        </p>
                            <p>
                                Veuillez acheter des crédits ou réduire le nombre de destinataires.
                        </p>

                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <div className="d-flex justify-content-end">
                        <button
                            onClick={() => { toggleModalCreditInsuffisant(); toggleModalPayment() }}
                            className="btn btn-success  m-1">Acheter des crédits</button>
                        <button onClick={() => toggleModalCreditInsuffisant()} className="btn btn-danger m-1">Fermer</button>
                    </div>
                </ModalFooter>
            </Modal>
        </>
    )
})

const ModalBuilder = observer((props) => {

    const {
        isOpenModalBuilder,
        saveModele,
        loadingStatus,
        loadingMsg,
        toggleModalBuilder,
        persistModele,
        editorOptions,
        setPreview,
        preview

    } = props



    const [isOpenModalSave, setIsOpenModalSave] = useState(false);
    const [modeleName, setModeleName] = useState('');

    const toggleModalSave = () => {
        setIsOpenModalSave(!isOpenModalSave)
    }

    const doPersistModeleEmail = () => {
        designer.current.exportHtml(design => {
            persistModele(modeleName, design.design, design.html)
            setIsOpenModalSave(!isOpenModalSave)
        })
    }


    const designer = useRef(null)
    let timer = null;


    const doSaveModele = () => {
        if (preview.editorType == 1) {
            saveModele(null, preview.message, preview.editorType)
        }

        if (preview.editorType == 2) {
            designer.current.exportHtml(design => {
                saveModele(design.design, design.html, preview.editorType)
            })
        }


    }



    // console.log(preview.json)
    const load = () => {
        console.log('LOAD DESIGNER')

        //Permet de vérifier que unlayer est bien chargé
        if (designer && designer.current && window.unlayer && preview.editorType == 2) {
            console.log(designer)
            if (timer) {
                clearInterval(timer)
                console.log('INNER LOAD DESIGNER')
                // console.log('load', preview.json)
                designer.current.loadDesign(JSON.parse(preview.json))
                return true;
            }

        }
        return false;
    }

    useEffect(() => {
        // console.log('use effect LOAD DESIGNER open modal')
        // console.log(toJS(preview))
        // console.log('useEffect')
        if (!load() && preview.json && preview.editorType == 2)
            timer = setTimeout(load, 500);
    }, [isOpenModalBuilder])

    return (
        <>
            <Modal isOpen={isOpenModalBuilder} backdrop="static" unmountOnClose={false} className="modal-xxxl">
                <BlockUi tag="div" blocking={loadingStatus} message={loadingMsg} loader={<BarLoader></BarLoader>}>
                    <ModalBody>
                        <div className="d-flex justify-content-end">
                            <div className="m-1">
                                <button className="btn btn-danger" onClick={() => toggleModalBuilder()}>
                                    <FontAwesomeIcon icon={faTimes} className="mr-2"></FontAwesomeIcon>
                                Quitter
                            </button>
                            </div>
                            {/* <div className="m-1">
                                <button className="btn btn-old" tab="2" onClick={() => toggleModalSave()}>
                                    <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
                                Sauvegarder et continuer
                            </button>
                            </div> */}
                            <div className="m-1">
                                <button className="btn btn-old" tab="2" onClick={() => toggleModalSave()}>
                                    <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
                                Sauvegarder
                            </button>
                            </div>
                            <div className="m-1">

                                <button className="btn btn-success" tab="2" onClick={() => { doSaveModele(); toggleModalBuilder() }}>
                                    <FontAwesomeIcon icon={faCheck} className="mr-2"></FontAwesomeIcon>
                                Valider
                                 </button>
                            </div>


                        </div>
                        <div className="row">
                            <div className="col">
                                {
                                    (preview.editorType == 1) &&
                                    <CKEditor
                                        data={preview.message}
                                        type="classic"
                                        config={{
                                            toolbarGroups: [
                                                { name: 'clipboard', groups: ['clipboard', 'undo'] },
                                                { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
                                                { name: 'links' },
                                                { name: 'insert' },
                                                { name: 'forms' },
                                                { name: 'tools' },
                                                { name: 'document', groups: ['mode', 'document', 'doctools'] },
                                                { name: 'others' },
                                                '/',
                                                { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
                                                { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi'] },
                                                { name: 'styles', groups: ['Format', 'Font', 'FontSize'] },
                                                { name: 'colors', groups: ['TextColor', 'BGColor'] },
                                                { name: 'about' }
                                            ],
                                            language: 'fr',
                                            height: "500px",
                                            fullPage: true,
                                            colorButton_enableMore: true,
                                            allowedContent: true,
                                            extraPlugins: 'justify,uploadimage,font,colorbutton,colordialog',
                                            filebrowserUploadUrl: process.env['REACT_APP_API_URL'] + "/scripts/campagne/campagne.php?cmd=uploadEditorImage&responseType=json",

                                        }}
                                        onChange={(e) => setPreview(null, e.editor.getData())}
                                        onPluginsLoaded={(e) => {
                                            var editor = e.editor,
                                                config = editor.config;

                                            editor.ui.addRichCombo('infos_client', {
                                                label: 'Informations clients',
                                                title: 'Informations clients',
                                                toolbar: 'about,0',

                                                panel: {
                                                    css: [window.CKEDITOR.skin.getPath('editor')].concat(config.contentsCss),
                                                    multiSelect: false,
                                                    attributes: { 'aria-label': 'Informations clients' }
                                                },

                                                init: function () {
                                                    Object.keys(editorOptions.mergeTags).map(
                                                        key => this.add(editorOptions.mergeTags[key].value, editorOptions.mergeTags[key].name)
                                                    )
                                                },

                                                onClick: function (value) {
                                                    editor.focus();
                                                    editor.fire('saveSnapshot');

                                                    editor.insertHtml(value);

                                                    editor.fire('saveSnapshot');
                                                }
                                            });
                                        }}
                                    />
                                }
                                {
                                    (preview.editorType == 2) &&
                                    <EmailEditor projectId={4740} options={editorOptions} saveDesign={(json) => setPreview(json, null)} onLoad={() => load()} ref={designer} minHeight="85vh" />
                                }

                            </div>
                        </div>
                    </ModalBody>
                </BlockUi>
            </Modal>
            <Modal isOpen={isOpenModalSave} backdrop="static" unmountOnClose={false} className="modal-xxxl">
                <BlockUi tag="div" blocking={loadingStatus} message={loadingMsg} loader={<BarLoader></BarLoader>}>
                    <ModalBody>
                        <div className="form-group">
                            <label>Nom du modèle</label>
                            <MyField name="titre" type="text"
                                className="form-control"
                                onChange={(e) => setModeleName(e.target.value)}
                                value={modeleName}>
                            </MyField>
                        </div>
                        <button className="btn btn-old" tab="2" onClick={() => toggleModalSave()}>
                            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
                                Fermer
                            </button>
                        <button className="btn btn-old" tab="2" onClick={() => doPersistModeleEmail()}>
                            <FontAwesomeIcon icon={faSave} className="mr-2"></FontAwesomeIcon>
                                Sauvegarder
                            </button>
                    </ModalBody>
                </BlockUi>
            </Modal>
        </>
    )
})

const ModalLoad = observer((props) => {

    const [selectedRow, setSelectedRow] = useState(null)
    const [selectedRowPre, setSelectedRowPre] = useState(null)


    const [activeTab, setActiveTab] = useState('1')

    const toggleTab = tab => {
        console.log(tab)
        if (activeTab !== tab)
            setActiveTab(tab);
    }

    const {
        modeEnvoi,
        editModele,
        isOpenModalLoad,
        loadingStatus,
        loadingMsg,
        dataModele,

        columnsModele,
        pageIndexModele,
        pageCountModele,
        pageLimitModele,
        pageTotalModele,
        prevPageModele,
        nextPageModele,
        goToPageModele,
        setSortModele,
        sortByModele,
        sortOrderModele,

        dataModelePre,
        columnsModelePre,
        pageIndexModelePre,
        pageCountModelePre,
        pageLimitModelePre,
        pageTotalModelePre,
        prevPageModelePre,
        nextPageModelePre,
        goToPageModelePre,
        setSortModelePre,
        sortByModelePre,
        sortOrderModelePre,

        loadingMsgModele,
        loadingStatusModele,
        toggleModalLoad,
        setPreviewSaved,
        previewSaved,
        deleteModele,
        useModele,

        maxHeightModele

    } = props

    const modeleTable = useTable(
        {
            columns: columnsModele,
            data: dataModele,
            initialState: {
                hiddenColumns: columnsModele.filter((item) => (item.isHidden)).map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: pageIndexModele,
                pageSize: pageLimitModele,
                pageCount: pageCountModele,
                pageTotal: pageTotalModele,
            },
            // useRowSelect,
            modeEnvoi: modeEnvoi,
            setPreviewSaved: (json, contenu) => setPreviewSaved(json, contenu),
            editModele: (json, contenu, editorType) => editModele(json, contenu, editorType),
            setSelectedRow: setSelectedRow,
            useModele: (json, contenu) => useModele(json, contenu),
            deleteModele: (id) => deleteModele(id),
        }
    )

    const modelePreTable = useTable(
        {
            columns: columnsModelePre,
            data: dataModelePre,
            initialState: {
                hiddenColumns: columnsModelePre.filter((item) => (item.isHidden)).map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: pageIndexModelePre,
                pageSize: pageLimitModelePre,
                pageCount: pageCountModelePre,
                pageTotal: pageTotalModelePre,
            },
            // useRowSelect,
            modeEnvoi: modeEnvoi,
            setPreviewSaved: (json, contenu) => setPreviewSaved(json, contenu),
            editModele: (json, contenu, editorType) => editModele(json, contenu, editorType),
            setSelectedRow: setSelectedRowPre,
            useModele: (json, contenu) => useModele(json, contenu),
            deleteModele: (id) => deleteModele(id),
        }
    )

    const closeBtn = (<button onClick={() => toggleModalLoad()} className="btn btn-danger float-right">Fermer</button>)

    return (
        <>
            <Modal isOpen={isOpenModalLoad} backdrop="static"
                unmountOnClose={false}
                tag="div"
                toggle={toggleModalLoad}
                className="modal-xxxl">
                <BlockUi tag="div" blocking={loadingStatus} message={loadingMsg} loader={<BarLoader></BarLoader>}>
                    <ModalHeader toggle={toggleModalLoad} close={closeBtn}>
                        Charger un modèle
                    </ModalHeader>
                    <ModalBody>
                        <div className="row">
                            <div className="col-5">
                                <div className="col-12">
                                    <Nav tabs>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '1' })}
                                                onClick={() => { toggleTab('1'); }}>
                                                <span className="h6">
                                                    Templates globaux
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={classnames({ active: activeTab === '2' })}
                                                onClick={() => { toggleTab('2'); }}>
                                                <span className="h6">
                                                    Mes templates
                                                </span>
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent className="p-0" activeTab={activeTab}>
                                        <TabPane tabId="1" className="animated fadeIn">
                                            <BlockUi tag="div" style={{ height: '100%' }} blocking={loadingStatusModele} message={loadingMsgModele}
                                                loader={<BarLoader></BarLoader>}>
                                                <TemplateSet
                                                    rows={modelePreTable.rows}
                                                    prepareRow={modelePreTable.prepareRow}
                                                    selectedRow={selectedRow}
                                                    useModele={(json, contenu) => useModele(json, contenu)}
                                                    editModele={(json, contenu, editorType) => editModele(json, contenu, editorType)}
                                                    setSelectedRow={setSelectedRow}
                                                    setPreviewSaved={setPreviewSaved}
                                                    loadingMsg={loadingMsgModele}
                                                    loadingStatus={loadingStatusModele}
                                                    prevPage={() => prevPageModele()}
                                                    nextPage={() => nextPageModele()}
                                                    goToPage={(page) => goToPageModele(page)}
                                                    pageCount={pageCountModelePre}
                                                    pageIndex={pageIndexModelePre}
                                                    pageLimit={pageLimitModelePre}
                                                    pageTotal={pageTotalModelePre}
                                                    btnClassName="btn btn-outline-success btn-sm"
                                                    maxHeight={maxHeightModele}
                                                ></TemplateSet>
                                            </BlockUi>
                                        </TabPane>
                                        <TabPane tabId="2" className="animated fadeIn">
                                            <BlockUi tag="div" style={{ height: '100%' }} blocking={loadingStatusModele} message={loadingMsgModele}
                                                loader={<BarLoader></BarLoader>}>
                                                <TemplateSet
                                                    rows={modeleTable.rows}
                                                    prepareRow={modeleTable.prepareRow}
                                                    selectedRow={selectedRow}
                                                    useModele={(json, contenu) => useModele(json, contenu)}
                                                    editModele={editModele}
                                                    setSelectedRow={setSelectedRow}
                                                    setPreviewSaved={setPreviewSaved}
                                                    loadingMsg={loadingMsgModele}
                                                    loadingStatus={loadingStatusModele}
                                                    prevPage={() => prevPageModele()}
                                                    nextPage={() => nextPageModele()}
                                                    goToPage={(page) => goToPageModele(page)}
                                                    pageCount={pageCountModele}
                                                    pageIndex={pageIndexModele}
                                                    pageLimit={pageLimitModele}
                                                    pageTotal={pageTotalModele}
                                                    btnClassName="btn btn-outline-success btn-sm"
                                                    maxHeight={maxHeightModele}
                                                ></TemplateSet>
                                            </BlockUi>
                                        </TabPane>
                                    </TabContent>
                                </div>
                                {/* <div className="col-12">
                                    <h4>Modèles sauvegardés</h4>
                                    <DataTable
                                        headerGroups={modeleTable.headerGroups}
                                        getTableBodyProps={modeleTable.getTableBodyProps}
                                        rows={modeleTable.rows}
                                        prepareRow={modeleTable.prepareRow}
                                        getTableProps={modeleTable.getTableProps}
                                        columns={columnsModele}
                                        loadingMsg={loadingMsgModele}
                                        loadingStatus={loadingStatusModele}
                                        prevPage={() => prevPageModele()}
                                        nextPage={() => nextPageModele()}
                                        setSort={(sortBy) => setSortModele(sortBy)}
                                        sortOrder={sortOrderModele}
                                        sortBy={sortByModele}
                                        goToPage={(page) => goToPageModele(page)}
                                        pageCount={pageCountModele}
                                        pageIndex={pageIndexModele}
                                        pageLimit={pageLimitModele}
                                        pageTotal={pageTotalModele}
                                        maxHeight="100vh"
                                        selectedRow={selectedRow}
                                        hidePaginationLabel={true}
                                        setSelectedRow={setSelectedRow}
                                        btnClassName="btn btn-outline-success btn-sm"
                                    ></DataTable>
                                </div>
                                <div className="col-12">
                                    <h4>Modèles prédéfinis</h4>
                                    <DataTable
                                        headerGroups={modelePreTable.headerGroups}
                                        getTableBodyProps={modelePreTable.getTableBodyProps}
                                        rows={modelePreTable.rows}
                                        prepareRow={modelePreTable.prepareRow}
                                        getTableProps={modelePreTable.getTableProps}
                                        columns={columnsModelePre}
                                        loadingMsg={loadingMsgModele}
                                        loadingStatus={loadingStatusModele}
                                        prevPage={() => prevPageModelePre()}
                                        nextPage={() => nextPageModelePre()}
                                        setSort={(sortBy) => setSortModelePre(sortBy)}
                                        sortOrder={sortOrderModelePre}
                                        sortBy={sortByModelePre}
                                        goToPage={(page) => goToPageModelePre(page)}
                                        pageCount={pageCountModelePre}
                                        pageIndex={pageIndexModelePre}
                                        pageLimit={pageLimitModelePre}
                                        pageTotal={pageTotalModelePre}
                                        maxHeight="100vh"
                                        selectedRow={selectedRowPre}
                                        hidePaginationLabel={true}
                                        setSelectedRow={setSelectedRowPre}
                                        btnClassName="btn btn-outline-success btn-sm"
                                    ></DataTable>
                                </div> */}
                            </div>
                            <div className="col-7">
                                {
                                    modeEnvoi == 'email' &&
                                    <PreviewEmail
                                        message={previewSaved.message}
                                        loadingMsgModele={loadingMsgModele}
                                        loadingStatusModele={loadingStatusModele}
                                    ></PreviewEmail>
                                }
                                {
                                    modeEnvoi == 'sms' &&
                                    <PreviewSMS
                                        message={previewSaved.message}
                                        loadingMsgModele={loadingMsgModele}
                                        loadingStatusModele={loadingStatusModele}
                                    ></PreviewSMS>
                                }
                            </div>
                        </div>
                    </ModalBody>
                </BlockUi>
            </Modal>

        </>
    )
})

const CampagneWizard = observer((props) => {

    const params = useParams()
    const mode = params.mode
    const action = params.action

    const obj = props.location.obj
    const notResetFilter = props.location.notResetFilter

    const { values, errors, touched, handleChange, setFieldValue, handleSubmit, validateForm, handleReset } = useFormik({
        enableReinitialize: true,
        initialValues: CampagneWizardStore.getInitialValues(params.action, params.mode, obj),
        validationSchema: CampagneWizardStore.getValidationSchema(),
        onSubmit: (values) => CampagneWizardStore.submitCampagne(values),
        validateOnChange: false,
        validateOnBlur: false
    })
    // console.log(values)


    useEffect(() => {

        console.log('UseEff mode', params.mode)
        handleReset()
        CampagneWizardStore.init(mode, action, notResetFilter, setFieldValue, obj)

    }, [params.mode])

    const listTypeCoupon = CampagneWizardStore.props.listTypeCoupon


    useEffect(() => {
        CampagneWizardStore.changeTitles(values['envoiRecurr'])
    }, [values['envoiRecurr']])


    //Formik variables

    const useModele = (json, contenu) => {
        console.log('useModele', json)
        setFieldValue('message_json', json)
        setFieldValue('message', contenu)
        CampagneWizardStore.toggleModalLoad()
    }

    const saveModele = (json, contenu, editorType) => {
        console.log('saveModele', contenu)

        if (json)
            setFieldValue('message_json', JSON.stringify(json))
        if (contenu)
            setFieldValue('message', contenu)
        if (editorType)
            setFieldValue('editorType', editorType)

    }

    const eraseModele = async () => {

        let next = await confirmation({
            type: 'confirmNoPass',
            text: 'Etes-vous sûr(e) de vouloir effacer le modèle en cours ?'
        })

        if (next) {
            setFieldValue('message_json', '')
            setFieldValue('message', '')
            setFieldValue('editorType', null)
        }
    }

    const editModele = (json, contenu, editorType = null) => {
        // console.log('editModele', json)
        console.log('editorType', editorType)
        CampagneWizardStore.setModeleToEdit(json, contenu, editorType)
        // if (json)
        //     setFieldValue('message_json', json)
        // if (contenu)
        //     setFieldValue('message', contenu)
        CampagneWizardStore.toggleModalLoad()

        if (params.mode == 'email')
            CampagneWizardStore.toggleModalBuilder(editorType)
        else if (params.mode == 'sms')
            CampagneWizardStore.toggleModalSMS()
    }

    const getSMSCount = (msg) => {
        //On cherche le nombre de caractères non ASCII

        let chars = 0
        for (let i = 0; i < msg.length; i++) {
            if (msg.charAt(i).match(/[^\x20-\x7e]/g)) {
                chars = chars + 2
                // console.log(msg.charAt(i) + ":@ == " + chars);
            }

            else {
                chars = chars + 1
                // console.log(msg.charAt(i) + ":- == " + chars);
            }


        }

        let messages = Math.ceil(chars / 160)
        let remaining = messages * 160 - (chars % (messages * 160) || messages * 160)

        // return SmsCounter(msg)

        return {
            length: chars,
            messages: messages,
            remaining: remaining,
            // special : allFoundCharacters.length
        }
    }


    const cltTable = useTable(
        {
            columns: CampagneWizardStore.props.columns,
            data: CampagneWizardStore.props.data,
            initialState: {
                hiddenColumns: CampagneWizardStore.props.columns
                    .filter((item) => ((item.isHidden) || (params.mode != 'sms' && item.accessor == 'smsLength'))
                    )
                    .map((item) => (item.accessor)),
                manualPagination: true,
                pageIndex: CampagneWizardStore.props.pageIndex,
                pageSize: CampagneWizardStore.props.pageLimit,
                pageCount: CampagneWizardStore.props.pageCount,
                pageTotal: CampagneWizardStore.props.pageTotal,
            },
            toggleCheckboxAll: () => CampagneWizardStore.toggleCheckboxAll(),
            exclues: CampagneWizardStore.props.exclues.slice(),
            toggleCheckbox: (index, smsLength) => CampagneWizardStore.toggleCheckbox(index, smsLength),
            allIsSelected: () => CampagneWizardStore.allIsSelected(),
            getBadgeColor: CampagneWizardStore.getBadgeColor,
            setUnitsHeader: CampagneWizardStore.setUnitsHeader,
        }
    )


    return (
        <>

            <Prompt
                when={!CampagneWizardStore.props.success}
                message="test"
            />


            <ProgressBar
                titles={CampagneWizardStore.props.titles}
                action={action}
                success={CampagneWizardStore.props.success}
                isLoading={CampagneWizardStore.props.loading.status}
                validateForm={validateForm}
                nextStep={() => CampagneWizardStore.nextStep()}
                prevStep={() => CampagneWizardStore.prevStep()}
                currStep={CampagneWizardStore.props.currStep}
                showBuilder={CampagneWizardStore.props.showBuilder}
                toggleBuilder={() => CampagneWizardStore.toggleBuilder()}
                exportModele={() => CampagneWizardStore.exportModele()}
                submitCampagne={() => handleSubmit()}
            >

            </ProgressBar>
            <div className="container-fluid" style={{ marginTop: '80px' }}>
                {/* <h2 className="card-title">Campagnes</h2> */}
                <div className="animated fadeIn">

                    <div className="row">
                        <div className="col-12">
                            <BlockUi tag="div" blocking={CampagneWizardStore.props.loading.status}
                                message={CampagneWizardStore.props.loading.msg}
                                loader={<BarLoader></BarLoader>}
                                className="card" style={{ minHeight: 'calc(100vh - 190px)' }}>
                                <div className="card-body">
                                    {/* {console.log(toJS(values))} */}
                                    {
                                        CampagneWizardStore.props.success &&
                                        <div className="d-flex flex-column center-v mt-5">
                                            <FontAwesomeIcon size="9x" icon={faCheckCircle} className="text-success"></FontAwesomeIcon>
                                            {action == 'new' &&
                                                <p class="h3 mt-3">Votre campagne a bien été enregistré.</p>
                                            }
                                            {action == 'edit' &&
                                                <p class="h3 mt-3">Votre campagne a bien été modifié.</p>
                                            }
                                            <div className="d-flex center-h mt-3">
                                                <Link to="/dashboard" className="btn rounded btn-lg btn-old mx-2">Tableau de bord</Link>
                                                <Link to="/campaigns" className="btn rounded btn-lg btn-old mx-2">Liste des campagnes</Link>
                                            </div>
                                        </div>
                                    }
                                    {

                                        CampagneWizardStore.props.titles[CampagneWizardStore.props.currStep].title == 'Paramètres'
                                        && values['modeEnvoi'].length > 0 && !CampagneWizardStore.props.success &&
                                        <>
                                            <StepParametres
                                                values={values}
                                                errors={errors}
                                                touched={touched}
                                                handleChange={handleChange}
                                                setFieldValue={setFieldValue}
                                                timezones={CampagneWizardStore.props.timezones}
                                                groupOptionsTimezones={toJS(CampagneWizardStore.props.groupOptionsTimezones)}
                                                frequenceEnvoi={frequenceEnvoi}
                                            ></StepParametres>

                                        </>
                                    }
                                    {
                                        CampagneWizardStore.props.titles[CampagneWizardStore.props.currStep].title == 'Coupons'
                                        && values['modeEnvoi'].length > 0 && !CampagneWizardStore.props.success &&
                                        <StepCoupons
                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            validateForm={validateForm}
                                            unite1={UserStore.props.user.unitesPME1}
                                            unite2={UserStore.props.user.unitesPME2}
                                            listTypeCoupon={listTypeCoupon}

                                        ></StepCoupons>
                                    }
                                    {
                                        CampagneWizardStore.props.titles[CampagneWizardStore.props.currStep].title == 'Conception'
                                        && values['modeEnvoi'].length > 0 && !CampagneWizardStore.props.success &&
                                        <StepConception
                                            setMsg={(msg) => CampagneWizardStore.setMsg(msg)}
                                            toggleModalBuilder={(editorType) => CampagneWizardStore.toggleModalBuilder(editorType)}
                                            toggleModalLoad={() => CampagneWizardStore.toggleModalLoad()}
                                            toggleModalSMS={() => CampagneWizardStore.toggleModalSMS()}
                                            smsCount={(msg) => getSMSCount(msg)}
                                            editorOptions={CampagneWizardStore.getEditoroptions()}
                                            showBuilder={CampagneWizardStore.props.showBuilder}
                                            getModele={(design) => CampagneWizardStore.getModele(design)}
                                            eraseModele={() => eraseModele()}

                                            values={values}
                                            errors={errors}
                                            touched={touched}
                                            handleChange={handleChange}
                                            setFieldValue={setFieldValue}
                                            handleSubmit={handleSubmit}
                                            validateForm={validateForm}

                                            //Modele EMAILs
                                            setPreview={(json, contenu) => CampagneWizardStore.setPreview(json, contenu)}
                                            deleteModele={(id) => CampagneWizardStore.deleteModele(id)}
                                            doLoadingModele={(state, msg) => CampagneWizardStore.doLoadingModele(state, msg)}
                                            columnsModele={CampagneWizardStore.props.columnsModele}
                                            dataModele={CampagneWizardStore.props.dataModele}
                                            loadingMsgModele={CampagneWizardStore.props.loadingModele.msg}
                                            loadingStatusModele={CampagneWizardStore.props.loadingModele.status}
                                            getModeleEmail={() => CampagneWizardStore.getModeleEmail()}
                                            prevPageModele={() => CampagneWizardStore.prevPageModele()}
                                            nextPageModele={() => CampagneWizardStore.nextPageModele()}
                                            setSortModele={(sortBy) => CampagneWizardStore.setSortModele(sortBy)}
                                            sortOrderModele={CampagneWizardStore.props.sortOrderModele}
                                            sortByModele={CampagneWizardStore.props.sortByModele}
                                            goToPageModele={(page) => CampagneWizardStore.goToPageModele(page)}
                                            pageCountModele={CampagneWizardStore.props.pageCountModele}
                                            pageIndexModele={CampagneWizardStore.props.pageIndexModele}
                                            pageLimitModele={CampagneWizardStore.props.pageLimitModele}
                                            pageTotalModele={CampagneWizardStore.props.pageTotalModele}
                                            maxHeightModele="calc(100vh - 260px)"
                                            btnClassName="btn btn-outline-success btn-sm"
                                        ></StepConception>
                                    }

                                    {
                                        CampagneWizardStore.props.titles[CampagneWizardStore.props.currStep].title == 'Destinataires'
                                        && values['modeEnvoi'].length > 0 && !CampagneWizardStore.props.success &&
                                        <>
                                            <StepDest

                                                onSubmitFilter={() => CampagneWizardStore.getFilterdClients()}
                                                toggleModalPayment={() => CampagneWizardStore.toggleModalPayment()}
                                                toggleModalSave={() => ClientFilterStore.toggleModalSave()}
                                                modeEnvoi={values['modeEnvoi']}
                                                countActiveFilter={ClientFilterStore.props.countActiveFilter}
                                                loadedFilter={ClientFilterStore.props.loadedFilter}
                                                nbSmsToSend={CampagneWizardStore.getNbSmsToSend()}
                                                oneSmsLength={CampagneWizardStore.props.oneSmsLength}
                                                totalDestinataires={CampagneWizardStore.getTotalDestinatiares()}
                                                creditsActuels={CampagneWizardStore.getCreditsActuels()}
                                                creditsAfter={CampagneWizardStore.getCreditsAfter()}
                                                creditsToUse={CampagneWizardStore.getCreditsToUse()}
                                                exclues={CampagneWizardStore.props.exclues}

                                                headerGroups={cltTable.headerGroups}
                                                getTableBodyProps={cltTable.getTableBodyProps}
                                                rows={cltTable.rows}
                                                prepareRow={cltTable.prepareRow}
                                                getTableProps={cltTable.getTableProps}
                                                columns={CampagneWizardStore.props.columns}
                                                loadingMsg={CampagneWizardStore.props.loading.msg}
                                                loadingStatus={CampagneWizardStore.props.loading.status}

                                                prevPage={() => CampagneWizardStore.prevPage()}
                                                nextPage={() => CampagneWizardStore.nextPage()}
                                                setSort={(sortBy) => CampagneWizardStore.setSort(sortBy)}
                                                sortOrder={CampagneWizardStore.props.sortOrder}
                                                sortBy={CampagneWizardStore.props.sortBy}
                                                goToPage={(page) => CampagneWizardStore.goToPage(page)}
                                                pageCount={CampagneWizardStore.props.pageCount}
                                                pageIndex={CampagneWizardStore.props.pageIndex}
                                                pageLimit={CampagneWizardStore.props.pageLimit}
                                                pageTotal={CampagneWizardStore.props.pageTotal}
                                                credits={CampagneWizardStore.props.credits}



                                                maxHeight="46vh"
                                            ></StepDest>

                                            <CreditInsuffisant
                                                isOpenCreditInsuffisant={CampagneWizardStore.props.isOpenCreditInsuffisant}
                                                toggleModalCreditInsuffisant={() => CampagneWizardStore.toggleModalCreditInsuffisant()}
                                                creditsAfter={CampagneWizardStore.getCreditsAfter()}
                                                toggleModalPayment={() => CampagneWizardStore.toggleModalPayment()}
                                            ></CreditInsuffisant>

                                        </>
                                    }
                                    {
                                        CampagneWizardStore.props.titles[CampagneWizardStore.props.currStep].title == 'Récapitulatif'
                                        && values['modeEnvoi'].length > 0 && !CampagneWizardStore.props.success &&
                                        <StepRecap
                                            values={values}
                                            isCampagne={UserStore.isCampagne()}
                                            totalDestinataires={CampagneWizardStore.getTotalDestinatiares()}
                                            creditsActuels={CampagneWizardStore.getCreditsActuels()}
                                            creditsAfter={CampagneWizardStore.getCreditsAfter()}
                                            creditsToUse={CampagneWizardStore.getCreditsToUse()}
                                            unite1={UserStore.props.user.unitesPME1}
                                            unite2={UserStore.props.user.unitesPME2}
                                        ></StepRecap>

                                    }


                                </div>
                                <>
                                    {CampagneWizardStore.props.isOpenModalPayment && values['modeEnvoi'].length > 0 &&
                                        <AddCredit

                                            toggleModalPayment={() => CampagneWizardStore.toggleModalPayment()}
                                            submitAddCreditForm={(values) => CampagneWizardStore.submitAddCreditForm(values)}
                                            getPrix={(prix, loading) => CampagneWizardStore.getPrix(prix, loading)}
                                            isOpenModalPayment={CampagneWizardStore.props.isOpenModalPayment}
                                            loadingMsg={CampagneWizardStore.props.loadingPayment.msg}
                                            loadingStatus={CampagneWizardStore.props.loadingPayment.status}
                                            tarifs={CampagneWizardStore.props.tarifs}
                                            prix={CampagneWizardStore.props.prix}
                                            modeEnvoi={values['modeEnvoi']}
                                            coefficientSMSMail={CampagneWizardStore.coefficientSMSMail()}
                                        />
                                    }
                                </>


                                <ModalBuilder
                                    preview={CampagneWizardStore.props.preview}
                                    setPreview={(json, contenu) => CampagneWizardStore.setPreview(json, contenu)}
                                    saveModele={(json, contenu, editorType) => saveModele(json, contenu, editorType)}
                                    json={values['message_json']}
                                    toggleModalBuilder={(editorType) => CampagneWizardStore.toggleModalBuilder(editorType)}
                                    isOpenModalBuilder={CampagneWizardStore.props.isOpenModalBuilder}
                                    saveBuilder={() => CampagneWizardStore.saveBuilder()}
                                    persistModele={(nomModele, json, contenu) => CampagneWizardStore.persistModele(nomModele, json, contenu)}
                                    loadingMsg={CampagneWizardStore.props.loading.msg}
                                    loadingStatus={CampagneWizardStore.props.loading.status}
                                    editorOptions={CampagneWizardStore.getEditoroptions()}
                                >



                                </ModalBuilder>
                                {/* <ModalSMS
                                    preview={CampagneWizardStore.props.preview}
                                    setPreview={(json, contenu) => CampagneWizardStore.setPreview(json, contenu)}
                                    toggleModalSMS={() => CampagneWizardStore.toggleModalSMS()}
                                    isOpenModalSMS={CampagneWizardStore.props.isOpenModalSMS}
                                    smsCount={getSMSCount}
                                    saveModele={(json, contenu) => saveModele(json, contenu)}
                                    editorOptions={CampagneWizardStore.getEditoroptions()}
                                >
                                </ModalSMS> */}
                                <ModalLoad
                                    modeEnvoi={values['modeEnvoi']}
                                    toggleModalLoad={() => CampagneWizardStore.toggleModalLoad()}
                                    json={values['message_json']}
                                    toggleModalBuilder={(editorType) => CampagneWizardStore.toggleModalBuilder(editorType)}
                                    isOpenModalLoad={CampagneWizardStore.props.isOpenModalLoad}
                                    saveBuilder={() => CampagneWizardStore.saveBuilder()}
                                    loadingMsg={CampagneWizardStore.props.loading.msg}
                                    loadingStatus={CampagneWizardStore.props.loading.status}
                                    previewSaved={CampagneWizardStore.props.previewSaved}

                                    //Modele EMAILs
                                    getModele={(design) => CampagneWizardStore.getModele(design)}

                                    setPreviewSaved={(json, contenu) => CampagneWizardStore.setPreviewSaved(json, contenu)}
                                    useModele={(json, contenu) => useModele(json, contenu)}
                                    editModele={(json, contenu, editorType) => editModele(json, contenu, editorType)}
                                    deleteModele={(id) => CampagneWizardStore.deleteModele(id)}

                                    doLoadingModele={(state, msg) => CampagneWizardStore.doLoadingModele(state, msg)}
                                    columnsModele={CampagneWizardStore.props.columnsModele}
                                    dataModele={CampagneWizardStore.props.dataModele}
                                    loadingMsgModele={CampagneWizardStore.props.loadingModele.msg}
                                    loadingStatusModele={CampagneWizardStore.props.loadingModele.status}
                                    getModeleEmail={() => CampagneWizardStore.getModeleEmail()}
                                    prevPageModele={() => CampagneWizardStore.prevPageModele()}
                                    nextPageModele={() => CampagneWizardStore.nextPageModele()}
                                    setSortModele={(sortBy) => CampagneWizardStore.setSortModele(sortBy)}
                                    sortOrderModele={CampagneWizardStore.props.sortOrderModele}
                                    sortByModele={CampagneWizardStore.props.sortByModele}
                                    goToPageModele={(page) => CampagneWizardStore.goToPageModele(page)}
                                    pageCountModele={CampagneWizardStore.props.pageCountModele}
                                    pageIndexModele={CampagneWizardStore.props.pageIndexModele}
                                    pageLimitModele={CampagneWizardStore.props.pageLimitModele}
                                    pageTotalModele={CampagneWizardStore.props.pageTotalModele}

                                    columnsModelePre={CampagneWizardStore.props.columnsModelePre}
                                    dataModelePre={CampagneWizardStore.props.dataModelePre}
                                    prevPageModelePre={() => CampagneWizardStore.prevPageModelePre()}
                                    nextPageModelePre={() => CampagneWizardStore.nextPageModelePre()}
                                    setSortModelePre={(sortBy) => CampagneWizardStore.setSortModelePre(sortBy)}
                                    sortOrderModelePre={CampagneWizardStore.props.sortOrderModelePre}
                                    sortByModelePre={CampagneWizardStore.props.sortByModelePre}
                                    goToPageModelePre={(page) => CampagneWizardStore.goToPageModelePre(page)}
                                    pageCountModelePre={CampagneWizardStore.props.pageCountModelePre}
                                    pageIndexModelePre={CampagneWizardStore.props.pageIndexModelePre}
                                    pageLimitModelePre={CampagneWizardStore.props.pageLimitModelePre}
                                    pageTotalModelePre={CampagneWizardStore.props.pageTotalModelePre}

                                    maxHeightModele="calc(100vh - 260px)"
                                    btnClassName="btn btn-outline-success btn-sm"

                                >

                                </ModalLoad>

                            </BlockUi>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )

})


export default withRouter(CampagneWizard)