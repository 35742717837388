
import React from 'react'
import { observable, toJS } from 'mobx'
import api from '../api'
import MainStore from './MainStore'
import { typeFid, TypeFid } from '../utils/constants'
import UserStore from './UserStore'
import moment from 'moment'


const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours'
    },
    data: [],
    filters: [],
    pageStart: 0,
    pageIndex: 0,
    pageLimit: 30,
    pageTotal: 0,
    pageCount: 0,
}

const TransacStore = {
    /***** Props ******/

    /***** Methods ******/
    async getTransacs(whereClause = ' ') {

        let where = this.getWhere(whereClause)
        let data = {
            'objName': 'listeTransac',
            'cmd': 'getRowsP',
            'where': where,
            'start': this.pageStart,
            'limit': this.pageLimit,
            'sort': 'DateTransaction',
            'dir': 'DESC',
        }

        const res = await api.post('/scripts/transac/transac.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.data = res.data.rows
                this.props.pageTotal = res.data.totalCount
                this.props.pageCount = Math.ceil(res.data.totalCount / this.props.limit)

            } else {
                this.props.data = []
            }
        }

        return this.props.data
    },
    getWhere(where) {
        if (!where)
            where += ' WHERE '

        this.props.filters
            .filter((item) => item.active)
            .map((item, index) => {
                let and = ' AND '
                if (index != 0 && !where)
                    and = ' '
                if (item.value) {
                    if (item.type == 'text')
                        where += and + item.field + " " + item.operator + " '%" + item.value + "%'"
                    if (item.type == 'number')
                        where += and + item.field + " " + item.operator + " " + item.value
                    if (item.type == 'date')
                        where += and + item.field + " " + item.operator + " '" + item.value + "'"
                }

            })

        return where

    },

    reset(){
        this.props = props
    }
}

export default observable(TransacStore)