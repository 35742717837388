import React from 'react'
import { observable, toJS } from 'mobx'
import api from '../api'
import myYup from '../utils/myYup'
import { toast } from 'react-toastify';
import { Civilite, Operators, modeEnvoi, modeEnvoiDetails, modeEnvoiMean, frequenceEnvoi } from '../utils/constants'
import moment from 'moment';
import ClientFilterStore from './ClientFilterStore';
import UserStore from './UserStore';
import { number } from '../utils/numeral';
import { EditModeleDropDown } from '../components/Campagne/CampagneWizard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { count as SmsCounter } from 'sms-counter-npm'



const props = {
    loading: {
        status: false,
        msg: 'Chargement en cours'
    },
    loadingPayment: {
        status: false,
        msg: 'Chargement en cours'
    },
    loadingModele: {
        status: false,
        msg: 'Chargement en cours'
    },
    loadingTemplate: {
        status: false,
        msg: 'Chargement en cours'
    },
    currStep: 0,
    data: [],
    success: false,
    pageStart: 0,
    pageIndex: 0,
    pageLimit: 30,
    pageTotal: 0,
    pageCount: 0,
    sortBy: 'clientNom',
    sortOrder: 'ASC',
    dataModele: [],
    dataModelePre: [],
    pageStartModele: 0,
    pageIndexModele: 0,
    pageLimitModele: 30,
    pageTotalModele: 0,
    pageCountModele: 0,
    sortByModele: 'DateCreation',
    sortOrderModele: 'ASC',
    pageStartModelePre: 0,
    pageIndexModelePre: 0,
    pageLimitModelePre: 30,
    pageTotalModelePre: 0,
    pageCountModelePre: 0,
    sortByModelePre: 'DateCreation',
    sortOrderModelePre: 'ASC',
    previewIsDesktop: true,
    isOpenModalPayment: false,
    isOpenModalBuilder: false,
    isOpenModalLoad: false,
    isOpenModalSMS: false,
    isOpenCreditInsuffisant: false,
    message: '',
    messageJson: null,
    builder: null,
    tarifs: [],
    groupOptionsTimezones: [],
    prix: null,
    prixCom: {},
    modeEnvoi: null,
    showBuilder: false,
    nbSmsToSend: 0,
    oneSmsLength: 0,
    exclues: [],
    msg: '',
    whereClients: null,
    listTypeCoupon: [
        { id: 'pme1', label: '' },
        { id: 'pme2', label: '' }
    ],
    previewSaved: {
        message: '',
        json: null
    },
    preview: {
        message: '',
        json: null,
        editorType: null
    },
    initialValues: {
        modeEnvoi: '',
        titre: '',
        objet: '',
        message: '',
        typeEnvoi: '',
        timezone: null,
        saveModele: false,
        message_json: null,
        checkboxCoupon: "Ne pas offrir d'e-coupon",
        typeCoupon: "pme1",
        MotifEcoupons1: '',
        reqEUREcoupons: '',
        reqPointsEcoupons: '',
        validEUREcoupons: '',
        validPointsEcoupons: '',
        immediatProgramme: "",
        dateEnvoi: "",
        programmerHeure: "",
        envoiRecurr: "",
    },
    titles: [
        {
            title: 'Paramètres',
            hide: [],
            validationSchema: myYup.object().shape({
                typeEnvoi: myYup.string().required(),
                dateEnvoi: myYup.string()
                    .when('typeEnvoi', {
                        is: (val) => val == "Programmé",
                        then: myYup.string().required().date(),
                        otherwise: null,
                    }),
                programmerHeure: myYup.string()
                    .when('typeEnvoi', {
                        is: (val) => val == "Programmé",
                        then: myYup.string().required(),
                        otherwise: null,
                    }),
                timezone: myYup.string().nullable()
                    .when('typeEnvoi', {
                        is: (val) => val == "Programmé" || val == "Immédiat",
                        then: myYup.string().required(),
                        otherwise: null,
                    }),
                envoiRecurr: myYup.object()
                    .when('typeEnvoi', {
                        is: (val) => val == "Récurrent",
                        then: myYup.object({
                            id: myYup.string().required().recurrent()
                        }),
                        otherwise: null,
                    })

            })
        },
        {
            title: 'Coupons',
            hide: [],
            validationSchema:
                myYup.object().shape({
                    "checkboxCoupon": myYup.string(),
                    "MotifEcoupons1": myYup.string().when('checkboxCoupon', {
                        is: (val) => val == "Offrir un coupon",
                        then: myYup.string().required().max(24),
                        otherwise: null,
                    }),
                    "reqEUREcoupons": myYup.number()
                        .when(['checkboxCoupon', 'typeCoupon'], {
                            is: (checkboxCoupon, typeCoupon) => checkboxCoupon == "Offrir un coupon" && typeCoupon.id == "pme2",
                            then: myYup
                                .number()
                                .required()
                                .min(1)
                                .max(2000),
                            otherwise: null,
                        }),
                    "reqPointsEcoupons": myYup.number('ZEZEZE')
                        .when(['checkboxCoupon', 'typeCoupon'], {
                            is: (checkboxCoupon, typeCoupon) => checkboxCoupon == "Offrir un coupon" && typeCoupon.id == "pme1",
                            then: myYup
                                .number()
                                .required()
                                .min(1)
                                .max(2000),
                            otherwise: null,

                        }),
                    "validEUREcoupons": myYup.number()
                        .when(['checkboxCoupon', 'typeCoupon'], {
                            is: (checkboxCoupon, typeCoupon) => checkboxCoupon == "Offrir un coupon" && typeCoupon.id == "pme2",
                            then: myYup
                                .number()
                                .required()
                                .min(1),
                            otherwise: null,
                        }),
                    "validPointsEcoupons": myYup.number()
                        .when(['checkboxCoupon', 'typeCoupon'], {
                            is: (checkboxCoupon, typeCoupon) => checkboxCoupon == "Offrir un coupon" && typeCoupon.id == "pme1",
                            then: myYup
                                .number()
                                .required()
                                .min(1),
                            otherwise: null,
                        }),
                })

        },
        {
            title: 'Conception',
            hide: [],
            validationSchema: myYup.object().shape({
                titre: myYup.string().required(),
                objet: myYup.string().when('modeEnvoi', {
                    is: (val) => { console.log(val); return val == "email" },
                    then: myYup.string().required(),
                    otherwise: myYup.string(),
                }),
                message: myYup.string().required()
            }),
        },
        {
            title: 'Destinataires',
            validationSchema: null,
            hide: ['Anniversaires']
        },
        {
            title: 'Récapitulatif',
            validationSchema: null,
            hide: []
        }
    ],
    columnsModele: [
        {
            Header: 'Id',
            accessor: 'id_modele',
            field: 'id_modele',
            isSortable: false,
            isHidden: true
        },
        {
            Header: 'Nom du modèle',
            accessor: 'nom_modele',
            field: 'nom_modele',
            isSortable: true,
            isHidden: false
        },
        {
            Header: 'json',
            accessor: 'json',
            field: 'json',
            isSortable: false,
            isHidden: true
        },
        {
            Header: 'contenu',
            accessor: 'contenu',
            field: 'contenu',
            isSortable: false,
            isHidden: true
        },
        {
            Header: 'preview',
            accessor: 'preview',
            field: 'preview',
            isSortable: false,
            isHidden: true
        },
        {
            Header: 'editorType',
            accessor: 'editorType',
            field: 'editorType',
            isSortable: false,
            isHidden: true
        },
        {
            Header: 'Date de création',
            accessor: 'dateCreation',
            field: 'dateCreation',
            isSortable: true,
            isHidden: false,
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY')
                else
                    return "-"
            },
        },
        // {
        //     Header: 'Aperçu',
        //     accessor: 'preview',
        //     field: 'preview',
        //     isSortable: false,
        //     isHidden: false,
        //     Cell: ({ row, setPreviewSaved, setSelectedRow }) => {

        //         return (
        //             <button onClick={() => { setPreviewSaved(row.values.json, row.values.contenu); setSelectedRow(row.id) }} className="btn btn-xs btn-outline-old">
        //                 <FontAwesomeIcon icon={faSearch} className="mr-2"></FontAwesomeIcon>
        //                 Aperçu
        //             </button>
        //         )
        //     }
        // },
        // {
        //     Header: 'Actions',
        //     accessor: 'actions',
        //     field: 'actions',
        //     isSortable: false,
        //     isHidden: false,
        //     Cell: ({ row, setPreview, editModele, deleteModele, useModele, modeEnvoi }) => {

        //         return (
        //             <EditModeleDropDown
        //                 id={row.values.id_modele}
        //                 json={row.values.json}
        //                 modeEnvoi={modeEnvoi}
        //                 contenu={row.values.contenu}
        //                 setPreview={setPreview}
        //                 useModele={useModele}
        //                 editModele={editModele}
        //                 deleteModele={deleteModele}
        //             ></EditModeleDropDown >
        //         )
        //     }
        // },

    ],
    columnsModelePre: [
        {
            Header: 'Id',
            accessor: 'id_modele',
            field: 'id_modele',
            isSortable: false,
            isHidden: true
        },
        {
            Header: 'Nom du modèle',
            accessor: 'nom_modele',
            field: 'nom_modele',
            isSortable: true,
            isHidden: false
        },
        {
            Header: 'json',
            accessor: 'json',
            field: 'json',
            isSortable: false,
            isHidden: true
        },
        {
            Header: 'contenu',
            accessor: 'contenu',
            field: 'contenu',
            isSortable: false,
            isHidden: true
        },
        {
            Header: 'preview',
            accessor: 'preview',
            field: 'preview',
            isSortable: false,
            isHidden: true
        },
        {
            Header: 'editorType',
            accessor: 'editorType',
            field: 'editorType',
            isSortable: false,
            isHidden: true
        },
        {
            Header: 'Date de création',
            accessor: 'dateCreation',
            field: 'dateCreation',
            isSortable: true,
            isHidden: false,
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('DD/MM/YYYY')
                else
                    return "-"
            },
        },
        // {
        //     Header: 'Aperçu',
        //     accessor: 'preview',
        //     field: 'preview',
        //     isSortable: false,
        //     isHidden: false,
        //     Cell: ({ row, setPreviewSaved, setSelectedRow }) => {

        //         return (
        //             <button onClick={() => { setPreviewSaved(row.values.json, row.values.contenu); setSelectedRow(row.id) }} className="btn btn-xs btn-outline-old">
        //                 <FontAwesomeIcon icon={faSearch} className="mr-2"></FontAwesomeIcon>
        //                 Aperçu
        //             </button>
        //         )
        //     }
        // },
        // {
        //     Header: 'Actions',
        //     accessor: 'actions',
        //     field: 'actions',
        //     isSortable: false,
        //     isHidden: false,
        //     Cell: ({ row, setPreview, editModele, deleteModele, useModele, modeEnvoi }) => {

        //         return (
        //             <EditModeleDropDown
        //                 id={row.values.id_modele}
        //                 json={row.values.json}
        //                 modeEnvoi={modeEnvoi}
        //                 contenu={row.values.contenu}
        //                 setPreview={setPreview}
        //                 useModele={useModele}
        //                 editModele={editModele}
        //                 deleteModele={deleteModele}
        //             ></EditModeleDropDown >
        //         )
        //     }
        // },

    ],
    columns: [
        {
            Header: 'Id',
            accessor: 'idcli',
            field: 'idcli',
            isSortable: false,
            isHidden: true
        },
        {
            id: 'selection',
            Header: ({ exclues, data, toggleCheckboxAll }) => (
                <div className="form-check abc-checkbox abc-checkbox-fid">
                    <input id="item_all" className="form-check-input" onChange={() => toggleCheckboxAll()} checked={!exclues.some(r => data.map(item => item.idcli).includes(r.idcli))} type="checkbox" />
                    <label for="item_all" className="form-check-label"></label>
                </div>
            ),
            Cell: ({ row, toggleCheckbox, exclues }) => (
                <div className="form-check abc-checkbox abc-checkbox-fid">
                    <input id={'item_' + row.id} checked={!exclues.map(item => item.idcli).includes(row.values.idcli)} onChange={() => toggleCheckbox(row.values.idcli, row.values.smsLength)} className="form-check-input" type="checkbox" />
                    <label for={'item_' + row.id} className="form-check-label"></label>
                </div>
            ),
        },
        // {
        //     Header: 'Qté SMS',
        //     accessor: 'smsLength',
        //     field: 'smsLength',
        //     isSortable: false,
        //     isHidden: false,
        //     Cell: ({ cell: { value } }) => (
        //         <span className="sms-count">{value}</span>
        //     ),
        // },
        {
            Header: 'N° de carte',
            accessor: 'cltNumCard',
            field: 'clientNumCard',
            isSortable: true,
            isHidden: true
        },
        {
            Header: 'Civilité',
            accessor: 'cltCiv',
            field: 'clientCivilite',
            Cell: ({ cell: { value } }) => (value ? Civilite[value] : '-')
        },
        {
            Header: 'Nom',
            accessor: 'clientNP',
            isSortable: true,
            field: 'clientNP',
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'Pme1',
            isSortable: true,
            field: 'Pme1',
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'Pme2',
            isSortable: true,
            field: 'Pme2',
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumCredPme1',
            field: 'cumCredPme1',
            isSortable: true,
            isHidden: false,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: ({ column, setUnitsHeader }) => {
                return setUnitsHeader(column.id)
            },
            accessor: 'cumCredPme2',
            field: 'cumCredPme2',
            isSortable: true,
            isHidden: false,
            Cell: ({ cell: { value } }) => number(value),
        },
        {
            Header: 'Dernier Achat',
            accessor: 'derAchat',
            field: 'dernierAchat',
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).fromNow()
                else
                    return "-"
            },
        },
        {
            Header: 'Né le',
            accessor: 'cltNeLe',
            isSortable: true,
            field: 'clientNeLe',
            Cell: ({ cell: { value } }) => {
                if (moment(value).isValid())
                    return moment(value).format('L')
                else
                    return "-"
            }
        },
        {
            Header: 'Expiration Pme1',
            isSortable: true,
            accessor: 'Expiration_Pme1',

        },
        {
            Header: 'Expiration Pme2',
            isSortable: true,
            accessor: 'Expiration_Pme2',
        },
        {
            Header: 'Tél. mobile',
            accessor: 'cltGsm',
            isSortable: true,
            field: 'clientGsm',
        },
        {
            Header: 'Email',
            accessor: 'cltEmail',
            field: 'clientEmail',
            isSortable: true,
            isEditable: true,
            typeInput: 'email',
            isHidden: false
        },
        {
            Header: 'Enseigne',
            accessor: 'NomCom',
            isSortable: true,
            isHidden: true
        }

    ],
    designer: null,
    patternFilter: {
        idcommercantFid: {
            active: true,
            title: 'Enseigne(s)',
            name: 'idcommercantFid',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                name: 'idcommercantFid',
                type: 'multiple',
                list: [
                    { id: 'all', label: 'Tout sélectionner' },
                    { id: '1', label: 'THIRIET JABRUN' },
                    { id: '2', label: 'THIRIET JABRUN' },
                    { id: '3', label: 'THIRIET JABRUN' },
                    { id: '4', label: 'THIRIET JABRUN' },
                    { id: '5', label: 'THIRIET JABRUN' },
                    { id: '6', label: 'THIRIET JABRUN' },
                ],
            },
            default: {
                name: 'idcommercantFid',
                operator: 'in',
                value: [1, 2, 3]
            },
        },
        clientCivilite: {
            active: true,
            title: 'Civilité',
            name: 'clientCivilite',
            operator: {
                type: 'multiple',
                list: ['in'],
            },
            field: {
                type: 'multiple',
                list: Civilite.map((item, index) => ({ id: index, label: item })).filter(item => item.id != ''),
            },
            default: {
                name: 'clientCivilite',
                operator: ['in'],
                value: [1, 2, 3]
            }

        },
        clientNom: {
            active: true,
            title: 'Nom',
            name: 'clientNom',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientNom',
                type: 'text'
            },
            default: {
                name: 'clientNom',
                operator: 'like',
                value: 'Nom'
            }
        },
        clientPrenom: {
            active: true,
            title: 'Prénom',
            name: 'clientPrenom',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientNom',
                type: 'text'
            },
            default: {
                name: 'clientPrenom',
                operator: 'like',
                value: 'Prénom'
            }
        },
        clientAdresse1: {
            active: true,
            title: 'Adresse 1',
            name: 'clientAdresse1',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientAdresse1',
                type: 'text'
            },
            default: {
                name: 'clientAdresse1',
                operator: 'like',
                value: 'Adresse 1'
            }
        },
        clientAdress2: {
            active: true,
            title: 'Adresse 1',
            name: 'clientAdress2',
            operator: {
                type: 'select',
                list: ['like', 'not like', '=', '<>'].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientAdress2',
                type: 'text'
            },
            default: {
                name: 'clientAdress2',
                operator: 'like',
                value: 'Adresse 2'
            }
        },
        clientNeLe: {
            active: true,
            title: 'Date de naissance',
            name: 'clientNeLe',
            operator: {
                type: 'select',
                list: ['=', '<>', 'between', '>', '>=', '<', '<='].map(item => ({ id: item, label: Operators[item] }))
            },
            field: {
                name: 'clientNeLe',
                type: 'date'
            },
            default: {
                name: 'clientNeLe',
                operator: 'like',
                value: moment().format('YYYY-MM-DD')
            }
        }
    },
    credits: {},
    initialFilterValues: [],
    originalTimzoneList: [],
    campStatut: []

}

const CampagneWizardStore = {
    /***** Props ******/
    props: props,

    async init(mode, action, notResetFilter, setFieldValue, obj = null) {
        console.log(action + ' ' + mode)
        // console.log(toJS(obj))
        this.reset()
        await this.initColumns()
        await this.initTitles()
        this.props.modeEnvoi = mode
        // console.log(toJS(this.props.initialValues))
        this.doLoading(true, 'Chargement en cours ...')
        // console.log(notResetFilter)
        if (!notResetFilter)
            ClientFilterStore.reset()
        await ClientFilterStore.initFilterClient()

        await this.getClients()
        await this.getCreditsAmount()
        await this.getModeleEmail()
        await this.getModeleEmailPre()
        await this.getPrixCom()
        await this.getTimeZones()
        this.initListCoupons()

        if (action == 'new' && !obj) {
            setFieldValue('modeEnvoi', mode)
            // console.log(toJS(this.props.listTypeCoupon[0]))
            setFieldValue('typeCoupon', this.props.listTypeCoupon[0])
            let timezone = this.props.originalTimzoneList.find(item => moment.tz.guess().includes(item.label))
            if (timezone)
                setFieldValue('timezone', toJS(timezone))

        }

        if (obj) {
            // this.props.exclues = obj['exclues'].split(",")
            // console.log(toJS(obj['exclues']),toJS(obj['exclues'].split(",")))
            ClientFilterStore.loadCriteresJson(JSON.parse(obj['criteres_json']))
            let timezone = this.props.originalTimzoneList.find(item => item.value == obj['timezone'])
            if (timezone)
                setFieldValue('timezone', toJS(timezone))
            if (obj['idCoupon']) {
                console.log('IF')
                setFieldValue('typeCoupon', this.props.listTypeCoupon.find(item => {
                    console.log(parseFloat(obj['reqPointsEcoupons']) > 0, item.id)
                    if (parseFloat(obj['reqPointsEcoupons']) > 0)
                        return item.id == 'pme1'
                    if (parseFloat(obj['reqEUREcoupons']) > 0)
                        return item.id == 'pme2'

                }))
            }
            else {
                console.log('ELSE')
                setFieldValue('typeCoupon', this.props.listTypeCoupon[0])
            }

            if (mode == 'email') {
                if (obj['message_json']) {
                    console.log(JSON.parse(obj['message_json']))
                    this.setPreview(JSON.parse(obj['message_json']), obj['message'], obj['editorType'])
                }
            }
            if (mode == 'sms') {
                if (obj['message']) {
                    this.setMsg(obj['message'])
                }
            }
        }

        this.doLoading(false)
    },
    initListCoupons() {
        let list = this.props.listTypeCoupon.map(item => {
            if (item.id == 'pme1')
                return { id: item.id, label: UserStore.props.user.unitesPME1 }
            if (item.id == 'pme2')
                return { id: item.id, label: UserStore.props.user.unitesPME2 }
        })

        if (UserStore.isWallet() || UserStore.isNovacash())
            this.props.listTypeCoupon = list.filter(item => item.id = "pme2")

        this.props.listTypeCoupon = list
    },
    async initEdit(mode, obj) {
        this.reset()
        await this.initColumns()
        this.props.modeEnvoi = mode
        this.doLoading(true, 'Chargement en cours ...')
        ClientFilterStore.reset()
        await ClientFilterStore.initFilterClient()
        await this.getClients()
        await this.getCreditsAmount()
        await this.getModeleEmail()
        await this.getModeleEmailPre()
        await this.getPrixCom()
        await this.getTimeZones()
        // await this.getInitialValuesForEdit(mode, obj)

        this.doLoading(false)
    },
    async initColumns() {
        return new Promise(resolve => {
            this.props.columns = this.props.columns.map(item => {

                if (UserStore.isWallet()) {
                    if (item.accessor == 'cltNele'
                        // || item.accessor == 'Segment'
                        || item.accessor == 'Pme1'
                        || item.accessor == 'cltNeLe'
                        || item.accessor == 'cltGsm'
                        || item.accessor == 'cltEmail'
                        || item.accessor == 'cumCredPme1'
                        || item.accessor == 'cumRech1'
                        || item.accessor == 'cumBonus1'
                        || item.accessor == 'cumDebPme1'
                        || item.accessor == 'NouveauPME1'
                        || item.accessor == 'Expiration_Pme1'
                        || item.accessor == 'Expiration_Pme2'
                        || item.accessor == 'creditPme1'
                        || item.accessor == 'debitPme1'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true
                    }

                    if (item.accessor == 'derAchat') {
                        item.Header = "Dernière transaction"
                    }

                    if (item.accessor == 'cltNumCard') {
                        item.Header = "N° client"
                    }
                }

                if (UserStore.isNovacash()) {
                    if (
                        item.accessor == 'Pme1'
                        || item.accessor == 'cumCredPme1'
                        || item.accessor == 'cumRech1'
                        || item.accessor == 'cumBonus1'
                        || item.accessor == 'cumDebPme1'
                        || item.accessor == 'NouveauPME1'
                        || item.accessor == 'Expiration_Pme1'
                        || item.accessor == 'creditPme1'
                        || item.accessor == 'debitPme1'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true

                    }
                }

                if (UserStore.isFidSimple()) {
                    if (item.accessor == 'Pme2'
                        || item.accessor == 'cumDebPme2'
                        || item.accessor == 'cumRech2'
                        || item.accessor == 'cumCredPme2'
                        || item.accessor == 'cumBonus2'
                        || item.accessor == 'NouveauPME2'
                        || item.accessor == 'creditPme2'
                        || item.accessor == 'debitPme2'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true

                    }
                }

                if (UserStore.isCampagne()) {
                    if (
                        item.accessor == 'Pme2'
                        || item.accessor == 'cltNumCard'
                        || item.accessor == 'cltCardOFF'
                        || item.accessor == 'cltCardOFF'
                        || item.accessor == 'cumDebPme2'
                        || item.accessor == 'cumRech2'
                        || item.accessor == 'cumCredPme2'
                        || item.accessor == 'cumBonus2'
                        || item.accessor == 'derAchat'
                        || item.accessor == 'NouveauPME2'
                        || item.accessor == 'creditPme2'
                        || item.accessor == 'debitPme2'
                        || item.accessor == 'Pme1'
                        || item.accessor == 'cumCredPme1'
                        || item.accessor == 'cumRech1'
                        || item.accessor == 'cumBonus1'
                        || item.accessor == 'cumDebPme1'
                        || item.accessor == 'NouveauPME1'
                        || item.accessor == 'Expiration_Pme1'
                        || item.accessor == 'Expiration_Pme2'
                        || item.accessor == 'creditPme1'
                        || item.accessor == 'debitPme1'
                        || item.accessor == 'Segment'
                        || item.accessor == 'RFM_Score'
                        || item.accessor == 'cltRIB'
                        || item.accessor == 'cumSpot'
                        || item.accessor == 'cumSpot'
                        || item.accessor == 'cltPersonnalise'
                        || item.accessor == 'NomCom'
                    ) {
                        item.isNotDisplay = true
                        item.isSortable = false
                        item.isFilterable = false
                        item.isHidden = true
                    }
                    if (
                        item.accessor == 'cltCP'
                        || item.accessor == 'cltVille'
                    ) {
                        item.isHidden = false
                    }
                }

                return item
            })

            resolve(true)
        })

    },
    getInitialValues(action, mode, obj = null) {
        if (!obj)
            return this.props.initialValues
        // console.log(toJS(obj))
        // console.log(JSON.parse(obj['message_json']))
        let res = {
            modeEnvoi: mode,
            idCampagnesms: obj['idcampagnesms'],
            titre: obj['titre'],
            etat: obj['etat'],
            objet: obj['objet'] ? obj['objet'] : '',
            message: obj['message'],
            message_json: obj['message_json'] ? obj['message_json'] : null,
            typeEnvoi: obj['typeEnvoi'],
            timezone: '',
            checkboxCoupon: obj['idCoupon'].length ? "Offrir un coupon" : "Ne pas offrir d'e-coupon",
            editorType: obj['editorType'],
            saveModele: false,
            json: obj['json'],
            id_coupon: obj['idCoupon'],
            MotifEcoupons1: obj['MotifEcoupons1'],
            reqEUREcoupons: obj['reqEUREcoupons'],
            reqPointsEcoupons: obj['reqPointsEcoupons'],
            validEUREcoupons: obj['validEUREcoupons'],
            validPointsEcoupons: obj['validPointsEcoupons'],
            immediatProgramme: "",
            dateEnvoi: moment(obj['dateEnvoi']).format('YYYY-MM-DD'),
            programmerHeure: "",
            envoiRecurr: "",
        }

        //Si envoi programme
        if (obj['recurrent'] == 2) {
            // console.log(obj['timezone'])
            res['envoiRecurr'] = ""
            res['typeEnvoi'] = "Programmé"
            res['programmerHeure'] = moment(obj['dateEnvoi']).format('HH:mm')
            const timezone = this.props.originalTimzoneList.find(item => item.id == obj['timezone'])
            res['timezone'] = this.props.originalTimzoneList.find(item => item.id == obj['timezone'])
        }

        if (obj['recurrent'] != 0 && obj['recurrent'] != 2) {
            res['envoiRecurr'] = obj['recurrent'] == 1 ? frequenceEnvoi.find(item => item.id == "Anniversaires") : frequenceEnvoi.find(item => item.id == obj['recurrent'])
            res['typeEnvoi'] = "Récurrent"
            res['programmerHeure'] = moment(obj['dateEnvoi']).format('HH:mm')
            res['timezone'] = this.props.originalTimzoneList.find(item => item.id == obj['timezone'])
        }


        return res

    },
    async getStatutCampagne() {

        let data = {
            'objName': 'statutCampagne',
            'cmd': 'getRows',
            'where': 'idcampagnesms= 1445',
            'start': this.props.pageStart,
            'sort': 'envoye ASC',
        }


        const res = await api.post('/scripts/sms/smss.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.campStatut = !res.data.rows ? [] : res.data.rows
            } else {
                this.props.campStatut = []
            }
        }

    },
    initTitles() {
        return new Promise(resolve => {
            if (UserStore.isCampagne())
                this.props.titles = this.props.titles.filter(item => item.title != 'Coupons')

            resolve(true)
        })
    },
    changeTitles(type) {
        if (typeof type != 'object')
            return this.props.titles = props.titles
        if (type.id == 'Anniversaires')
            this.props.titles = this.props.titles.filter(item => !item.hide.includes('Anniversaires'))
        else
            this.props.titles = props.titles

        if (UserStore.isCampagne())
            this.props.titles = this.props.titles.filter(item => item.title != 'Coupons')
    },
    doLoading(state, msg = '') {
        this.props.loading.msg = msg
        this.props.loading.status = state
    },
    doLoadingPayment(state, msg = '') {
        this.props.loadingPayment.msg = msg
        this.props.loadingPayment.status = state
    },
    doLoadingModele(state, msg = '') {
        this.props.loadingModele.msg = msg
        this.props.loadingModele.status = state
    },
    rowIsSelected(id) {
        const position = this.props.exclues.findIndex(item => item.idcli == id)
        console.log(id, position)
        return position < 0
    },
    allIsSelected() {
        console.log('allIsSelect')
        return !this.props.exclues.some(r => this.props.data.map(item => item.idcli).includes(r.idcli))
    },
    async submitCampagne(values) {
        this.doLoading(true, 'Enregistrement en cours ...')
        console.log(values)
        let to_send = toJS({ ...values })

        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let clause = ''
        if (!UserStore.isWallet()) {
            if (this.props.modeEnvoi == "sms")
                clause = " AND LENGTH(clientGsm) > 8 "
            else if (this.props.modeEnvoi == "email")
                clause = " AND LENGTH(clientEmail) > 5 "
        }

        let where = ''

        if (UserStore.isReseau())
            where = ClientFilterStore.getWhere('c.iDreseauFid = ' +
                idRes + ' AND clientTypeCarte<> 6  '
                + ' AND clientAdresseIncorrect=0 '
                + clause, 'campaign')
        else
            where = ClientFilterStore.getWhere('c.idcommercantFid = ' +
                idCom + ' AND clientTypeCarte<> 6 '
                + ' AND clientAdresseIncorrect=0 '
                + clause, 'campaign')

        to_send['modeEnvoi'] = modeEnvoiDetails[to_send['modeEnvoi']].key
        to_send['criteres'] = where
        to_send['criteres_json'] = JSON.stringify(toJS(ClientFilterStore.getCurrentFilterJson()))
        to_send['message_json'] = to_send['message_json']
        to_send['timezone'] = to_send['timezone'].value
        to_send['selectionnes'] = []
        to_send['exclues'] = toJS(this.props.exclues.map(item => item.idcli))
        if (typeof to_send['envoiRecurr'] == 'object')
            to_send['envoiRecurr'] = to_send['envoiRecurr'].id
        console.log(to_send['criteres'])
        console.log(to_send)



        let data = {}
        if (to_send['idCampagnesms']) {
            if (parseInt(to_send['etat']) == -1 || parseInt(to_send['etat']) == 1) {
                data = {
                    cmd: "enregistrerCampagneV2",
                    idCommercant: UserStore.props.user.idCom,
                    idReseau: UserStore.props.user.idRes,
                    idUser: UserStore.props.user.idUser,
                    data: JSON.stringify(to_send)
                }
            }
            else {
                data = {
                    cmd: "mettreAjourCampagneV2",
                    idCampagnesms: to_send['idCampagnesms'],
                    idCommercant: UserStore.props.user.idCom,
                    idReseau: UserStore.props.user.idRes,
                    idUser: UserStore.props.user.idUser,
                    data: JSON.stringify(to_send)
                }
            }
        } else {
            data = {
                cmd: "enregistrerCampagneV2",
                idCommercant: UserStore.props.user.idCom,
                idReseau: UserStore.props.user.idRes,
                idUser: UserStore.props.user.idUser,
                data: JSON.stringify(to_send)
            }
        }

        const res = await api.post("/scripts/campagne/campagne.php", data)

        if (res.data.success) {
            // this.props.currStep = -1
            this.props.success = true
            this.props.preview = {}
            toast.success(res.data.error)
        } else {
            toast.error(res.data.error)
        }
        this.doLoading(false)

    },
    getEditoroptions() {
        let editorOptions = {
            locale: 'fr-FR',
            appearance: {
                panels: {
                    tools: {
                        dock: 'left'
                    }
                }
            },
            mergeTags: {
                civilite: {
                    name: "Mme/Mlle/Mr",
                    value: " ::civilite:: "
                },
                nom: {
                    name: "Nom du client",
                    value: " ::nom:: "
                },
                prenom: {
                    name: "Prénom du client",
                    value: " ::prenom:: "
                },
                solde_points: {
                    name: "Solde " + UserStore.props.user.unitesPME1 + " du client",
                    value: " ::soldePME1:: " + UserStore.props.user.unitesPME1 + " "
                },
                solde_euros: {
                    name: "Solde " + UserStore.props.user.unitesPME2 + " du client",
                    value: " ::soldePME2:: " + UserStore.props.user.unitesPME2 + " "
                },
                exp_points: {
                    name: "Nombre de jours avant expiration des " + UserStore.props.user.unitesPME1,
                    value: " ::expirationPME1:: jour(s) "
                },
                exp_euros: {
                    name: "Nombre de jours avant expiration des " + UserStore.props.user.unitesPME2,
                    value: " ::expirationPME2:: jour(s) "
                },
            },
            translations: {
                'fr-FR': {
                    "labels.merge_tags": "Informations du client",
                    "editor.placeholder.text": "Pas de contenu ici. Faites glisser le contenu de gauche.",
                }
            }
        }
        if (UserStore.isReseau())
            editorOptions['mergeTags']['nomResCom'] = {
                name: "Nom du réseau :" + UserStore.props.user.nom_reseau,
                value: UserStore.props.user.nom_reseau
            }
        else if (UserStore.isCommercant())
            editorOptions['mergeTags']['nomResCom'] = {
                name: "Nom du réseau :" + UserStore.props.user.nom_commercant,
                value: UserStore.props.user.nom_commercant
            }

        if (UserStore.isCampagne()) {
            delete editorOptions['mergeTags']['solde_points']
            delete editorOptions['mergeTags']['solde_euros']
            delete editorOptions['mergeTags']['exp_points']
            delete editorOptions['mergeTags']['exp_euros']
        }

        return editorOptions
    },
    setModeleToEdit(json, contenu) {
        if (json)
            this.props.preview.json = json
        if (contenu)
            this.props.preview.message = contenu
    },
    setPreview(json, contenu, editorType) {
        if (json)
            this.props.preview.json = JSON.stringify(json)
        if (contenu)
            this.props.preview.message = contenu
        if (editorType)
            this.props.preview.editorType = editorType
    },
    setPreviewSaved(json, contenu) {
        if (json)
            this.props.previewSaved.json = JSON.stringify(json)
        if (contenu)
            this.props.previewSaved.message = contenu
    },
    editModele(json, contenu) {
        console.log(json)
        this.props.preview.json = json
        this.props.preview.message = contenu
        this.toggleModalLoad()
        if (this.props.modeEnvoi == 'email')
            this.toggleModalBuilder()
        else if (this.props.modeEnvoi == 'sms')
            this.toggleModalSMS()
    },
    addTag(myField, tag) {
        console.log('addTag')
        if (!myField)
            return
        //IE support
        if (document.selection) {
            myField.focus();
            let sel = document.selection.createRange();
            sel.text = tag;
        }
        //MOZILLA and others
        else if (myField.selectionStart || myField.selectionStart == '0') {
            let startPos = myField.selectionStart;
            let endPos = myField.selectionEnd;
            myField.value = myField.value.substring(0, startPos)
                + tag
                + myField.value.substring(endPos, myField.value.length);
            myField.selectionStart = startPos + tag.length;
            myField.selectionEnd = startPos + tag.length;
        } else {
            myField.value += tag;
        }
    },
    // saveSMS(sms) {
    //     this.props.initialValues.message = sms
    // },
    // setSMS() {
    //     this.props.initialValues.message = this.props.preview.message
    // },
    // useModele(json, contenu) {
    //     // console.log('useModel',json)
    //     this.props.initialValues.json = json
    //     this.props.initialValues.message = contenu
    //     this.toggleModalLoad()
    //     // this.toggleModalBuilder()
    // },
    async deleteModele(id) {
        console.log(id)
        this.doLoading(true, 'Suppression en cours ...')

        const idUser = UserStore.props.user.idUser
        let toSave = {
            "id_modele": id,
        }

        let data = {
            'objName': 'modele_sms',
            'cmd': 'delRows',
            'data': JSON.stringify([toSave]),
            'where': ' id_modele = ' + id
            // 'query': JSON.stringify(where)
        }

        const res = await api.post('/scripts/campagne/modeleEmail.php', data)
        if (res.data.success) {
            toast.success('Le modèle a bien été supprimé')
        }
        else {
            toast.error('Une erreur est survenue')
        }
        this.getModeleEmail()
        this.doLoading(false)
    },
    toggleCheckboxAll() {
        console.log('hasExclue')
        const hasOneExclue = this.props.exclues.some(r => this.props.data.map(item => item.idcli).includes(r.idcli))
        if (hasOneExclue) {
            this.props.data.map(item => {
                const position = this.props.exclues.findIndex(value => value.idcli == item.idcli);
                if (position > -1) {
                    this.props.exclues.splice(position, 1)
                }
            })
        }
        else {
            console.log('NothingExclue')
            this.props.data.map(item => {
                const position = this.props.exclues.findIndex(value => value.idcli == item.idcli);
                if (position < 0)
                    this.props.exclues.push({ idcli: item.idcli, smsLength: item.smsLength })
            })
        }
        console.log(toJS(this.props.exclues))
    },
    toggleCheckbox(idcli, smsLength) {
        const position = this.props.exclues.findIndex(item => item.idcli == idcli);
        console.log(idcli, position)
        if (position > -1) {
            this.props.exclues.splice(position, 1)
        } else {
            this.props.exclues.push({ idcli: idcli, smsLength: smsLength })
        }
    },
    allIsSelected() {
        return this.props.exclues.length == 0
    },
    async getCreditsAmount() {
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let where = ''
        if (UserStore.isReseau())
            where = 'iDreseauFid = ' + idRes
        else
            where = 'idcommercantFid = ' + idCom

        let data = {
            start: 0,
            limit: 1,
            sort: 'nbSMS',
            dir: 'DESC',
            cmd: 'getRows',
            objName: 'leCompteSMS',
            where: where,
        }

        const res = await api.post('/scripts/sms/smss.php', data)
        if (res.data.success) {
            if (res.data.rows && res.data.rows.length) {
                this.props.credits = !res.data.rows.length ? {} : res.data.rows[0]

                this.props.credits.cumulSMS = parseInt(res.data.rows[0].cumulSMS)
                this.props.credits.nbSMS = parseInt(res.data.rows[0].nbSMS)
                this.props.credits.pCourrier = parseInt(res.data.rows[0].pCourrier)
                this.props.credits.pMail = parseInt(res.data.rows[0].pMail)
                this.props.credits.pPush = parseInt(res.data.rows[0].pPush)
                this.props.credits.pSms = parseInt(res.data.rows[0].pSms)

            }
        }
    },

    async getFilterdClients() {
        this.doLoading(true, 'Chargement en cours ...')
        await this.getClients()
        this.resetExclues()
        this.doLoading(false)
    },
    async getTarifs() {
        let data = {
            'cmd': 'getTarifsJson',
            'param': UserStore.props.user.idCompteSMS
        }

        const res = await api.post('/scripts/cb/tarifs.php', data)
        if (res.data.success) {
            this.props.tarifs = res.data.rows.map(item => ({ id: item[0], label: number(item[0]) }))
            // console.log(toJS(this.props.tarifs))
        }
        else {
            toast.error('GET TARIFS - Une erreur est survenue.')
        }

    },
    async getPrix(montant, loading = false) {
        if (loading)
            this.doLoadingPayment(true, 'Chargement du prix ...')
        let data = {
            'cmd': 'getPrix',
            'param': parseFloat(montant)
        }

        const res = await api.post('/scripts/cb/tarifs.php', data)
        if (res.data) {
            this.props.prix = res.data.prix
            this.doLoadingPayment(false)
            return res.data.prix
        } else {
            this.doLoadingPayment(false)
        }

    },
    async getTimeZones() {

        let data = {
            'cmd': 'listeTZ',
        }

        const res = await api.post('/scripts/campagne/utils.php', data)
        if (res.data.success) {

            let choixRapide = []
            let original = []

            let result = res.data.rows.reduce(function (r, a) {
                let fuseau = a.tz.split(" ( ")
                let temp = fuseau[0].split("/")
                let pays = temp[0]
                temp.shift()
                let ville = temp.join(',')
                r[pays] = r[pays] || []

                original.push({
                    value: a.tzId,
                    label: ville
                })

                r[pays].push({
                    value: a.tzId,
                    label: ville,
                    // heure: fuseau[1].replace(' )', '')
                });

                if ([349, 109, 139, 85].includes(a.tzId)) {
                    choixRapide.push({
                        value: a.tzId,
                        label: ville,
                        // heure: fuseau[1].replace(' )', '')
                    })
                }


                return r;
            }, Object.create(null));
            // console.log(original)
            this.props.originalTimzoneList = original

            let list = Object.keys(result).map(item => ({ label: item, options: result[item] }))

            list.unshift({ label: 'CHOIX RAPIDE', options: choixRapide })
            // console.log(list)
            this.props.groupOptionsTimezones = list
        }
    },
    async getPrixCom(montant, loading = false) {
        if (loading)
            this.doLoadingPayment(true, 'Chargement du prix ...')
        let data = {
            'cmd': 'getEquivJson',
            'param': UserStore.props.user.idCompteSMS
        }

        const res = await api.post('/scripts/cb/tarifs.php', data)
        if (res.data.success) {
            this.props.prixCom = res.data.rows
            this.doLoadingPayment(false)
            return res.data.prixCom
        } else {
            this.doLoadingPayment(false)
        }

    },
    async submitAddCreditForm(values) {
        // console.log(values)
        // return
        this.doLoadingPayment(true, 'Paiement en cours ...')
        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom

        let who = null
        let nom = null
        let id = null

        const to_send = { ...values } // On clone l'objet
        to_send['nBank'] = to_send['nBank'].trim().replace(/ /g, '')
        // console.log(to_send['nBank'].trim().replace(/ /g,''))

        if (UserStore.isReseau()) {
            nom = UserStore.props.user.nom_reseau
            who = "res"
            id = idRes
        }

        if (UserStore.isCommercant()) {
            nom = UserStore.props.user.nom_commercant
            who = "com"
            id = idCom
        }

        let data = {
            "cmd": "paieSMS",
            "ref": "paieSMS",
            "dataCb": JSON.stringify(to_send),
            "id": id,
            "who": who,
            "nom": nom
        }

        const res = await api.post('/scripts/cb/cb.php', data)
        if (res.data.success) {
            toast.success("Paiement réussi - " + res.data.COMMENTAIRE)
            this.doLoadingPayment(true, 'Mise à jour des crédits ...')
            await this.getCreditsAmount()
            this.toggleModalPayment()
        }
        else {
            toast.error(res.data.error)
        }

        this.doLoadingPayment(false)
    },
    resetExclues() {
        this.props.exclues = []
    },
    getSmsLength(){
        return this.props.oneSmsLength
    },
    getNbSmsToSend() {
        if (this.props.modeEnvoi == 'sms')
            return this.props.nbSmsToSend - (this.props.exclues.length * this.props.oneSmsLength)
        else if (this.props.modeEnvoi == 'email'){
            return this.getTotalDestinatiares()
        }
    },
    getTotalDestinatiares() {
        return this.props.pageTotal - this.props.exclues.length
    },
    coefficientSMSMail() {

        let res = []
        if (this.props.modeEnvoi) {
            const labelPrix = modeEnvoiDetails[this.props.modeEnvoi].prixLabel
            // console.log(labelPrix,toJS(this.props.prixCom))
            res = [{
                label: modeEnvoiDetails[this.props.modeEnvoi].label,
                value: parseFloat(this.props.prixCom[labelPrix])
            }]
        }
        else {
            res = []
            Object.keys(modeEnvoiDetails).map(key => {
                if (modeEnvoiDetails[key].show)
                    res.push({
                        label: modeEnvoiDetails[key].label,
                        value: parseFloat(this.props.prixCom[modeEnvoiDetails[key].prixLabel])
                    })
            })
        }
        console.log(toJS(res))
        return res
    },
    getCreditsToUse() {
        // console.log(this.props.modeEnvoi)
        // const labelPrix = modeEnvoiDetails[this.props.modeEnvoi].prixLabel
        // if (this.props.modeEnvoi == 'sms') {
        //     const nbSmsToSend = this.getNbSmsToSend()
        //     return (nbSmsToSend * this.props.prixCom[labelPrix])
        // }
        // const totalDest = this.getTotalDestinatiares()
        // return (totalDest * this.props.prixCom[labelPrix])
        const labelPrix = modeEnvoiDetails[this.props.modeEnvoi].prixLabel
        if (this.props.modeEnvoi == 'sms') {
            const nbSmsToSend = this.getNbSmsToSend()
            return nbSmsToSend
        }
        const totalDest = this.getTotalDestinatiares()
        return totalDest
    },
    getCreditsActuels() {
        const labelPrix = modeEnvoiDetails[this.props.modeEnvoi].prixLabel
        return parseInt(this.props.credits.nbSMS / this.props.prixCom[labelPrix])
    },
    getCreditsAfter() {
        const creditsToUse = this.getCreditsToUse()
        const crerditActuels = this.getCreditsActuels()
        return crerditActuels - creditsToUse
    },
    async toggleModalPayment() {
        if (this.props.isOpenModalPayment == false) {
            this.doLoadingPayment(true, 'Chargement en cours ...')
            await this.getTarifs()
            if (this.props.tarifs.length)
                this.props.isOpenModalPayment = !this.props.isOpenModalPayment

            this.doLoadingPayment(false)
        } else {
            this.props.isOpenModalPayment = !this.props.isOpenModalPayment
        }
    },
    setMsg(msg) {
        this.props.msg = msg
    },
    async getClients() {

        const idRes = UserStore.props.user.idRes
        const idCom = UserStore.props.user.idCom
        // this.props.loading.msg = 'Chargement des données'
        // this.props.loading.status = true
        this.props.sortBy = this.props.sortBy ? this.props.sortBy : 'idClientFid'
        this.props.sortOrder = this.props.sortOrder ? this.props.sortOrder : 'ASC'
        let clause = ''
        if (!UserStore.isWallet()) {
            if (this.props.modeEnvoi == "sms")
                clause = " AND LENGTH(clientGsm) > 8 "
            else if (this.props.modeEnvoi == "email")
                clause = " AND LENGTH(clientEmail) > 5 "
        }

        let where = ''

        if (UserStore.isReseau())
            where = ClientFilterStore.getWhere('clientfid.iDreseauFid = ' +
                idRes + ' AND clientTypeCarte<> 6  '
                + ' AND clientAdresseIncorrect=0 '
                + clause)
        else
            where = ClientFilterStore.getWhere('clientfid.idcommercantFid = ' +
                idCom + ' AND clientTypeCarte<> 6 '
                + ' AND clientAdresseIncorrect=0 '
                + clause)

        //Pour WALLET
        if (UserStore.isWallet()) {
            if (UserStore.isReseau())

                where = ClientFilterStore.getWhere('clientfid.iDreseauFid = ' +
                    idRes + ' AND clientTypeCarte<> 6  '
                    + ' AND clientAdresseIncorrect=0 AND  clientCardDesactive  = 0 AND clientNumCard is not null AND clientPME2 >= 0'
                    + clause)
            else
                where = ClientFilterStore.getWhere('clientfid.idcommercantFid = ' +
                    idCom + ' AND clientTypeCarte<> 6 '
                    + ' AND clientAdresseIncorrect=0 AND  clientCardDesactive  = 0 AND clientNumCard is not null AND clientPME2 >= 0'
                    + clause)
        }
        ///END POUR wALLET
        let data = {
            'objName': 'gridCartes',
            'cmd': 'getRowsDest',
            'where': where,
            'msg': this.props.msg,
            'modeEnvoi': this.props.modeEnvoi,
            'start': this.props.pageStart,
            'limit': this.props.pageLimit,
            'sort': this.props.sortBy,
            'dir': this.props.sortOrder,
            // 'query': JSON.stringify(where)
        }

        this.props.whereClients = where

        const res = await api.post('/scripts/clients/clients.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.data = !res.data.rows ? [] : res.data.rows
                this.props.pageTotal = res.data.totalCount
                this.props.pageCount = Math.ceil(res.data.totalCount / this.props.pageLimit)
                if (this.props.modeEnvoi == 'sms'){
                    this.props.nbSmsToSend = parseInt(res.data.nb_sms_to_send)
                    this.props.oneSmsLength = parseInt(res.data.taille_sms)
                }

            } else {
                this.props.data = []
            }
        }
        // this.props.loading.status = false
    },
    async persistModele(nom_modele, json, contenu) {
        this.doLoadingModele(true, 'Chargement en cours...')
        const idUser = UserStore.props.user.idUser

        let data = {
            objName: 'modele_sms',
            cmd: 'saveRows',
            data: JSON.stringify({
                newRecord: true,
                usersfid_id: idUser,
                dateCreation: moment().format('YYYY-MM-DD'),
                type: 2,
                contenu: contenu,
                nom_modele: nom_modele,
                json: json
            })
        }

        const res = await api.post('/scripts/campagne/modeleEmail.php', data)
        if (res.data.success) {
            toast.success('Votre modèle a bien été sauvegardé')
        }
        else {
            toast.error("Votre modèle n'a pas été sauvegardé")
        }
        this.doLoadingModele(false)
    },
    async getModeleEmail() {
        this.doLoadingModele(true, 'Chargement en cours...')
        const idUser = UserStore.props.user.idUser
        let data = {
            'objName': 'modele_sms',
            'cmd': 'getRows',
            'start': this.props.pageStartModele,
            'limit': this.props.pageLimitModele,
            'sort': this.props.sortByModele,
            'dir': this.props.sortOrderModele,
            where: ' usersfid_id = ' + idUser + ' AND type= ' + modeEnvoiDetails[this.props.modeEnvoi].index
            // 'query': JSON.stringify(where)
        }

        const res = await api.post('/scripts/campagne/modeleEmail.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.dataModele = !res.data.rows ? [] : res.data.rows
                this.props.pageTotalModele = res.data.totalCount
                this.props.pageCountModele = Math.ceil(res.data.totalCount / this.props.pageLimitModele)

            } else {
                this.props.dataModele = []
            }
        }
        this.doLoadingModele(false)
    },
    async getModeleEmailPre() {
        this.doLoadingModele(true, 'Chargement en cours...')

        let data = {
            'objName': 'modele_sms_predefini',
            'cmd': 'getRows',
            'start': this.props.pageStartModelePre,
            'limit': this.props.pageLimitModelePre,
            'sort': this.props.sortByModelePre,
            'dir': this.props.sortOrderModelePre,
            where: ' type= ' + modeEnvoiDetails[this.props.modeEnvoi].index
            // 'query': JSON.stringify(where)
        }

        const res = await api.post('/scripts/campagne/modeleEmail.php', data)
        if (res.data.success) {
            if (res.data.rows) {
                this.props.dataModelePre = !res.data.rows ? [] : res.data.rows
                this.props.pageTotalModelePre = res.data.totalCount
                this.props.pageCountModelePre = Math.ceil(res.data.totalCount / this.props.pageLimitModelePre)

            } else {
                this.props.dataModelePre = []
            }
        }
        this.doLoadingModele(false)
    },
    toggleModalBuilder(editorType = null) {
        console.log('toggle BUILDER type = ' + editorType)
        if (editorType)
            this.props.preview.editorType = editorType
        this.props.isOpenModalBuilder = !this.props.isOpenModalBuilder
    },
    toggleModalCreditInsuffisant() {
        console.log('toggle CREDITS')
        this.props.isOpenCreditInsuffisant = !this.props.isOpenCreditInsuffisant
    },
    toggleModalSMS() {
        console.log('toggle SMS')
        this.props.isOpenModalSMS = !this.props.isOpenModalSMS
    },
    toggleModalLoad() {
        console.log('toggle LOAD')
        this.props.isOpenModalLoad = !this.props.isOpenModalLoad
    },
    getValidationSchema() {
        return this.props.titles[this.props.currStep].validationSchema
    },
    previewSwitch() {
        this.props.previewIsDesktop = !this.props.previewIsDesktop
    },
    nextStep() {
        const creditAfter = this.getCreditsAfter()
        if (this.props.titles[this.props.currStep].title == 'Destinataires' && creditAfter < 0) {
            return this.toggleModalCreditInsuffisant()
        }
        this.props.currStep++;
    },
    prevStep() {
        this.props.currStep--;
    },
    addFilter() {
        this.props.initialFilterValues.push({ ...this.props.patternFilter.clientNom.default })
        console.log(toJS(this.props.initialFilterValues))
    },
    changeFilterType(name, index) {
        console.log(toJS(this.props.initialFilterValues))
        console.log('index : ', index)
        this.props.initialFilterValues[index] = this.props.patternFilter[name].default
        console.log(toJS(this.props.initialFilterValues[index]))
        console.log(toJS(this.props.initialFilterValues))
    },
    changeFilterOperator(value, index) {
        if (value == 'between') {
            this.props.initialFilterValues[index].value = [null, null]
        }
        return this.props.initialFilterValues[index].operator = value
    },

    changeFilterValue(value, index) {
        console.log(value)
        return this.props.initialFilterValues[index].value = value
    },

    changeFilterValueMultiple(value, index) {

        let position = this.props.initialFilterValues[index].value.findIndex(o => o == value);
        console.log('position :', position)
        if (position === -1)
            this.props.initialFilterValues[index].value = [...this.props.initialFilterValues[index].value, value]
        else
            this.props.initialFilterValues[index].value = [...this.props.initialFilterValues[index].value.filter(item => item != value)]

    },

    changeFilterValueBetween(value, index, order) {
        return this.props.initialFilterValues[index].value[order] = value
    },

    getListFilter() {
        return Object.keys(this.props.patternFilter)
            .map(item => ({ id: this.props.patternFilter[item].name, label: this.props.patternFilter[item].title }))
    },
    getBadgeColor(value) {
        const bg_colors = ['bg-danger', 'bg-warning', 'bg-lightblue', 'bg-info', 'bg-success', 'bg-secondary']

        if (value < 2)
            return bg_colors[0]
        if (value < 4)
            return bg_colors[1]
        if (value < 6)
            return bg_colors[2]
        if (value < 8)
            return bg_colors[3]
        if (value <= 10)
            return bg_colors[4]

        return bg_colors[5];
    },
    setUnitsHeader(colId) {

        if (colId == 'Pme1')
            return 'Solde (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'Pme2')
            return 'Solde (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumDebPme2')
            return 'Debits (' + UserStore.props.user.unitesPME2court + ')'
        if (colId == 'cumCredPme1')
            return 'Cumul (' + UserStore.props.user.unitesPME1court + ')'
        if (colId == 'cumCredPme2')
            return 'Cumul (' + UserStore.props.user.unitesPME2court + ')'
        //-----//

        return 'Vide'

    },
    async setSortModele(value) {
        this.doLoadingModele(true, 'Chargement en cours ...')
        if (this.props.sortByModele != value) {
            this.props.sortByModele = value
            this.props.sortOrderModele = 'ASC'
        }
        else {
            if (this.props.sortOrderModele == 'ASC')
                this.props.sortOrderModele = 'DESC'
            else if (this.props.sortOrderModele == 'DESC') {
                this.props.sortOrderModele = null
                this.props.sortByModele = null

            }
        }

        await this.getModeleEmail()
        this.doLoadingModele(false)
        // console.log(value, this.props.sortOrder)
    },
    async nextPageModele() {
        this.props.pageIndexModele = this.props.pageIndexModele + 1
        this.props.pageStartModele = this.props.pageIndexModele * this.props.pageLimitModele
        this.doLoadingModele(true, 'Chargement en cours ...')
        await this.getModeleEmail()
        this.doLoadingModele(false)
    },
    async prevPageModele() {
        this.props.pageIndexModele = this.props.pageIndexModele - 1
        this.props.pageStartModele = this.props.pageIndexModele * this.props.pageLimitModele
        this.doLoadingModele(true, 'Chargement en cours ...')
        await this.getModeleEmail()
        this.doLoadingModele(false)
    },
    async goToPageModele(page) {
        this.props.pageIndexModele = page
        this.props.pageStartModele = this.props.pageIndexModele * this.props.pageLimitModele
        this.doLoadingModele(true, 'Chargement en cours ...')
        await this.getModeleEmail()
        this.doLoadingModele(false)
    },
    async setSortModelePre(value) {
        this.doLoadingModele(true, 'Chargement en cours ...')
        if (this.props.sortByModele != value) {
            this.props.sortByModele = value
            this.props.sortOrderModele = 'ASC'
        }
        else {
            if (this.props.sortOrderModele == 'ASC')
                this.props.sortOrderModele = 'DESC'
            else if (this.props.sortOrderModele == 'DESC') {
                this.props.sortOrderModele = false
                this.props.sortByModele = null

            }
        }

        await this.getModeleEmailPre()
        this.doLoadingModele(false)
        // console.log(value, this.props.sortOrder)
    },
    async nextPageModelePre() {
        this.props.pageIndexModele = this.props.pageIndexModele + 1
        this.props.pageStartModele = this.props.pageIndexModele * this.props.pageLimitModele
        this.doLoadingModele(true, 'Chargement en cours ...')
        await this.getModeleEmailPre()
        this.doLoadingModele(false)
    },
    async prevPageModelePre() {
        this.props.pageIndexModele = this.props.pageIndexModele - 1
        this.props.pageStartModele = this.props.pageIndexModele * this.props.pageLimitModele
        this.doLoadingModele(true, 'Chargement en cours ...')
        await this.getModeleEmailPre()
        this.doLoadingModele(false)
    },
    async goToPageModelePre(page) {
        this.props.pageIndexModele = page
        this.props.pageStartModele = this.props.pageIndexModele * this.props.pageLimitModele
        this.doLoadingModele(true, 'Chargement en cours ...')
        await this.getModeleEmailPre()
        this.doLoadingModele(false)
    },
    async setSort(value) {
        this.doLoading(true, 'Chargement en cours ...')
        if (this.props.sortBy != value) {
            this.props.sortBy = value
            this.props.sortOrder = 'ASC'
        }
        else {
            if (this.props.sortOrder == 'ASC')
                this.props.sortOrder = 'DESC'
            else if (this.props.sortOrder == 'DESC') {
                this.props.sortOrder = false
                this.props.sortBy = null

            }
        }

        await this.getClients()
        this.doLoading(false)
        // console.log(value, this.props.sortOrder)
    },
    async nextPage() {
        this.props.pageIndex = this.props.pageIndex + 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getClients()
        this.doLoading(false)
    },
    async prevPage() {
        this.props.pageIndex = this.props.pageIndex - 1
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getClients()
        this.doLoading(false)
    },
    async goToPage(page) {
        this.props.pageIndex = page
        this.props.pageStart = this.props.pageIndex * this.props.pageLimit
        this.doLoading(true, 'Chargement en cours ...')
        await this.getClients()
        this.doLoading(false)
    },
    reset() {
        this.props = props
    }
}

export default observable(CampagneWizardStore)

