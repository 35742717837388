import React from 'react'
import Iframe from 'react-iframe'



const NovacardView = (props) => {
    return (
        <>
            {/* <div className="container-fluid"> */}
                {/* <h2 className="card-title">Tableau de bord</h2> */}
                <div className="animated fadeIn" style={{ height: 'calc(100vh - 70px)' }}>
                    <Iframe
                        url={process.env['REACT_APP_OLD_ADMIN_URL']}
                        width="100%"
                        height="100%"
                        display="initial"
                        position="relative"
                        frameBorder={0}
                        styles={{border : '0px'}}
                        allowFullScreen />

                </div>
            {/* </div> */}
        </>

    )
}

export default NovacardView